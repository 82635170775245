@charset "UTF-8";
.form-control:hover {
  -webkit-box-shadow: inset 0 0 0 1px #000;
  box-shadow: inset 0 0 0 1px #000;
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 100;
  font-display: auto;
  src: url("./fonts/Inter/Inter-Thin.woff2?v=3.15") format("woff2"), url("./fonts/Inter/Inter-Thin.woff?v=3.15") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 100;
  font-display: auto;
  src: url("./fonts/Inter/Inter-ThinItalic.woff2?v=3.15") format("woff2"), url("./fonts/Inter/Inter-ThinItalic.woff?v=3.15") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 200;
  font-display: auto;
  src: url("./fonts/Inter/Inter-ExtraLight.woff2?v=3.15") format("woff2"), url("./fonts/Inter/Inter-ExtraLight.woff?v=3.15") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 200;
  font-display: auto;
  src: url("./fonts/Inter/Inter-ExtraLightItalic.woff2?v=3.15") format("woff2"), url("./fonts/Inter/Inter-ExtraLightItalic.woff?v=3.15") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-display: auto;
  src: url("./fonts/Inter/Inter-Light.woff2?v=3.15") format("woff2"), url("./fonts/Inter/Inter-Light.woff?v=3.15") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 300;
  font-display: auto;
  src: url("./fonts/Inter/Inter-LightItalic.woff2?v=3.15") format("woff2"), url("./fonts/Inter/Inter-LightItalic.woff?v=3.15") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url("./fonts/Inter/Inter-Regular.woff2?v=3.15") format("woff2"), url("./fonts/Inter/Inter-Regular.woff?v=3.15") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 400;
  font-display: auto;
  src: url("./fonts/Inter/Inter-Italic.woff2?v=3.15") format("woff2"), url("./fonts/Inter/Inter-Italic.woff?v=3.15") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-display: auto;
  src: url("./fonts/Inter/Inter-Medium.woff2?v=3.15") format("woff2"), url("./fonts/Inter/Inter-Medium.woff?v=3.15") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 500;
  font-display: auto;
  src: url("./fonts/Inter/Inter-MediumItalic.woff2?v=3.15") format("woff2"), url("./fonts/Inter/Inter-MediumItalic.woff?v=3.15") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-display: auto;
  src: url("./fonts/Inter/Inter-SemiBold.woff2?v=3.15") format("woff2"), url("./fonts/Inter/Inter-SemiBold.woff?v=3.15") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 600;
  font-display: auto;
  src: url("./fonts/Inter/Inter-SemiBoldItalic.woff2?v=3.15") format("woff2"), url("./fonts/Inter/Inter-SemiBoldItalic.woff?v=3.15") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-display: auto;
  src: url("./fonts/Inter/Inter-Bold.woff2?v=3.15") format("woff2"), url("./fonts/Inter/Inter-Bold.woff?v=3.15") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 700;
  font-display: auto;
  src: url("./fonts/Inter/Inter-BoldItalic.woff2?v=3.15") format("woff2"), url("./fonts/Inter/Inter-BoldItalic.woff?v=3.15") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  font-display: auto;
  src: url("./fonts/Inter/Inter-ExtraBold.woff2?v=3.15") format("woff2"), url("./fonts/Inter/Inter-ExtraBold.woff?v=3.15") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 800;
  font-display: auto;
  src: url("./fonts/Inter/Inter-ExtraBoldItalic.woff2?v=3.15") format("woff2"), url("./fonts/Inter/Inter-ExtraBoldItalic.woff?v=3.15") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 900;
  font-display: auto;
  src: url("./fonts/Inter/Inter-Black.woff2?v=3.15") format("woff2"), url("./fonts/Inter/Inter-Black.woff?v=3.15") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 900;
  font-display: auto;
  src: url("./fonts/Inter/Inter-BlackItalic.woff2?v=3.15") format("woff2"), url("./fonts/Inter/Inter-BlackItalic.woff?v=3.15") format("woff");
}
@font-face {
  font-family: "Noto_Sans_JP";
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/NotoSansJP/noto-sans-jp-v28-japanese-regular.eot");
  /* IE9 Compat Modes */
  src: local(""), url("./fonts/NotoSansJP/noto-sans-jp-v28-japanese-regular.eot?#iefix") format("embedded-opentype"), url("./fonts/NotoSansJP/noto-sans-jp-v28-japanese-regular.woff2") format("woff2"), url("./fonts/NotoSansJP/noto-sans-jp-v28-japanese-regular.woff") format("woff"), url("./fonts/NotoSansJP/noto-sans-jp-v28-japanese-regular.otf") format("opentype"), url("./fonts/NotoSansJP/noto-sans-jp-v28-japanese-regular.svg#NotoSansJP") format("svg");
  /* Legacy iOS */
}
@font-face {
  font-family: "Noto_Sans_JP";
  font-display: auto;
  font-style: normal;
  font-weight: 300;
  src: url("./fonts/NotoSansJP/noto-sans-jp-v28-japanese-300.eot");
  /* IE9 Compat Modes */
  src: local(""), url("./fonts/NotoSansJP/noto-sans-jp-v28-japanese-300.eot?#iefix") format("embedded-opentype"), url("./fonts/NotoSansJP/noto-sans-jp-v28-japanese-300.woff2") format("woff2"), url("./fonts/NotoSansJP/noto-sans-jp-v28-japanese-300.woff") format("woff"), url("./fonts/NotoSansJP/noto-sans-jp-v28-japanese-300.otf") format("opentype"), url("./fonts/NotoSansJP/noto-sans-jp-v28-japanese-300.svg#NotoSansJP") format("svg");
  /* Legacy iOS */
}
@font-face {
  font-family: "Noto_Sans_JP";
  font-display: auto;
  font-style: normal;
  font-weight: 500;
  src: url("./fonts/NotoSansJP/noto-sans-jp-v28-japanese-500.eot");
  /* IE9 Compat Modes */
  src: local(""), url("./fonts/NotoSansJP/noto-sans-jp-v28-japanese-500.eot?#iefix") format("embedded-opentype"), url("./fonts/NotoSansJP/noto-sans-jp-v28-japanese-500.woff2") format("woff2"), url("./fonts/NotoSansJP/noto-sans-jp-v28-japanese-500.woff") format("woff"), url("./fonts/NotoSansJP/noto-sans-jp-v28-japanese-500.otf") format("opentype"), url("./fonts/NotoSansJP/noto-sans-jp-v28-japanese-500.svg#NotoSansJP") format("svg");
  /* Legacy iOS */
}
@font-face {
  font-family: "Noto_Sans_JP";
  font-display: auto;
  font-style: normal;
  font-weight: 700;
  src: url("./fonts/NotoSansJP/noto-sans-jp-v28-japanese-700.eot");
  /* IE9 Compat Modes */
  src: local(""), url("./fonts/NotoSansJP/noto-sans-jp-v28-japanese-700.eot?#iefix") format("embedded-opentype"), url("./fonts/NotoSansJP/noto-sans-jp-v28-japanese-700.woff2") format("woff2"), url("./fonts/NotoSansJP/noto-sans-jp-v28-japanese-700.woff") format("woff"), url("./fonts/NotoSansJP/noto-sans-jp-v28-japanese-700.otf") format("opentype"), url("./fonts/NotoSansJP/noto-sans-jp-v28-japanese-700.svg#NotoSansJP") format("svg");
  /* Legacy iOS */
}
@font-face {
  font-family: "Noto_Sans_JP";
  font-display: auto;
  font-style: normal;
  font-weight: 900;
  src: url("./fonts/NotoSansJP/noto-sans-jp-v28-japanese-900.eot");
  /* IE9 Compat Modes */
  src: local(""), url("./fonts/NotoSansJP/noto-sans-jp-v28-japanese-900.eot?#iefix") format("embedded-opentype"), url("./fonts/NotoSansJP/noto-sans-jp-v28-japanese-900.woff2") format("woff2"), url("./fonts/NotoSansJP/noto-sans-jp-v28-japanese-900.woff") format("woff"), url("./fonts/NotoSansJP/noto-sans-jp-v28-japanese-900.otf") format("opentype"), url("./fonts/NotoSansJP/noto-sans-jp-v28-japanese-900.svg#NotoSansJP") format("svg");
  /* Legacy iOS */
}
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #000;
  --secondary: #6e777a;
  --success: #00aa0c;
  --info: #2469ff;
  --warning: #da7400;
  --danger: #dd3724;
  --light: #c4c8ca;
  --dark: #414949;
  --white: white;
  --black: #231f20;
  --socgen: #e60028;
  --primary-alt: #fff;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --breakpoint-xxl: 1600px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

:root {
  --apricot: #e88e5e;
  --azure: #38699f;
  --cornflower-blue: #6488ff;
  --faded-jade: #408182;
  --heather: #b4bbcb;
  --mako: #444852;
  --puerto-rico: #41c49d;
  --royal-blue: #5c56e8;
  --spicy-mix: #854b4b;
  --valencia: #dc4941;
  --victoria: #514b90;
  --mars: #d53229;
  --mercury: #e8e8e8;
  --manatee: #8b93a8;
  --dodger-blue: #5d55ff;
  --pacific-blue: #00b4bb;
  --turquoise-blue: #68efad;
  --buttercup: #f6a416;
  --copper-rust: #974a5a;
  --east-bay: #4a5885;
  --plantation: #275855;
}

:root {
  --bg-lvl1: #fff;
  --bg-lvl2: #f5f6f7;
  --bg-lvl3: #eceeef;
  --bg-lvl4: #e3e6e7;
  --bg-alt-lvl1: #303333;
  --bg-alt-lvl2: #222424;
  --bg-alt-lvl3: #151616;
  --bg-alt-lvl4: #000;
}

*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(35, 31, 32, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.4285714286;
  color: #000;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #6e777a;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #000;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.875rem;
  padding-bottom: 0.875rem;
  color: rgba(0, 0, 0, 0.4);
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

body {
  font-family: "Inter", "Noto_Sans_JP", sans-serif;
}

a:not(.text-link) {
  font-weight: 500;
}
a.text-link {
  color: #000;
  text-decoration: none;
  text-underline-position: under;
  text-decoration: underline;
}
a.text-link:hover:not(:disabled):not(.disabled) {
  background-color: rgba(0, 0, 0, 0.08);
}
a.text-link:not(:disabled):not(.disabled):active, a.text-link:not(:disabled):not(.disabled).active {
  background-color: rgba(0, 0, 0, 0.15);
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.25rem;
}

h2, .h2 {
  font-size: 1.875rem;
}

h3, .h3 {
  font-size: 1.5rem;
}

h4, .h4 {
  font-size: 1.3125rem;
}

h5, .h5 {
  font-size: 1.125rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 7rem;
  font-weight: 700;
  line-height: 1.2;
}

.display-2 {
  font-size: 4.25rem;
  font-weight: 700;
  line-height: 1.2;
}

.display-3 {
  font-size: 2.625rem;
  font-weight: 700;
  line-height: 1.2;
}

.display-4 {
  font-size: 1.625rem;
  font-weight: 700;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(196, 200, 202, 0.4);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.625rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: rgba(0, 0, 0, 0.4);
}
.blockquote-footer::before {
  content: "— ";
}

* {
  -webkit-font-variant-ligatures: none;
  font-variant-ligatures: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: 700;
}

h1,
.h1 {
  line-height: 1;
  letter-spacing: -0.03em;
}

h2,
.h2 {
  line-height: 1.0666666667;
  letter-spacing: -0.025em;
}

h3,
.h3 {
  line-height: 1.1666666667;
  letter-spacing: -0.02em;
}

h4,
.h4 {
  line-height: 1.1428571429;
  letter-spacing: -0.015em;
}

h5,
.h5 {
  line-height: 1.1111111111;
  letter-spacing: -0.01em;
}

h6,
.h6 {
  line-height: 1;
  letter-spacing: -0.01em;
}

.bg-lvl4 .display-5.text-success,
.bg-lvl4 .h4.text-success,
.bg-lvl4 h4.text-success,
.bg-lvl4 .h5.text-success,
.bg-lvl4 h5.text-succes {
  color: #006b07 !important;
}
.bg-lvl4 .display-5.text-warning,
.bg-lvl4 .h4.text-warning,
.bg-lvl4 h4.text-warning,
.bg-lvl4 .h5.text-warning,
.bg-lvl4 h5.text-warning {
  color: #934f00 !important;
}
.bg-lvl4 .display-5.text-danger,
.bg-lvl4 .h4.text-danger,
.bg-lvl4 h4.text-danger,
.bg-lvl4 .h5.text-danger,
.bg-lvl4 h5.text-danger {
  color: #c22f1f !important;
}

.bg-lvl3 .display-5.text-success,
.bg-lvl3 .h4.text-success,
.bg-lvl3 h4.text-success,
.bg-lvl3 .h5.text-success,
.bg-lvl3 h5.text-succes {
  color: #007508 !important;
}
.bg-lvl3 .display-5.text-warning,
.bg-lvl3 .h4.text-warning,
.bg-lvl3 h4.text-warning,
.bg-lvl3 .h5.text-warning,
.bg-lvl3 h5.text-warning {
  color: #9d5400 !important;
}
.bg-lvl3 .display-5.text-danger,
.bg-lvl3 .h4.text-danger,
.bg-lvl3 h4.text-danger,
.bg-lvl3 .h5.text-danger,
.bg-lvl3 h5.text-danger {
  color: #cb3120 !important;
}

.bg-lvl2 .display-5.text-success,
.bg-lvl2 .h4.text-success,
.bg-lvl2 h4.text-success,
.bg-lvl2 .h5.text-success,
.bg-lvl2 h5.text-succes {
  color: #007f09 !important;
}
.bg-lvl2 .display-5.text-warning,
.bg-lvl2 .h4.text-warning,
.bg-lvl2 h4.text-warning,
.bg-lvl2 .h5.text-warning,
.bg-lvl2 h5.text-warning {
  color: #a75900 !important;
}
.bg-lvl2 .display-5.text-danger,
.bg-lvl2 .h4.text-danger,
.bg-lvl2 h4.text-danger,
.bg-lvl2 .h5.text-danger,
.bg-lvl2 h5.text-danger {
  color: #d43321 !important;
}

.bg-lvl1 .display-5.text-success,
.bg-lvl1 .h4.text-success,
.bg-lvl1 h4.text-success,
.bg-lvl1 .h5.text-success,
.bg-lvl1 h5.text-succes {
  color: #008b0a !important;
}
.bg-lvl1 .display-5.text-warning,
.bg-lvl1 .h4.text-warning,
.bg-lvl1 h4.text-warning,
.bg-lvl1 .h5.text-warning,
.bg-lvl1 h5.text-warning {
  color: #b15e00 !important;
}
.bg-lvl1 .display-5.text-danger,
.bg-lvl1 .h4.text-danger,
.bg-lvl1 h4.text-danger,
.bg-lvl1 .h5.text-danger,
.bg-lvl1 h5.text-danger {
  color: #dd3724 !important;
}

.bg-alt-lvl4 .display-5.text-success,
.bg-alt-lvl4 .h4.text-success,
.bg-alt-lvl4 h4.text-success,
.bg-alt-lvl4 .h5.text-success,
.bg-alt-lvl4 h5.text-succes {
  color: #00a83e !important;
}
.bg-alt-lvl4 .display-5.text-warning,
.bg-alt-lvl4 .h4.text-warning,
.bg-alt-lvl4 h4.text-warning,
.bg-alt-lvl4 .h5.text-warning,
.bg-alt-lvl4 h5.text-warning {
  color: #cc7400 !important;
}
.bg-alt-lvl4 .display-5.text-danger,
.bg-alt-lvl4 .h4.text-danger,
.bg-alt-lvl4 h4.text-danger,
.bg-alt-lvl4 .h5.text-danger,
.bg-alt-lvl4 h5.text-danger {
  color: #db4e33 !important;
}

.bg-alt-lvl3 .display-5.text-success,
.bg-alt-lvl3 .h4.text-success,
.bg-alt-lvl3 h4.text-success,
.bg-alt-lvl3 .h5.text-success,
.bg-alt-lvl3 h5.text-succes {
  color: #00b844 !important;
}
.bg-alt-lvl3 .display-5.text-warning,
.bg-alt-lvl3 .h4.text-warning,
.bg-alt-lvl3 h4.text-warning,
.bg-alt-lvl3 .h5.text-warning,
.bg-alt-lvl3 h5.text-warning {
  color: #db7c00 !important;
}
.bg-alt-lvl3 .display-5.text-danger,
.bg-alt-lvl3 .h4.text-danger,
.bg-alt-lvl3 h4.text-danger,
.bg-alt-lvl3 .h5.text-danger,
.bg-alt-lvl3 h5.text-danger {
  color: #ea563a !important;
}

.bg-alt-lvl2 .display-5.text-success,
.bg-alt-lvl2 .h4.text-success,
.bg-alt-lvl2 h4.text-success,
.bg-alt-lvl2 .h5.text-success,
.bg-alt-lvl2 h5.text-succes {
  color: #00c74a !important;
}
.bg-alt-lvl2 .display-5.text-warning,
.bg-alt-lvl2 .h4.text-warning,
.bg-alt-lvl2 h4.text-warning,
.bg-alt-lvl2 .h5.text-warning,
.bg-alt-lvl2 h5.text-warning {
  color: #eb8500 !important;
}
.bg-alt-lvl2 .display-5.text-danger,
.bg-alt-lvl2 .h4.text-danger,
.bg-alt-lvl2 h4.text-danger,
.bg-alt-lvl2 .h5.text-danger,
.bg-alt-lvl2 h5.text-danger {
  color: #f56043 !important;
}

.bg-alt-lvl1 .display-5.text-success,
.bg-alt-lvl1 .h4.text-success,
.bg-alt-lvl1 h4.text-success,
.bg-alt-lvl1 .h5.text-success,
.bg-alt-lvl1 h5.text-succes {
  color: #00d750 !important;
}
.bg-alt-lvl1 .display-5.text-warning,
.bg-alt-lvl1 .h4.text-warning,
.bg-alt-lvl1 h4.text-warning,
.bg-alt-lvl1 .h5.text-warning,
.bg-alt-lvl1 h5.text-warning {
  color: #f98d00 !important;
}
.bg-alt-lvl1 .display-5.text-danger,
.bg-alt-lvl1 .h4.text-danger,
.bg-alt-lvl1 h4.text-danger,
.bg-alt-lvl1 .h5.text-danger,
.bg-alt-lvl1 h5.text-danger {
  color: #ff694b !important;
}

.lead {
  line-height: 1.3333333333;
}

.display-number {
  font-weight: 400;
}

.display-1,
.display-2,
.display-3,
.display-4,
.display-5 {
  font-weight: 800;
}

.display-1 {
  line-height: 1;
  letter-spacing: -0.04em;
}

.display-2 {
  line-height: 1.0588235294;
  letter-spacing: -0.035em;
}

.display-3 {
  line-height: 1.0476190476;
  letter-spacing: -0.03em;
}

.display-4 {
  line-height: 1.0769230769;
  letter-spacing: -0.025em;
}

.display-5 {
  font-size: 1.125rem;
  letter-spacing: -0.02em;
  line-height: 1.1111111111;
}

.blockquote {
  font-weight: 500;
  line-height: 1.3333333333;
}

.blockquote-footer {
  font-size: 1rem;
  line-height: 2.1666666667rem;
}

.line-height-1 {
  line-height: 1 !important;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #c43275;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1600px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1440px;
  }
}
.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -12px;
  margin-left: -12px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xxl,
.col-xxl-auto, .col-xxl-12, .col-xxl-11, .col-xxl-10, .col-xxl-9, .col-xxl-8, .col-xxl-7, .col-xxl-6, .col-xxl-5, .col-xxl-4, .col-xxl-3, .col-xxl-2, .col-xxl-1, .col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
}

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.3333333333%;
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 16.6666666667%;
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 8.3333333333%;
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 16.6666666667%;
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.3333333333%;
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 41.6666666667%;
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 58.3333333333%;
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 66.6666666667%;
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 83.3333333333%;
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 91.6666666667%;
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  -webkit-box-ordinal-group: 0;
  -ms-flex-order: -1;
  order: -1;
}

.order-last {
  -webkit-box-ordinal-group: 14;
  -ms-flex-order: 13;
  order: 13;
}

.order-0 {
  -webkit-box-ordinal-group: 1;
  -ms-flex-order: 0;
  order: 0;
}

.order-1 {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
}

.order-2 {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
}

.order-3 {
  -webkit-box-ordinal-group: 4;
  -ms-flex-order: 3;
  order: 3;
}

.order-4 {
  -webkit-box-ordinal-group: 5;
  -ms-flex-order: 4;
  order: 4;
}

.order-5 {
  -webkit-box-ordinal-group: 6;
  -ms-flex-order: 5;
  order: 5;
}

.order-6 {
  -webkit-box-ordinal-group: 7;
  -ms-flex-order: 6;
  order: 6;
}

.order-7 {
  -webkit-box-ordinal-group: 8;
  -ms-flex-order: 7;
  order: 7;
}

.order-8 {
  -webkit-box-ordinal-group: 9;
  -ms-flex-order: 8;
  order: 8;
}

.order-9 {
  -webkit-box-ordinal-group: 10;
  -ms-flex-order: 9;
  order: 9;
}

.order-10 {
  -webkit-box-ordinal-group: 11;
  -ms-flex-order: 10;
  order: 10;
}

.order-11 {
  -webkit-box-ordinal-group: 12;
  -ms-flex-order: 11;
  order: 11;
}

.order-12 {
  -webkit-box-ordinal-group: 13;
  -ms-flex-order: 12;
  order: 12;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-sm-1 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-sm-2 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-sm-3 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.3333333333%;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-sm-5 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-sm-6 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.6666666667%;
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.3333333333%;
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-sm-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.6666666667%;
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.3333333333%;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-sm-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.6666666667%;
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-sm-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.3333333333%;
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-sm-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.6666666667%;
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-sm-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.3333333333%;
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-sm-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.6666666667%;
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-sm-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }

  .order-sm-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }

  .order-sm-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }

  .order-sm-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }

  .order-sm-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }

  .order-sm-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }

  .order-sm-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }

  .order-sm-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }

  .order-sm-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }

  .order-sm-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }

  .order-sm-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }

  .order-sm-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }

  .order-sm-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }

  .order-sm-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }

  .order-sm-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }

  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }

  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }

  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }

  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-md-1 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-md-2 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-md-3 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.3333333333%;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-md-5 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-md-6 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.6666666667%;
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-md-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.3333333333%;
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-md-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.6666666667%;
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.3333333333%;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-md-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.6666666667%;
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-md-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.3333333333%;
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-md-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.6666666667%;
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-md-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.3333333333%;
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-md-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.6666666667%;
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-md-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }

  .order-md-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }

  .order-md-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }

  .order-md-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }

  .order-md-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }

  .order-md-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }

  .order-md-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }

  .order-md-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }

  .order-md-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }

  .order-md-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }

  .order-md-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }

  .order-md-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }

  .order-md-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }

  .order-md-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }

  .order-md-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.3333333333%;
  }

  .offset-md-2 {
    margin-left: 16.6666666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333333333%;
  }

  .offset-md-5 {
    margin-left: 41.6666666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333333333%;
  }

  .offset-md-8 {
    margin-left: 66.6666666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333333333%;
  }

  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-lg-1 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-lg-2 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-lg-3 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.3333333333%;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-lg-5 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-lg-6 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.6666666667%;
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.3333333333%;
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-lg-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.6666666667%;
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.3333333333%;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-lg-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.6666666667%;
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-lg-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.3333333333%;
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-lg-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.6666666667%;
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-lg-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.3333333333%;
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-lg-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.6666666667%;
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-lg-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }

  .order-lg-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }

  .order-lg-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }

  .order-lg-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }

  .order-lg-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }

  .order-lg-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }

  .order-lg-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }

  .order-lg-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }

  .order-lg-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }

  .order-lg-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }

  .order-lg-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }

  .order-lg-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }

  .order-lg-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }

  .order-lg-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }

  .order-lg-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }

  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }

  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }

  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }

  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-xl-1 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-xl-2 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-xl-3 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.3333333333%;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-xl-5 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-xl-6 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.6666666667%;
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xl-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.3333333333%;
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-xl-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.6666666667%;
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.3333333333%;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-xl-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.6666666667%;
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-xl-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.3333333333%;
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-xl-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.6666666667%;
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-xl-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.3333333333%;
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-xl-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.6666666667%;
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-xl-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }

  .order-xl-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }

  .order-xl-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }

  .order-xl-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }

  .order-xl-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }

  .order-xl-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }

  .order-xl-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }

  .order-xl-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }

  .order-xl-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }

  .order-xl-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }

  .order-xl-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }

  .order-xl-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }

  .order-xl-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }

  .order-xl-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }

  .order-xl-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }

  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }

  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }

  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }

  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1600px) {
  .col-xxl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-xxl-1 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-xxl-2 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-xxl-3 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.3333333333%;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-xxl-4 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-xxl-5 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-xxl-6 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.6666666667%;
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xxl-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xxl-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.3333333333%;
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-xxl-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.6666666667%;
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xxl-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xxl-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.3333333333%;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-xxl-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.6666666667%;
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-xxl-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xxl-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.3333333333%;
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-xxl-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.6666666667%;
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-xxl-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xxl-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.3333333333%;
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-xxl-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.6666666667%;
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-xxl-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xxl-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }

  .order-xxl-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }

  .order-xxl-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }

  .order-xxl-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }

  .order-xxl-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }

  .order-xxl-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }

  .order-xxl-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }

  .order-xxl-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }

  .order-xxl-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }

  .order-xxl-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }

  .order-xxl-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }

  .order-xxl-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }

  .order-xxl-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }

  .order-xxl-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }

  .order-xxl-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.3333333333%;
  }

  .offset-xxl-2 {
    margin-left: 16.6666666667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.3333333333%;
  }

  .offset-xxl-5 {
    margin-left: 41.6666666667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.3333333333%;
  }

  .offset-xxl-8 {
    margin-left: 66.6666666667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.3333333333%;
  }

  .offset-xxl-11 {
    margin-left: 91.6666666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #000;
}
.table th,
.table td {
  padding: 0.875rem;
  vertical-align: top;
  border-top: 1px solid rgba(196, 200, 202, 0.7);
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid rgba(196, 200, 202, 0.7);
}
.table tbody + tbody {
  border-top: 2px solid rgba(196, 200, 202, 0.7);
}

.table-sm th,
.table-sm td {
  padding: 0.375rem;
}

.table-bordered {
  border: 1px solid rgba(196, 200, 202, 0.7);
}
.table-bordered th,
.table-bordered td {
  border: 1px solid rgba(196, 200, 202, 0.7);
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(35, 31, 32, 0.05);
}

.table-hover tbody tr:hover {
  color: #000;
  background-color: rgba(0, 0, 0, 0.1);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: rgba(184, 184, 184, 0.2);
  color: #b8b8b8;
}

.table-hover .table-primary:hover {
  background-color: #ababab;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #ababab;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: rgba(214, 217, 218, 0.2);
  color: #d6d9da;
}

.table-hover .table-secondary:hover {
  background-color: #c9cdce;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c9cdce;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: rgba(184, 231, 187, 0.2);
  color: #b8e7bb;
}

.table-hover .table-success:hover {
  background-color: #a5e1a9;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #a5e1a9;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: rgba(194, 213, 255, 0.2);
  color: #c2d5ff;
}

.table-hover .table-info:hover {
  background-color: #a9c3ff;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #a9c3ff;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: rgba(245, 216, 184, 0.2);
  color: #f5d8b8;
}

.table-hover .table-warning:hover {
  background-color: #f2cca2;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #f2cca2;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: rgba(245, 199, 194, 0.2);
  color: #f5c7c2;
}

.table-hover .table-danger:hover {
  background-color: #f1b3ac;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b3ac;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: rgba(238, 240, 240, 0.2);
  color: #eef0f0;
}

.table-hover .table-light:hover {
  background-color: #e0e4e4;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #e0e4e4;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: rgba(202, 204, 204, 0.2);
  color: #cacccc;
}

.table-hover .table-dark:hover {
  background-color: #bdbfbf;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #bdbfbf;
}

.table-white,
.table-white > th,
.table-white > td {
  background-color: rgba(255, 255, 255, 0.2);
  color: white;
}

.table-hover .table-white:hover {
  background-color: #f2f2f2;
}
.table-hover .table-white:hover > td,
.table-hover .table-white:hover > th {
  background-color: #f2f2f2;
}

.table-black,
.table-black > th,
.table-black > td {
  background-color: rgba(193, 192, 193, 0.2);
  color: #c1c0c1;
}

.table-hover .table-black:hover {
  background-color: #b4b3b4;
}
.table-hover .table-black:hover > td,
.table-hover .table-black:hover > th {
  background-color: #b4b3b4;
}

.table-socgen,
.table-socgen > th,
.table-socgen > td {
  background-color: rgba(248, 184, 195, 0.2);
  color: #f8b8c3;
}

.table-hover .table-socgen:hover {
  background-color: #f6a1af;
}
.table-hover .table-socgen:hover > td,
.table-hover .table-socgen:hover > th {
  background-color: #f6a1af;
}

.table-primary-alt,
.table-primary-alt > th,
.table-primary-alt > td {
  background-color: rgba(255, 255, 255, 0.2);
  color: white;
}

.table-hover .table-primary-alt:hover {
  background-color: #f2f2f2;
}
.table-hover .table-primary-alt:hover > td,
.table-hover .table-primary-alt:hover > th {
  background-color: #f2f2f2;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.2);
  color: rgba(0, 0, 0, 0.1);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.1);
}

.table .thead-dark th {
  color: #fff;
  background-color: #000;
  border-color: #414949;
}
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: rgba(196, 200, 202, 0.7);
}

.table-dark {
  color: #fff;
  background-color: #000;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #414949;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: #151616;
}
.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: transparent;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1599.98px) {
  .table-responsive-xxl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xxl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}

.table thead th {
  color: #6e777a;
  border-bottom-color: #000;
  border-bottom-width: 1px;
  border-top-color: rgba(196, 200, 202, 0.7);
  font-weight: 500;
}
.table.table-dark thead th {
  border-bottom-color: #fff;
}

.table-hover tbody tr:hover td,
.table-hover tbody tr:hover th {
  border-color: transparent;
}
.table-hover tbody tr.active, .table-hover tbody tr:active {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.table-hover tbody tr.active td,
.table-hover tbody tr.active th, .table-hover tbody tr:active td,
.table-hover tbody tr:active th {
  background: rgba(0, 0, 0, 0.1);
}
.table-hover.table-bordered tbody tr:hover td,
.table-hover.table-bordered tbody tr:hover th {
  border-style: double;
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: rgba(0, 0, 0, 0.2);
  color: black;
}

.table-hover .table-primary:hover {
  background-color: black;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: black;
}

.table-hover-primary tbody tr:hover {
  color: #000;
  background-color: rgba(0, 0, 0, 0.1);
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: rgba(110, 119, 122, 0.2);
  color: #6e777a;
}

.table-hover .table-secondary:hover {
  background-color: #626a6d;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #626a6d;
}

.table-hover-secondary tbody tr:hover {
  color: #6e777a;
  background-color: rgba(110, 119, 122, 0.1);
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: rgba(0, 170, 12, 0.2);
  color: #00aa0c;
}

.table-hover .table-success:hover {
  background-color: #00910a;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #00910a;
}

.table-hover-success tbody tr:hover {
  color: #00aa0c;
  background-color: rgba(0, 170, 12, 0.1);
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: rgba(36, 105, 255, 0.2);
  color: #2469ff;
}

.table-hover .table-info:hover {
  background-color: #0b58ff;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #0b58ff;
}

.table-hover-info tbody tr:hover {
  color: #2469ff;
  background-color: rgba(36, 105, 255, 0.1);
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: rgba(218, 116, 0, 0.2);
  color: #da7400;
}

.table-hover .table-warning:hover {
  background-color: #c16600;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #c16600;
}

.table-hover-warning tbody tr:hover {
  color: #da7400;
  background-color: rgba(218, 116, 0, 0.1);
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: rgba(221, 55, 36, 0.2);
  color: #dd3724;
}

.table-hover .table-danger:hover {
  background-color: #c8301f;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #c8301f;
}

.table-hover-danger tbody tr:hover {
  color: #dd3724;
  background-color: rgba(221, 55, 36, 0.1);
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: rgba(196, 200, 202, 0.2);
  color: #c4c8ca;
}

.table-hover .table-light:hover {
  background-color: #b7bbbe;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #b7bbbe;
}

.table-hover-light tbody tr:hover {
  color: #c4c8ca;
  background-color: rgba(196, 200, 202, 0.1);
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: rgba(65, 73, 73, 0.2);
  color: #414949;
}

.table-hover .table-dark:hover {
  background-color: #353c3c;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #353c3c;
}

.table-hover-dark tbody tr:hover {
  color: #414949;
  background-color: rgba(65, 73, 73, 0.1);
}

.table-white,
.table-white > th,
.table-white > td {
  background-color: rgba(255, 255, 255, 0.2);
  color: white;
}

.table-hover .table-white:hover {
  background-color: #f2f2f2;
}
.table-hover .table-white:hover > td,
.table-hover .table-white:hover > th {
  background-color: #f2f2f2;
}

.table-hover-white tbody tr:hover {
  color: white;
  background-color: rgba(255, 255, 255, 0.1);
}

.table-black,
.table-black > th,
.table-black > td {
  background-color: rgba(35, 31, 32, 0.2);
  color: #231f20;
}

.table-hover .table-black:hover {
  background-color: #151314;
}
.table-hover .table-black:hover > td,
.table-hover .table-black:hover > th {
  background-color: #151314;
}

.table-hover-black tbody tr:hover {
  color: #231f20;
  background-color: rgba(35, 31, 32, 0.1);
}

.table-socgen,
.table-socgen > th,
.table-socgen > td {
  background-color: rgba(230, 0, 40, 0.2);
  color: #e60028;
}

.table-hover .table-socgen:hover {
  background-color: #cd0024;
}
.table-hover .table-socgen:hover > td,
.table-hover .table-socgen:hover > th {
  background-color: #cd0024;
}

.table-hover-socgen tbody tr:hover {
  color: #e60028;
  background-color: rgba(230, 0, 40, 0.1);
}

.table-primary-alt,
.table-primary-alt > th,
.table-primary-alt > td {
  background-color: rgba(255, 255, 255, 0.2);
  color: white;
}

.table-hover .table-primary-alt:hover {
  background-color: #f2f2f2;
}
.table-hover .table-primary-alt:hover > td,
.table-hover .table-primary-alt:hover > th {
  background-color: #f2f2f2;
}

.table-hover-primary-alt tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.4285714286em + 0.75rem);
  padding: 0.375rem 0.625rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.4285714286;
  color: #000;
  background-color: rgba(110, 119, 122, 0.15);
  background-clip: padding-box;
  border: 0rem solid transparent;
  border-radius: 0;
  -webkit-transition: none;
  transition: none;
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}
.form-control:focus {
  color: #000;
  background-color: rgba(110, 119, 122, 0.15);
  border-color: #6e7575;
  outline: 0;
  -webkit-box-shadow: inset 0 0 0 2px #000;
  box-shadow: inset 0 0 0 2px #000;
}
.form-control::-webkit-input-placeholder {
  color: #646d6f;
  opacity: 1;
}
.form-control::-moz-placeholder {
  color: #646d6f;
  opacity: 1;
}
.form-control:-ms-input-placeholder {
  color: #646d6f;
  opacity: 1;
}
.form-control::-ms-input-placeholder {
  color: #646d6f;
  opacity: 1;
}
.form-control::placeholder {
  color: #646d6f;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: transparent;
  opacity: 1;
}

input[type=date].form-control,
input[type=time].form-control,
input[type=datetime-local].form-control,
input[type=month].form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

select.form-control:focus::-ms-value {
  color: #000;
  background-color: rgba(110, 119, 122, 0.15);
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.4285714286;
}

.col-form-label-lg {
  padding-top: 0.5625rem;
  padding-bottom: 0.5625rem;
  font-size: 1rem;
  line-height: 1.5714285714;
}

.col-form-label-sm {
  padding-top: 0rem;
  padding-bottom: 0rem;
  font-size: 0.75rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 0.875rem;
  line-height: 1.4285714286;
  color: currentColor;
  background-color: transparent;
  border: solid transparent;
  border-width: 0rem 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc( 1.125rem + 0.125rem + 0rem );
  padding: 0rem 0.5rem;
  font-size: 0.75rem;
  line-height: 1.5;
}

.form-control-lg {
  height: calc( 2.5714285714rem + 0.125rem + 0rem );
  padding: 0.5625rem 0.875rem;
  font-size: 1rem;
  line-height: 1.5714285714;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  color: rgba(0, 0, 0, 0.4);
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #00aa0c;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.valid-feedback .icon {
  border: 1px solid currentColor;
  border-radius: 50%;
  width: 0.7rem;
  height: 0.7rem;
  margin-right: 3px;
  line-height: 1;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  width: 250px;
  padding: 0.25rem 1rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1;
  color: #fff;
  background-color: #00aa0c;
}

.form-control.is-valid,
.custom-select.is-valid {
  border-color: #00aa0c;
  -webkit-box-shadow: inset 0 -0.0625rem 0 0 #00aa0c;
  box-shadow: inset 0 -0.0625rem 0 0 #00aa0c;
}
.form-control.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-feedback {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.form-control.is-valid ~ .valid-tooltip,
.custom-select.is-valid ~ .valid-tooltip {
  display: block;
}
.form-control.is-valid:disabled, .form-control.is-valid.disabled, .form-control.is-valid[readonly], .form-control.is-valid.readonly,
.custom-select.is-valid:disabled,
.custom-select.is-valid.disabled,
.custom-select.is-valid[readonly],
.custom-select.is-valid.readonly {
  border-color: #00aa0c;
  -webkit-box-shadow: inset 0 -0.0625rem 0 0 #00aa0c;
  box-shadow: inset 0 -0.0625rem 0 0 #00aa0c;
}
.form-control.is-valid:not(:disabled):not(.disabled):not([readonly]):not(.readonly):hover,
.custom-select.is-valid:not(:disabled):not(.disabled):not([readonly]):not(.readonly):hover {
  -webkit-box-shadow: inset 0 0 0 0.0625rem #00aa0c;
  box-shadow: inset 0 0 0 0.0625rem #00aa0c;
}
.form-control.is-valid:not(:disabled):not(.disabled):not([readonly]):not(.readonly):focus,
.custom-select.is-valid:not(:disabled):not(.disabled):not([readonly]):not(.readonly):focus {
  -webkit-box-shadow: inset 0 0 0 2px #00aa0c;
  box-shadow: inset 0 0 0 2px #00aa0c;
}

.was-validated .form-control:valid,
.was-validated .custom-select:valid {
  border-color: #00aa0c;
  -webkit-box-shadow: inset 0 -0.0625rem 0 0 #00aa0c;
  box-shadow: inset 0 -0.0625rem 0 0 #00aa0c;
}
.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .custom-select:valid ~ .valid-feedback {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.was-validated .form-control:valid ~ .valid-tooltip,
.was-validated .custom-select:valid ~ .valid-tooltip {
  display: block;
}
.was-validated .form-control:valid:disabled, .was-validated .form-control:valid.disabled, .was-validated .form-control:valid[readonly], .was-validated .form-control:valid.readonly,
.was-validated .custom-select:valid:disabled,
.was-validated .custom-select:valid.disabled,
.was-validated .custom-select:valid[readonly],
.was-validated .custom-select:valid.readonly {
  border-color: #00aa0c;
  -webkit-box-shadow: inset 0 -0.0625rem 0 0 #00aa0c;
  box-shadow: inset 0 -0.0625rem 0 0 #00aa0c;
}
.was-validated .form-control:valid:not(:disabled):not(.disabled):not([readonly]):not(.readonly):hover,
.was-validated .custom-select:valid:not(:disabled):not(.disabled):not([readonly]):not(.readonly):hover {
  -webkit-box-shadow: inset 0 0 0 0.0625rem #00aa0c;
  box-shadow: inset 0 0 0 0.0625rem #00aa0c;
}
.was-validated .form-control:valid:not(:disabled):not(.disabled):not([readonly]):not(.readonly):focus,
.was-validated .custom-select:valid:not(:disabled):not(.disabled):not([readonly]):not(.readonly):focus {
  -webkit-box-shadow: inset 0 0 0 2px #00aa0c;
  box-shadow: inset 0 0 0 2px #00aa0c;
}

.was-validated .form-control.is-valid {
  border-color: #00aa0c;
  -webkit-box-shadow: inset 0 -0.0625rem 0 0 #00aa0c;
  box-shadow: inset 0 -0.0625rem 0 0 #00aa0c;
}
.was-validated .form-control.is-valid ~ .valid-feedback {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.was-validated .form-control.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .form-control.is-valid:disabled, .was-validated .form-control.is-valid.disabled, .was-validated .form-control.is-valid[readonly], .was-validated .form-control.is-valid.readonly {
  border-color: #00aa0c;
  -webkit-box-shadow: inset 0 -0.0625rem 0 0 #00aa0c;
  box-shadow: inset 0 -0.0625rem 0 0 #00aa0c;
}
.was-validated .form-control.is-valid:not(:disabled):not(.disabled):not([readonly]):not(.readonly):hover {
  -webkit-box-shadow: inset 0 0 0 0.0625rem #00aa0c;
  box-shadow: inset 0 0 0 0.0625rem #00aa0c;
}
.was-validated .form-control.is-valid:not(:disabled):not(.disabled):not([readonly]):not(.readonly):focus {
  -webkit-box-shadow: inset 0 0 0 2px #00aa0c;
  box-shadow: inset 0 0 0 2px #00aa0c;
}

.form-check-input.is-valid ~ .form-check-label {
  color: #00aa0c;
}

.custom-control-input.is-valid ~ .custom-control-label {
  color: #00aa0c;
}
.custom-control-input.is-valid ~ .custom-control-label::before {
  background-color: #2bff3a;
}
.custom-control-input.is-valid ~ .valid-feedback {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block;
}
.custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #00dd10;
}
.custom-control-input.is-valid:focus ~ .custom-control-label::before {
  -webkit-box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 170, 12, 0.25);
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 170, 12, 0.25);
}

.custom-file-input.is-valid ~ .custom-file-label {
  border-color: #00aa0c;
}
.custom-file-input.is-valid ~ .custom-file-label::before {
  border-color: inherit;
}
.custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block;
}
.custom-file-input.is-valid:focus ~ .custom-file-label {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 170, 12, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(0, 170, 12, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dd3724;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.invalid-feedback .icon {
  border: 1px solid currentColor;
  border-radius: 50%;
  width: 0.7rem;
  height: 0.7rem;
  margin-right: 3px;
  line-height: 1;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  width: 250px;
  padding: 0.25rem 1rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1;
  color: #fff;
  background-color: #dd3724;
}

.form-control.is-invalid,
.custom-select.is-invalid {
  border-color: #dd3724;
  -webkit-box-shadow: inset 0 -0.0625rem 0 0 #dd3724;
  box-shadow: inset 0 -0.0625rem 0 0 #dd3724;
}
.form-control.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-feedback {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.form-control.is-invalid ~ .invalid-tooltip,
.custom-select.is-invalid ~ .invalid-tooltip {
  display: block;
}
.form-control.is-invalid:disabled, .form-control.is-invalid.disabled, .form-control.is-invalid[readonly], .form-control.is-invalid.readonly,
.custom-select.is-invalid:disabled,
.custom-select.is-invalid.disabled,
.custom-select.is-invalid[readonly],
.custom-select.is-invalid.readonly {
  border-color: #dd3724;
  -webkit-box-shadow: inset 0 -0.0625rem 0 0 #dd3724;
  box-shadow: inset 0 -0.0625rem 0 0 #dd3724;
}
.form-control.is-invalid:not(:disabled):not(.disabled):not([readonly]):not(.readonly):hover,
.custom-select.is-invalid:not(:disabled):not(.disabled):not([readonly]):not(.readonly):hover {
  -webkit-box-shadow: inset 0 0 0 0.0625rem #dd3724;
  box-shadow: inset 0 0 0 0.0625rem #dd3724;
}
.form-control.is-invalid:not(:disabled):not(.disabled):not([readonly]):not(.readonly):focus,
.custom-select.is-invalid:not(:disabled):not(.disabled):not([readonly]):not(.readonly):focus {
  -webkit-box-shadow: inset 0 0 0 2px #dd3724;
  box-shadow: inset 0 0 0 2px #dd3724;
}

.was-validated .form-control:invalid,
.was-validated .custom-select:invalid {
  border-color: #dd3724;
  -webkit-box-shadow: inset 0 -0.0625rem 0 0 #dd3724;
  box-shadow: inset 0 -0.0625rem 0 0 #dd3724;
}
.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .custom-select:invalid ~ .invalid-feedback {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.was-validated .form-control:invalid ~ .invalid-tooltip,
.was-validated .custom-select:invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .form-control:invalid:disabled, .was-validated .form-control:invalid.disabled, .was-validated .form-control:invalid[readonly], .was-validated .form-control:invalid.readonly,
.was-validated .custom-select:invalid:disabled,
.was-validated .custom-select:invalid.disabled,
.was-validated .custom-select:invalid[readonly],
.was-validated .custom-select:invalid.readonly {
  border-color: #dd3724;
  -webkit-box-shadow: inset 0 -0.0625rem 0 0 #dd3724;
  box-shadow: inset 0 -0.0625rem 0 0 #dd3724;
}
.was-validated .form-control:invalid:not(:disabled):not(.disabled):not([readonly]):not(.readonly):hover,
.was-validated .custom-select:invalid:not(:disabled):not(.disabled):not([readonly]):not(.readonly):hover {
  -webkit-box-shadow: inset 0 0 0 0.0625rem #dd3724;
  box-shadow: inset 0 0 0 0.0625rem #dd3724;
}
.was-validated .form-control:invalid:not(:disabled):not(.disabled):not([readonly]):not(.readonly):focus,
.was-validated .custom-select:invalid:not(:disabled):not(.disabled):not([readonly]):not(.readonly):focus {
  -webkit-box-shadow: inset 0 0 0 2px #dd3724;
  box-shadow: inset 0 0 0 2px #dd3724;
}

.was-validated .form-control.is-invalid {
  border-color: #dd3724;
  -webkit-box-shadow: inset 0 -0.0625rem 0 0 #dd3724;
  box-shadow: inset 0 -0.0625rem 0 0 #dd3724;
}
.was-validated .form-control.is-invalid ~ .invalid-feedback {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.was-validated .form-control.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .form-control.is-invalid:disabled, .was-validated .form-control.is-invalid.disabled, .was-validated .form-control.is-invalid[readonly], .was-validated .form-control.is-invalid.readonly {
  border-color: #dd3724;
  -webkit-box-shadow: inset 0 -0.0625rem 0 0 #dd3724;
  box-shadow: inset 0 -0.0625rem 0 0 #dd3724;
}
.was-validated .form-control.is-invalid:not(:disabled):not(.disabled):not([readonly]):not(.readonly):hover {
  -webkit-box-shadow: inset 0 0 0 0.0625rem #dd3724;
  box-shadow: inset 0 0 0 0.0625rem #dd3724;
}
.was-validated .form-control.is-invalid:not(:disabled):not(.disabled):not([readonly]):not(.readonly):focus {
  -webkit-box-shadow: inset 0 0 0 2px #dd3724;
  box-shadow: inset 0 0 0 2px #dd3724;
}

.form-check-input.is-invalid ~ .form-check-label {
  color: #dd3724;
}

.custom-control-input.is-invalid ~ .custom-control-label {
  color: #dd3724;
}
.custom-control-input.is-invalid ~ .custom-control-label::before {
  background-color: #ee9c92;
}
.custom-control-input.is-invalid ~ .invalid-feedback {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block;
}
.custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: #e45f50;
}
.custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  -webkit-box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(221, 55, 36, 0.25);
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(221, 55, 36, 0.25);
}

.custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dd3724;
}
.custom-file-input.is-invalid ~ .custom-file-label::before {
  border-color: inherit;
}
.custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block;
}
.custom-file-input.is-invalid:focus ~ .custom-file-label {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(221, 55, 36, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(221, 55, 36, 0.25);
}

.form-inline {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
.form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.form-control,
.custom-select {
  font-weight: 400;
}
.form-control::-webkit-input-placeholder, .custom-select::-webkit-input-placeholder {
  font-weight: 300;
}
.form-control::-moz-placeholder, .custom-select::-moz-placeholder {
  font-weight: 300;
}
.form-control:-ms-input-placeholder, .custom-select:-ms-input-placeholder {
  font-weight: 300;
}
.form-control::-ms-input-placeholder, .custom-select::-ms-input-placeholder {
  font-weight: 300;
}
.form-control::placeholder,
.custom-select::placeholder {
  font-weight: 300;
}
.form-control.disabled, .disabled .form-control, .form-control:disabled,
.custom-select.disabled,
.disabled .custom-select,
.custom-select:disabled {
  -webkit-box-shadow: inset 0 -0.0625rem 0 rgba(0, 0, 0, 0.4);
  box-shadow: inset 0 -0.0625rem 0 rgba(0, 0, 0, 0.4);
  opacity: 0.4;
}
.form-control.readonly, .form-control[readonly],
.custom-select.readonly,
.custom-select[readonly] {
  -webkit-box-shadow: inset 0 -0.0625rem 0 rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 -0.0625rem 0 rgba(0, 0, 0, 0.2);
  background-color: transparent;
}
.disabled .form-control, .form-control.disabled, .form-control[disabled],
.disabled .custom-select,
.custom-select.disabled,
.custom-select[disabled] {
  background-color: transparent;
}

.form-control-alt {
  background: transparent;
  -webkit-box-shadow: inset 0 0 0 1px #eceeef;
  box-shadow: inset 0 0 0 1px #eceeef;
}

.form-control-plaintext:focus {
  outline: none;
}

.bg-lvl4 .form-control::-webkit-input-placeholder, .bg-lvl4 .custom-select::-webkit-input-placeholder {
  color: #555c5d;
}

.bg-lvl4 .form-control::-moz-placeholder, .bg-lvl4 .custom-select::-moz-placeholder {
  color: #555c5d;
}

.bg-lvl4 .form-control:-ms-input-placeholder, .bg-lvl4 .custom-select:-ms-input-placeholder {
  color: #555c5d;
}

.bg-lvl4 .form-control::-ms-input-placeholder, .bg-lvl4 .custom-select::-ms-input-placeholder {
  color: #555c5d;
}

.bg-lvl4 .form-control::placeholder,
.bg-lvl4 .custom-select::placeholder {
  color: #555c5d;
}

.bg-lvl3 .form-control::-webkit-input-placeholder, .bg-lvl3 .custom-select::-webkit-input-placeholder {
  color: #5c6465;
}

.bg-lvl3 .form-control::-moz-placeholder, .bg-lvl3 .custom-select::-moz-placeholder {
  color: #5c6465;
}

.bg-lvl3 .form-control:-ms-input-placeholder, .bg-lvl3 .custom-select:-ms-input-placeholder {
  color: #5c6465;
}

.bg-lvl3 .form-control::-ms-input-placeholder, .bg-lvl3 .custom-select::-ms-input-placeholder {
  color: #5c6465;
}

.bg-lvl3 .form-control::placeholder,
.bg-lvl3 .custom-select::placeholder {
  color: #5c6465;
}

.bg-lvl2 .form-control::-webkit-input-placeholder, .bg-lvl2 .custom-select::-webkit-input-placeholder {
  color: #5e6668;
}

.bg-lvl2 .form-control::-moz-placeholder, .bg-lvl2 .custom-select::-moz-placeholder {
  color: #5e6668;
}

.bg-lvl2 .form-control:-ms-input-placeholder, .bg-lvl2 .custom-select:-ms-input-placeholder {
  color: #5e6668;
}

.bg-lvl2 .form-control::-ms-input-placeholder, .bg-lvl2 .custom-select::-ms-input-placeholder {
  color: #5e6668;
}

.bg-lvl2 .form-control::placeholder,
.bg-lvl2 .custom-select::placeholder {
  color: #5e6668;
}

.bg-lvl1 .form-control::-webkit-input-placeholder, .bg-lvl1 .custom-select::-webkit-input-placeholder {
  color: #646d6f;
}

.bg-lvl1 .form-control::-moz-placeholder, .bg-lvl1 .custom-select::-moz-placeholder {
  color: #646d6f;
}

.bg-lvl1 .form-control:-ms-input-placeholder, .bg-lvl1 .custom-select:-ms-input-placeholder {
  color: #646d6f;
}

.bg-lvl1 .form-control::-ms-input-placeholder, .bg-lvl1 .custom-select::-ms-input-placeholder {
  color: #646d6f;
}

.bg-lvl1 .form-control::placeholder,
.bg-lvl1 .custom-select::placeholder {
  color: #646d6f;
}

.bg-alt-lvl4 .form-control::-webkit-input-placeholder, .bg-alt-lvl4 .custom-select::-webkit-input-placeholder {
  color: #848d8e;
}

.bg-alt-lvl4 .form-control::-moz-placeholder, .bg-alt-lvl4 .custom-select::-moz-placeholder {
  color: #848d8e;
}

.bg-alt-lvl4 .form-control:-ms-input-placeholder, .bg-alt-lvl4 .custom-select:-ms-input-placeholder {
  color: #848d8e;
}

.bg-alt-lvl4 .form-control::-ms-input-placeholder, .bg-alt-lvl4 .custom-select::-ms-input-placeholder {
  color: #848d8e;
}

.bg-alt-lvl4 .form-control::placeholder,
.bg-alt-lvl4 .custom-select::placeholder {
  color: #848d8e;
}

.bg-alt-lvl3 .form-control::-webkit-input-placeholder, .bg-alt-lvl3 .custom-select::-webkit-input-placeholder {
  color: #8f9799;
}

.bg-alt-lvl3 .form-control::-moz-placeholder, .bg-alt-lvl3 .custom-select::-moz-placeholder {
  color: #8f9799;
}

.bg-alt-lvl3 .form-control:-ms-input-placeholder, .bg-alt-lvl3 .custom-select:-ms-input-placeholder {
  color: #8f9799;
}

.bg-alt-lvl3 .form-control::-ms-input-placeholder, .bg-alt-lvl3 .custom-select::-ms-input-placeholder {
  color: #8f9799;
}

.bg-alt-lvl3 .form-control::placeholder,
.bg-alt-lvl3 .custom-select::placeholder {
  color: #8f9799;
}

.bg-alt-lvl2 .form-control::-webkit-input-placeholder, .bg-alt-lvl2 .custom-select::-webkit-input-placeholder {
  color: #99a1a2;
}

.bg-alt-lvl2 .form-control::-moz-placeholder, .bg-alt-lvl2 .custom-select::-moz-placeholder {
  color: #99a1a2;
}

.bg-alt-lvl2 .form-control:-ms-input-placeholder, .bg-alt-lvl2 .custom-select:-ms-input-placeholder {
  color: #99a1a2;
}

.bg-alt-lvl2 .form-control::-ms-input-placeholder, .bg-alt-lvl2 .custom-select::-ms-input-placeholder {
  color: #99a1a2;
}

.bg-alt-lvl2 .form-control::placeholder,
.bg-alt-lvl2 .custom-select::placeholder {
  color: #99a1a2;
}

.bg-alt-lvl1 .form-control::-webkit-input-placeholder, .bg-alt-lvl1 .custom-select::-webkit-input-placeholder {
  color: #a9b0b1;
}

.bg-alt-lvl1 .form-control::-moz-placeholder, .bg-alt-lvl1 .custom-select::-moz-placeholder {
  color: #a9b0b1;
}

.bg-alt-lvl1 .form-control:-ms-input-placeholder, .bg-alt-lvl1 .custom-select:-ms-input-placeholder {
  color: #a9b0b1;
}

.bg-alt-lvl1 .form-control::-ms-input-placeholder, .bg-alt-lvl1 .custom-select::-ms-input-placeholder {
  color: #a9b0b1;
}

.bg-alt-lvl1 .form-control::placeholder,
.bg-alt-lvl1 .custom-select::placeholder {
  color: #a9b0b1;
}

.form-group > label {
  color: #6e777a;
}

.form-check-label {
  line-height: 1.7;
}

.col-form-label {
  text-align: right;
}

.col-form-label-xl {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
}

.input-group-xl .form-control,
.form-control-xl {
  height: 3rem;
  padding: 0.75rem 1rem;
  font-size: 1rem;
  line-height: 1.5;
}

select:not([size]):not([multiple]).form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=utrf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%236e777a' preserveAspectRatio='xMaxYMid meet' viewBox='0 0 24 24'%3E%3Cpath d='M8 10l4 4 4-4z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 0 center;
  padding-right: calc(1.4285714286em + 0.75rem);
}
select:not([size]):not([multiple]).form-control:disabled {
  background-image: url("data:image/svg+xml;charset=utrf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%236e777a' preserveAspectRatio='xMaxYMid meet' style='opacity:0.4' viewBox='0 0 24 24'%3E%3Cpath d='M8 10l4 4 4-4z'/%3E%3C/svg%3E");
}
select:not([size]):not([multiple]).form-control::-ms-expand {
  opacity: 0;
}
select:not([size]):not([multiple]).form-control option,
select:not([size]):not([multiple]).form-control optgroup {
  background: #fff;
}

.form-group > label {
  font-weight: 500;
}

.warning-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #da7400;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.warning-feedback .icon {
  border: 1px solid currentColor;
  border-radius: 50%;
  width: 0.7rem;
  height: 0.7rem;
  margin-right: 3px;
  line-height: 1;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.warning-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  width: 250px;
  padding: 0.25rem 1rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1;
  color: #fff;
  background-color: #da7400;
}

.form-control.is-warning,
.custom-select.is-warning {
  border-color: #da7400;
  -webkit-box-shadow: inset 0 -0.0625rem 0 0 #da7400;
  box-shadow: inset 0 -0.0625rem 0 0 #da7400;
}
.form-control.is-warning ~ .warning-feedback,
.custom-select.is-warning ~ .warning-feedback {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.form-control.is-warning ~ .warning-tooltip,
.custom-select.is-warning ~ .warning-tooltip {
  display: block;
}
.form-control.is-warning:disabled, .form-control.is-warning.disabled, .form-control.is-warning[readonly], .form-control.is-warning.readonly,
.custom-select.is-warning:disabled,
.custom-select.is-warning.disabled,
.custom-select.is-warning[readonly],
.custom-select.is-warning.readonly {
  border-color: #da7400;
  -webkit-box-shadow: inset 0 -0.0625rem 0 0 #da7400;
  box-shadow: inset 0 -0.0625rem 0 0 #da7400;
}
.form-control.is-warning:not(:disabled):not(.disabled):not([readonly]):not(.readonly):hover,
.custom-select.is-warning:not(:disabled):not(.disabled):not([readonly]):not(.readonly):hover {
  -webkit-box-shadow: inset 0 0 0 0.0625rem #da7400;
  box-shadow: inset 0 0 0 0.0625rem #da7400;
}
.form-control.is-warning:not(:disabled):not(.disabled):not([readonly]):not(.readonly):focus,
.custom-select.is-warning:not(:disabled):not(.disabled):not([readonly]):not(.readonly):focus {
  -webkit-box-shadow: inset 0 0 0 2px #da7400;
  box-shadow: inset 0 0 0 2px #da7400;
}

.was-validated .form-control:warning,
.was-validated .custom-select:warning {
  border-color: #da7400;
  -webkit-box-shadow: inset 0 -0.0625rem 0 0 #da7400;
  box-shadow: inset 0 -0.0625rem 0 0 #da7400;
}
.was-validated .form-control:warning ~ .warning-feedback,
.was-validated .custom-select:warning ~ .warning-feedback {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.was-validated .form-control:warning ~ .warning-tooltip,
.was-validated .custom-select:warning ~ .warning-tooltip {
  display: block;
}
.was-validated .form-control:warning:disabled, .was-validated .form-control:warning.disabled, .was-validated .form-control:warning[readonly], .was-validated .form-control:warning.readonly,
.was-validated .custom-select:warning:disabled,
.was-validated .custom-select:warning.disabled,
.was-validated .custom-select:warning[readonly],
.was-validated .custom-select:warning.readonly {
  border-color: #da7400;
  -webkit-box-shadow: inset 0 -0.0625rem 0 0 #da7400;
  box-shadow: inset 0 -0.0625rem 0 0 #da7400;
}
.was-validated .form-control:warning:not(:disabled):not(.disabled):not([readonly]):not(.readonly):hover,
.was-validated .custom-select:warning:not(:disabled):not(.disabled):not([readonly]):not(.readonly):hover {
  -webkit-box-shadow: inset 0 0 0 0.0625rem #da7400;
  box-shadow: inset 0 0 0 0.0625rem #da7400;
}
.was-validated .form-control:warning:not(:disabled):not(.disabled):not([readonly]):not(.readonly):focus,
.was-validated .custom-select:warning:not(:disabled):not(.disabled):not([readonly]):not(.readonly):focus {
  -webkit-box-shadow: inset 0 0 0 2px #da7400;
  box-shadow: inset 0 0 0 2px #da7400;
}

.was-validated .form-control.is-warning {
  border-color: #da7400;
  -webkit-box-shadow: inset 0 -0.0625rem 0 0 #da7400;
  box-shadow: inset 0 -0.0625rem 0 0 #da7400;
}
.was-validated .form-control.is-warning ~ .warning-feedback {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.was-validated .form-control.is-warning ~ .warning-tooltip {
  display: block;
}
.was-validated .form-control.is-warning:disabled, .was-validated .form-control.is-warning.disabled, .was-validated .form-control.is-warning[readonly], .was-validated .form-control.is-warning.readonly {
  border-color: #da7400;
  -webkit-box-shadow: inset 0 -0.0625rem 0 0 #da7400;
  box-shadow: inset 0 -0.0625rem 0 0 #da7400;
}
.was-validated .form-control.is-warning:not(:disabled):not(.disabled):not([readonly]):not(.readonly):hover {
  -webkit-box-shadow: inset 0 0 0 0.0625rem #da7400;
  box-shadow: inset 0 0 0 0.0625rem #da7400;
}
.was-validated .form-control.is-warning:not(:disabled):not(.disabled):not([readonly]):not(.readonly):focus {
  -webkit-box-shadow: inset 0 0 0 2px #da7400;
  box-shadow: inset 0 0 0 2px #da7400;
}

.form-check-input.is-warning ~ .form-check-label {
  color: #da7400;
}

.custom-control-input.is-warning ~ .custom-control-label {
  color: #da7400;
}
.custom-control-input.is-warning ~ .custom-control-label::before {
  background-color: #ffb25b;
}
.custom-control-input.is-warning ~ .warning-feedback {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.custom-control-input.is-warning ~ .warning-tooltip {
  display: block;
}
.custom-control-input.is-warning:checked ~ .custom-control-label::before {
  background-color: #ff8e0e;
}
.custom-control-input.is-warning:focus ~ .custom-control-label::before {
  -webkit-box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(218, 116, 0, 0.25);
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(218, 116, 0, 0.25);
}

.custom-file-input.is-warning ~ .custom-file-label {
  border-color: #da7400;
}
.custom-file-input.is-warning ~ .custom-file-label::before {
  border-color: inherit;
}
.custom-file-input.is-warning ~ .warning-feedback,
.custom-file-input.is-warning ~ .warning-tooltip {
  display: block;
}
.custom-file-input.is-warning:focus ~ .custom-file-label {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(218, 116, 0, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(218, 116, 0, 0.25);
}

.form-control[type=search]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  height: 16px;
  width: 16px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24'%3E%3Cpath fill='%23000' d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'/%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3C/svg%3E");
}

.form-control-sm[type=search]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  height: 14px;
  width: 14px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 24 24'%3E%3Cpath fill='%23000' d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'/%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3C/svg%3E");
}

.form-control-lg[type=search]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  height: 18px;
  width: 18px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 24 24'%3E%3Cpath fill='%23000' d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'/%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3C/svg%3E");
}

.form-control-xl[type=search]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 24 24'%3E%3Cpath fill='%23000' d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'/%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3C/svg%3E");
}

.btn {
  display: inline-block;
  font-weight: 500;
  color: #000;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 0.0625rem solid transparent;
  padding: 0.3125rem 0.9375rem;
  font-size: 0.875rem;
  line-height: 1.4285714286;
  border-radius: 0;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    -webkit-transition: none;
    transition: none;
  }
}
.btn:hover {
  color: #000;
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(48, 51, 51, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(48, 51, 51, 0.25);
}
.btn.disabled, .btn:disabled {
  opacity: 0.4;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #000;
  border-color: #000;
}
.btn-primary:hover {
  color: #fff;
  background-color: black;
  border-color: black;
}
.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: black;
  border-color: black;
  -webkit-box-shadow: 0 0 0 0.125rem rgba(38, 38, 38, 0.5);
  box-shadow: 0 0 0 0.125rem rgba(38, 38, 38, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #000;
  border-color: #000;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: black;
  border-color: black;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.125rem rgba(38, 38, 38, 0.5);
  box-shadow: 0 0 0 0.125rem rgba(38, 38, 38, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #6e777a;
  border-color: #6e777a;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #5c6366;
  border-color: #565d5f;
}
.btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: #5c6366;
  border-color: #565d5f;
  -webkit-box-shadow: 0 0 0 0.125rem rgba(132, 139, 142, 0.5);
  box-shadow: 0 0 0 0.125rem rgba(132, 139, 142, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6e777a;
  border-color: #6e777a;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #565d5f;
  border-color: #505658;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.125rem rgba(132, 139, 142, 0.5);
  box-shadow: 0 0 0 0.125rem rgba(132, 139, 142, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #00aa0c;
  border-color: #00aa0c;
}
.btn-success:hover {
  color: #fff;
  background-color: #008409;
  border-color: #007708;
}
.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #008409;
  border-color: #007708;
  -webkit-box-shadow: 0 0 0 0.125rem rgba(38, 183, 48, 0.5);
  box-shadow: 0 0 0 0.125rem rgba(38, 183, 48, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #00aa0c;
  border-color: #00aa0c;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #007708;
  border-color: #006a08;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.125rem rgba(38, 183, 48, 0.5);
  box-shadow: 0 0 0 0.125rem rgba(38, 183, 48, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #2469ff;
  border-color: #2469ff;
}
.btn-info:hover {
  color: #fff;
  background-color: #0050fd;
  border-color: #004cf0;
}
.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #0050fd;
  border-color: #004cf0;
  -webkit-box-shadow: 0 0 0 0.125rem rgba(69, 128, 255, 0.5);
  box-shadow: 0 0 0 0.125rem rgba(69, 128, 255, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #2469ff;
  border-color: #2469ff;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #004cf0;
  border-color: #0048e3;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.125rem rgba(69, 128, 255, 0.5);
  box-shadow: 0 0 0 0.125rem rgba(69, 128, 255, 0.5);
}

.btn-warning {
  color: #fff;
  background-color: #da7400;
  border-color: #da7400;
}
.btn-warning:hover {
  color: #fff;
  background-color: #b46000;
  border-color: #a75900;
}
.btn-warning:focus, .btn-warning.focus {
  color: #fff;
  background-color: #b46000;
  border-color: #a75900;
  -webkit-box-shadow: 0 0 0 0.125rem rgba(224, 137, 38, 0.5);
  box-shadow: 0 0 0 0.125rem rgba(224, 137, 38, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #fff;
  background-color: #da7400;
  border-color: #da7400;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #a75900;
  border-color: #9a5200;
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.125rem rgba(224, 137, 38, 0.5);
  box-shadow: 0 0 0 0.125rem rgba(224, 137, 38, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #dd3724;
  border-color: #dd3724;
}
.btn-danger:hover {
  color: #fff;
  background-color: #bd2e1d;
  border-color: #b22b1c;
}
.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #bd2e1d;
  border-color: #b22b1c;
  -webkit-box-shadow: 0 0 0 0.125rem rgba(226, 85, 69, 0.5);
  box-shadow: 0 0 0 0.125rem rgba(226, 85, 69, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dd3724;
  border-color: #dd3724;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #b22b1c;
  border-color: #a7281a;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.125rem rgba(226, 85, 69, 0.5);
  box-shadow: 0 0 0 0.125rem rgba(226, 85, 69, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #c4c8ca;
  border-color: #c4c8ca;
}
.btn-light:hover {
  color: #212529;
  background-color: #b0b5b8;
  border-color: #a9afb2;
}
.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: #b0b5b8;
  border-color: #a9afb2;
  -webkit-box-shadow: 0 0 0 0.125rem rgba(172, 176, 178, 0.5);
  box-shadow: 0 0 0 0.125rem rgba(172, 176, 178, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #c4c8ca;
  border-color: #c4c8ca;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #fff;
  background-color: #a9afb2;
  border-color: #a2a9ac;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.125rem rgba(172, 176, 178, 0.5);
  box-shadow: 0 0 0 0.125rem rgba(172, 176, 178, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #414949;
  border-color: #414949;
}
.btn-dark:hover {
  color: #fff;
  background-color: #2f3535;
  border-color: #292e2e;
}
.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #2f3535;
  border-color: #292e2e;
  -webkit-box-shadow: 0 0 0 0.125rem rgba(94, 100, 100, 0.5);
  box-shadow: 0 0 0 0.125rem rgba(94, 100, 100, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #414949;
  border-color: #414949;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #292e2e;
  border-color: #232727;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.125rem rgba(94, 100, 100, 0.5);
  box-shadow: 0 0 0 0.125rem rgba(94, 100, 100, 0.5);
}

.btn-white {
  color: #212529;
  background-color: white;
  border-color: white;
}
.btn-white:hover {
  color: #212529;
  background-color: #ececec;
  border-color: #e6e6e6;
}
.btn-white:focus, .btn-white.focus {
  color: #212529;
  background-color: #ececec;
  border-color: #e6e6e6;
  -webkit-box-shadow: 0 0 0 0.125rem rgba(222, 222, 223, 0.5);
  box-shadow: 0 0 0 0.125rem rgba(222, 222, 223, 0.5);
}
.btn-white.disabled, .btn-white:disabled {
  color: #212529;
  background-color: white;
  border-color: white;
}
.btn-white:not(:disabled):not(.disabled):active, .btn-white:not(:disabled):not(.disabled).active, .show > .btn-white.dropdown-toggle {
  color: #212529;
  background-color: #e6e6e6;
  border-color: #dfdfdf;
}
.btn-white:not(:disabled):not(.disabled):active:focus, .btn-white:not(:disabled):not(.disabled).active:focus, .show > .btn-white.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.125rem rgba(222, 222, 223, 0.5);
  box-shadow: 0 0 0 0.125rem rgba(222, 222, 223, 0.5);
}

.btn-black {
  color: #fff;
  background-color: #231f20;
  border-color: #231f20;
}
.btn-black:hover {
  color: #fff;
  background-color: #0f0d0d;
  border-color: #080707;
}
.btn-black:focus, .btn-black.focus {
  color: #fff;
  background-color: #0f0d0d;
  border-color: #080707;
  -webkit-box-shadow: 0 0 0 0.125rem rgba(68, 65, 65, 0.5);
  box-shadow: 0 0 0 0.125rem rgba(68, 65, 65, 0.5);
}
.btn-black.disabled, .btn-black:disabled {
  color: #fff;
  background-color: #231f20;
  border-color: #231f20;
}
.btn-black:not(:disabled):not(.disabled):active, .btn-black:not(:disabled):not(.disabled).active, .show > .btn-black.dropdown-toggle {
  color: #fff;
  background-color: #080707;
  border-color: #010101;
}
.btn-black:not(:disabled):not(.disabled):active:focus, .btn-black:not(:disabled):not(.disabled).active:focus, .show > .btn-black.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.125rem rgba(68, 65, 65, 0.5);
  box-shadow: 0 0 0 0.125rem rgba(68, 65, 65, 0.5);
}

.btn-socgen {
  color: #fff;
  background-color: #e60028;
  border-color: #e60028;
}
.btn-socgen:hover {
  color: #fff;
  background-color: #c00021;
  border-color: #b3001f;
}
.btn-socgen:focus, .btn-socgen.focus {
  color: #fff;
  background-color: #c00021;
  border-color: #b3001f;
  -webkit-box-shadow: 0 0 0 0.125rem rgba(234, 38, 72, 0.5);
  box-shadow: 0 0 0 0.125rem rgba(234, 38, 72, 0.5);
}
.btn-socgen.disabled, .btn-socgen:disabled {
  color: #fff;
  background-color: #e60028;
  border-color: #e60028;
}
.btn-socgen:not(:disabled):not(.disabled):active, .btn-socgen:not(:disabled):not(.disabled).active, .show > .btn-socgen.dropdown-toggle {
  color: #fff;
  background-color: #b3001f;
  border-color: #a6001d;
}
.btn-socgen:not(:disabled):not(.disabled):active:focus, .btn-socgen:not(:disabled):not(.disabled).active:focus, .show > .btn-socgen.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.125rem rgba(234, 38, 72, 0.5);
  box-shadow: 0 0 0 0.125rem rgba(234, 38, 72, 0.5);
}

.btn-primary-alt {
  color: #212529;
  background-color: #fff;
  border-color: #fff;
}
.btn-primary-alt:hover {
  color: #212529;
  background-color: #ececec;
  border-color: #e6e6e6;
}
.btn-primary-alt:focus, .btn-primary-alt.focus {
  color: #212529;
  background-color: #ececec;
  border-color: #e6e6e6;
  -webkit-box-shadow: 0 0 0 0.125rem rgba(222, 222, 223, 0.5);
  box-shadow: 0 0 0 0.125rem rgba(222, 222, 223, 0.5);
}
.btn-primary-alt.disabled, .btn-primary-alt:disabled {
  color: #212529;
  background-color: #fff;
  border-color: #fff;
}
.btn-primary-alt:not(:disabled):not(.disabled):active, .btn-primary-alt:not(:disabled):not(.disabled).active, .show > .btn-primary-alt.dropdown-toggle {
  color: #212529;
  background-color: #e6e6e6;
  border-color: #dfdfdf;
}
.btn-primary-alt:not(:disabled):not(.disabled):active:focus, .btn-primary-alt:not(:disabled):not(.disabled).active:focus, .show > .btn-primary-alt.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.125rem rgba(222, 222, 223, 0.5);
  box-shadow: 0 0 0 0.125rem rgba(222, 222, 223, 0.5);
}

.btn-outline-primary {
  color: #000;
  border-color: #000;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #000;
  border-color: #000;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  -webkit-box-shadow: 0 0 0 0.125rem rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 0 0.125rem rgba(0, 0, 0, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #000;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #000;
  border-color: #000;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.125rem rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 0 0.125rem rgba(0, 0, 0, 0.5);
}

.btn-outline-secondary {
  color: #6e777a;
  border-color: #6e777a;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6e777a;
  border-color: #6e777a;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  -webkit-box-shadow: 0 0 0 0.125rem rgba(110, 119, 122, 0.5);
  box-shadow: 0 0 0 0.125rem rgba(110, 119, 122, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6e777a;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6e777a;
  border-color: #6e777a;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.125rem rgba(110, 119, 122, 0.5);
  box-shadow: 0 0 0 0.125rem rgba(110, 119, 122, 0.5);
}

.btn-outline-success {
  color: #00aa0c;
  border-color: #00aa0c;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #00aa0c;
  border-color: #00aa0c;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  -webkit-box-shadow: 0 0 0 0.125rem rgba(0, 170, 12, 0.5);
  box-shadow: 0 0 0 0.125rem rgba(0, 170, 12, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #00aa0c;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #00aa0c;
  border-color: #00aa0c;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.125rem rgba(0, 170, 12, 0.5);
  box-shadow: 0 0 0 0.125rem rgba(0, 170, 12, 0.5);
}

.btn-outline-info {
  color: #2469ff;
  border-color: #2469ff;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #2469ff;
  border-color: #2469ff;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  -webkit-box-shadow: 0 0 0 0.125rem rgba(36, 105, 255, 0.5);
  box-shadow: 0 0 0 0.125rem rgba(36, 105, 255, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #2469ff;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #2469ff;
  border-color: #2469ff;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.125rem rgba(36, 105, 255, 0.5);
  box-shadow: 0 0 0 0.125rem rgba(36, 105, 255, 0.5);
}

.btn-outline-warning {
  color: #da7400;
  border-color: #da7400;
}
.btn-outline-warning:hover {
  color: #fff;
  background-color: #da7400;
  border-color: #da7400;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  -webkit-box-shadow: 0 0 0 0.125rem rgba(218, 116, 0, 0.5);
  box-shadow: 0 0 0 0.125rem rgba(218, 116, 0, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #da7400;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #fff;
  background-color: #da7400;
  border-color: #da7400;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.125rem rgba(218, 116, 0, 0.5);
  box-shadow: 0 0 0 0.125rem rgba(218, 116, 0, 0.5);
}

.btn-outline-danger {
  color: #dd3724;
  border-color: #dd3724;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #dd3724;
  border-color: #dd3724;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  -webkit-box-shadow: 0 0 0 0.125rem rgba(221, 55, 36, 0.5);
  box-shadow: 0 0 0 0.125rem rgba(221, 55, 36, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dd3724;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dd3724;
  border-color: #dd3724;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.125rem rgba(221, 55, 36, 0.5);
  box-shadow: 0 0 0 0.125rem rgba(221, 55, 36, 0.5);
}

.btn-outline-light {
  color: #c4c8ca;
  border-color: #c4c8ca;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #c4c8ca;
  border-color: #c4c8ca;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  -webkit-box-shadow: 0 0 0 0.125rem rgba(196, 200, 202, 0.5);
  box-shadow: 0 0 0 0.125rem rgba(196, 200, 202, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #c4c8ca;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #c4c8ca;
  border-color: #c4c8ca;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.125rem rgba(196, 200, 202, 0.5);
  box-shadow: 0 0 0 0.125rem rgba(196, 200, 202, 0.5);
}

.btn-outline-dark {
  color: #414949;
  border-color: #414949;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #414949;
  border-color: #414949;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  -webkit-box-shadow: 0 0 0 0.125rem rgba(65, 73, 73, 0.5);
  box-shadow: 0 0 0 0.125rem rgba(65, 73, 73, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #414949;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #414949;
  border-color: #414949;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.125rem rgba(65, 73, 73, 0.5);
  box-shadow: 0 0 0 0.125rem rgba(65, 73, 73, 0.5);
}

.btn-outline-white {
  color: white;
  border-color: white;
}
.btn-outline-white:hover {
  color: #212529;
  background-color: white;
  border-color: white;
}
.btn-outline-white:focus, .btn-outline-white.focus {
  -webkit-box-shadow: 0 0 0 0.125rem rgba(255, 255, 255, 0.5);
  box-shadow: 0 0 0 0.125rem rgba(255, 255, 255, 0.5);
}
.btn-outline-white.disabled, .btn-outline-white:disabled {
  color: white;
  background-color: transparent;
}
.btn-outline-white:not(:disabled):not(.disabled):active, .btn-outline-white:not(:disabled):not(.disabled).active, .show > .btn-outline-white.dropdown-toggle {
  color: #212529;
  background-color: white;
  border-color: white;
}
.btn-outline-white:not(:disabled):not(.disabled):active:focus, .btn-outline-white:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-white.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.125rem rgba(255, 255, 255, 0.5);
  box-shadow: 0 0 0 0.125rem rgba(255, 255, 255, 0.5);
}

.btn-outline-black {
  color: #231f20;
  border-color: #231f20;
}
.btn-outline-black:hover {
  color: #fff;
  background-color: #231f20;
  border-color: #231f20;
}
.btn-outline-black:focus, .btn-outline-black.focus {
  -webkit-box-shadow: 0 0 0 0.125rem rgba(35, 31, 32, 0.5);
  box-shadow: 0 0 0 0.125rem rgba(35, 31, 32, 0.5);
}
.btn-outline-black.disabled, .btn-outline-black:disabled {
  color: #231f20;
  background-color: transparent;
}
.btn-outline-black:not(:disabled):not(.disabled):active, .btn-outline-black:not(:disabled):not(.disabled).active, .show > .btn-outline-black.dropdown-toggle {
  color: #fff;
  background-color: #231f20;
  border-color: #231f20;
}
.btn-outline-black:not(:disabled):not(.disabled):active:focus, .btn-outline-black:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-black.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.125rem rgba(35, 31, 32, 0.5);
  box-shadow: 0 0 0 0.125rem rgba(35, 31, 32, 0.5);
}

.btn-outline-socgen {
  color: #e60028;
  border-color: #e60028;
}
.btn-outline-socgen:hover {
  color: #fff;
  background-color: #e60028;
  border-color: #e60028;
}
.btn-outline-socgen:focus, .btn-outline-socgen.focus {
  -webkit-box-shadow: 0 0 0 0.125rem rgba(230, 0, 40, 0.5);
  box-shadow: 0 0 0 0.125rem rgba(230, 0, 40, 0.5);
}
.btn-outline-socgen.disabled, .btn-outline-socgen:disabled {
  color: #e60028;
  background-color: transparent;
}
.btn-outline-socgen:not(:disabled):not(.disabled):active, .btn-outline-socgen:not(:disabled):not(.disabled).active, .show > .btn-outline-socgen.dropdown-toggle {
  color: #fff;
  background-color: #e60028;
  border-color: #e60028;
}
.btn-outline-socgen:not(:disabled):not(.disabled):active:focus, .btn-outline-socgen:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-socgen.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.125rem rgba(230, 0, 40, 0.5);
  box-shadow: 0 0 0 0.125rem rgba(230, 0, 40, 0.5);
}

.btn-outline-primary-alt {
  color: #fff;
  border-color: #fff;
}
.btn-outline-primary-alt:hover {
  color: #212529;
  background-color: #fff;
  border-color: #fff;
}
.btn-outline-primary-alt:focus, .btn-outline-primary-alt.focus {
  -webkit-box-shadow: 0 0 0 0.125rem rgba(255, 255, 255, 0.5);
  box-shadow: 0 0 0 0.125rem rgba(255, 255, 255, 0.5);
}
.btn-outline-primary-alt.disabled, .btn-outline-primary-alt:disabled {
  color: #fff;
  background-color: transparent;
}
.btn-outline-primary-alt:not(:disabled):not(.disabled):active, .btn-outline-primary-alt:not(:disabled):not(.disabled).active, .show > .btn-outline-primary-alt.dropdown-toggle {
  color: #212529;
  background-color: #fff;
  border-color: #fff;
}
.btn-outline-primary-alt:not(:disabled):not(.disabled):active:focus, .btn-outline-primary-alt:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary-alt.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.125rem rgba(255, 255, 255, 0.5);
  box-shadow: 0 0 0 0.125rem rgba(255, 255, 255, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #6e777a;
  text-decoration: none;
}
.btn-link:hover {
  color: #000;
  text-decoration: underline;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1.1875rem;
  font-size: 1rem;
  line-height: 1.5714285714;
  border-radius: 0;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0 0.4375rem;
  font-size: 0.75rem;
  line-height: 1.5;
  border-radius: 0;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.btn {
  font-weight: 500;
}

.btn-primary {
  color: #fff;
}
.btn-primary.btn-primary-alt {
  color: #000;
}
.btn-primary.btn-primary-alt:focus, .btn-primary.btn-primary-alt.focus, .btn-primary.btn-primary-alt:hover {
  color: #000;
}
.btn-primary.btn-primary-alt:not(:disabled):not(.disabled):active, .btn-primary.btn-primary-alt:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
}
.btn-primary:hover {
  color: #fff;
  background-color: black;
  border-color: black;
}
.btn-primary:focus, .btn-primary.focus {
  color: #fff;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #000 !important;
  border-color: #000 !important;
}

.btn-secondary {
  color: #fff;
}
.btn-secondary.btn-primary-alt {
  color: #000;
}
.btn-secondary.btn-primary-alt:focus, .btn-secondary.btn-primary-alt.focus, .btn-secondary.btn-primary-alt:hover {
  color: #000;
}
.btn-secondary.btn-primary-alt:not(:disabled):not(.disabled):active, .btn-secondary.btn-primary-alt:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #5f676a;
  border-color: #5f676a;
}
.btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #6e777a !important;
  border-color: #6e777a !important;
}

.btn-success {
  color: #fff;
}
.btn-success.btn-primary-alt {
  color: #000;
}
.btn-success.btn-primary-alt:focus, .btn-success.btn-primary-alt.focus, .btn-success.btn-primary-alt:hover {
  color: #000;
}
.btn-success.btn-primary-alt:not(:disabled):not(.disabled):active, .btn-success.btn-primary-alt:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
}
.btn-success:hover {
  color: #fff;
  background-color: #008b0a;
  border-color: #008b0a;
}
.btn-success:focus, .btn-success.focus {
  color: #fff;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #00aa0c !important;
  border-color: #00aa0c !important;
}

.btn-info {
  color: #fff;
}
.btn-info.btn-primary-alt {
  color: #000;
}
.btn-info.btn-primary-alt:focus, .btn-info.btn-primary-alt.focus, .btn-info.btn-primary-alt:hover {
  color: #000;
}
.btn-info.btn-primary-alt:not(:disabled):not(.disabled):active, .btn-info.btn-primary-alt:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
}
.btn-info:hover {
  color: #fff;
  background-color: #0554ff;
  border-color: #0554ff;
}
.btn-info:focus, .btn-info.focus {
  color: #fff;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #2469ff !important;
  border-color: #2469ff !important;
}

.btn-warning {
  color: #fff;
}
.btn-warning.btn-primary-alt {
  color: #000;
}
.btn-warning.btn-primary-alt:focus, .btn-warning.btn-primary-alt.focus, .btn-warning.btn-primary-alt:hover {
  color: #000;
}
.btn-warning.btn-primary-alt:not(:disabled):not(.disabled):active, .btn-warning.btn-primary-alt:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
}
.btn-warning:hover {
  color: #fff;
  background-color: #bb6400;
  border-color: #bb6400;
}
.btn-warning:focus, .btn-warning.focus {
  color: #fff;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #da7400 !important;
  border-color: #da7400 !important;
}

.btn-danger {
  color: #fff;
}
.btn-danger.btn-primary-alt {
  color: #000;
}
.btn-danger.btn-primary-alt:focus, .btn-danger.btn-primary-alt.focus, .btn-danger.btn-primary-alt:hover {
  color: #000;
}
.btn-danger.btn-primary-alt:not(:disabled):not(.disabled):active, .btn-danger.btn-primary-alt:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
}
.btn-danger:hover {
  color: #fff;
  background-color: #c42f1e;
  border-color: #c42f1e;
}
.btn-danger:focus, .btn-danger.focus {
  color: #fff;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #dd3724 !important;
  border-color: #dd3724 !important;
}

.btn-light {
  color: #fff;
}
.btn-light.btn-primary-alt {
  color: #000;
}
.btn-light.btn-primary-alt:focus, .btn-light.btn-primary-alt.focus, .btn-light.btn-primary-alt:hover {
  color: #000;
}
.btn-light.btn-primary-alt:not(:disabled):not(.disabled):active, .btn-light.btn-primary-alt:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
}
.btn-light:hover {
  color: #fff;
  background-color: #b4b9bc;
  border-color: #b4b9bc;
}
.btn-light:focus, .btn-light.focus {
  color: #fff;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #c4c8ca !important;
  border-color: #c4c8ca !important;
}

.btn-dark {
  color: #fff;
}
.btn-dark.btn-primary-alt {
  color: #000;
}
.btn-dark.btn-primary-alt:focus, .btn-dark.btn-primary-alt.focus, .btn-dark.btn-primary-alt:hover {
  color: #000;
}
.btn-dark.btn-primary-alt:not(:disabled):not(.disabled):active, .btn-dark.btn-primary-alt:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
}
.btn-dark:hover {
  color: #fff;
  background-color: #333939;
  border-color: #333939;
}
.btn-dark:focus, .btn-dark.focus {
  color: #fff;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #414949 !important;
  border-color: #414949 !important;
}

.btn-white {
  color: #fff;
}
.btn-white.btn-primary-alt {
  color: #000;
}
.btn-white.btn-primary-alt:focus, .btn-white.btn-primary-alt.focus, .btn-white.btn-primary-alt:hover {
  color: #000;
}
.btn-white.btn-primary-alt:not(:disabled):not(.disabled):active, .btn-white.btn-primary-alt:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
}
.btn-white:hover {
  color: #fff;
  background-color: #f0f0f0;
  border-color: #f0f0f0;
}
.btn-white:focus, .btn-white.focus {
  color: #fff;
}
.btn-white:not(:disabled):not(.disabled):active, .btn-white:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: white !important;
  border-color: white !important;
}

.btn-black {
  color: #fff;
}
.btn-black.btn-primary-alt {
  color: #000;
}
.btn-black.btn-primary-alt:focus, .btn-black.btn-primary-alt.focus, .btn-black.btn-primary-alt:hover {
  color: #000;
}
.btn-black.btn-primary-alt:not(:disabled):not(.disabled):active, .btn-black.btn-primary-alt:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
}
.btn-black:hover {
  color: #fff;
  background-color: #131111;
  border-color: #131111;
}
.btn-black:focus, .btn-black.focus {
  color: #fff;
}
.btn-black:not(:disabled):not(.disabled):active, .btn-black:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #231f20 !important;
  border-color: #231f20 !important;
}

.btn-socgen {
  color: #fff;
}
.btn-socgen.btn-primary-alt {
  color: #000;
}
.btn-socgen.btn-primary-alt:focus, .btn-socgen.btn-primary-alt.focus, .btn-socgen.btn-primary-alt:hover {
  color: #000;
}
.btn-socgen.btn-primary-alt:not(:disabled):not(.disabled):active, .btn-socgen.btn-primary-alt:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
}
.btn-socgen:hover {
  color: #fff;
  background-color: #c70023;
  border-color: #c70023;
}
.btn-socgen:focus, .btn-socgen.focus {
  color: #fff;
}
.btn-socgen:not(:disabled):not(.disabled):active, .btn-socgen:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #e60028 !important;
  border-color: #e60028 !important;
}

.btn-primary-alt {
  color: #fff;
}
.btn-primary-alt.btn-primary-alt {
  color: #000;
}
.btn-primary-alt.btn-primary-alt:focus, .btn-primary-alt.btn-primary-alt.focus, .btn-primary-alt.btn-primary-alt:hover {
  color: #000;
}
.btn-primary-alt.btn-primary-alt:not(:disabled):not(.disabled):active, .btn-primary-alt.btn-primary-alt:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
}
.btn-primary-alt:hover {
  color: #fff;
  background-color: #f0f0f0;
  border-color: #f0f0f0;
}
.btn-primary-alt:focus, .btn-primary-alt.focus {
  color: #fff;
}
.btn-primary-alt:not(:disabled):not(.disabled):active, .btn-primary-alt:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #fff !important;
  border-color: #fff !important;
}

.bg-lvl4 .btn.btn-secondary {
  background-color: #5f6668;
  border-color: #5f6668;
}
.bg-lvl4 .btn.btn-secondary:hover {
  background-color: #505658;
  border-color: #505658;
}
.bg-lvl4 .btn.btn-secondary:focus, .bg-lvl4 .btn.btn-secondary.focus {
  color: #fff;
}
.bg-lvl4 .btn.btn-secondary:not(:disabled):not(.disabled):active, .bg-lvl4 .btn.btn-secondary:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #5f6668 !important;
  border-color: #5f6668 !important;
}

.bg-lvl4 .btn.btn-light {
  background-color: #b1b7b9;
  border-color: #b1b7b9;
}
.bg-lvl4 .btn.btn-light:hover {
  background-color: #a1a8ab;
  border-color: #a1a8ab;
}
.bg-lvl4 .btn.btn-light:focus, .bg-lvl4 .btn.btn-light.focus {
  color: #fff;
}
.bg-lvl4 .btn.btn-light:not(:disabled):not(.disabled):active, .bg-lvl4 .btn.btn-light:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #b1b7b9 !important;
  border-color: #b1b7b9 !important;
}

.bg-lvl4 .btn.btn-dark {
  background-color: #323939;
  border-color: #323939;
}
.bg-lvl4 .btn.btn-dark:hover {
  background-color: #242929;
  border-color: #242929;
}
.bg-lvl4 .btn.btn-dark:focus, .bg-lvl4 .btn.btn-dark.focus {
  color: #fff;
}
.bg-lvl4 .btn.btn-dark:not(:disabled):not(.disabled):active, .bg-lvl4 .btn.btn-dark:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #323939 !important;
  border-color: #323939 !important;
}

.bg-lvl4 .btn.btn-info {
  background-color: #0554ff;
  border-color: #0554ff;
}
.bg-lvl4 .btn.btn-info:hover {
  background-color: #0048e5;
  border-color: #0048e5;
}
.bg-lvl4 .btn.btn-info:focus, .bg-lvl4 .btn.btn-info.focus {
  color: #fff;
}
.bg-lvl4 .btn.btn-info:not(:disabled):not(.disabled):active, .bg-lvl4 .btn.btn-info:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #0554ff !important;
  border-color: #0554ff !important;
}

.bg-lvl4 .btn.btn-success {
  background-color: #008a09;
  border-color: #008a09;
}
.bg-lvl4 .btn.btn-success:hover {
  background-color: #006b07;
  border-color: #006b07;
}
.bg-lvl4 .btn.btn-success:focus, .bg-lvl4 .btn.btn-success.focus {
  color: #fff;
}
.bg-lvl4 .btn.btn-success:not(:disabled):not(.disabled):active, .bg-lvl4 .btn.btn-success:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #008a09 !important;
  border-color: #008a09 !important;
}

.bg-lvl4 .btn.btn-warning {
  background-color: #bc6500;
  border-color: #bc6500;
}
.bg-lvl4 .btn.btn-warning:hover {
  background-color: #9d5500;
  border-color: #9d5500;
}
.bg-lvl4 .btn.btn-warning:focus, .bg-lvl4 .btn.btn-warning.focus {
  color: #fff;
}
.bg-lvl4 .btn.btn-warning:not(:disabled):not(.disabled):active, .bg-lvl4 .btn.btn-warning:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #bc6500 !important;
  border-color: #bc6500 !important;
}

.bg-lvl4 .btn.btn-danger {
  background-color: #c22f1f;
  border-color: #c22f1f;
}
.bg-lvl4 .btn.btn-danger:hover {
  background-color: #a8291b;
  border-color: #a8291b;
}
.bg-lvl4 .btn.btn-danger:focus, .bg-lvl4 .btn.btn-danger.focus {
  color: #fff;
}
.bg-lvl4 .btn.btn-danger:not(:disabled):not(.disabled):active, .bg-lvl4 .btn.btn-danger:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #c22f1f !important;
  border-color: #c22f1f !important;
}

.bg-lvl3 .btn.btn-secondary {
  background-color: #666e70;
  border-color: #666e70;
}
.bg-lvl3 .btn.btn-secondary:hover {
  background-color: #575e60;
  border-color: #575e60;
}
.bg-lvl3 .btn.btn-secondary:focus, .bg-lvl3 .btn.btn-secondary.focus {
  color: #fff;
}
.bg-lvl3 .btn.btn-secondary:not(:disabled):not(.disabled):active, .bg-lvl3 .btn.btn-secondary:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #666e70 !important;
  border-color: #666e70 !important;
}

.bg-lvl3 .btn.btn-light {
  background-color: #b9bec0;
  border-color: #b9bec0;
}
.bg-lvl3 .btn.btn-light:hover {
  background-color: #a9afb2;
  border-color: #a9afb2;
}
.bg-lvl3 .btn.btn-light:focus, .bg-lvl3 .btn.btn-light.focus {
  color: #fff;
}
.bg-lvl3 .btn.btn-light:not(:disabled):not(.disabled):active, .bg-lvl3 .btn.btn-light:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #b9bec0 !important;
  border-color: #b9bec0 !important;
}

.bg-lvl3 .btn.btn-dark {
  background-color: #373e3e;
  border-color: #373e3e;
}
.bg-lvl3 .btn.btn-dark:hover {
  background-color: #292e2e;
  border-color: #292e2e;
}
.bg-lvl3 .btn.btn-dark:focus, .bg-lvl3 .btn.btn-dark.focus {
  color: #fff;
}
.bg-lvl3 .btn.btn-dark:not(:disabled):not(.disabled):active, .bg-lvl3 .btn.btn-dark:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #373e3e !important;
  border-color: #373e3e !important;
}

.bg-lvl3 .btn.btn-info {
  background-color: #0f5bff;
  border-color: #0f5bff;
}
.bg-lvl3 .btn.btn-info:hover {
  background-color: #004cef;
  border-color: #004cef;
}
.bg-lvl3 .btn.btn-info:focus, .bg-lvl3 .btn.btn-info.focus {
  color: #fff;
}
.bg-lvl3 .btn.btn-info:not(:disabled):not(.disabled):active, .bg-lvl3 .btn.btn-info:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #0f5bff !important;
  border-color: #0f5bff !important;
}

.bg-lvl3 .btn.btn-success {
  background-color: #00940a;
  border-color: #00940a;
}
.bg-lvl3 .btn.btn-success:hover {
  background-color: #007508;
  border-color: #007508;
}
.bg-lvl3 .btn.btn-success:focus, .bg-lvl3 .btn.btn-success.focus {
  color: #fff;
}
.bg-lvl3 .btn.btn-success:not(:disabled):not(.disabled):active, .bg-lvl3 .btn.btn-success:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #00940a !important;
  border-color: #00940a !important;
}

.bg-lvl3 .btn.btn-warning {
  background-color: #c66a00;
  border-color: #c66a00;
}
.bg-lvl3 .btn.btn-warning:hover {
  background-color: #a75a00;
  border-color: #a75a00;
}
.bg-lvl3 .btn.btn-warning:focus, .bg-lvl3 .btn.btn-warning.focus {
  color: #fff;
}
.bg-lvl3 .btn.btn-warning:not(:disabled):not(.disabled):active, .bg-lvl3 .btn.btn-warning:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #c66a00 !important;
  border-color: #c66a00 !important;
}

.bg-lvl3 .btn.btn-danger {
  background-color: #cb3120;
  border-color: #cb3120;
}
.bg-lvl3 .btn.btn-danger:hover {
  background-color: #b12b1c;
  border-color: #b12b1c;
}
.bg-lvl3 .btn.btn-danger:focus, .bg-lvl3 .btn.btn-danger.focus {
  color: #fff;
}
.bg-lvl3 .btn.btn-danger:not(:disabled):not(.disabled):active, .bg-lvl3 .btn.btn-danger:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #cb3120 !important;
  border-color: #cb3120 !important;
}

.bg-lvl2 .btn.btn-secondary {
  background-color: #687073;
  border-color: #687073;
}
.bg-lvl2 .btn.btn-secondary:hover {
  background-color: #596063;
  border-color: #596063;
}
.bg-lvl2 .btn.btn-secondary:focus, .bg-lvl2 .btn.btn-secondary.focus {
  color: #fff;
}
.bg-lvl2 .btn.btn-secondary:not(:disabled):not(.disabled):active, .bg-lvl2 .btn.btn-secondary:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #687073 !important;
  border-color: #687073 !important;
}

.bg-lvl2 .btn.btn-light {
  background-color: #c1c5c7;
  border-color: #c1c5c7;
}
.bg-lvl2 .btn.btn-light:hover {
  background-color: #b1b6b8;
  border-color: #b1b6b8;
}
.bg-lvl2 .btn.btn-light:focus, .bg-lvl2 .btn.btn-light.focus {
  color: #fff;
}
.bg-lvl2 .btn.btn-light:not(:disabled):not(.disabled):active, .bg-lvl2 .btn.btn-light:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #c1c5c7 !important;
  border-color: #c1c5c7 !important;
}

.bg-lvl2 .btn.btn-dark {
  background-color: #3c4343;
  border-color: #3c4343;
}
.bg-lvl2 .btn.btn-dark:hover {
  background-color: #2e3333;
  border-color: #2e3333;
}
.bg-lvl2 .btn.btn-dark:focus, .bg-lvl2 .btn.btn-dark.focus {
  color: #fff;
}
.bg-lvl2 .btn.btn-dark:not(:disabled):not(.disabled):active, .bg-lvl2 .btn.btn-dark:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #3c4343 !important;
  border-color: #3c4343 !important;
}

.bg-lvl2 .btn.btn-info {
  background-color: #1a62ff;
  border-color: #1a62ff;
}
.bg-lvl2 .btn.btn-info:hover {
  background-color: #004ffa;
  border-color: #004ffa;
}
.bg-lvl2 .btn.btn-info:focus, .bg-lvl2 .btn.btn-info.focus {
  color: #fff;
}
.bg-lvl2 .btn.btn-info:not(:disabled):not(.disabled):active, .bg-lvl2 .btn.btn-info:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #1a62ff !important;
  border-color: #1a62ff !important;
}

.bg-lvl2 .btn.btn-success {
  background-color: #009e0b;
  border-color: #009e0b;
}
.bg-lvl2 .btn.btn-success:hover {
  background-color: #007f09;
  border-color: #007f09;
}
.bg-lvl2 .btn.btn-success:focus, .bg-lvl2 .btn.btn-success.focus {
  color: #fff;
}
.bg-lvl2 .btn.btn-success:not(:disabled):not(.disabled):active, .bg-lvl2 .btn.btn-success:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #009e0b !important;
  border-color: #009e0b !important;
}

.bg-lvl2 .btn.btn-warning {
  background-color: #d06f00;
  border-color: #d06f00;
}
.bg-lvl2 .btn.btn-warning:hover {
  background-color: #b15f00;
  border-color: #b15f00;
}
.bg-lvl2 .btn.btn-warning:focus, .bg-lvl2 .btn.btn-warning.focus {
  color: #fff;
}
.bg-lvl2 .btn.btn-warning:not(:disabled):not(.disabled):active, .bg-lvl2 .btn.btn-warning:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #d06f00 !important;
  border-color: #d06f00 !important;
}

.bg-lvl2 .btn.btn-danger {
  background-color: #d43321;
  border-color: #d43321;
}
.bg-lvl2 .btn.btn-danger:hover {
  background-color: #ba2d1d;
  border-color: #ba2d1d;
}
.bg-lvl2 .btn.btn-danger:focus, .bg-lvl2 .btn.btn-danger.focus {
  color: #fff;
}
.bg-lvl2 .btn.btn-danger:not(:disabled):not(.disabled):active, .bg-lvl2 .btn.btn-danger:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #d43321 !important;
  border-color: #d43321 !important;
}

.bg-lvl1 .btn.btn-secondary {
  background-color: #6e777a;
  border-color: #6e777a;
}
.bg-lvl1 .btn.btn-secondary:hover {
  background-color: #5f676a;
  border-color: #5f676a;
}
.bg-lvl1 .btn.btn-secondary:focus, .bg-lvl1 .btn.btn-secondary.focus {
  color: #fff;
}
.bg-lvl1 .btn.btn-secondary:not(:disabled):not(.disabled):active, .bg-lvl1 .btn.btn-secondary:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #6e777a !important;
  border-color: #6e777a !important;
}

.bg-lvl1 .btn.btn-light {
  background-color: #c4c8ca;
  border-color: #c4c8ca;
}
.bg-lvl1 .btn.btn-light:hover {
  background-color: #b4b9bc;
  border-color: #b4b9bc;
}
.bg-lvl1 .btn.btn-light:focus, .bg-lvl1 .btn.btn-light.focus {
  color: #fff;
}
.bg-lvl1 .btn.btn-light:not(:disabled):not(.disabled):active, .bg-lvl1 .btn.btn-light:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #c4c8ca !important;
  border-color: #c4c8ca !important;
}

.bg-lvl1 .btn.btn-dark {
  background-color: #414949;
  border-color: #414949;
}
.bg-lvl1 .btn.btn-dark:hover {
  background-color: #333939;
  border-color: #333939;
}
.bg-lvl1 .btn.btn-dark:focus, .bg-lvl1 .btn.btn-dark.focus {
  color: #fff;
}
.bg-lvl1 .btn.btn-dark:not(:disabled):not(.disabled):active, .bg-lvl1 .btn.btn-dark:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #414949 !important;
  border-color: #414949 !important;
}

.bg-lvl1 .btn.btn-info {
  background-color: #2469ff;
  border-color: #2469ff;
}
.bg-lvl1 .btn.btn-info:hover {
  background-color: #0554ff;
  border-color: #0554ff;
}
.bg-lvl1 .btn.btn-info:focus, .bg-lvl1 .btn.btn-info.focus {
  color: #fff;
}
.bg-lvl1 .btn.btn-info:not(:disabled):not(.disabled):active, .bg-lvl1 .btn.btn-info:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #2469ff !important;
  border-color: #2469ff !important;
}

.bg-lvl1 .btn.btn-success {
  background-color: #00aa0c;
  border-color: #00aa0c;
}
.bg-lvl1 .btn.btn-success:hover {
  background-color: #008b0a;
  border-color: #008b0a;
}
.bg-lvl1 .btn.btn-success:focus, .bg-lvl1 .btn.btn-success.focus {
  color: #fff;
}
.bg-lvl1 .btn.btn-success:not(:disabled):not(.disabled):active, .bg-lvl1 .btn.btn-success:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #00aa0c !important;
  border-color: #00aa0c !important;
}

.bg-lvl1 .btn.btn-warning {
  background-color: #da7400;
  border-color: #da7400;
}
.bg-lvl1 .btn.btn-warning:hover {
  background-color: #bb6400;
  border-color: #bb6400;
}
.bg-lvl1 .btn.btn-warning:focus, .bg-lvl1 .btn.btn-warning.focus {
  color: #fff;
}
.bg-lvl1 .btn.btn-warning:not(:disabled):not(.disabled):active, .bg-lvl1 .btn.btn-warning:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #da7400 !important;
  border-color: #da7400 !important;
}

.bg-lvl1 .btn.btn-danger {
  background-color: #dd3724;
  border-color: #dd3724;
}
.bg-lvl1 .btn.btn-danger:hover {
  background-color: #c42f1e;
  border-color: #c42f1e;
}
.bg-lvl1 .btn.btn-danger:focus, .bg-lvl1 .btn.btn-danger.focus {
  color: #fff;
}
.bg-lvl1 .btn.btn-danger:not(:disabled):not(.disabled):active, .bg-lvl1 .btn.btn-danger:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #dd3724 !important;
  border-color: #dd3724 !important;
}

.bg-alt-lvl4 .btn.btn-secondary {
  background-color: #70797a;
  border-color: #70797a;
}
.bg-alt-lvl4 .btn.btn-secondary:hover {
  background-color: #61696a;
  border-color: #61696a;
}
.bg-alt-lvl4 .btn.btn-secondary:focus, .bg-alt-lvl4 .btn.btn-secondary.focus {
  color: #fff;
}
.bg-alt-lvl4 .btn.btn-secondary:not(:disabled):not(.disabled):active, .bg-alt-lvl4 .btn.btn-secondary:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #70797a !important;
  border-color: #70797a !important;
}

.bg-alt-lvl4 .btn.btn-light {
  background-color: #a3afb3;
  border-color: #a3afb3;
}
.bg-alt-lvl4 .btn.btn-light:hover {
  background-color: #92a0a5;
  border-color: #92a0a5;
}
.bg-alt-lvl4 .btn.btn-light:focus, .bg-alt-lvl4 .btn.btn-light.focus {
  color: #fff;
}
.bg-alt-lvl4 .btn.btn-light:not(:disabled):not(.disabled):active, .bg-alt-lvl4 .btn.btn-light:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #a3afb3 !important;
  border-color: #a3afb3 !important;
}

.bg-alt-lvl4 .btn.btn-dark {
  background-color: #343737;
  border-color: #343737;
}
.bg-alt-lvl4 .btn.btn-dark:hover {
  background-color: #252727;
  border-color: #252727;
}
.bg-alt-lvl4 .btn.btn-dark:focus, .bg-alt-lvl4 .btn.btn-dark.focus {
  color: #fff;
}
.bg-alt-lvl4 .btn.btn-dark:not(:disabled):not(.disabled):active, .bg-alt-lvl4 .btn.btn-dark:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #343737 !important;
  border-color: #343737 !important;
}

.bg-alt-lvl4 .btn.btn-info {
  background-color: #1476ff;
  border-color: #1476ff;
}
.bg-alt-lvl4 .btn.btn-info:hover {
  background-color: #0066f4;
  border-color: #0066f4;
}
.bg-alt-lvl4 .btn.btn-info:focus, .bg-alt-lvl4 .btn.btn-info.focus {
  color: #fff;
}
.bg-alt-lvl4 .btn.btn-info:not(:disabled):not(.disabled):active, .bg-alt-lvl4 .btn.btn-info:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #1476ff !important;
  border-color: #1476ff !important;
}

.bg-alt-lvl4 .btn.btn-success {
  background-color: #00a83e;
  border-color: #00a83e;
}
.bg-alt-lvl4 .btn.btn-success:hover {
  background-color: #008933;
  border-color: #008933;
}
.bg-alt-lvl4 .btn.btn-success:focus, .bg-alt-lvl4 .btn.btn-success.focus {
  color: #fff;
}
.bg-alt-lvl4 .btn.btn-success:not(:disabled):not(.disabled):active, .bg-alt-lvl4 .btn.btn-success:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #00a83e !important;
  border-color: #00a83e !important;
}

.bg-alt-lvl4 .btn.btn-warning {
  background-color: #cc7400;
  border-color: #cc7400;
}
.bg-alt-lvl4 .btn.btn-warning:hover {
  background-color: #ad6300;
  border-color: #ad6300;
}
.bg-alt-lvl4 .btn.btn-warning:focus, .bg-alt-lvl4 .btn.btn-warning.focus {
  color: #fff;
}
.bg-alt-lvl4 .btn.btn-warning:not(:disabled):not(.disabled):active, .bg-alt-lvl4 .btn.btn-warning:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #cc7400 !important;
  border-color: #cc7400 !important;
}

.bg-alt-lvl4 .btn.btn-danger {
  background-color: #d93d26;
  border-color: #d93d26;
}
.bg-alt-lvl4 .btn.btn-danger:hover {
  background-color: #bf3621;
  border-color: #bf3621;
}
.bg-alt-lvl4 .btn.btn-danger:focus, .bg-alt-lvl4 .btn.btn-danger.focus {
  color: #fff;
}
.bg-alt-lvl4 .btn.btn-danger:not(:disabled):not(.disabled):active, .bg-alt-lvl4 .btn.btn-danger:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #d93d26 !important;
  border-color: #d93d26 !important;
}

.bg-alt-lvl3 .btn.btn-secondary {
  background-color: #7a8385;
  border-color: #7a8385;
}
.bg-alt-lvl3 .btn.btn-secondary:hover {
  background-color: #6b7375;
  border-color: #6b7375;
}
.bg-alt-lvl3 .btn.btn-secondary:focus, .bg-alt-lvl3 .btn.btn-secondary.focus {
  color: #fff;
}
.bg-alt-lvl3 .btn.btn-secondary:not(:disabled):not(.disabled):active, .bg-alt-lvl3 .btn.btn-secondary:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #7a8385 !important;
  border-color: #7a8385 !important;
}

.bg-alt-lvl3 .btn.btn-light {
  background-color: #b1bbbe;
  border-color: #b1bbbe;
}
.bg-alt-lvl3 .btn.btn-light:hover {
  background-color: #a0acb0;
  border-color: #a0acb0;
}
.bg-alt-lvl3 .btn.btn-light:focus, .bg-alt-lvl3 .btn.btn-light.focus {
  color: #fff;
}
.bg-alt-lvl3 .btn.btn-light:not(:disabled):not(.disabled):active, .bg-alt-lvl3 .btn.btn-light:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #b1bbbe !important;
  border-color: #b1bbbe !important;
}

.bg-alt-lvl3 .btn.btn-dark {
  background-color: #3b3f3f;
  border-color: #3b3f3f;
}
.bg-alt-lvl3 .btn.btn-dark:hover {
  background-color: #2c2f2f;
  border-color: #2c2f2f;
}
.bg-alt-lvl3 .btn.btn-dark:focus, .bg-alt-lvl3 .btn.btn-dark.focus {
  color: #fff;
}
.bg-alt-lvl3 .btn.btn-dark:not(:disabled):not(.disabled):active, .bg-alt-lvl3 .btn.btn-dark:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #3b3f3f !important;
  border-color: #3b3f3f !important;
}

.bg-alt-lvl3 .btn.btn-info {
  background-color: #2982ff;
  border-color: #2982ff;
}
.bg-alt-lvl3 .btn.btn-info:hover {
  background-color: #0a70ff;
  border-color: #0a70ff;
}
.bg-alt-lvl3 .btn.btn-info:focus, .bg-alt-lvl3 .btn.btn-info.focus {
  color: #fff;
}
.bg-alt-lvl3 .btn.btn-info:not(:disabled):not(.disabled):active, .bg-alt-lvl3 .btn.btn-info:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #2982ff !important;
  border-color: #2982ff !important;
}

.bg-alt-lvl3 .btn.btn-success {
  background-color: #00b844;
  border-color: #00b844;
}
.bg-alt-lvl3 .btn.btn-success:hover {
  background-color: #009939;
  border-color: #009939;
}
.bg-alt-lvl3 .btn.btn-success:focus, .bg-alt-lvl3 .btn.btn-success.focus {
  color: #fff;
}
.bg-alt-lvl3 .btn.btn-success:not(:disabled):not(.disabled):active, .bg-alt-lvl3 .btn.btn-success:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #00b844 !important;
  border-color: #00b844 !important;
}

.bg-alt-lvl3 .btn.btn-warning {
  background-color: #db7c00;
  border-color: #db7c00;
}
.bg-alt-lvl3 .btn.btn-warning:hover {
  background-color: #bc6b00;
  border-color: #bc6b00;
}
.bg-alt-lvl3 .btn.btn-warning:focus, .bg-alt-lvl3 .btn.btn-warning.focus {
  color: #fff;
}
.bg-alt-lvl3 .btn.btn-warning:not(:disabled):not(.disabled):active, .bg-alt-lvl3 .btn.btn-warning:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #db7c00 !important;
  border-color: #db7c00 !important;
}

.bg-alt-lvl3 .btn.btn-danger {
  background-color: #e8442c;
  border-color: #e8442c;
}
.bg-alt-lvl3 .btn.btn-danger:hover {
  background-color: #dd3118;
  border-color: #dd3118;
}
.bg-alt-lvl3 .btn.btn-danger:focus, .bg-alt-lvl3 .btn.btn-danger.focus {
  color: #fff;
}
.bg-alt-lvl3 .btn.btn-danger:not(:disabled):not(.disabled):active, .bg-alt-lvl3 .btn.btn-danger:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #e8442c !important;
  border-color: #e8442c !important;
}

.bg-alt-lvl2 .btn.btn-secondary {
  background-color: #848d8f;
  border-color: #848d8f;
}
.bg-alt-lvl2 .btn.btn-secondary:hover {
  background-color: #747e80;
  border-color: #747e80;
}
.bg-alt-lvl2 .btn.btn-secondary:focus, .bg-alt-lvl2 .btn.btn-secondary.focus {
  color: #fff;
}
.bg-alt-lvl2 .btn.btn-secondary:not(:disabled):not(.disabled):active, .bg-alt-lvl2 .btn.btn-secondary:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #848d8f !important;
  border-color: #848d8f !important;
}

.bg-alt-lvl2 .btn.btn-light {
  background-color: #c2cacc;
  border-color: #c2cacc;
}
.bg-alt-lvl2 .btn.btn-light:hover {
  background-color: #b1bcbe;
  border-color: #b1bcbe;
}
.bg-alt-lvl2 .btn.btn-light:focus, .bg-alt-lvl2 .btn.btn-light.focus {
  color: #fff;
}
.bg-alt-lvl2 .btn.btn-light:not(:disabled):not(.disabled):active, .bg-alt-lvl2 .btn.btn-light:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #c2cacc !important;
  border-color: #c2cacc !important;
}

.bg-alt-lvl2 .btn.btn-dark {
  background-color: #454a4a;
  border-color: #454a4a;
}
.bg-alt-lvl2 .btn.btn-dark:hover {
  background-color: #363a3a;
  border-color: #363a3a;
}
.bg-alt-lvl2 .btn.btn-dark:focus, .bg-alt-lvl2 .btn.btn-dark.focus {
  color: #fff;
}
.bg-alt-lvl2 .btn.btn-dark:not(:disabled):not(.disabled):active, .bg-alt-lvl2 .btn.btn-dark:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #454a4a !important;
  border-color: #454a4a !important;
}

.bg-alt-lvl2 .btn.btn-info {
  background-color: #3d8eff;
  border-color: #3d8eff;
}
.bg-alt-lvl2 .btn.btn-info:hover {
  background-color: #1e7cff;
  border-color: #1e7cff;
}
.bg-alt-lvl2 .btn.btn-info:focus, .bg-alt-lvl2 .btn.btn-info.focus {
  color: #fff;
}
.bg-alt-lvl2 .btn.btn-info:not(:disabled):not(.disabled):active, .bg-alt-lvl2 .btn.btn-info:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #3d8eff !important;
  border-color: #3d8eff !important;
}

.bg-alt-lvl2 .btn.btn-success {
  background-color: #00c74a;
  border-color: #00c74a;
}
.bg-alt-lvl2 .btn.btn-success:hover {
  background-color: #00a83f;
  border-color: #00a83f;
}
.bg-alt-lvl2 .btn.btn-success:focus, .bg-alt-lvl2 .btn.btn-success.focus {
  color: #fff;
}
.bg-alt-lvl2 .btn.btn-success:not(:disabled):not(.disabled):active, .bg-alt-lvl2 .btn.btn-success:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #00c74a !important;
  border-color: #00c74a !important;
}

.bg-alt-lvl2 .btn.btn-warning {
  background-color: #eb8500;
  border-color: #eb8500;
}
.bg-alt-lvl2 .btn.btn-warning:hover {
  background-color: #cc7400;
  border-color: #cc7400;
}
.bg-alt-lvl2 .btn.btn-warning:focus, .bg-alt-lvl2 .btn.btn-warning.focus {
  color: #fff;
}
.bg-alt-lvl2 .btn.btn-warning:not(:disabled):not(.disabled):active, .bg-alt-lvl2 .btn.btn-warning:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #eb8500 !important;
  border-color: #eb8500 !important;
}

.bg-alt-lvl2 .btn.btn-danger {
  background-color: #f44d34;
  border-color: #f44d34;
}
.bg-alt-lvl2 .btn.btn-danger:hover {
  background-color: #f23417;
  border-color: #f23417;
}
.bg-alt-lvl2 .btn.btn-danger:focus, .bg-alt-lvl2 .btn.btn-danger.focus {
  color: #fff;
}
.bg-alt-lvl2 .btn.btn-danger:not(:disabled):not(.disabled):active, .bg-alt-lvl2 .btn.btn-danger:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #f44d34 !important;
  border-color: #f44d34 !important;
}

.bg-alt-lvl1 .btn.btn-secondary {
  background-color: #949c9e;
  border-color: #949c9e;
}
.bg-alt-lvl1 .btn.btn-secondary:hover {
  background-color: #848d8f;
  border-color: #848d8f;
}
.bg-alt-lvl1 .btn.btn-secondary:focus, .bg-alt-lvl1 .btn.btn-secondary.focus {
  color: #fff;
}
.bg-alt-lvl1 .btn.btn-secondary:not(:disabled):not(.disabled):active, .bg-alt-lvl1 .btn.btn-secondary:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #949c9e !important;
  border-color: #949c9e !important;
}

.bg-alt-lvl1 .btn.btn-light {
  background-color: #d8ddde;
  border-color: #d8ddde;
}
.bg-alt-lvl1 .btn.btn-light:hover {
  background-color: #c7cfd0;
  border-color: #c7cfd0;
}
.bg-alt-lvl1 .btn.btn-light:focus, .bg-alt-lvl1 .btn.btn-light.focus {
  color: #fff;
}
.bg-alt-lvl1 .btn.btn-light:not(:disabled):not(.disabled):active, .bg-alt-lvl1 .btn.btn-light:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #d8ddde !important;
  border-color: #d8ddde !important;
}

.bg-alt-lvl1 .btn.btn-dark {
  background-color: #515757;
  border-color: #515757;
}
.bg-alt-lvl1 .btn.btn-dark:hover {
  background-color: #424747;
  border-color: #424747;
}
.bg-alt-lvl1 .btn.btn-dark:focus, .bg-alt-lvl1 .btn.btn-dark.focus {
  color: #fff;
}
.bg-alt-lvl1 .btn.btn-dark:not(:disabled):not(.disabled):active, .bg-alt-lvl1 .btn.btn-dark:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #515757 !important;
  border-color: #515757 !important;
}

.bg-alt-lvl1 .btn.btn-info {
  background-color: #529aff;
  border-color: #529aff;
}
.bg-alt-lvl1 .btn.btn-info:hover {
  background-color: #3388ff;
  border-color: #3388ff;
}
.bg-alt-lvl1 .btn.btn-info:focus, .bg-alt-lvl1 .btn.btn-info.focus {
  color: #fff;
}
.bg-alt-lvl1 .btn.btn-info:not(:disabled):not(.disabled):active, .bg-alt-lvl1 .btn.btn-info:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #529aff !important;
  border-color: #529aff !important;
}

.bg-alt-lvl1 .btn.btn-success {
  background-color: #00d750;
  border-color: #00d750;
}
.bg-alt-lvl1 .btn.btn-success:hover {
  background-color: #00b845;
  border-color: #00b845;
}
.bg-alt-lvl1 .btn.btn-success:focus, .bg-alt-lvl1 .btn.btn-success.focus {
  color: #fff;
}
.bg-alt-lvl1 .btn.btn-success:not(:disabled):not(.disabled):active, .bg-alt-lvl1 .btn.btn-success:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #00d750 !important;
  border-color: #00d750 !important;
}

.bg-alt-lvl1 .btn.btn-warning {
  background-color: #f98d00;
  border-color: #f98d00;
}
.bg-alt-lvl1 .btn.btn-warning:hover {
  background-color: #da7c00;
  border-color: #da7c00;
}
.bg-alt-lvl1 .btn.btn-warning:focus, .bg-alt-lvl1 .btn.btn-warning.focus {
  color: #fff;
}
.bg-alt-lvl1 .btn.btn-warning:not(:disabled):not(.disabled):active, .bg-alt-lvl1 .btn.btn-warning:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #f98d00 !important;
  border-color: #f98d00 !important;
}

.bg-alt-lvl1 .btn.btn-danger {
  background-color: #ff563c;
  border-color: #ff563c;
}
.bg-alt-lvl1 .btn.btn-danger:hover {
  background-color: #ff3b1d;
  border-color: #ff3b1d;
}
.bg-alt-lvl1 .btn.btn-danger:focus, .bg-alt-lvl1 .btn.btn-danger.focus {
  color: #fff;
}
.bg-alt-lvl1 .btn.btn-danger:not(:disabled):not(.disabled):active, .bg-alt-lvl1 .btn.btn-danger:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #ff563c !important;
  border-color: #ff563c !important;
}

.bg-lvl4 .btn.btn-discreet-secondary {
  color: #5f6668;
}
.bg-lvl4 .btn.btn-discreet-secondary:hover:not(:disabled):not(.disabled) {
  background-color: #5f6668;
  border-color: #5f6668;
}
.bg-lvl4 .btn.btn-discreet-secondary:not(:disabled):not(.disabled):focus, .bg-lvl4 .btn.btn-discreet-secondary:not(:disabled):not(.disabled).focus {
  background-color: #5f6668;
}
.bg-lvl4 .btn.btn-discreet-secondary:not(:disabled):not(.disabled):active, .bg-lvl4 .btn.btn-discreet-secondary:not(:disabled):not(.disabled).active {
  color: rgba(95, 102, 104, 0.75) !important;
  background-color: #5f6668;
  border-color: #5f6668;
}

.bg-lvl4 .btn.btn-discreet-light {
  color: #b1b7b9;
}
.bg-lvl4 .btn.btn-discreet-light:hover:not(:disabled):not(.disabled) {
  background-color: #b1b7b9;
  border-color: #b1b7b9;
}
.bg-lvl4 .btn.btn-discreet-light:not(:disabled):not(.disabled):focus, .bg-lvl4 .btn.btn-discreet-light:not(:disabled):not(.disabled).focus {
  background-color: #b1b7b9;
}
.bg-lvl4 .btn.btn-discreet-light:not(:disabled):not(.disabled):active, .bg-lvl4 .btn.btn-discreet-light:not(:disabled):not(.disabled).active {
  color: rgba(177, 183, 185, 0.75) !important;
  background-color: #b1b7b9;
  border-color: #b1b7b9;
}

.bg-lvl4 .btn.btn-discreet-dark {
  color: #323939;
}
.bg-lvl4 .btn.btn-discreet-dark:hover:not(:disabled):not(.disabled) {
  background-color: #323939;
  border-color: #323939;
}
.bg-lvl4 .btn.btn-discreet-dark:not(:disabled):not(.disabled):focus, .bg-lvl4 .btn.btn-discreet-dark:not(:disabled):not(.disabled).focus {
  background-color: #323939;
}
.bg-lvl4 .btn.btn-discreet-dark:not(:disabled):not(.disabled):active, .bg-lvl4 .btn.btn-discreet-dark:not(:disabled):not(.disabled).active {
  color: rgba(50, 57, 57, 0.75) !important;
  background-color: #323939;
  border-color: #323939;
}

.bg-lvl4 .btn.btn-discreet-info {
  color: #0554ff;
}
.bg-lvl4 .btn.btn-discreet-info:hover:not(:disabled):not(.disabled) {
  background-color: #0554ff;
  border-color: #0554ff;
}
.bg-lvl4 .btn.btn-discreet-info:not(:disabled):not(.disabled):focus, .bg-lvl4 .btn.btn-discreet-info:not(:disabled):not(.disabled).focus {
  background-color: #0554ff;
}
.bg-lvl4 .btn.btn-discreet-info:not(:disabled):not(.disabled):active, .bg-lvl4 .btn.btn-discreet-info:not(:disabled):not(.disabled).active {
  color: rgba(5, 84, 255, 0.75) !important;
  background-color: #0554ff;
  border-color: #0554ff;
}

.bg-lvl4 .btn.btn-discreet-success {
  color: #008a09;
}
.bg-lvl4 .btn.btn-discreet-success:hover:not(:disabled):not(.disabled) {
  background-color: #008a09;
  border-color: #008a09;
}
.bg-lvl4 .btn.btn-discreet-success:not(:disabled):not(.disabled):focus, .bg-lvl4 .btn.btn-discreet-success:not(:disabled):not(.disabled).focus {
  background-color: #008a09;
}
.bg-lvl4 .btn.btn-discreet-success:not(:disabled):not(.disabled):active, .bg-lvl4 .btn.btn-discreet-success:not(:disabled):not(.disabled).active {
  color: rgba(0, 138, 9, 0.75) !important;
  background-color: #008a09;
  border-color: #008a09;
}

.bg-lvl4 .btn.btn-discreet-warning {
  color: #bc6500;
}
.bg-lvl4 .btn.btn-discreet-warning:hover:not(:disabled):not(.disabled) {
  background-color: #bc6500;
  border-color: #bc6500;
}
.bg-lvl4 .btn.btn-discreet-warning:not(:disabled):not(.disabled):focus, .bg-lvl4 .btn.btn-discreet-warning:not(:disabled):not(.disabled).focus {
  background-color: #bc6500;
}
.bg-lvl4 .btn.btn-discreet-warning:not(:disabled):not(.disabled):active, .bg-lvl4 .btn.btn-discreet-warning:not(:disabled):not(.disabled).active {
  color: rgba(188, 101, 0, 0.75) !important;
  background-color: #bc6500;
  border-color: #bc6500;
}

.bg-lvl4 .btn.btn-discreet-danger {
  color: #c22f1f;
}
.bg-lvl4 .btn.btn-discreet-danger:hover:not(:disabled):not(.disabled) {
  background-color: #c22f1f;
  border-color: #c22f1f;
}
.bg-lvl4 .btn.btn-discreet-danger:not(:disabled):not(.disabled):focus, .bg-lvl4 .btn.btn-discreet-danger:not(:disabled):not(.disabled).focus {
  background-color: #c22f1f;
}
.bg-lvl4 .btn.btn-discreet-danger:not(:disabled):not(.disabled):active, .bg-lvl4 .btn.btn-discreet-danger:not(:disabled):not(.disabled).active {
  color: rgba(194, 47, 31, 0.75) !important;
  background-color: #c22f1f;
  border-color: #c22f1f;
}

.bg-lvl3 .btn.btn-discreet-secondary {
  color: #666e70;
}
.bg-lvl3 .btn.btn-discreet-secondary:hover:not(:disabled):not(.disabled) {
  background-color: #666e70;
  border-color: #666e70;
}
.bg-lvl3 .btn.btn-discreet-secondary:not(:disabled):not(.disabled):focus, .bg-lvl3 .btn.btn-discreet-secondary:not(:disabled):not(.disabled).focus {
  background-color: #666e70;
}
.bg-lvl3 .btn.btn-discreet-secondary:not(:disabled):not(.disabled):active, .bg-lvl3 .btn.btn-discreet-secondary:not(:disabled):not(.disabled).active {
  color: rgba(102, 110, 112, 0.75) !important;
  background-color: #666e70;
  border-color: #666e70;
}

.bg-lvl3 .btn.btn-discreet-light {
  color: #b9bec0;
}
.bg-lvl3 .btn.btn-discreet-light:hover:not(:disabled):not(.disabled) {
  background-color: #b9bec0;
  border-color: #b9bec0;
}
.bg-lvl3 .btn.btn-discreet-light:not(:disabled):not(.disabled):focus, .bg-lvl3 .btn.btn-discreet-light:not(:disabled):not(.disabled).focus {
  background-color: #b9bec0;
}
.bg-lvl3 .btn.btn-discreet-light:not(:disabled):not(.disabled):active, .bg-lvl3 .btn.btn-discreet-light:not(:disabled):not(.disabled).active {
  color: rgba(185, 190, 192, 0.75) !important;
  background-color: #b9bec0;
  border-color: #b9bec0;
}

.bg-lvl3 .btn.btn-discreet-dark {
  color: #373e3e;
}
.bg-lvl3 .btn.btn-discreet-dark:hover:not(:disabled):not(.disabled) {
  background-color: #373e3e;
  border-color: #373e3e;
}
.bg-lvl3 .btn.btn-discreet-dark:not(:disabled):not(.disabled):focus, .bg-lvl3 .btn.btn-discreet-dark:not(:disabled):not(.disabled).focus {
  background-color: #373e3e;
}
.bg-lvl3 .btn.btn-discreet-dark:not(:disabled):not(.disabled):active, .bg-lvl3 .btn.btn-discreet-dark:not(:disabled):not(.disabled).active {
  color: rgba(55, 62, 62, 0.75) !important;
  background-color: #373e3e;
  border-color: #373e3e;
}

.bg-lvl3 .btn.btn-discreet-info {
  color: #0f5bff;
}
.bg-lvl3 .btn.btn-discreet-info:hover:not(:disabled):not(.disabled) {
  background-color: #0f5bff;
  border-color: #0f5bff;
}
.bg-lvl3 .btn.btn-discreet-info:not(:disabled):not(.disabled):focus, .bg-lvl3 .btn.btn-discreet-info:not(:disabled):not(.disabled).focus {
  background-color: #0f5bff;
}
.bg-lvl3 .btn.btn-discreet-info:not(:disabled):not(.disabled):active, .bg-lvl3 .btn.btn-discreet-info:not(:disabled):not(.disabled).active {
  color: rgba(15, 91, 255, 0.75) !important;
  background-color: #0f5bff;
  border-color: #0f5bff;
}

.bg-lvl3 .btn.btn-discreet-success {
  color: #00940a;
}
.bg-lvl3 .btn.btn-discreet-success:hover:not(:disabled):not(.disabled) {
  background-color: #00940a;
  border-color: #00940a;
}
.bg-lvl3 .btn.btn-discreet-success:not(:disabled):not(.disabled):focus, .bg-lvl3 .btn.btn-discreet-success:not(:disabled):not(.disabled).focus {
  background-color: #00940a;
}
.bg-lvl3 .btn.btn-discreet-success:not(:disabled):not(.disabled):active, .bg-lvl3 .btn.btn-discreet-success:not(:disabled):not(.disabled).active {
  color: rgba(0, 148, 10, 0.75) !important;
  background-color: #00940a;
  border-color: #00940a;
}

.bg-lvl3 .btn.btn-discreet-warning {
  color: #c66a00;
}
.bg-lvl3 .btn.btn-discreet-warning:hover:not(:disabled):not(.disabled) {
  background-color: #c66a00;
  border-color: #c66a00;
}
.bg-lvl3 .btn.btn-discreet-warning:not(:disabled):not(.disabled):focus, .bg-lvl3 .btn.btn-discreet-warning:not(:disabled):not(.disabled).focus {
  background-color: #c66a00;
}
.bg-lvl3 .btn.btn-discreet-warning:not(:disabled):not(.disabled):active, .bg-lvl3 .btn.btn-discreet-warning:not(:disabled):not(.disabled).active {
  color: rgba(198, 106, 0, 0.75) !important;
  background-color: #c66a00;
  border-color: #c66a00;
}

.bg-lvl3 .btn.btn-discreet-danger {
  color: #cb3120;
}
.bg-lvl3 .btn.btn-discreet-danger:hover:not(:disabled):not(.disabled) {
  background-color: #cb3120;
  border-color: #cb3120;
}
.bg-lvl3 .btn.btn-discreet-danger:not(:disabled):not(.disabled):focus, .bg-lvl3 .btn.btn-discreet-danger:not(:disabled):not(.disabled).focus {
  background-color: #cb3120;
}
.bg-lvl3 .btn.btn-discreet-danger:not(:disabled):not(.disabled):active, .bg-lvl3 .btn.btn-discreet-danger:not(:disabled):not(.disabled).active {
  color: rgba(203, 49, 32, 0.75) !important;
  background-color: #cb3120;
  border-color: #cb3120;
}

.bg-lvl2 .btn.btn-discreet-secondary {
  color: #687073;
}
.bg-lvl2 .btn.btn-discreet-secondary:hover:not(:disabled):not(.disabled) {
  background-color: #687073;
  border-color: #687073;
}
.bg-lvl2 .btn.btn-discreet-secondary:not(:disabled):not(.disabled):focus, .bg-lvl2 .btn.btn-discreet-secondary:not(:disabled):not(.disabled).focus {
  background-color: #687073;
}
.bg-lvl2 .btn.btn-discreet-secondary:not(:disabled):not(.disabled):active, .bg-lvl2 .btn.btn-discreet-secondary:not(:disabled):not(.disabled).active {
  color: rgba(104, 112, 115, 0.75) !important;
  background-color: #687073;
  border-color: #687073;
}

.bg-lvl2 .btn.btn-discreet-light {
  color: #c1c5c7;
}
.bg-lvl2 .btn.btn-discreet-light:hover:not(:disabled):not(.disabled) {
  background-color: #c1c5c7;
  border-color: #c1c5c7;
}
.bg-lvl2 .btn.btn-discreet-light:not(:disabled):not(.disabled):focus, .bg-lvl2 .btn.btn-discreet-light:not(:disabled):not(.disabled).focus {
  background-color: #c1c5c7;
}
.bg-lvl2 .btn.btn-discreet-light:not(:disabled):not(.disabled):active, .bg-lvl2 .btn.btn-discreet-light:not(:disabled):not(.disabled).active {
  color: rgba(193, 197, 199, 0.75) !important;
  background-color: #c1c5c7;
  border-color: #c1c5c7;
}

.bg-lvl2 .btn.btn-discreet-dark {
  color: #3c4343;
}
.bg-lvl2 .btn.btn-discreet-dark:hover:not(:disabled):not(.disabled) {
  background-color: #3c4343;
  border-color: #3c4343;
}
.bg-lvl2 .btn.btn-discreet-dark:not(:disabled):not(.disabled):focus, .bg-lvl2 .btn.btn-discreet-dark:not(:disabled):not(.disabled).focus {
  background-color: #3c4343;
}
.bg-lvl2 .btn.btn-discreet-dark:not(:disabled):not(.disabled):active, .bg-lvl2 .btn.btn-discreet-dark:not(:disabled):not(.disabled).active {
  color: rgba(60, 67, 67, 0.75) !important;
  background-color: #3c4343;
  border-color: #3c4343;
}

.bg-lvl2 .btn.btn-discreet-info {
  color: #1a62ff;
}
.bg-lvl2 .btn.btn-discreet-info:hover:not(:disabled):not(.disabled) {
  background-color: #1a62ff;
  border-color: #1a62ff;
}
.bg-lvl2 .btn.btn-discreet-info:not(:disabled):not(.disabled):focus, .bg-lvl2 .btn.btn-discreet-info:not(:disabled):not(.disabled).focus {
  background-color: #1a62ff;
}
.bg-lvl2 .btn.btn-discreet-info:not(:disabled):not(.disabled):active, .bg-lvl2 .btn.btn-discreet-info:not(:disabled):not(.disabled).active {
  color: rgba(26, 98, 255, 0.75) !important;
  background-color: #1a62ff;
  border-color: #1a62ff;
}

.bg-lvl2 .btn.btn-discreet-success {
  color: #009e0b;
}
.bg-lvl2 .btn.btn-discreet-success:hover:not(:disabled):not(.disabled) {
  background-color: #009e0b;
  border-color: #009e0b;
}
.bg-lvl2 .btn.btn-discreet-success:not(:disabled):not(.disabled):focus, .bg-lvl2 .btn.btn-discreet-success:not(:disabled):not(.disabled).focus {
  background-color: #009e0b;
}
.bg-lvl2 .btn.btn-discreet-success:not(:disabled):not(.disabled):active, .bg-lvl2 .btn.btn-discreet-success:not(:disabled):not(.disabled).active {
  color: rgba(0, 158, 11, 0.75) !important;
  background-color: #009e0b;
  border-color: #009e0b;
}

.bg-lvl2 .btn.btn-discreet-warning {
  color: #d06f00;
}
.bg-lvl2 .btn.btn-discreet-warning:hover:not(:disabled):not(.disabled) {
  background-color: #d06f00;
  border-color: #d06f00;
}
.bg-lvl2 .btn.btn-discreet-warning:not(:disabled):not(.disabled):focus, .bg-lvl2 .btn.btn-discreet-warning:not(:disabled):not(.disabled).focus {
  background-color: #d06f00;
}
.bg-lvl2 .btn.btn-discreet-warning:not(:disabled):not(.disabled):active, .bg-lvl2 .btn.btn-discreet-warning:not(:disabled):not(.disabled).active {
  color: rgba(208, 111, 0, 0.75) !important;
  background-color: #d06f00;
  border-color: #d06f00;
}

.bg-lvl2 .btn.btn-discreet-danger {
  color: #d43321;
}
.bg-lvl2 .btn.btn-discreet-danger:hover:not(:disabled):not(.disabled) {
  background-color: #d43321;
  border-color: #d43321;
}
.bg-lvl2 .btn.btn-discreet-danger:not(:disabled):not(.disabled):focus, .bg-lvl2 .btn.btn-discreet-danger:not(:disabled):not(.disabled).focus {
  background-color: #d43321;
}
.bg-lvl2 .btn.btn-discreet-danger:not(:disabled):not(.disabled):active, .bg-lvl2 .btn.btn-discreet-danger:not(:disabled):not(.disabled).active {
  color: rgba(212, 51, 33, 0.75) !important;
  background-color: #d43321;
  border-color: #d43321;
}

.bg-lvl1 .btn.btn-discreet-secondary {
  color: #6e777a;
}
.bg-lvl1 .btn.btn-discreet-secondary:hover:not(:disabled):not(.disabled) {
  background-color: #6e777a;
  border-color: #6e777a;
}
.bg-lvl1 .btn.btn-discreet-secondary:not(:disabled):not(.disabled):focus, .bg-lvl1 .btn.btn-discreet-secondary:not(:disabled):not(.disabled).focus {
  background-color: #6e777a;
}
.bg-lvl1 .btn.btn-discreet-secondary:not(:disabled):not(.disabled):active, .bg-lvl1 .btn.btn-discreet-secondary:not(:disabled):not(.disabled).active {
  color: rgba(110, 119, 122, 0.75) !important;
  background-color: #6e777a;
  border-color: #6e777a;
}

.bg-lvl1 .btn.btn-discreet-light {
  color: #c4c8ca;
}
.bg-lvl1 .btn.btn-discreet-light:hover:not(:disabled):not(.disabled) {
  background-color: #c4c8ca;
  border-color: #c4c8ca;
}
.bg-lvl1 .btn.btn-discreet-light:not(:disabled):not(.disabled):focus, .bg-lvl1 .btn.btn-discreet-light:not(:disabled):not(.disabled).focus {
  background-color: #c4c8ca;
}
.bg-lvl1 .btn.btn-discreet-light:not(:disabled):not(.disabled):active, .bg-lvl1 .btn.btn-discreet-light:not(:disabled):not(.disabled).active {
  color: rgba(196, 200, 202, 0.75) !important;
  background-color: #c4c8ca;
  border-color: #c4c8ca;
}

.bg-lvl1 .btn.btn-discreet-dark {
  color: #414949;
}
.bg-lvl1 .btn.btn-discreet-dark:hover:not(:disabled):not(.disabled) {
  background-color: #414949;
  border-color: #414949;
}
.bg-lvl1 .btn.btn-discreet-dark:not(:disabled):not(.disabled):focus, .bg-lvl1 .btn.btn-discreet-dark:not(:disabled):not(.disabled).focus {
  background-color: #414949;
}
.bg-lvl1 .btn.btn-discreet-dark:not(:disabled):not(.disabled):active, .bg-lvl1 .btn.btn-discreet-dark:not(:disabled):not(.disabled).active {
  color: rgba(65, 73, 73, 0.75) !important;
  background-color: #414949;
  border-color: #414949;
}

.bg-lvl1 .btn.btn-discreet-info {
  color: #2469ff;
}
.bg-lvl1 .btn.btn-discreet-info:hover:not(:disabled):not(.disabled) {
  background-color: #2469ff;
  border-color: #2469ff;
}
.bg-lvl1 .btn.btn-discreet-info:not(:disabled):not(.disabled):focus, .bg-lvl1 .btn.btn-discreet-info:not(:disabled):not(.disabled).focus {
  background-color: #2469ff;
}
.bg-lvl1 .btn.btn-discreet-info:not(:disabled):not(.disabled):active, .bg-lvl1 .btn.btn-discreet-info:not(:disabled):not(.disabled).active {
  color: rgba(36, 105, 255, 0.75) !important;
  background-color: #2469ff;
  border-color: #2469ff;
}

.bg-lvl1 .btn.btn-discreet-success {
  color: #00aa0c;
}
.bg-lvl1 .btn.btn-discreet-success:hover:not(:disabled):not(.disabled) {
  background-color: #00aa0c;
  border-color: #00aa0c;
}
.bg-lvl1 .btn.btn-discreet-success:not(:disabled):not(.disabled):focus, .bg-lvl1 .btn.btn-discreet-success:not(:disabled):not(.disabled).focus {
  background-color: #00aa0c;
}
.bg-lvl1 .btn.btn-discreet-success:not(:disabled):not(.disabled):active, .bg-lvl1 .btn.btn-discreet-success:not(:disabled):not(.disabled).active {
  color: rgba(0, 170, 12, 0.75) !important;
  background-color: #00aa0c;
  border-color: #00aa0c;
}

.bg-lvl1 .btn.btn-discreet-warning {
  color: #da7400;
}
.bg-lvl1 .btn.btn-discreet-warning:hover:not(:disabled):not(.disabled) {
  background-color: #da7400;
  border-color: #da7400;
}
.bg-lvl1 .btn.btn-discreet-warning:not(:disabled):not(.disabled):focus, .bg-lvl1 .btn.btn-discreet-warning:not(:disabled):not(.disabled).focus {
  background-color: #da7400;
}
.bg-lvl1 .btn.btn-discreet-warning:not(:disabled):not(.disabled):active, .bg-lvl1 .btn.btn-discreet-warning:not(:disabled):not(.disabled).active {
  color: rgba(218, 116, 0, 0.75) !important;
  background-color: #da7400;
  border-color: #da7400;
}

.bg-lvl1 .btn.btn-discreet-danger {
  color: #dd3724;
}
.bg-lvl1 .btn.btn-discreet-danger:hover:not(:disabled):not(.disabled) {
  background-color: #dd3724;
  border-color: #dd3724;
}
.bg-lvl1 .btn.btn-discreet-danger:not(:disabled):not(.disabled):focus, .bg-lvl1 .btn.btn-discreet-danger:not(:disabled):not(.disabled).focus {
  background-color: #dd3724;
}
.bg-lvl1 .btn.btn-discreet-danger:not(:disabled):not(.disabled):active, .bg-lvl1 .btn.btn-discreet-danger:not(:disabled):not(.disabled).active {
  color: rgba(221, 55, 36, 0.75) !important;
  background-color: #dd3724;
  border-color: #dd3724;
}

.bg-alt-lvl4 .btn.btn-discreet-secondary {
  color: #70797a;
}
.bg-alt-lvl4 .btn.btn-discreet-secondary:hover:not(:disabled):not(.disabled) {
  background-color: #70797a;
  border-color: #70797a;
}
.bg-alt-lvl4 .btn.btn-discreet-secondary:not(:disabled):not(.disabled):focus, .bg-alt-lvl4 .btn.btn-discreet-secondary:not(:disabled):not(.disabled).focus {
  background-color: #70797a;
}
.bg-alt-lvl4 .btn.btn-discreet-secondary:not(:disabled):not(.disabled):active, .bg-alt-lvl4 .btn.btn-discreet-secondary:not(:disabled):not(.disabled).active {
  color: rgba(112, 121, 122, 0.75) !important;
  background-color: #70797a;
  border-color: #70797a;
}

.bg-alt-lvl4 .btn.btn-discreet-light {
  color: #a3afb3;
}
.bg-alt-lvl4 .btn.btn-discreet-light:hover:not(:disabled):not(.disabled) {
  background-color: #a3afb3;
  border-color: #a3afb3;
}
.bg-alt-lvl4 .btn.btn-discreet-light:not(:disabled):not(.disabled):focus, .bg-alt-lvl4 .btn.btn-discreet-light:not(:disabled):not(.disabled).focus {
  background-color: #a3afb3;
}
.bg-alt-lvl4 .btn.btn-discreet-light:not(:disabled):not(.disabled):active, .bg-alt-lvl4 .btn.btn-discreet-light:not(:disabled):not(.disabled).active {
  color: rgba(163, 175, 179, 0.75) !important;
  background-color: #a3afb3;
  border-color: #a3afb3;
}

.bg-alt-lvl4 .btn.btn-discreet-dark {
  color: #343737;
}
.bg-alt-lvl4 .btn.btn-discreet-dark:hover:not(:disabled):not(.disabled) {
  background-color: #343737;
  border-color: #343737;
}
.bg-alt-lvl4 .btn.btn-discreet-dark:not(:disabled):not(.disabled):focus, .bg-alt-lvl4 .btn.btn-discreet-dark:not(:disabled):not(.disabled).focus {
  background-color: #343737;
}
.bg-alt-lvl4 .btn.btn-discreet-dark:not(:disabled):not(.disabled):active, .bg-alt-lvl4 .btn.btn-discreet-dark:not(:disabled):not(.disabled).active {
  color: rgba(52, 55, 55, 0.75) !important;
  background-color: #343737;
  border-color: #343737;
}

.bg-alt-lvl4 .btn.btn-discreet-info {
  color: #1476ff;
}
.bg-alt-lvl4 .btn.btn-discreet-info:hover:not(:disabled):not(.disabled) {
  background-color: #1476ff;
  border-color: #1476ff;
}
.bg-alt-lvl4 .btn.btn-discreet-info:not(:disabled):not(.disabled):focus, .bg-alt-lvl4 .btn.btn-discreet-info:not(:disabled):not(.disabled).focus {
  background-color: #1476ff;
}
.bg-alt-lvl4 .btn.btn-discreet-info:not(:disabled):not(.disabled):active, .bg-alt-lvl4 .btn.btn-discreet-info:not(:disabled):not(.disabled).active {
  color: rgba(20, 118, 255, 0.75) !important;
  background-color: #1476ff;
  border-color: #1476ff;
}

.bg-alt-lvl4 .btn.btn-discreet-success {
  color: #00a83e;
}
.bg-alt-lvl4 .btn.btn-discreet-success:hover:not(:disabled):not(.disabled) {
  background-color: #00a83e;
  border-color: #00a83e;
}
.bg-alt-lvl4 .btn.btn-discreet-success:not(:disabled):not(.disabled):focus, .bg-alt-lvl4 .btn.btn-discreet-success:not(:disabled):not(.disabled).focus {
  background-color: #00a83e;
}
.bg-alt-lvl4 .btn.btn-discreet-success:not(:disabled):not(.disabled):active, .bg-alt-lvl4 .btn.btn-discreet-success:not(:disabled):not(.disabled).active {
  color: rgba(0, 168, 62, 0.75) !important;
  background-color: #00a83e;
  border-color: #00a83e;
}

.bg-alt-lvl4 .btn.btn-discreet-warning {
  color: #cc7400;
}
.bg-alt-lvl4 .btn.btn-discreet-warning:hover:not(:disabled):not(.disabled) {
  background-color: #cc7400;
  border-color: #cc7400;
}
.bg-alt-lvl4 .btn.btn-discreet-warning:not(:disabled):not(.disabled):focus, .bg-alt-lvl4 .btn.btn-discreet-warning:not(:disabled):not(.disabled).focus {
  background-color: #cc7400;
}
.bg-alt-lvl4 .btn.btn-discreet-warning:not(:disabled):not(.disabled):active, .bg-alt-lvl4 .btn.btn-discreet-warning:not(:disabled):not(.disabled).active {
  color: rgba(204, 116, 0, 0.75) !important;
  background-color: #cc7400;
  border-color: #cc7400;
}

.bg-alt-lvl4 .btn.btn-discreet-danger {
  color: #d93d26;
}
.bg-alt-lvl4 .btn.btn-discreet-danger:hover:not(:disabled):not(.disabled) {
  background-color: #d93d26;
  border-color: #d93d26;
}
.bg-alt-lvl4 .btn.btn-discreet-danger:not(:disabled):not(.disabled):focus, .bg-alt-lvl4 .btn.btn-discreet-danger:not(:disabled):not(.disabled).focus {
  background-color: #d93d26;
}
.bg-alt-lvl4 .btn.btn-discreet-danger:not(:disabled):not(.disabled):active, .bg-alt-lvl4 .btn.btn-discreet-danger:not(:disabled):not(.disabled).active {
  color: rgba(217, 61, 38, 0.75) !important;
  background-color: #d93d26;
  border-color: #d93d26;
}

.bg-alt-lvl3 .btn.btn-discreet-secondary {
  color: #7a8385;
}
.bg-alt-lvl3 .btn.btn-discreet-secondary:hover:not(:disabled):not(.disabled) {
  background-color: #7a8385;
  border-color: #7a8385;
}
.bg-alt-lvl3 .btn.btn-discreet-secondary:not(:disabled):not(.disabled):focus, .bg-alt-lvl3 .btn.btn-discreet-secondary:not(:disabled):not(.disabled).focus {
  background-color: #7a8385;
}
.bg-alt-lvl3 .btn.btn-discreet-secondary:not(:disabled):not(.disabled):active, .bg-alt-lvl3 .btn.btn-discreet-secondary:not(:disabled):not(.disabled).active {
  color: rgba(122, 131, 133, 0.75) !important;
  background-color: #7a8385;
  border-color: #7a8385;
}

.bg-alt-lvl3 .btn.btn-discreet-light {
  color: #b1bbbe;
}
.bg-alt-lvl3 .btn.btn-discreet-light:hover:not(:disabled):not(.disabled) {
  background-color: #b1bbbe;
  border-color: #b1bbbe;
}
.bg-alt-lvl3 .btn.btn-discreet-light:not(:disabled):not(.disabled):focus, .bg-alt-lvl3 .btn.btn-discreet-light:not(:disabled):not(.disabled).focus {
  background-color: #b1bbbe;
}
.bg-alt-lvl3 .btn.btn-discreet-light:not(:disabled):not(.disabled):active, .bg-alt-lvl3 .btn.btn-discreet-light:not(:disabled):not(.disabled).active {
  color: rgba(177, 187, 190, 0.75) !important;
  background-color: #b1bbbe;
  border-color: #b1bbbe;
}

.bg-alt-lvl3 .btn.btn-discreet-dark {
  color: #3b3f3f;
}
.bg-alt-lvl3 .btn.btn-discreet-dark:hover:not(:disabled):not(.disabled) {
  background-color: #3b3f3f;
  border-color: #3b3f3f;
}
.bg-alt-lvl3 .btn.btn-discreet-dark:not(:disabled):not(.disabled):focus, .bg-alt-lvl3 .btn.btn-discreet-dark:not(:disabled):not(.disabled).focus {
  background-color: #3b3f3f;
}
.bg-alt-lvl3 .btn.btn-discreet-dark:not(:disabled):not(.disabled):active, .bg-alt-lvl3 .btn.btn-discreet-dark:not(:disabled):not(.disabled).active {
  color: rgba(59, 63, 63, 0.75) !important;
  background-color: #3b3f3f;
  border-color: #3b3f3f;
}

.bg-alt-lvl3 .btn.btn-discreet-info {
  color: #2982ff;
}
.bg-alt-lvl3 .btn.btn-discreet-info:hover:not(:disabled):not(.disabled) {
  background-color: #2982ff;
  border-color: #2982ff;
}
.bg-alt-lvl3 .btn.btn-discreet-info:not(:disabled):not(.disabled):focus, .bg-alt-lvl3 .btn.btn-discreet-info:not(:disabled):not(.disabled).focus {
  background-color: #2982ff;
}
.bg-alt-lvl3 .btn.btn-discreet-info:not(:disabled):not(.disabled):active, .bg-alt-lvl3 .btn.btn-discreet-info:not(:disabled):not(.disabled).active {
  color: rgba(41, 130, 255, 0.75) !important;
  background-color: #2982ff;
  border-color: #2982ff;
}

.bg-alt-lvl3 .btn.btn-discreet-success {
  color: #00b844;
}
.bg-alt-lvl3 .btn.btn-discreet-success:hover:not(:disabled):not(.disabled) {
  background-color: #00b844;
  border-color: #00b844;
}
.bg-alt-lvl3 .btn.btn-discreet-success:not(:disabled):not(.disabled):focus, .bg-alt-lvl3 .btn.btn-discreet-success:not(:disabled):not(.disabled).focus {
  background-color: #00b844;
}
.bg-alt-lvl3 .btn.btn-discreet-success:not(:disabled):not(.disabled):active, .bg-alt-lvl3 .btn.btn-discreet-success:not(:disabled):not(.disabled).active {
  color: rgba(0, 184, 68, 0.75) !important;
  background-color: #00b844;
  border-color: #00b844;
}

.bg-alt-lvl3 .btn.btn-discreet-warning {
  color: #db7c00;
}
.bg-alt-lvl3 .btn.btn-discreet-warning:hover:not(:disabled):not(.disabled) {
  background-color: #db7c00;
  border-color: #db7c00;
}
.bg-alt-lvl3 .btn.btn-discreet-warning:not(:disabled):not(.disabled):focus, .bg-alt-lvl3 .btn.btn-discreet-warning:not(:disabled):not(.disabled).focus {
  background-color: #db7c00;
}
.bg-alt-lvl3 .btn.btn-discreet-warning:not(:disabled):not(.disabled):active, .bg-alt-lvl3 .btn.btn-discreet-warning:not(:disabled):not(.disabled).active {
  color: rgba(219, 124, 0, 0.75) !important;
  background-color: #db7c00;
  border-color: #db7c00;
}

.bg-alt-lvl3 .btn.btn-discreet-danger {
  color: #e8442c;
}
.bg-alt-lvl3 .btn.btn-discreet-danger:hover:not(:disabled):not(.disabled) {
  background-color: #e8442c;
  border-color: #e8442c;
}
.bg-alt-lvl3 .btn.btn-discreet-danger:not(:disabled):not(.disabled):focus, .bg-alt-lvl3 .btn.btn-discreet-danger:not(:disabled):not(.disabled).focus {
  background-color: #e8442c;
}
.bg-alt-lvl3 .btn.btn-discreet-danger:not(:disabled):not(.disabled):active, .bg-alt-lvl3 .btn.btn-discreet-danger:not(:disabled):not(.disabled).active {
  color: rgba(232, 68, 44, 0.75) !important;
  background-color: #e8442c;
  border-color: #e8442c;
}

.bg-alt-lvl2 .btn.btn-discreet-secondary {
  color: #848d8f;
}
.bg-alt-lvl2 .btn.btn-discreet-secondary:hover:not(:disabled):not(.disabled) {
  background-color: #848d8f;
  border-color: #848d8f;
}
.bg-alt-lvl2 .btn.btn-discreet-secondary:not(:disabled):not(.disabled):focus, .bg-alt-lvl2 .btn.btn-discreet-secondary:not(:disabled):not(.disabled).focus {
  background-color: #848d8f;
}
.bg-alt-lvl2 .btn.btn-discreet-secondary:not(:disabled):not(.disabled):active, .bg-alt-lvl2 .btn.btn-discreet-secondary:not(:disabled):not(.disabled).active {
  color: rgba(132, 141, 143, 0.75) !important;
  background-color: #848d8f;
  border-color: #848d8f;
}

.bg-alt-lvl2 .btn.btn-discreet-light {
  color: #c2cacc;
}
.bg-alt-lvl2 .btn.btn-discreet-light:hover:not(:disabled):not(.disabled) {
  background-color: #c2cacc;
  border-color: #c2cacc;
}
.bg-alt-lvl2 .btn.btn-discreet-light:not(:disabled):not(.disabled):focus, .bg-alt-lvl2 .btn.btn-discreet-light:not(:disabled):not(.disabled).focus {
  background-color: #c2cacc;
}
.bg-alt-lvl2 .btn.btn-discreet-light:not(:disabled):not(.disabled):active, .bg-alt-lvl2 .btn.btn-discreet-light:not(:disabled):not(.disabled).active {
  color: rgba(194, 202, 204, 0.75) !important;
  background-color: #c2cacc;
  border-color: #c2cacc;
}

.bg-alt-lvl2 .btn.btn-discreet-dark {
  color: #454a4a;
}
.bg-alt-lvl2 .btn.btn-discreet-dark:hover:not(:disabled):not(.disabled) {
  background-color: #454a4a;
  border-color: #454a4a;
}
.bg-alt-lvl2 .btn.btn-discreet-dark:not(:disabled):not(.disabled):focus, .bg-alt-lvl2 .btn.btn-discreet-dark:not(:disabled):not(.disabled).focus {
  background-color: #454a4a;
}
.bg-alt-lvl2 .btn.btn-discreet-dark:not(:disabled):not(.disabled):active, .bg-alt-lvl2 .btn.btn-discreet-dark:not(:disabled):not(.disabled).active {
  color: rgba(69, 74, 74, 0.75) !important;
  background-color: #454a4a;
  border-color: #454a4a;
}

.bg-alt-lvl2 .btn.btn-discreet-info {
  color: #3d8eff;
}
.bg-alt-lvl2 .btn.btn-discreet-info:hover:not(:disabled):not(.disabled) {
  background-color: #3d8eff;
  border-color: #3d8eff;
}
.bg-alt-lvl2 .btn.btn-discreet-info:not(:disabled):not(.disabled):focus, .bg-alt-lvl2 .btn.btn-discreet-info:not(:disabled):not(.disabled).focus {
  background-color: #3d8eff;
}
.bg-alt-lvl2 .btn.btn-discreet-info:not(:disabled):not(.disabled):active, .bg-alt-lvl2 .btn.btn-discreet-info:not(:disabled):not(.disabled).active {
  color: rgba(61, 142, 255, 0.75) !important;
  background-color: #3d8eff;
  border-color: #3d8eff;
}

.bg-alt-lvl2 .btn.btn-discreet-success {
  color: #00c74a;
}
.bg-alt-lvl2 .btn.btn-discreet-success:hover:not(:disabled):not(.disabled) {
  background-color: #00c74a;
  border-color: #00c74a;
}
.bg-alt-lvl2 .btn.btn-discreet-success:not(:disabled):not(.disabled):focus, .bg-alt-lvl2 .btn.btn-discreet-success:not(:disabled):not(.disabled).focus {
  background-color: #00c74a;
}
.bg-alt-lvl2 .btn.btn-discreet-success:not(:disabled):not(.disabled):active, .bg-alt-lvl2 .btn.btn-discreet-success:not(:disabled):not(.disabled).active {
  color: rgba(0, 199, 74, 0.75) !important;
  background-color: #00c74a;
  border-color: #00c74a;
}

.bg-alt-lvl2 .btn.btn-discreet-warning {
  color: #eb8500;
}
.bg-alt-lvl2 .btn.btn-discreet-warning:hover:not(:disabled):not(.disabled) {
  background-color: #eb8500;
  border-color: #eb8500;
}
.bg-alt-lvl2 .btn.btn-discreet-warning:not(:disabled):not(.disabled):focus, .bg-alt-lvl2 .btn.btn-discreet-warning:not(:disabled):not(.disabled).focus {
  background-color: #eb8500;
}
.bg-alt-lvl2 .btn.btn-discreet-warning:not(:disabled):not(.disabled):active, .bg-alt-lvl2 .btn.btn-discreet-warning:not(:disabled):not(.disabled).active {
  color: rgba(235, 133, 0, 0.75) !important;
  background-color: #eb8500;
  border-color: #eb8500;
}

.bg-alt-lvl2 .btn.btn-discreet-danger {
  color: #f44d34;
}
.bg-alt-lvl2 .btn.btn-discreet-danger:hover:not(:disabled):not(.disabled) {
  background-color: #f44d34;
  border-color: #f44d34;
}
.bg-alt-lvl2 .btn.btn-discreet-danger:not(:disabled):not(.disabled):focus, .bg-alt-lvl2 .btn.btn-discreet-danger:not(:disabled):not(.disabled).focus {
  background-color: #f44d34;
}
.bg-alt-lvl2 .btn.btn-discreet-danger:not(:disabled):not(.disabled):active, .bg-alt-lvl2 .btn.btn-discreet-danger:not(:disabled):not(.disabled).active {
  color: rgba(244, 77, 52, 0.75) !important;
  background-color: #f44d34;
  border-color: #f44d34;
}

.bg-alt-lvl1 .btn.btn-discreet-secondary {
  color: #949c9e;
}
.bg-alt-lvl1 .btn.btn-discreet-secondary:hover:not(:disabled):not(.disabled) {
  background-color: #949c9e;
  border-color: #949c9e;
}
.bg-alt-lvl1 .btn.btn-discreet-secondary:not(:disabled):not(.disabled):focus, .bg-alt-lvl1 .btn.btn-discreet-secondary:not(:disabled):not(.disabled).focus {
  background-color: #949c9e;
}
.bg-alt-lvl1 .btn.btn-discreet-secondary:not(:disabled):not(.disabled):active, .bg-alt-lvl1 .btn.btn-discreet-secondary:not(:disabled):not(.disabled).active {
  color: rgba(148, 156, 158, 0.75) !important;
  background-color: #949c9e;
  border-color: #949c9e;
}

.bg-alt-lvl1 .btn.btn-discreet-light {
  color: #d8ddde;
}
.bg-alt-lvl1 .btn.btn-discreet-light:hover:not(:disabled):not(.disabled) {
  background-color: #d8ddde;
  border-color: #d8ddde;
}
.bg-alt-lvl1 .btn.btn-discreet-light:not(:disabled):not(.disabled):focus, .bg-alt-lvl1 .btn.btn-discreet-light:not(:disabled):not(.disabled).focus {
  background-color: #d8ddde;
}
.bg-alt-lvl1 .btn.btn-discreet-light:not(:disabled):not(.disabled):active, .bg-alt-lvl1 .btn.btn-discreet-light:not(:disabled):not(.disabled).active {
  color: rgba(216, 221, 222, 0.75) !important;
  background-color: #d8ddde;
  border-color: #d8ddde;
}

.bg-alt-lvl1 .btn.btn-discreet-dark {
  color: #515757;
}
.bg-alt-lvl1 .btn.btn-discreet-dark:hover:not(:disabled):not(.disabled) {
  background-color: #515757;
  border-color: #515757;
}
.bg-alt-lvl1 .btn.btn-discreet-dark:not(:disabled):not(.disabled):focus, .bg-alt-lvl1 .btn.btn-discreet-dark:not(:disabled):not(.disabled).focus {
  background-color: #515757;
}
.bg-alt-lvl1 .btn.btn-discreet-dark:not(:disabled):not(.disabled):active, .bg-alt-lvl1 .btn.btn-discreet-dark:not(:disabled):not(.disabled).active {
  color: rgba(81, 87, 87, 0.75) !important;
  background-color: #515757;
  border-color: #515757;
}

.bg-alt-lvl1 .btn.btn-discreet-info {
  color: #529aff;
}
.bg-alt-lvl1 .btn.btn-discreet-info:hover:not(:disabled):not(.disabled) {
  background-color: #529aff;
  border-color: #529aff;
}
.bg-alt-lvl1 .btn.btn-discreet-info:not(:disabled):not(.disabled):focus, .bg-alt-lvl1 .btn.btn-discreet-info:not(:disabled):not(.disabled).focus {
  background-color: #529aff;
}
.bg-alt-lvl1 .btn.btn-discreet-info:not(:disabled):not(.disabled):active, .bg-alt-lvl1 .btn.btn-discreet-info:not(:disabled):not(.disabled).active {
  color: rgba(82, 154, 255, 0.75) !important;
  background-color: #529aff;
  border-color: #529aff;
}

.bg-alt-lvl1 .btn.btn-discreet-success {
  color: #00d750;
}
.bg-alt-lvl1 .btn.btn-discreet-success:hover:not(:disabled):not(.disabled) {
  background-color: #00d750;
  border-color: #00d750;
}
.bg-alt-lvl1 .btn.btn-discreet-success:not(:disabled):not(.disabled):focus, .bg-alt-lvl1 .btn.btn-discreet-success:not(:disabled):not(.disabled).focus {
  background-color: #00d750;
}
.bg-alt-lvl1 .btn.btn-discreet-success:not(:disabled):not(.disabled):active, .bg-alt-lvl1 .btn.btn-discreet-success:not(:disabled):not(.disabled).active {
  color: rgba(0, 215, 80, 0.75) !important;
  background-color: #00d750;
  border-color: #00d750;
}

.bg-alt-lvl1 .btn.btn-discreet-warning {
  color: #f98d00;
}
.bg-alt-lvl1 .btn.btn-discreet-warning:hover:not(:disabled):not(.disabled) {
  background-color: #f98d00;
  border-color: #f98d00;
}
.bg-alt-lvl1 .btn.btn-discreet-warning:not(:disabled):not(.disabled):focus, .bg-alt-lvl1 .btn.btn-discreet-warning:not(:disabled):not(.disabled).focus {
  background-color: #f98d00;
}
.bg-alt-lvl1 .btn.btn-discreet-warning:not(:disabled):not(.disabled):active, .bg-alt-lvl1 .btn.btn-discreet-warning:not(:disabled):not(.disabled).active {
  color: rgba(249, 141, 0, 0.75) !important;
  background-color: #f98d00;
  border-color: #f98d00;
}

.bg-alt-lvl1 .btn.btn-discreet-danger {
  color: #ff563c;
}
.bg-alt-lvl1 .btn.btn-discreet-danger:hover:not(:disabled):not(.disabled) {
  background-color: #ff563c;
  border-color: #ff563c;
}
.bg-alt-lvl1 .btn.btn-discreet-danger:not(:disabled):not(.disabled):focus, .bg-alt-lvl1 .btn.btn-discreet-danger:not(:disabled):not(.disabled).focus {
  background-color: #ff563c;
}
.bg-alt-lvl1 .btn.btn-discreet-danger:not(:disabled):not(.disabled):active, .bg-alt-lvl1 .btn.btn-discreet-danger:not(:disabled):not(.disabled).active {
  color: rgba(255, 86, 60, 0.75) !important;
  background-color: #ff563c;
  border-color: #ff563c;
}

.bg-lvl4 .btn.btn-discreet-secondary:focus, .bg-lvl4 .btn.btn-discreet-secondary.focus {
  color: #fff !important;
}
.bg-lvl4 .btn.btn-discreet-secondary:hover:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #505658;
  border-color: #505658;
}
.bg-lvl4 .btn.btn-discreet-secondary:not(:disabled):not(.disabled):active, .bg-lvl4 .btn.btn-discreet-secondary:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #5f6668;
  border-color: #5f6668;
}

.bg-lvl4 .btn.btn-discreet-light:focus, .bg-lvl4 .btn.btn-discreet-light.focus {
  color: #fff !important;
}
.bg-lvl4 .btn.btn-discreet-light:hover:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #a1a8ab;
  border-color: #a1a8ab;
}
.bg-lvl4 .btn.btn-discreet-light:not(:disabled):not(.disabled):active, .bg-lvl4 .btn.btn-discreet-light:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #b1b7b9;
  border-color: #b1b7b9;
}

.bg-lvl4 .btn.btn-discreet-dark:focus, .bg-lvl4 .btn.btn-discreet-dark.focus {
  color: #fff !important;
}
.bg-lvl4 .btn.btn-discreet-dark:hover:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #242929;
  border-color: #242929;
}
.bg-lvl4 .btn.btn-discreet-dark:not(:disabled):not(.disabled):active, .bg-lvl4 .btn.btn-discreet-dark:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #323939;
  border-color: #323939;
}

.bg-lvl4 .btn.btn-discreet-info:focus, .bg-lvl4 .btn.btn-discreet-info.focus {
  color: #fff !important;
}
.bg-lvl4 .btn.btn-discreet-info:hover:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #0048e5;
  border-color: #0048e5;
}
.bg-lvl4 .btn.btn-discreet-info:not(:disabled):not(.disabled):active, .bg-lvl4 .btn.btn-discreet-info:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #0554ff;
  border-color: #0554ff;
}

.bg-lvl4 .btn.btn-discreet-success:focus, .bg-lvl4 .btn.btn-discreet-success.focus {
  color: #fff !important;
}
.bg-lvl4 .btn.btn-discreet-success:hover:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #006b07;
  border-color: #006b07;
}
.bg-lvl4 .btn.btn-discreet-success:not(:disabled):not(.disabled):active, .bg-lvl4 .btn.btn-discreet-success:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #008a09;
  border-color: #008a09;
}

.bg-lvl4 .btn.btn-discreet-warning:focus, .bg-lvl4 .btn.btn-discreet-warning.focus {
  color: #fff !important;
}
.bg-lvl4 .btn.btn-discreet-warning:hover:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #9d5500;
  border-color: #9d5500;
}
.bg-lvl4 .btn.btn-discreet-warning:not(:disabled):not(.disabled):active, .bg-lvl4 .btn.btn-discreet-warning:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #bc6500;
  border-color: #bc6500;
}

.bg-lvl4 .btn.btn-discreet-danger:focus, .bg-lvl4 .btn.btn-discreet-danger.focus {
  color: #fff !important;
}
.bg-lvl4 .btn.btn-discreet-danger:hover:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #a8291b;
  border-color: #a8291b;
}
.bg-lvl4 .btn.btn-discreet-danger:not(:disabled):not(.disabled):active, .bg-lvl4 .btn.btn-discreet-danger:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #c22f1f;
  border-color: #c22f1f;
}

.bg-lvl4 .btn.btn-discreet-secondary {
  color: #555c5d;
}
.bg-lvl4 .btn.btn-discreet-info {
  color: #004df5;
}
.bg-lvl4 .btn.btn-discreet-success {
  color: #006106;
}
.bg-lvl4 .btn.btn-discreet-warning {
  color: #844700;
}
.bg-lvl4 .btn.btn-discreet-danger {
  color: #b02b1c;
}

.bg-lvl3 .btn.btn-discreet-secondary:focus, .bg-lvl3 .btn.btn-discreet-secondary.focus {
  color: #fff !important;
}
.bg-lvl3 .btn.btn-discreet-secondary:hover:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #575e60;
  border-color: #575e60;
}
.bg-lvl3 .btn.btn-discreet-secondary:not(:disabled):not(.disabled):active, .bg-lvl3 .btn.btn-discreet-secondary:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #666e70;
  border-color: #666e70;
}

.bg-lvl3 .btn.btn-discreet-light:focus, .bg-lvl3 .btn.btn-discreet-light.focus {
  color: #fff !important;
}
.bg-lvl3 .btn.btn-discreet-light:hover:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #a9afb2;
  border-color: #a9afb2;
}
.bg-lvl3 .btn.btn-discreet-light:not(:disabled):not(.disabled):active, .bg-lvl3 .btn.btn-discreet-light:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #b9bec0;
  border-color: #b9bec0;
}

.bg-lvl3 .btn.btn-discreet-dark:focus, .bg-lvl3 .btn.btn-discreet-dark.focus {
  color: #fff !important;
}
.bg-lvl3 .btn.btn-discreet-dark:hover:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #292e2e;
  border-color: #292e2e;
}
.bg-lvl3 .btn.btn-discreet-dark:not(:disabled):not(.disabled):active, .bg-lvl3 .btn.btn-discreet-dark:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #373e3e;
  border-color: #373e3e;
}

.bg-lvl3 .btn.btn-discreet-info:focus, .bg-lvl3 .btn.btn-discreet-info.focus {
  color: #fff !important;
}
.bg-lvl3 .btn.btn-discreet-info:hover:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #004cef;
  border-color: #004cef;
}
.bg-lvl3 .btn.btn-discreet-info:not(:disabled):not(.disabled):active, .bg-lvl3 .btn.btn-discreet-info:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #0f5bff;
  border-color: #0f5bff;
}

.bg-lvl3 .btn.btn-discreet-success:focus, .bg-lvl3 .btn.btn-discreet-success.focus {
  color: #fff !important;
}
.bg-lvl3 .btn.btn-discreet-success:hover:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #007508;
  border-color: #007508;
}
.bg-lvl3 .btn.btn-discreet-success:not(:disabled):not(.disabled):active, .bg-lvl3 .btn.btn-discreet-success:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #00940a;
  border-color: #00940a;
}

.bg-lvl3 .btn.btn-discreet-warning:focus, .bg-lvl3 .btn.btn-discreet-warning.focus {
  color: #fff !important;
}
.bg-lvl3 .btn.btn-discreet-warning:hover:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #a75a00;
  border-color: #a75a00;
}
.bg-lvl3 .btn.btn-discreet-warning:not(:disabled):not(.disabled):active, .bg-lvl3 .btn.btn-discreet-warning:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #c66a00;
  border-color: #c66a00;
}

.bg-lvl3 .btn.btn-discreet-danger:focus, .bg-lvl3 .btn.btn-discreet-danger.focus {
  color: #fff !important;
}
.bg-lvl3 .btn.btn-discreet-danger:hover:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #b12b1c;
  border-color: #b12b1c;
}
.bg-lvl3 .btn.btn-discreet-danger:not(:disabled):not(.disabled):active, .bg-lvl3 .btn.btn-discreet-danger:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #cb3120;
  border-color: #cb3120;
}

.bg-lvl3 .btn.btn-discreet-secondary {
  color: #5c6465;
}
.bg-lvl3 .btn.btn-discreet-info {
  color: #0051ff;
}
.bg-lvl3 .btn.btn-discreet-success {
  color: #006b07;
}
.bg-lvl3 .btn.btn-discreet-warning {
  color: #8e4c00;
}
.bg-lvl3 .btn.btn-discreet-danger {
  color: #b92d1d;
}

.bg-lvl2 .btn.btn-discreet-secondary:focus, .bg-lvl2 .btn.btn-discreet-secondary.focus {
  color: #fff !important;
}
.bg-lvl2 .btn.btn-discreet-secondary:hover:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #596063;
  border-color: #596063;
}
.bg-lvl2 .btn.btn-discreet-secondary:not(:disabled):not(.disabled):active, .bg-lvl2 .btn.btn-discreet-secondary:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #687073;
  border-color: #687073;
}

.bg-lvl2 .btn.btn-discreet-light:focus, .bg-lvl2 .btn.btn-discreet-light.focus {
  color: #fff !important;
}
.bg-lvl2 .btn.btn-discreet-light:hover:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #b1b6b8;
  border-color: #b1b6b8;
}
.bg-lvl2 .btn.btn-discreet-light:not(:disabled):not(.disabled):active, .bg-lvl2 .btn.btn-discreet-light:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #c1c5c7;
  border-color: #c1c5c7;
}

.bg-lvl2 .btn.btn-discreet-dark:focus, .bg-lvl2 .btn.btn-discreet-dark.focus {
  color: #fff !important;
}
.bg-lvl2 .btn.btn-discreet-dark:hover:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #2e3333;
  border-color: #2e3333;
}
.bg-lvl2 .btn.btn-discreet-dark:not(:disabled):not(.disabled):active, .bg-lvl2 .btn.btn-discreet-dark:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #3c4343;
  border-color: #3c4343;
}

.bg-lvl2 .btn.btn-discreet-info:focus, .bg-lvl2 .btn.btn-discreet-info.focus {
  color: #fff !important;
}
.bg-lvl2 .btn.btn-discreet-info:hover:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #004ffa;
  border-color: #004ffa;
}
.bg-lvl2 .btn.btn-discreet-info:not(:disabled):not(.disabled):active, .bg-lvl2 .btn.btn-discreet-info:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #1a62ff;
  border-color: #1a62ff;
}

.bg-lvl2 .btn.btn-discreet-success:focus, .bg-lvl2 .btn.btn-discreet-success.focus {
  color: #fff !important;
}
.bg-lvl2 .btn.btn-discreet-success:hover:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #007f09;
  border-color: #007f09;
}
.bg-lvl2 .btn.btn-discreet-success:not(:disabled):not(.disabled):active, .bg-lvl2 .btn.btn-discreet-success:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #009e0b;
  border-color: #009e0b;
}

.bg-lvl2 .btn.btn-discreet-warning:focus, .bg-lvl2 .btn.btn-discreet-warning.focus {
  color: #fff !important;
}
.bg-lvl2 .btn.btn-discreet-warning:hover:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #b15f00;
  border-color: #b15f00;
}
.bg-lvl2 .btn.btn-discreet-warning:not(:disabled):not(.disabled):active, .bg-lvl2 .btn.btn-discreet-warning:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #d06f00;
  border-color: #d06f00;
}

.bg-lvl2 .btn.btn-discreet-danger:focus, .bg-lvl2 .btn.btn-discreet-danger.focus {
  color: #fff !important;
}
.bg-lvl2 .btn.btn-discreet-danger:hover:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #ba2d1d;
  border-color: #ba2d1d;
}
.bg-lvl2 .btn.btn-discreet-danger:not(:disabled):not(.disabled):active, .bg-lvl2 .btn.btn-discreet-danger:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #d43321;
  border-color: #d43321;
}

.bg-lvl2 .btn.btn-discreet-secondary {
  color: #5e6668;
}
.bg-lvl2 .btn.btn-discreet-info {
  color: #0b58ff;
}
.bg-lvl2 .btn.btn-discreet-success {
  color: #007508;
}
.bg-lvl2 .btn.btn-discreet-warning {
  color: #985100;
}
.bg-lvl2 .btn.btn-discreet-danger {
  color: #c22f1e;
}

.bg-lvl1 .btn.btn-discreet-secondary:focus, .bg-lvl1 .btn.btn-discreet-secondary.focus {
  color: #fff !important;
}
.bg-lvl1 .btn.btn-discreet-secondary:hover:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #5f676a;
  border-color: #5f676a;
}
.bg-lvl1 .btn.btn-discreet-secondary:not(:disabled):not(.disabled):active, .bg-lvl1 .btn.btn-discreet-secondary:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #6e777a;
  border-color: #6e777a;
}

.bg-lvl1 .btn.btn-discreet-light:focus, .bg-lvl1 .btn.btn-discreet-light.focus {
  color: #fff !important;
}
.bg-lvl1 .btn.btn-discreet-light:hover:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #b4b9bc;
  border-color: #b4b9bc;
}
.bg-lvl1 .btn.btn-discreet-light:not(:disabled):not(.disabled):active, .bg-lvl1 .btn.btn-discreet-light:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #c4c8ca;
  border-color: #c4c8ca;
}

.bg-lvl1 .btn.btn-discreet-dark:focus, .bg-lvl1 .btn.btn-discreet-dark.focus {
  color: #fff !important;
}
.bg-lvl1 .btn.btn-discreet-dark:hover:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #333939;
  border-color: #333939;
}
.bg-lvl1 .btn.btn-discreet-dark:not(:disabled):not(.disabled):active, .bg-lvl1 .btn.btn-discreet-dark:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #414949;
  border-color: #414949;
}

.bg-lvl1 .btn.btn-discreet-info:focus, .bg-lvl1 .btn.btn-discreet-info.focus {
  color: #fff !important;
}
.bg-lvl1 .btn.btn-discreet-info:hover:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #0554ff;
  border-color: #0554ff;
}
.bg-lvl1 .btn.btn-discreet-info:not(:disabled):not(.disabled):active, .bg-lvl1 .btn.btn-discreet-info:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #2469ff;
  border-color: #2469ff;
}

.bg-lvl1 .btn.btn-discreet-success:focus, .bg-lvl1 .btn.btn-discreet-success.focus {
  color: #fff !important;
}
.bg-lvl1 .btn.btn-discreet-success:hover:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #008b0a;
  border-color: #008b0a;
}
.bg-lvl1 .btn.btn-discreet-success:not(:disabled):not(.disabled):active, .bg-lvl1 .btn.btn-discreet-success:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #00aa0c;
  border-color: #00aa0c;
}

.bg-lvl1 .btn.btn-discreet-warning:focus, .bg-lvl1 .btn.btn-discreet-warning.focus {
  color: #fff !important;
}
.bg-lvl1 .btn.btn-discreet-warning:hover:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #bb6400;
  border-color: #bb6400;
}
.bg-lvl1 .btn.btn-discreet-warning:not(:disabled):not(.disabled):active, .bg-lvl1 .btn.btn-discreet-warning:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #da7400;
  border-color: #da7400;
}

.bg-lvl1 .btn.btn-discreet-danger:focus, .bg-lvl1 .btn.btn-discreet-danger.focus {
  color: #fff !important;
}
.bg-lvl1 .btn.btn-discreet-danger:hover:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #c42f1e;
  border-color: #c42f1e;
}
.bg-lvl1 .btn.btn-discreet-danger:not(:disabled):not(.disabled):active, .bg-lvl1 .btn.btn-discreet-danger:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #dd3724;
  border-color: #dd3724;
}

.bg-lvl1 .btn.btn-discreet-secondary {
  color: #646d6f;
}
.bg-lvl1 .btn.btn-discreet-info {
  color: #155fff;
}
.bg-lvl1 .btn.btn-discreet-success {
  color: #008109;
}
.bg-lvl1 .btn.btn-discreet-warning {
  color: #a25600;
}
.bg-lvl1 .btn.btn-discreet-danger {
  color: #cd3220;
}

.bg-alt-lvl4 .btn.btn-discreet-secondary:focus, .bg-alt-lvl4 .btn.btn-discreet-secondary.focus {
  color: #000 !important;
}
.bg-alt-lvl4 .btn.btn-discreet-secondary:hover:not(:disabled):not(.disabled) {
  color: #000;
  background-color: #7a8485;
  border-color: #7a8485;
}
.bg-alt-lvl4 .btn.btn-discreet-secondary:not(:disabled):not(.disabled):active, .bg-alt-lvl4 .btn.btn-discreet-secondary:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
  background-color: #70797a;
  border-color: #70797a;
}

.bg-alt-lvl4 .btn.btn-discreet-light:focus, .bg-alt-lvl4 .btn.btn-discreet-light.focus {
  color: #000 !important;
}
.bg-alt-lvl4 .btn.btn-discreet-light:hover:not(:disabled):not(.disabled) {
  color: #000;
  background-color: #aeb9bc;
  border-color: #aeb9bc;
}
.bg-alt-lvl4 .btn.btn-discreet-light:not(:disabled):not(.disabled):active, .bg-alt-lvl4 .btn.btn-discreet-light:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
  background-color: #a3afb3;
  border-color: #a3afb3;
}

.bg-alt-lvl4 .btn.btn-discreet-dark:focus, .bg-alt-lvl4 .btn.btn-discreet-dark.focus {
  color: #000 !important;
}
.bg-alt-lvl4 .btn.btn-discreet-dark:hover:not(:disabled):not(.disabled) {
  color: #000;
  background-color: #3e4141;
  border-color: #3e4141;
}
.bg-alt-lvl4 .btn.btn-discreet-dark:not(:disabled):not(.disabled):active, .bg-alt-lvl4 .btn.btn-discreet-dark:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
  background-color: #343737;
  border-color: #343737;
}

.bg-alt-lvl4 .btn.btn-discreet-info:focus, .bg-alt-lvl4 .btn.btn-discreet-info.focus {
  color: #000 !important;
}
.bg-alt-lvl4 .btn.btn-discreet-info:hover:not(:disabled):not(.disabled) {
  color: #000;
  background-color: #2882ff;
  border-color: #2882ff;
}
.bg-alt-lvl4 .btn.btn-discreet-info:not(:disabled):not(.disabled):active, .bg-alt-lvl4 .btn.btn-discreet-info:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
  background-color: #1476ff;
  border-color: #1476ff;
}

.bg-alt-lvl4 .btn.btn-discreet-success:focus, .bg-alt-lvl4 .btn.btn-discreet-success.focus {
  color: #000 !important;
}
.bg-alt-lvl4 .btn.btn-discreet-success:hover:not(:disabled):not(.disabled) {
  color: #000;
  background-color: #00bc46;
  border-color: #00bc46;
}
.bg-alt-lvl4 .btn.btn-discreet-success:not(:disabled):not(.disabled):active, .bg-alt-lvl4 .btn.btn-discreet-success:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
  background-color: #00a83e;
  border-color: #00a83e;
}

.bg-alt-lvl4 .btn.btn-discreet-warning:focus, .bg-alt-lvl4 .btn.btn-discreet-warning.focus {
  color: #000 !important;
}
.bg-alt-lvl4 .btn.btn-discreet-warning:hover:not(:disabled):not(.disabled) {
  color: #000;
  background-color: #e08000;
  border-color: #e08000;
}
.bg-alt-lvl4 .btn.btn-discreet-warning:not(:disabled):not(.disabled):active, .bg-alt-lvl4 .btn.btn-discreet-warning:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
  background-color: #cc7400;
  border-color: #cc7400;
}

.bg-alt-lvl4 .btn.btn-discreet-danger:focus, .bg-alt-lvl4 .btn.btn-discreet-danger.focus {
  color: #000 !important;
}
.bg-alt-lvl4 .btn.btn-discreet-danger:hover:not(:disabled):not(.disabled) {
  color: #000;
  background-color: #dc4d37;
  border-color: #dc4d37;
}
.bg-alt-lvl4 .btn.btn-discreet-danger:not(:disabled):not(.disabled):active, .bg-alt-lvl4 .btn.btn-discreet-danger:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
  background-color: #d93d26;
  border-color: #d93d26;
}

.bg-alt-lvl4 .btn.btn-discreet-secondary {
  color: #848d8e;
}
.bg-alt-lvl4 .btn.btn-discreet-info {
  color: #2889ff;
}
.bg-alt-lvl4 .btn.btn-discreet-danger {
  color: #db4e33;
}

.bg-alt-lvl3 .btn.btn-discreet-secondary:focus, .bg-alt-lvl3 .btn.btn-discreet-secondary.focus {
  color: #000 !important;
}
.bg-alt-lvl3 .btn.btn-discreet-secondary:hover:not(:disabled):not(.disabled) {
  color: #000;
  background-color: #858d8f;
  border-color: #858d8f;
}
.bg-alt-lvl3 .btn.btn-discreet-secondary:not(:disabled):not(.disabled):active, .bg-alt-lvl3 .btn.btn-discreet-secondary:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
  background-color: #7a8385;
  border-color: #7a8385;
}

.bg-alt-lvl3 .btn.btn-discreet-light:focus, .bg-alt-lvl3 .btn.btn-discreet-light.focus {
  color: #000 !important;
}
.bg-alt-lvl3 .btn.btn-discreet-light:hover:not(:disabled):not(.disabled) {
  color: #000;
  background-color: #bcc5c7;
  border-color: #bcc5c7;
}
.bg-alt-lvl3 .btn.btn-discreet-light:not(:disabled):not(.disabled):active, .bg-alt-lvl3 .btn.btn-discreet-light:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
  background-color: #b1bbbe;
  border-color: #b1bbbe;
}

.bg-alt-lvl3 .btn.btn-discreet-dark:focus, .bg-alt-lvl3 .btn.btn-discreet-dark.focus {
  color: #000 !important;
}
.bg-alt-lvl3 .btn.btn-discreet-dark:hover:not(:disabled):not(.disabled) {
  color: #000;
  background-color: #454a4a;
  border-color: #454a4a;
}
.bg-alt-lvl3 .btn.btn-discreet-dark:not(:disabled):not(.disabled):active, .bg-alt-lvl3 .btn.btn-discreet-dark:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
  background-color: #3b3f3f;
  border-color: #3b3f3f;
}

.bg-alt-lvl3 .btn.btn-discreet-info:focus, .bg-alt-lvl3 .btn.btn-discreet-info.focus {
  color: #000 !important;
}
.bg-alt-lvl3 .btn.btn-discreet-info:hover:not(:disabled):not(.disabled) {
  color: #000;
  background-color: #3d8eff;
  border-color: #3d8eff;
}
.bg-alt-lvl3 .btn.btn-discreet-info:not(:disabled):not(.disabled):active, .bg-alt-lvl3 .btn.btn-discreet-info:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
  background-color: #2982ff;
  border-color: #2982ff;
}

.bg-alt-lvl3 .btn.btn-discreet-success:focus, .bg-alt-lvl3 .btn.btn-discreet-success.focus {
  color: #000 !important;
}
.bg-alt-lvl3 .btn.btn-discreet-success:hover:not(:disabled):not(.disabled) {
  color: #000;
  background-color: #00cc4c;
  border-color: #00cc4c;
}
.bg-alt-lvl3 .btn.btn-discreet-success:not(:disabled):not(.disabled):active, .bg-alt-lvl3 .btn.btn-discreet-success:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
  background-color: #00b844;
  border-color: #00b844;
}

.bg-alt-lvl3 .btn.btn-discreet-warning:focus, .bg-alt-lvl3 .btn.btn-discreet-warning.focus {
  color: #000 !important;
}
.bg-alt-lvl3 .btn.btn-discreet-warning:hover:not(:disabled):not(.disabled) {
  color: #000;
  background-color: #ef8800;
  border-color: #ef8800;
}
.bg-alt-lvl3 .btn.btn-discreet-warning:not(:disabled):not(.disabled):active, .bg-alt-lvl3 .btn.btn-discreet-warning:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
  background-color: #db7c00;
  border-color: #db7c00;
}

.bg-alt-lvl3 .btn.btn-discreet-danger:focus, .bg-alt-lvl3 .btn.btn-discreet-danger.focus {
  color: #000 !important;
}
.bg-alt-lvl3 .btn.btn-discreet-danger:hover:not(:disabled):not(.disabled) {
  color: #000;
  background-color: #ea543e;
  border-color: #ea543e;
}
.bg-alt-lvl3 .btn.btn-discreet-danger:not(:disabled):not(.disabled):active, .bg-alt-lvl3 .btn.btn-discreet-danger:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
  background-color: #e8442c;
  border-color: #e8442c;
}

.bg-alt-lvl3 .btn.btn-discreet-secondary {
  color: #8f9799;
}
.bg-alt-lvl3 .btn.btn-discreet-info {
  color: #3d94ff;
}
.bg-alt-lvl3 .btn.btn-discreet-danger {
  color: #ea563a;
}

.bg-alt-lvl2 .btn.btn-discreet-secondary:focus, .bg-alt-lvl2 .btn.btn-discreet-secondary.focus {
  color: #000 !important;
}
.bg-alt-lvl2 .btn.btn-discreet-secondary:hover:not(:disabled):not(.disabled) {
  color: #000;
  background-color: #8f9799;
  border-color: #8f9799;
}
.bg-alt-lvl2 .btn.btn-discreet-secondary:not(:disabled):not(.disabled):active, .bg-alt-lvl2 .btn.btn-discreet-secondary:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
  background-color: #848d8f;
  border-color: #848d8f;
}

.bg-alt-lvl2 .btn.btn-discreet-light:focus, .bg-alt-lvl2 .btn.btn-discreet-light.focus {
  color: #000 !important;
}
.bg-alt-lvl2 .btn.btn-discreet-light:hover:not(:disabled):not(.disabled) {
  color: #000;
  background-color: #cdd4d5;
  border-color: #cdd4d5;
}
.bg-alt-lvl2 .btn.btn-discreet-light:not(:disabled):not(.disabled):active, .bg-alt-lvl2 .btn.btn-discreet-light:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
  background-color: #c2cacc;
  border-color: #c2cacc;
}

.bg-alt-lvl2 .btn.btn-discreet-dark:focus, .bg-alt-lvl2 .btn.btn-discreet-dark.focus {
  color: #000 !important;
}
.bg-alt-lvl2 .btn.btn-discreet-dark:hover:not(:disabled):not(.disabled) {
  color: #000;
  background-color: #4f5555;
  border-color: #4f5555;
}
.bg-alt-lvl2 .btn.btn-discreet-dark:not(:disabled):not(.disabled):active, .bg-alt-lvl2 .btn.btn-discreet-dark:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
  background-color: #454a4a;
  border-color: #454a4a;
}

.bg-alt-lvl2 .btn.btn-discreet-info:focus, .bg-alt-lvl2 .btn.btn-discreet-info.focus {
  color: #000 !important;
}
.bg-alt-lvl2 .btn.btn-discreet-info:hover:not(:disabled):not(.disabled) {
  color: #000;
  background-color: #519aff;
  border-color: #519aff;
}
.bg-alt-lvl2 .btn.btn-discreet-info:not(:disabled):not(.disabled):active, .bg-alt-lvl2 .btn.btn-discreet-info:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
  background-color: #3d8eff;
  border-color: #3d8eff;
}

.bg-alt-lvl2 .btn.btn-discreet-success:focus, .bg-alt-lvl2 .btn.btn-discreet-success.focus {
  color: #000 !important;
}
.bg-alt-lvl2 .btn.btn-discreet-success:hover:not(:disabled):not(.disabled) {
  color: #000;
  background-color: #00db52;
  border-color: #00db52;
}
.bg-alt-lvl2 .btn.btn-discreet-success:not(:disabled):not(.disabled):active, .bg-alt-lvl2 .btn.btn-discreet-success:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
  background-color: #00c74a;
  border-color: #00c74a;
}

.bg-alt-lvl2 .btn.btn-discreet-warning:focus, .bg-alt-lvl2 .btn.btn-discreet-warning.focus {
  color: #000 !important;
}
.bg-alt-lvl2 .btn.btn-discreet-warning:hover:not(:disabled):not(.disabled) {
  color: #000;
  background-color: #ff9000;
  border-color: #ff9000;
}
.bg-alt-lvl2 .btn.btn-discreet-warning:not(:disabled):not(.disabled):active, .bg-alt-lvl2 .btn.btn-discreet-warning:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
  background-color: #eb8500;
  border-color: #eb8500;
}

.bg-alt-lvl2 .btn.btn-discreet-danger:focus, .bg-alt-lvl2 .btn.btn-discreet-danger.focus {
  color: #000 !important;
}
.bg-alt-lvl2 .btn.btn-discreet-danger:hover:not(:disabled):not(.disabled) {
  color: #000;
  background-color: #f55e47;
  border-color: #f55e47;
}
.bg-alt-lvl2 .btn.btn-discreet-danger:not(:disabled):not(.disabled):active, .bg-alt-lvl2 .btn.btn-discreet-danger:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
  background-color: #f44d34;
  border-color: #f44d34;
}

.bg-alt-lvl2 .btn.btn-discreet-secondary {
  color: #99a1a2;
}
.bg-alt-lvl2 .btn.btn-discreet-info {
  color: #51a0ff;
}
.bg-alt-lvl2 .btn.btn-discreet-danger {
  color: #f56043;
}

.bg-alt-lvl1 .btn.btn-discreet-secondary:focus, .bg-alt-lvl1 .btn.btn-discreet-secondary.focus {
  color: #000 !important;
}
.bg-alt-lvl1 .btn.btn-discreet-secondary:hover:not(:disabled):not(.disabled) {
  color: #000;
  background-color: #9fa6a8;
  border-color: #9fa6a8;
}
.bg-alt-lvl1 .btn.btn-discreet-secondary:not(:disabled):not(.disabled):active, .bg-alt-lvl1 .btn.btn-discreet-secondary:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
  background-color: #949c9e;
  border-color: #949c9e;
}

.bg-alt-lvl1 .btn.btn-discreet-light:focus, .bg-alt-lvl1 .btn.btn-discreet-light.focus {
  color: #000 !important;
}
.bg-alt-lvl1 .btn.btn-discreet-light:hover:not(:disabled):not(.disabled) {
  color: #000;
  background-color: #e3e7e7;
  border-color: #e3e7e7;
}
.bg-alt-lvl1 .btn.btn-discreet-light:not(:disabled):not(.disabled):active, .bg-alt-lvl1 .btn.btn-discreet-light:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
  background-color: #d8ddde;
  border-color: #d8ddde;
}

.bg-alt-lvl1 .btn.btn-discreet-dark:focus, .bg-alt-lvl1 .btn.btn-discreet-dark.focus {
  color: #000 !important;
}
.bg-alt-lvl1 .btn.btn-discreet-dark:hover:not(:disabled):not(.disabled) {
  color: #000;
  background-color: #5b6262;
  border-color: #5b6262;
}
.bg-alt-lvl1 .btn.btn-discreet-dark:not(:disabled):not(.disabled):active, .bg-alt-lvl1 .btn.btn-discreet-dark:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
  background-color: #515757;
  border-color: #515757;
}

.bg-alt-lvl1 .btn.btn-discreet-info:focus, .bg-alt-lvl1 .btn.btn-discreet-info.focus {
  color: #000 !important;
}
.bg-alt-lvl1 .btn.btn-discreet-info:hover:not(:disabled):not(.disabled) {
  color: #000;
  background-color: #66a6ff;
  border-color: #66a6ff;
}
.bg-alt-lvl1 .btn.btn-discreet-info:not(:disabled):not(.disabled):active, .bg-alt-lvl1 .btn.btn-discreet-info:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
  background-color: #529aff;
  border-color: #529aff;
}

.bg-alt-lvl1 .btn.btn-discreet-success:focus, .bg-alt-lvl1 .btn.btn-discreet-success.focus {
  color: #000 !important;
}
.bg-alt-lvl1 .btn.btn-discreet-success:hover:not(:disabled):not(.disabled) {
  color: #000;
  background-color: #00eb58;
  border-color: #00eb58;
}
.bg-alt-lvl1 .btn.btn-discreet-success:not(:disabled):not(.disabled):active, .bg-alt-lvl1 .btn.btn-discreet-success:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
  background-color: #00d750;
  border-color: #00d750;
}

.bg-alt-lvl1 .btn.btn-discreet-warning:focus, .bg-alt-lvl1 .btn.btn-discreet-warning.focus {
  color: #000 !important;
}
.bg-alt-lvl1 .btn.btn-discreet-warning:hover:not(:disabled):not(.disabled) {
  color: #000;
  background-color: #ff970e;
  border-color: #ff970e;
}
.bg-alt-lvl1 .btn.btn-discreet-warning:not(:disabled):not(.disabled):active, .bg-alt-lvl1 .btn.btn-discreet-warning:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
  background-color: #f98d00;
  border-color: #f98d00;
}

.bg-alt-lvl1 .btn.btn-discreet-danger:focus, .bg-alt-lvl1 .btn.btn-discreet-danger.focus {
  color: #000 !important;
}
.bg-alt-lvl1 .btn.btn-discreet-danger:hover:not(:disabled):not(.disabled) {
  color: #000;
  background-color: #ff6850;
  border-color: #ff6850;
}
.bg-alt-lvl1 .btn.btn-discreet-danger:not(:disabled):not(.disabled):active, .bg-alt-lvl1 .btn.btn-discreet-danger:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
  background-color: #ff563c;
  border-color: #ff563c;
}

.bg-alt-lvl1 .btn.btn-discreet-secondary {
  color: #a9b0b1;
}
.bg-alt-lvl1 .btn.btn-discreet-info {
  color: #66abff;
}
.bg-alt-lvl1 .btn.btn-discreet-danger {
  color: #ff694b;
}

.bg-alt-lvl4 .btn.btn-secondary {
  color: #000;
}
.bg-alt-lvl4 .btn.btn-secondary:hover {
  background-color: #7a8485;
  border-color: #7a8485;
}
.bg-alt-lvl4 .btn.btn-secondary:not(:disabled):not(.disabled):active, .bg-alt-lvl4 .btn.btn-secondary:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
}

.bg-alt-lvl4 .btn.btn-light {
  color: #000;
}
.bg-alt-lvl4 .btn.btn-light:hover {
  background-color: #aeb9bc;
  border-color: #aeb9bc;
}
.bg-alt-lvl4 .btn.btn-light:not(:disabled):not(.disabled):active, .bg-alt-lvl4 .btn.btn-light:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
}

.bg-alt-lvl4 .btn.btn-dark {
  color: #000;
}
.bg-alt-lvl4 .btn.btn-dark:hover {
  background-color: #3e4141;
  border-color: #3e4141;
}
.bg-alt-lvl4 .btn.btn-dark:not(:disabled):not(.disabled):active, .bg-alt-lvl4 .btn.btn-dark:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
}

.bg-alt-lvl4 .btn.btn-info {
  color: #000;
}
.bg-alt-lvl4 .btn.btn-info:hover {
  background-color: #2882ff;
  border-color: #2882ff;
}
.bg-alt-lvl4 .btn.btn-info:not(:disabled):not(.disabled):active, .bg-alt-lvl4 .btn.btn-info:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
}

.bg-alt-lvl4 .btn.btn-success {
  color: #000;
}
.bg-alt-lvl4 .btn.btn-success:hover {
  background-color: #00bc46;
  border-color: #00bc46;
}
.bg-alt-lvl4 .btn.btn-success:not(:disabled):not(.disabled):active, .bg-alt-lvl4 .btn.btn-success:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
}

.bg-alt-lvl4 .btn.btn-warning {
  color: #000;
}
.bg-alt-lvl4 .btn.btn-warning:hover {
  background-color: #e08000;
  border-color: #e08000;
}
.bg-alt-lvl4 .btn.btn-warning:not(:disabled):not(.disabled):active, .bg-alt-lvl4 .btn.btn-warning:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
}

.bg-alt-lvl4 .btn.btn-danger {
  color: #000;
}
.bg-alt-lvl4 .btn.btn-danger:hover {
  background-color: #dc4d37;
  border-color: #dc4d37;
}
.bg-alt-lvl4 .btn.btn-danger:not(:disabled):not(.disabled):active, .bg-alt-lvl4 .btn.btn-danger:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
}

.bg-alt-lvl3 .btn.btn-secondary {
  color: #000;
}
.bg-alt-lvl3 .btn.btn-secondary:hover {
  background-color: #858d8f;
  border-color: #858d8f;
}
.bg-alt-lvl3 .btn.btn-secondary:not(:disabled):not(.disabled):active, .bg-alt-lvl3 .btn.btn-secondary:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
}

.bg-alt-lvl3 .btn.btn-light {
  color: #000;
}
.bg-alt-lvl3 .btn.btn-light:hover {
  background-color: #bcc5c7;
  border-color: #bcc5c7;
}
.bg-alt-lvl3 .btn.btn-light:not(:disabled):not(.disabled):active, .bg-alt-lvl3 .btn.btn-light:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
}

.bg-alt-lvl3 .btn.btn-dark {
  color: #000;
}
.bg-alt-lvl3 .btn.btn-dark:hover {
  background-color: #454a4a;
  border-color: #454a4a;
}
.bg-alt-lvl3 .btn.btn-dark:not(:disabled):not(.disabled):active, .bg-alt-lvl3 .btn.btn-dark:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
}

.bg-alt-lvl3 .btn.btn-info {
  color: #000;
}
.bg-alt-lvl3 .btn.btn-info:hover {
  background-color: #3d8eff;
  border-color: #3d8eff;
}
.bg-alt-lvl3 .btn.btn-info:not(:disabled):not(.disabled):active, .bg-alt-lvl3 .btn.btn-info:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
}

.bg-alt-lvl3 .btn.btn-success {
  color: #000;
}
.bg-alt-lvl3 .btn.btn-success:hover {
  background-color: #00cc4c;
  border-color: #00cc4c;
}
.bg-alt-lvl3 .btn.btn-success:not(:disabled):not(.disabled):active, .bg-alt-lvl3 .btn.btn-success:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
}

.bg-alt-lvl3 .btn.btn-warning {
  color: #000;
}
.bg-alt-lvl3 .btn.btn-warning:hover {
  background-color: #ef8800;
  border-color: #ef8800;
}
.bg-alt-lvl3 .btn.btn-warning:not(:disabled):not(.disabled):active, .bg-alt-lvl3 .btn.btn-warning:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
}

.bg-alt-lvl3 .btn.btn-danger {
  color: #000;
}
.bg-alt-lvl3 .btn.btn-danger:hover {
  background-color: #ea543e;
  border-color: #ea543e;
}
.bg-alt-lvl3 .btn.btn-danger:not(:disabled):not(.disabled):active, .bg-alt-lvl3 .btn.btn-danger:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
}

.bg-alt-lvl2 .btn.btn-secondary {
  color: #000;
}
.bg-alt-lvl2 .btn.btn-secondary:hover {
  background-color: #8f9799;
  border-color: #8f9799;
}
.bg-alt-lvl2 .btn.btn-secondary:not(:disabled):not(.disabled):active, .bg-alt-lvl2 .btn.btn-secondary:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
}

.bg-alt-lvl2 .btn.btn-light {
  color: #000;
}
.bg-alt-lvl2 .btn.btn-light:hover {
  background-color: #cdd4d5;
  border-color: #cdd4d5;
}
.bg-alt-lvl2 .btn.btn-light:not(:disabled):not(.disabled):active, .bg-alt-lvl2 .btn.btn-light:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
}

.bg-alt-lvl2 .btn.btn-dark {
  color: #000;
}
.bg-alt-lvl2 .btn.btn-dark:hover {
  background-color: #4f5555;
  border-color: #4f5555;
}
.bg-alt-lvl2 .btn.btn-dark:not(:disabled):not(.disabled):active, .bg-alt-lvl2 .btn.btn-dark:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
}

.bg-alt-lvl2 .btn.btn-info {
  color: #000;
}
.bg-alt-lvl2 .btn.btn-info:hover {
  background-color: #519aff;
  border-color: #519aff;
}
.bg-alt-lvl2 .btn.btn-info:not(:disabled):not(.disabled):active, .bg-alt-lvl2 .btn.btn-info:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
}

.bg-alt-lvl2 .btn.btn-success {
  color: #000;
}
.bg-alt-lvl2 .btn.btn-success:hover {
  background-color: #00db52;
  border-color: #00db52;
}
.bg-alt-lvl2 .btn.btn-success:not(:disabled):not(.disabled):active, .bg-alt-lvl2 .btn.btn-success:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
}

.bg-alt-lvl2 .btn.btn-warning {
  color: #000;
}
.bg-alt-lvl2 .btn.btn-warning:hover {
  background-color: #ff9000;
  border-color: #ff9000;
}
.bg-alt-lvl2 .btn.btn-warning:not(:disabled):not(.disabled):active, .bg-alt-lvl2 .btn.btn-warning:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
}

.bg-alt-lvl2 .btn.btn-danger {
  color: #000;
}
.bg-alt-lvl2 .btn.btn-danger:hover {
  background-color: #f55e47;
  border-color: #f55e47;
}
.bg-alt-lvl2 .btn.btn-danger:not(:disabled):not(.disabled):active, .bg-alt-lvl2 .btn.btn-danger:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
}

.bg-alt-lvl1 .btn.btn-secondary {
  color: #000;
}
.bg-alt-lvl1 .btn.btn-secondary:hover {
  background-color: #9fa6a8;
  border-color: #9fa6a8;
}
.bg-alt-lvl1 .btn.btn-secondary:not(:disabled):not(.disabled):active, .bg-alt-lvl1 .btn.btn-secondary:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
}

.bg-alt-lvl1 .btn.btn-light {
  color: #000;
}
.bg-alt-lvl1 .btn.btn-light:hover {
  background-color: #e3e7e7;
  border-color: #e3e7e7;
}
.bg-alt-lvl1 .btn.btn-light:not(:disabled):not(.disabled):active, .bg-alt-lvl1 .btn.btn-light:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
}

.bg-alt-lvl1 .btn.btn-dark {
  color: #000;
}
.bg-alt-lvl1 .btn.btn-dark:hover {
  background-color: #5b6262;
  border-color: #5b6262;
}
.bg-alt-lvl1 .btn.btn-dark:not(:disabled):not(.disabled):active, .bg-alt-lvl1 .btn.btn-dark:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
}

.bg-alt-lvl1 .btn.btn-info {
  color: #000;
}
.bg-alt-lvl1 .btn.btn-info:hover {
  background-color: #66a6ff;
  border-color: #66a6ff;
}
.bg-alt-lvl1 .btn.btn-info:not(:disabled):not(.disabled):active, .bg-alt-lvl1 .btn.btn-info:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
}

.bg-alt-lvl1 .btn.btn-success {
  color: #000;
}
.bg-alt-lvl1 .btn.btn-success:hover {
  background-color: #00eb58;
  border-color: #00eb58;
}
.bg-alt-lvl1 .btn.btn-success:not(:disabled):not(.disabled):active, .bg-alt-lvl1 .btn.btn-success:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
}

.bg-alt-lvl1 .btn.btn-warning {
  color: #000;
}
.bg-alt-lvl1 .btn.btn-warning:hover {
  background-color: #ff970e;
  border-color: #ff970e;
}
.bg-alt-lvl1 .btn.btn-warning:not(:disabled):not(.disabled):active, .bg-alt-lvl1 .btn.btn-warning:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
}

.bg-alt-lvl1 .btn.btn-danger {
  color: #000;
}
.bg-alt-lvl1 .btn.btn-danger:hover {
  background-color: #ff6850;
  border-color: #ff6850;
}
.bg-alt-lvl1 .btn.btn-danger:not(:disabled):not(.disabled):active, .bg-alt-lvl1 .btn.btn-danger:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
}

.bg-lvl4 .btn.btn-secondary {
  color: #fff;
}
.bg-lvl4 .btn.btn-secondary:hover {
  background-color: #505658;
  border-color: #505658;
}
.bg-lvl4 .btn.btn-secondary:not(:disabled):not(.disabled):active, .bg-lvl4 .btn.btn-secondary:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
}

.bg-lvl4 .btn.btn-light {
  color: #fff;
}
.bg-lvl4 .btn.btn-light:hover {
  background-color: #a1a8ab;
  border-color: #a1a8ab;
}
.bg-lvl4 .btn.btn-light:not(:disabled):not(.disabled):active, .bg-lvl4 .btn.btn-light:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
}

.bg-lvl4 .btn.btn-dark {
  color: #fff;
}
.bg-lvl4 .btn.btn-dark:hover {
  background-color: #242929;
  border-color: #242929;
}
.bg-lvl4 .btn.btn-dark:not(:disabled):not(.disabled):active, .bg-lvl4 .btn.btn-dark:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
}

.bg-lvl4 .btn.btn-info {
  color: #fff;
}
.bg-lvl4 .btn.btn-info:hover {
  background-color: #0048e5;
  border-color: #0048e5;
}
.bg-lvl4 .btn.btn-info:not(:disabled):not(.disabled):active, .bg-lvl4 .btn.btn-info:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
}

.bg-lvl4 .btn.btn-success {
  color: #fff;
}
.bg-lvl4 .btn.btn-success:hover {
  background-color: #006b07;
  border-color: #006b07;
}
.bg-lvl4 .btn.btn-success:not(:disabled):not(.disabled):active, .bg-lvl4 .btn.btn-success:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
}

.bg-lvl4 .btn.btn-warning {
  color: #fff;
}
.bg-lvl4 .btn.btn-warning:hover {
  background-color: #9d5500;
  border-color: #9d5500;
}
.bg-lvl4 .btn.btn-warning:not(:disabled):not(.disabled):active, .bg-lvl4 .btn.btn-warning:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
}

.bg-lvl4 .btn.btn-danger {
  color: #fff;
}
.bg-lvl4 .btn.btn-danger:hover {
  background-color: #a8291b;
  border-color: #a8291b;
}
.bg-lvl4 .btn.btn-danger:not(:disabled):not(.disabled):active, .bg-lvl4 .btn.btn-danger:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
}

.bg-lvl3 .btn.btn-secondary {
  color: #fff;
}
.bg-lvl3 .btn.btn-secondary:hover {
  background-color: #575e60;
  border-color: #575e60;
}
.bg-lvl3 .btn.btn-secondary:not(:disabled):not(.disabled):active, .bg-lvl3 .btn.btn-secondary:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
}

.bg-lvl3 .btn.btn-light {
  color: #fff;
}
.bg-lvl3 .btn.btn-light:hover {
  background-color: #a9afb2;
  border-color: #a9afb2;
}
.bg-lvl3 .btn.btn-light:not(:disabled):not(.disabled):active, .bg-lvl3 .btn.btn-light:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
}

.bg-lvl3 .btn.btn-dark {
  color: #fff;
}
.bg-lvl3 .btn.btn-dark:hover {
  background-color: #292e2e;
  border-color: #292e2e;
}
.bg-lvl3 .btn.btn-dark:not(:disabled):not(.disabled):active, .bg-lvl3 .btn.btn-dark:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
}

.bg-lvl3 .btn.btn-info {
  color: #fff;
}
.bg-lvl3 .btn.btn-info:hover {
  background-color: #004cef;
  border-color: #004cef;
}
.bg-lvl3 .btn.btn-info:not(:disabled):not(.disabled):active, .bg-lvl3 .btn.btn-info:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
}

.bg-lvl3 .btn.btn-success {
  color: #fff;
}
.bg-lvl3 .btn.btn-success:hover {
  background-color: #007508;
  border-color: #007508;
}
.bg-lvl3 .btn.btn-success:not(:disabled):not(.disabled):active, .bg-lvl3 .btn.btn-success:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
}

.bg-lvl3 .btn.btn-warning {
  color: #fff;
}
.bg-lvl3 .btn.btn-warning:hover {
  background-color: #a75a00;
  border-color: #a75a00;
}
.bg-lvl3 .btn.btn-warning:not(:disabled):not(.disabled):active, .bg-lvl3 .btn.btn-warning:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
}

.bg-lvl3 .btn.btn-danger {
  color: #fff;
}
.bg-lvl3 .btn.btn-danger:hover {
  background-color: #b12b1c;
  border-color: #b12b1c;
}
.bg-lvl3 .btn.btn-danger:not(:disabled):not(.disabled):active, .bg-lvl3 .btn.btn-danger:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
}

.bg-lvl2 .btn.btn-secondary {
  color: #fff;
}
.bg-lvl2 .btn.btn-secondary:hover {
  background-color: #596063;
  border-color: #596063;
}
.bg-lvl2 .btn.btn-secondary:not(:disabled):not(.disabled):active, .bg-lvl2 .btn.btn-secondary:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
}

.bg-lvl2 .btn.btn-light {
  color: #fff;
}
.bg-lvl2 .btn.btn-light:hover {
  background-color: #b1b6b8;
  border-color: #b1b6b8;
}
.bg-lvl2 .btn.btn-light:not(:disabled):not(.disabled):active, .bg-lvl2 .btn.btn-light:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
}

.bg-lvl2 .btn.btn-dark {
  color: #fff;
}
.bg-lvl2 .btn.btn-dark:hover {
  background-color: #2e3333;
  border-color: #2e3333;
}
.bg-lvl2 .btn.btn-dark:not(:disabled):not(.disabled):active, .bg-lvl2 .btn.btn-dark:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
}

.bg-lvl2 .btn.btn-info {
  color: #fff;
}
.bg-lvl2 .btn.btn-info:hover {
  background-color: #004ffa;
  border-color: #004ffa;
}
.bg-lvl2 .btn.btn-info:not(:disabled):not(.disabled):active, .bg-lvl2 .btn.btn-info:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
}

.bg-lvl2 .btn.btn-success {
  color: #fff;
}
.bg-lvl2 .btn.btn-success:hover {
  background-color: #007f09;
  border-color: #007f09;
}
.bg-lvl2 .btn.btn-success:not(:disabled):not(.disabled):active, .bg-lvl2 .btn.btn-success:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
}

.bg-lvl2 .btn.btn-warning {
  color: #fff;
}
.bg-lvl2 .btn.btn-warning:hover {
  background-color: #b15f00;
  border-color: #b15f00;
}
.bg-lvl2 .btn.btn-warning:not(:disabled):not(.disabled):active, .bg-lvl2 .btn.btn-warning:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
}

.bg-lvl2 .btn.btn-danger {
  color: #fff;
}
.bg-lvl2 .btn.btn-danger:hover {
  background-color: #ba2d1d;
  border-color: #ba2d1d;
}
.bg-lvl2 .btn.btn-danger:not(:disabled):not(.disabled):active, .bg-lvl2 .btn.btn-danger:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
}

.bg-lvl1 .btn.btn-secondary {
  color: #fff;
}
.bg-lvl1 .btn.btn-secondary:hover {
  background-color: #5f676a;
  border-color: #5f676a;
}
.bg-lvl1 .btn.btn-secondary:not(:disabled):not(.disabled):active, .bg-lvl1 .btn.btn-secondary:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
}

.bg-lvl1 .btn.btn-light {
  color: #fff;
}
.bg-lvl1 .btn.btn-light:hover {
  background-color: #b4b9bc;
  border-color: #b4b9bc;
}
.bg-lvl1 .btn.btn-light:not(:disabled):not(.disabled):active, .bg-lvl1 .btn.btn-light:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
}

.bg-lvl1 .btn.btn-dark {
  color: #fff;
}
.bg-lvl1 .btn.btn-dark:hover {
  background-color: #333939;
  border-color: #333939;
}
.bg-lvl1 .btn.btn-dark:not(:disabled):not(.disabled):active, .bg-lvl1 .btn.btn-dark:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
}

.bg-lvl1 .btn.btn-info {
  color: #fff;
}
.bg-lvl1 .btn.btn-info:hover {
  background-color: #0554ff;
  border-color: #0554ff;
}
.bg-lvl1 .btn.btn-info:not(:disabled):not(.disabled):active, .bg-lvl1 .btn.btn-info:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
}

.bg-lvl1 .btn.btn-success {
  color: #fff;
}
.bg-lvl1 .btn.btn-success:hover {
  background-color: #008b0a;
  border-color: #008b0a;
}
.bg-lvl1 .btn.btn-success:not(:disabled):not(.disabled):active, .bg-lvl1 .btn.btn-success:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
}

.bg-lvl1 .btn.btn-warning {
  color: #fff;
}
.bg-lvl1 .btn.btn-warning:hover {
  background-color: #bb6400;
  border-color: #bb6400;
}
.bg-lvl1 .btn.btn-warning:not(:disabled):not(.disabled):active, .bg-lvl1 .btn.btn-warning:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
}

.bg-lvl1 .btn.btn-danger {
  color: #fff;
}
.bg-lvl1 .btn.btn-danger:hover {
  background-color: #c42f1e;
  border-color: #c42f1e;
}
.bg-lvl1 .btn.btn-danger:not(:disabled):not(.disabled):active, .bg-lvl1 .btn.btn-danger:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
}

.btn-link:hover {
  text-decoration: none;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: none;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(48, 51, 51, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(48, 51, 51, 0.25);
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1.1875rem;
  font-size: 0.875rem;
  line-height: 1.5714285714;
  border-radius: 0;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0 0.4375rem;
  font-size: 0.75rem;
  line-height: 1.5;
  border-radius: 0;
}

.btn-group-xl .btn,
.btn-xl {
  padding: 0.6875rem 1.4375rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn.btn-flat-socgen {
  color: #e60028;
  background-color: transparent;
}
.btn.btn-flat-socgen:hover {
  text-decoration: none;
  background-color: rgba(230, 0, 40, 0.1);
}
.btn.btn-flat-socgen:focus, .btn.btn-flat-socgen.focus {
  background-color: rgba(230, 0, 40, 0.1);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn.btn-flat-socgen:not([disabled]):not(.disabled).active, .btn.btn-flat-socgen:not([disabled]):not(.disabled):active {
  background-color: rgba(230, 0, 40, 0.2);
}
.btn.btn-flat-socgen:disabled, .btn.btn-flat-socgen.disabled {
  color: #e60028;
  background-color: transparent;
}
.btn.btn-flat {
  color: #000;
  background-color: transparent;
}
.btn.btn-flat:hover {
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.1);
}
.btn.btn-flat:focus, .btn.btn-flat.focus {
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn.btn-flat:not([disabled]):not(.disabled).active, .btn.btn-flat:not([disabled]):not(.disabled):active {
  background-color: rgba(0, 0, 0, 0.2);
}
.btn.btn-flat:disabled, .btn.btn-flat.disabled {
  color: #000;
  background-color: transparent;
}
.btn.btn-flat:not(:hover):not(:active):not(.active):not(:focus):not(.focus):not([disabled]):not(.disabled) {
  color: #6e777a;
}
.btn.btn-discreet-socgen {
  color: #e60028;
  background-color: rgba(230, 0, 40, 0.1);
}
.btn.btn-discreet-socgen:hover:not([disabled]):not(.disabled), .btn.btn-discreet-socgen:focus:not([disabled]):not(.disabled) {
  color: #fff;
  background-color: #e60028;
  border-color: #e60028;
}
.btn.btn-discreet-socgen:not([disabled]):not(.disabled):active, .btn.btn-discreet-socgen:not([disabled]):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75);
  background-color: #e60028;
  border-color: #e60028;
}
.btn.btn-discreet-socgen:disabled, .btn.btn-discreet-socgen.disabled {
  color: #e60028;
  background-color: transparent;
}
.btn.btn-outline-socgen {
  background-color: transparent;
  border-color: #e60028;
  color: #e60028;
}
.btn.btn-outline-socgen:hover {
  background-color: #e60028;
  color: #fff;
}
.btn.btn-outline-socgen:focus, .btn.btn-outline-socgen.focus {
  background-color: #e60028;
  color: #fff;
}
.btn.btn-outline-socgen:not(:disabled):not(.disabled):active, .btn.btn-outline-socgen:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75);
}
.btn.btn-outline-socgen.disabled, .btn.btn-outline-socgen:disabled {
  color: #e60028;
  background-color: transparent;
  border-color: #e60028;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn.btn-toggle-socgen {
  color: #e60028;
  background-color: transparent;
  border-color: #e60028;
}
.btn.btn-toggle-socgen:hover {
  background-color: rgba(230, 0, 40, 0.2);
}
.btn.btn-toggle-socgen:not([disabled]):not(.disabled):active {
  background-color: rgba(230, 0, 40, 0.3);
}
.btn.btn-toggle-socgen.disabled, .btn.btn-toggle-socgen:disabled {
  background-color: transparent;
  color: #e60028;
}
.btn.btn-toggle-socgen.active {
  color: #fff;
  background-color: #e60028;
}
.btn.btn-toggle-socgen.active:hover, .btn.btn-toggle-socgen.active:focus, .btn.btn-toggle-socgen.active.focus {
  background-color: #f64243;
  border-color: #f64243;
}
.btn.btn-toggle-socgen.active:not([disabled]):not(.disabled):active {
  color: rgba(255, 255, 255, 0.75);
  background-color: #e60028;
  border-color: #e60028;
}

.btn.btn-flat-primary {
  color: #000;
  background-color: transparent;
}
.btn.btn-flat-primary:hover {
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.1);
}
.btn.btn-flat-primary:focus, .btn.btn-flat-primary.focus {
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn.btn-flat-primary:not([disabled]):not(.disabled).active, .btn.btn-flat-primary:not([disabled]):not(.disabled):active {
  background-color: rgba(0, 0, 0, 0.2);
}
.btn.btn-flat-primary:disabled, .btn.btn-flat-primary.disabled {
  color: #000;
  background-color: transparent;
}
.btn.btn-flat {
  color: #000;
  background-color: transparent;
}
.btn.btn-flat:hover {
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.1);
}
.btn.btn-flat:focus, .btn.btn-flat.focus {
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn.btn-flat:not([disabled]):not(.disabled).active, .btn.btn-flat:not([disabled]):not(.disabled):active {
  background-color: rgba(0, 0, 0, 0.2);
}
.btn.btn-flat:disabled, .btn.btn-flat.disabled {
  color: #000;
  background-color: transparent;
}
.btn.btn-flat:not(:hover):not(:active):not(.active):not(:focus):not(.focus):not([disabled]):not(.disabled) {
  color: #6e777a;
}
.btn.btn-discreet-primary {
  color: #000;
  background-color: rgba(0, 0, 0, 0.1);
}
.btn.btn-discreet-primary:hover:not([disabled]):not(.disabled), .btn.btn-discreet-primary:focus:not([disabled]):not(.disabled) {
  color: #fff;
  background-color: #000;
  border-color: #000;
}
.btn.btn-discreet-primary:not([disabled]):not(.disabled):active, .btn.btn-discreet-primary:not([disabled]):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75);
  background-color: #000;
  border-color: #000;
}
.btn.btn-discreet-primary:disabled, .btn.btn-discreet-primary.disabled {
  color: #000;
  background-color: transparent;
}
.btn.btn-outline-primary {
  background-color: transparent;
  border-color: #000;
  color: #000;
}
.btn.btn-outline-primary:hover {
  background-color: #000;
  color: #fff;
}
.btn.btn-outline-primary:focus, .btn.btn-outline-primary.focus {
  background-color: #000;
  color: #fff;
}
.btn.btn-outline-primary:not(:disabled):not(.disabled):active, .btn.btn-outline-primary:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75);
}
.btn.btn-outline-primary.disabled, .btn.btn-outline-primary:disabled {
  color: #000;
  background-color: transparent;
  border-color: #000;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn.btn-toggle-primary {
  color: #000;
  background-color: transparent;
  border-color: #000;
}
.btn.btn-toggle-primary:hover {
  background-color: rgba(0, 0, 0, 0.2);
}
.btn.btn-toggle-primary:not([disabled]):not(.disabled):active {
  background-color: rgba(0, 0, 0, 0.3);
}
.btn.btn-toggle-primary.disabled, .btn.btn-toggle-primary:disabled {
  background-color: transparent;
  color: #000;
}
.btn.btn-toggle-primary.active {
  color: #fff;
  background-color: #000;
}
.btn.btn-toggle-primary.active:hover, .btn.btn-toggle-primary.active:focus, .btn.btn-toggle-primary.active.focus {
  background-color: #414646;
  border-color: #414646;
}
.btn.btn-toggle-primary.active:not([disabled]):not(.disabled):active {
  color: rgba(255, 255, 255, 0.75);
  background-color: #000;
  border-color: #000;
}

.btn.btn-flat-primary-alt {
  color: #fff;
  background-color: transparent;
}
.btn.btn-flat-primary-alt:hover {
  text-decoration: none;
  background-color: rgba(255, 255, 255, 0.1);
}
.btn.btn-flat-primary-alt:focus, .btn.btn-flat-primary-alt.focus {
  background-color: rgba(255, 255, 255, 0.1);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn.btn-flat-primary-alt:not([disabled]):not(.disabled).active, .btn.btn-flat-primary-alt:not([disabled]):not(.disabled):active {
  background-color: rgba(255, 255, 255, 0.2);
}
.btn.btn-flat-primary-alt:disabled, .btn.btn-flat-primary-alt.disabled {
  color: #fff;
  background-color: transparent;
}
.btn.btn-flat {
  color: #000;
  background-color: transparent;
}
.btn.btn-flat:hover {
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.1);
}
.btn.btn-flat:focus, .btn.btn-flat.focus {
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn.btn-flat:not([disabled]):not(.disabled).active, .btn.btn-flat:not([disabled]):not(.disabled):active {
  background-color: rgba(0, 0, 0, 0.2);
}
.btn.btn-flat:disabled, .btn.btn-flat.disabled {
  color: #000;
  background-color: transparent;
}
.btn.btn-flat:not(:hover):not(:active):not(.active):not(:focus):not(.focus):not([disabled]):not(.disabled) {
  color: #6e777a;
}
.btn.btn-discreet-primary-alt {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
}
.btn.btn-discreet-primary-alt:hover:not([disabled]):not(.disabled), .btn.btn-discreet-primary-alt:focus:not([disabled]):not(.disabled) {
  color: #fff;
  background-color: #fff;
  border-color: #fff;
}
.btn.btn-discreet-primary-alt:not([disabled]):not(.disabled):active, .btn.btn-discreet-primary-alt:not([disabled]):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75);
  background-color: #fff;
  border-color: #fff;
}
.btn.btn-discreet-primary-alt:disabled, .btn.btn-discreet-primary-alt.disabled {
  color: #fff;
  background-color: transparent;
}
.btn.btn-outline-primary-alt {
  background-color: transparent;
  border-color: #fff;
  color: #fff;
}
.btn.btn-outline-primary-alt:hover {
  background-color: #fff;
  color: #fff;
}
.btn.btn-outline-primary-alt:focus, .btn.btn-outline-primary-alt.focus {
  background-color: #fff;
  color: #fff;
}
.btn.btn-outline-primary-alt:not(:disabled):not(.disabled):active, .btn.btn-outline-primary-alt:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75);
}
.btn.btn-outline-primary-alt.disabled, .btn.btn-outline-primary-alt:disabled {
  color: #fff;
  background-color: transparent;
  border-color: #fff;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn.btn-toggle-primary-alt {
  color: #fff;
  background-color: transparent;
  border-color: #fff;
}
.btn.btn-toggle-primary-alt:hover {
  background-color: rgba(255, 255, 255, 0.2);
}
.btn.btn-toggle-primary-alt:not([disabled]):not(.disabled):active {
  background-color: rgba(255, 255, 255, 0.3);
}
.btn.btn-toggle-primary-alt.disabled, .btn.btn-toggle-primary-alt:disabled {
  background-color: transparent;
  color: #fff;
}
.btn.btn-toggle-primary-alt.active {
  color: #fff;
  background-color: #fff;
}
.btn.btn-toggle-primary-alt.active:hover, .btn.btn-toggle-primary-alt.active:focus, .btn.btn-toggle-primary-alt.active.focus {
  background-color: #fff;
  border-color: #fff;
}
.btn.btn-toggle-primary-alt.active:not([disabled]):not(.disabled):active {
  color: rgba(33, 37, 41, 0.75);
  background-color: #fff;
  border-color: #fff;
}

.btn.btn-flat-secondary {
  color: #6e777a;
  background-color: transparent;
}
.btn.btn-flat-secondary:hover {
  text-decoration: none;
  background-color: rgba(110, 119, 122, 0.1);
}
.btn.btn-flat-secondary:focus, .btn.btn-flat-secondary.focus {
  background-color: rgba(110, 119, 122, 0.1);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn.btn-flat-secondary:not([disabled]):not(.disabled).active, .btn.btn-flat-secondary:not([disabled]):not(.disabled):active {
  background-color: rgba(110, 119, 122, 0.2);
}
.btn.btn-flat-secondary:disabled, .btn.btn-flat-secondary.disabled {
  color: #6e777a;
  background-color: transparent;
}
.btn.btn-flat {
  color: #000;
  background-color: transparent;
}
.btn.btn-flat:hover {
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.1);
}
.btn.btn-flat:focus, .btn.btn-flat.focus {
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn.btn-flat:not([disabled]):not(.disabled).active, .btn.btn-flat:not([disabled]):not(.disabled):active {
  background-color: rgba(0, 0, 0, 0.2);
}
.btn.btn-flat:disabled, .btn.btn-flat.disabled {
  color: #000;
  background-color: transparent;
}
.btn.btn-flat:not(:hover):not(:active):not(.active):not(:focus):not(.focus):not([disabled]):not(.disabled) {
  color: #6e777a;
}
.btn.btn-discreet-secondary {
  color: #6e777a;
  background-color: rgba(110, 119, 122, 0.1);
}
.btn.btn-discreet-secondary:hover:not([disabled]):not(.disabled), .btn.btn-discreet-secondary:focus:not([disabled]):not(.disabled) {
  color: #fff;
  background-color: #6e777a;
  border-color: #6e777a;
}
.btn.btn-discreet-secondary:not([disabled]):not(.disabled):active, .btn.btn-discreet-secondary:not([disabled]):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75);
  background-color: #6e777a;
  border-color: #6e777a;
}
.btn.btn-discreet-secondary:disabled, .btn.btn-discreet-secondary.disabled {
  color: #6e777a;
  background-color: transparent;
}
.btn.btn-outline-secondary {
  background-color: transparent;
  border-color: #6e777a;
  color: #6e777a;
}
.btn.btn-outline-secondary:hover {
  background-color: #6e777a;
  color: #fff;
}
.btn.btn-outline-secondary:focus, .btn.btn-outline-secondary.focus {
  background-color: #6e777a;
  color: #fff;
}
.btn.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn.btn-outline-secondary:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75);
}
.btn.btn-outline-secondary.disabled, .btn.btn-outline-secondary:disabled {
  color: #6e777a;
  background-color: transparent;
  border-color: #6e777a;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn.btn-toggle-secondary {
  color: #6e777a;
  background-color: transparent;
  border-color: #6e777a;
}
.btn.btn-toggle-secondary:hover {
  background-color: rgba(110, 119, 122, 0.2);
}
.btn.btn-toggle-secondary:not([disabled]):not(.disabled):active {
  background-color: rgba(110, 119, 122, 0.3);
}
.btn.btn-toggle-secondary.disabled, .btn.btn-toggle-secondary:disabled {
  background-color: transparent;
  color: #6e777a;
}
.btn.btn-toggle-secondary.active {
  color: #fff;
  background-color: #6e777a;
}
.btn.btn-toggle-secondary.active:hover, .btn.btn-toggle-secondary.active:focus, .btn.btn-toggle-secondary.active.focus {
  background-color: #afb8b8;
  border-color: #afb8b8;
}
.btn.btn-toggle-secondary.active:not([disabled]):not(.disabled):active {
  color: rgba(255, 255, 255, 0.75);
  background-color: #6e777a;
  border-color: #6e777a;
}

.btn.btn-flat-success {
  color: #00aa0c;
  background-color: transparent;
}
.btn.btn-flat-success:hover {
  text-decoration: none;
  background-color: rgba(0, 170, 12, 0.1);
}
.btn.btn-flat-success:focus, .btn.btn-flat-success.focus {
  background-color: rgba(0, 170, 12, 0.1);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn.btn-flat-success:not([disabled]):not(.disabled).active, .btn.btn-flat-success:not([disabled]):not(.disabled):active {
  background-color: rgba(0, 170, 12, 0.2);
}
.btn.btn-flat-success:disabled, .btn.btn-flat-success.disabled {
  color: #00aa0c;
  background-color: transparent;
}
.btn.btn-flat {
  color: #000;
  background-color: transparent;
}
.btn.btn-flat:hover {
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.1);
}
.btn.btn-flat:focus, .btn.btn-flat.focus {
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn.btn-flat:not([disabled]):not(.disabled).active, .btn.btn-flat:not([disabled]):not(.disabled):active {
  background-color: rgba(0, 0, 0, 0.2);
}
.btn.btn-flat:disabled, .btn.btn-flat.disabled {
  color: #000;
  background-color: transparent;
}
.btn.btn-flat:not(:hover):not(:active):not(.active):not(:focus):not(.focus):not([disabled]):not(.disabled) {
  color: #6e777a;
}
.btn.btn-discreet-success {
  color: #00aa0c;
  background-color: rgba(0, 170, 12, 0.1);
}
.btn.btn-discreet-success:hover:not([disabled]):not(.disabled), .btn.btn-discreet-success:focus:not([disabled]):not(.disabled) {
  color: #fff;
  background-color: #00aa0c;
  border-color: #00aa0c;
}
.btn.btn-discreet-success:not([disabled]):not(.disabled):active, .btn.btn-discreet-success:not([disabled]):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75);
  background-color: #00aa0c;
  border-color: #00aa0c;
}
.btn.btn-discreet-success:disabled, .btn.btn-discreet-success.disabled {
  color: #00aa0c;
  background-color: transparent;
}
.btn.btn-outline-success {
  background-color: transparent;
  border-color: #00aa0c;
  color: #00aa0c;
}
.btn.btn-outline-success:hover {
  background-color: #00aa0c;
  color: #fff;
}
.btn.btn-outline-success:focus, .btn.btn-outline-success.focus {
  background-color: #00aa0c;
  color: #fff;
}
.btn.btn-outline-success:not(:disabled):not(.disabled):active, .btn.btn-outline-success:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75);
}
.btn.btn-outline-success.disabled, .btn.btn-outline-success:disabled {
  color: #00aa0c;
  background-color: transparent;
  border-color: #00aa0c;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn.btn-toggle-success {
  color: #00aa0c;
  background-color: transparent;
  border-color: #00aa0c;
}
.btn.btn-toggle-success:hover {
  background-color: rgba(0, 170, 12, 0.2);
}
.btn.btn-toggle-success:not([disabled]):not(.disabled):active {
  background-color: rgba(0, 170, 12, 0.3);
}
.btn.btn-toggle-success.disabled, .btn.btn-toggle-success:disabled {
  background-color: transparent;
  color: #00aa0c;
}
.btn.btn-toggle-success.active {
  color: #fff;
  background-color: #00aa0c;
}
.btn.btn-toggle-success.active:hover, .btn.btn-toggle-success.active:focus, .btn.btn-toggle-success.active.focus {
  background-color: #00be24;
  border-color: #00be24;
}
.btn.btn-toggle-success.active:not([disabled]):not(.disabled):active {
  color: rgba(255, 255, 255, 0.75);
  background-color: #00aa0c;
  border-color: #00aa0c;
}

.btn.btn-flat-info {
  color: #2469ff;
  background-color: transparent;
}
.btn.btn-flat-info:hover {
  text-decoration: none;
  background-color: rgba(36, 105, 255, 0.1);
}
.btn.btn-flat-info:focus, .btn.btn-flat-info.focus {
  background-color: rgba(36, 105, 255, 0.1);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn.btn-flat-info:not([disabled]):not(.disabled).active, .btn.btn-flat-info:not([disabled]):not(.disabled):active {
  background-color: rgba(36, 105, 255, 0.2);
}
.btn.btn-flat-info:disabled, .btn.btn-flat-info.disabled {
  color: #2469ff;
  background-color: transparent;
}
.btn.btn-flat {
  color: #000;
  background-color: transparent;
}
.btn.btn-flat:hover {
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.1);
}
.btn.btn-flat:focus, .btn.btn-flat.focus {
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn.btn-flat:not([disabled]):not(.disabled).active, .btn.btn-flat:not([disabled]):not(.disabled):active {
  background-color: rgba(0, 0, 0, 0.2);
}
.btn.btn-flat:disabled, .btn.btn-flat.disabled {
  color: #000;
  background-color: transparent;
}
.btn.btn-flat:not(:hover):not(:active):not(.active):not(:focus):not(.focus):not([disabled]):not(.disabled) {
  color: #6e777a;
}
.btn.btn-discreet-info {
  color: #2469ff;
  background-color: rgba(36, 105, 255, 0.1);
}
.btn.btn-discreet-info:hover:not([disabled]):not(.disabled), .btn.btn-discreet-info:focus:not([disabled]):not(.disabled) {
  color: #fff;
  background-color: #2469ff;
  border-color: #2469ff;
}
.btn.btn-discreet-info:not([disabled]):not(.disabled):active, .btn.btn-discreet-info:not([disabled]):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75);
  background-color: #2469ff;
  border-color: #2469ff;
}
.btn.btn-discreet-info:disabled, .btn.btn-discreet-info.disabled {
  color: #2469ff;
  background-color: transparent;
}
.btn.btn-outline-info {
  background-color: transparent;
  border-color: #2469ff;
  color: #2469ff;
}
.btn.btn-outline-info:hover {
  background-color: #2469ff;
  color: #fff;
}
.btn.btn-outline-info:focus, .btn.btn-outline-info.focus {
  background-color: #2469ff;
  color: #fff;
}
.btn.btn-outline-info:not(:disabled):not(.disabled):active, .btn.btn-outline-info:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75);
}
.btn.btn-outline-info.disabled, .btn.btn-outline-info:disabled {
  color: #2469ff;
  background-color: transparent;
  border-color: #2469ff;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn.btn-toggle-info {
  color: #2469ff;
  background-color: transparent;
  border-color: #2469ff;
}
.btn.btn-toggle-info:hover {
  background-color: rgba(36, 105, 255, 0.2);
}
.btn.btn-toggle-info:not([disabled]):not(.disabled):active {
  background-color: rgba(36, 105, 255, 0.3);
}
.btn.btn-toggle-info.disabled, .btn.btn-toggle-info:disabled {
  background-color: transparent;
  color: #2469ff;
}
.btn.btn-toggle-info.active {
  color: #fff;
  background-color: #2469ff;
}
.btn.btn-toggle-info.active:hover, .btn.btn-toggle-info.active:focus, .btn.btn-toggle-info.active.focus {
  background-color: #2989ff;
  border-color: #2989ff;
}
.btn.btn-toggle-info.active:not([disabled]):not(.disabled):active {
  color: rgba(255, 255, 255, 0.75);
  background-color: #2469ff;
  border-color: #2469ff;
}

.btn.btn-flat-warning {
  color: #da7400;
  background-color: transparent;
}
.btn.btn-flat-warning:hover {
  text-decoration: none;
  background-color: rgba(218, 116, 0, 0.1);
}
.btn.btn-flat-warning:focus, .btn.btn-flat-warning.focus {
  background-color: rgba(218, 116, 0, 0.1);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn.btn-flat-warning:not([disabled]):not(.disabled).active, .btn.btn-flat-warning:not([disabled]):not(.disabled):active {
  background-color: rgba(218, 116, 0, 0.2);
}
.btn.btn-flat-warning:disabled, .btn.btn-flat-warning.disabled {
  color: #da7400;
  background-color: transparent;
}
.btn.btn-flat {
  color: #000;
  background-color: transparent;
}
.btn.btn-flat:hover {
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.1);
}
.btn.btn-flat:focus, .btn.btn-flat.focus {
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn.btn-flat:not([disabled]):not(.disabled).active, .btn.btn-flat:not([disabled]):not(.disabled):active {
  background-color: rgba(0, 0, 0, 0.2);
}
.btn.btn-flat:disabled, .btn.btn-flat.disabled {
  color: #000;
  background-color: transparent;
}
.btn.btn-flat:not(:hover):not(:active):not(.active):not(:focus):not(.focus):not([disabled]):not(.disabled) {
  color: #6e777a;
}
.btn.btn-discreet-warning {
  color: #da7400;
  background-color: rgba(218, 116, 0, 0.1);
}
.btn.btn-discreet-warning:hover:not([disabled]):not(.disabled), .btn.btn-discreet-warning:focus:not([disabled]):not(.disabled) {
  color: #fff;
  background-color: #da7400;
  border-color: #da7400;
}
.btn.btn-discreet-warning:not([disabled]):not(.disabled):active, .btn.btn-discreet-warning:not([disabled]):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75);
  background-color: #da7400;
  border-color: #da7400;
}
.btn.btn-discreet-warning:disabled, .btn.btn-discreet-warning.disabled {
  color: #da7400;
  background-color: transparent;
}
.btn.btn-outline-warning {
  background-color: transparent;
  border-color: #da7400;
  color: #da7400;
}
.btn.btn-outline-warning:hover {
  background-color: #da7400;
  color: #fff;
}
.btn.btn-outline-warning:focus, .btn.btn-outline-warning.focus {
  background-color: #da7400;
  color: #fff;
}
.btn.btn-outline-warning:not(:disabled):not(.disabled):active, .btn.btn-outline-warning:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75);
}
.btn.btn-outline-warning.disabled, .btn.btn-outline-warning:disabled {
  color: #da7400;
  background-color: transparent;
  border-color: #da7400;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn.btn-toggle-warning {
  color: #da7400;
  background-color: transparent;
  border-color: #da7400;
}
.btn.btn-toggle-warning:hover {
  background-color: rgba(218, 116, 0, 0.2);
}
.btn.btn-toggle-warning:not([disabled]):not(.disabled):active {
  background-color: rgba(218, 116, 0, 0.3);
}
.btn.btn-toggle-warning.disabled, .btn.btn-toggle-warning:disabled {
  background-color: transparent;
  color: #da7400;
}
.btn.btn-toggle-warning.active {
  color: #fff;
  background-color: #da7400;
}
.btn.btn-toggle-warning.active:hover, .btn.btn-toggle-warning.active:focus, .btn.btn-toggle-warning.active.focus {
  background-color: #d88902;
  border-color: #d88902;
}
.btn.btn-toggle-warning.active:not([disabled]):not(.disabled):active {
  color: rgba(255, 255, 255, 0.75);
  background-color: #da7400;
  border-color: #da7400;
}

.btn.btn-flat-danger {
  color: #dd3724;
  background-color: transparent;
}
.btn.btn-flat-danger:hover {
  text-decoration: none;
  background-color: rgba(221, 55, 36, 0.1);
}
.btn.btn-flat-danger:focus, .btn.btn-flat-danger.focus {
  background-color: rgba(221, 55, 36, 0.1);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn.btn-flat-danger:not([disabled]):not(.disabled).active, .btn.btn-flat-danger:not([disabled]):not(.disabled):active {
  background-color: rgba(221, 55, 36, 0.2);
}
.btn.btn-flat-danger:disabled, .btn.btn-flat-danger.disabled {
  color: #dd3724;
  background-color: transparent;
}
.btn.btn-flat {
  color: #000;
  background-color: transparent;
}
.btn.btn-flat:hover {
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.1);
}
.btn.btn-flat:focus, .btn.btn-flat.focus {
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn.btn-flat:not([disabled]):not(.disabled).active, .btn.btn-flat:not([disabled]):not(.disabled):active {
  background-color: rgba(0, 0, 0, 0.2);
}
.btn.btn-flat:disabled, .btn.btn-flat.disabled {
  color: #000;
  background-color: transparent;
}
.btn.btn-flat:not(:hover):not(:active):not(.active):not(:focus):not(.focus):not([disabled]):not(.disabled) {
  color: #6e777a;
}
.btn.btn-discreet-danger {
  color: #dd3724;
  background-color: rgba(221, 55, 36, 0.1);
}
.btn.btn-discreet-danger:hover:not([disabled]):not(.disabled), .btn.btn-discreet-danger:focus:not([disabled]):not(.disabled) {
  color: #fff;
  background-color: #dd3724;
  border-color: #dd3724;
}
.btn.btn-discreet-danger:not([disabled]):not(.disabled):active, .btn.btn-discreet-danger:not([disabled]):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75);
  background-color: #dd3724;
  border-color: #dd3724;
}
.btn.btn-discreet-danger:disabled, .btn.btn-discreet-danger.disabled {
  color: #dd3724;
  background-color: transparent;
}
.btn.btn-outline-danger {
  background-color: transparent;
  border-color: #dd3724;
  color: #dd3724;
}
.btn.btn-outline-danger:hover {
  background-color: #dd3724;
  color: #fff;
}
.btn.btn-outline-danger:focus, .btn.btn-outline-danger.focus {
  background-color: #dd3724;
  color: #fff;
}
.btn.btn-outline-danger:not(:disabled):not(.disabled):active, .btn.btn-outline-danger:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75);
}
.btn.btn-outline-danger.disabled, .btn.btn-outline-danger:disabled {
  color: #dd3724;
  background-color: transparent;
  border-color: #dd3724;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn.btn-toggle-danger {
  color: #dd3724;
  background-color: transparent;
  border-color: #dd3724;
}
.btn.btn-toggle-danger:hover {
  background-color: rgba(221, 55, 36, 0.2);
}
.btn.btn-toggle-danger:not([disabled]):not(.disabled):active {
  background-color: rgba(221, 55, 36, 0.3);
}
.btn.btn-toggle-danger.disabled, .btn.btn-toggle-danger:disabled {
  background-color: transparent;
  color: #dd3724;
}
.btn.btn-toggle-danger.active {
  color: #fff;
  background-color: #dd3724;
}
.btn.btn-toggle-danger.active:hover, .btn.btn-toggle-danger.active:focus, .btn.btn-toggle-danger.active.focus {
  background-color: #e84a2e;
  border-color: #e84a2e;
}
.btn.btn-toggle-danger.active:not([disabled]):not(.disabled):active {
  color: rgba(255, 255, 255, 0.75);
  background-color: #dd3724;
  border-color: #dd3724;
}

.btn.btn-flat-light {
  color: #c4c8ca;
  background-color: transparent;
}
.btn.btn-flat-light:hover {
  text-decoration: none;
  background-color: rgba(196, 200, 202, 0.1);
}
.btn.btn-flat-light:focus, .btn.btn-flat-light.focus {
  background-color: rgba(196, 200, 202, 0.1);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn.btn-flat-light:not([disabled]):not(.disabled).active, .btn.btn-flat-light:not([disabled]):not(.disabled):active {
  background-color: rgba(196, 200, 202, 0.2);
}
.btn.btn-flat-light:disabled, .btn.btn-flat-light.disabled {
  color: #c4c8ca;
  background-color: transparent;
}
.btn.btn-flat {
  color: #000;
  background-color: transparent;
}
.btn.btn-flat:hover {
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.1);
}
.btn.btn-flat:focus, .btn.btn-flat.focus {
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn.btn-flat:not([disabled]):not(.disabled).active, .btn.btn-flat:not([disabled]):not(.disabled):active {
  background-color: rgba(0, 0, 0, 0.2);
}
.btn.btn-flat:disabled, .btn.btn-flat.disabled {
  color: #000;
  background-color: transparent;
}
.btn.btn-flat:not(:hover):not(:active):not(.active):not(:focus):not(.focus):not([disabled]):not(.disabled) {
  color: #6e777a;
}
.btn.btn-discreet-light {
  color: #c4c8ca;
  background-color: rgba(196, 200, 202, 0.1);
}
.btn.btn-discreet-light:hover:not([disabled]):not(.disabled), .btn.btn-discreet-light:focus:not([disabled]):not(.disabled) {
  color: #fff;
  background-color: #c4c8ca;
  border-color: #c4c8ca;
}
.btn.btn-discreet-light:not([disabled]):not(.disabled):active, .btn.btn-discreet-light:not([disabled]):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75);
  background-color: #c4c8ca;
  border-color: #c4c8ca;
}
.btn.btn-discreet-light:disabled, .btn.btn-discreet-light.disabled {
  color: #c4c8ca;
  background-color: transparent;
}
.btn.btn-outline-light {
  background-color: transparent;
  border-color: #c4c8ca;
  color: #c4c8ca;
}
.btn.btn-outline-light:hover {
  background-color: #c4c8ca;
  color: #fff;
}
.btn.btn-outline-light:focus, .btn.btn-outline-light.focus {
  background-color: #c4c8ca;
  color: #fff;
}
.btn.btn-outline-light:not(:disabled):not(.disabled):active, .btn.btn-outline-light:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75);
}
.btn.btn-outline-light.disabled, .btn.btn-outline-light:disabled {
  color: #c4c8ca;
  background-color: transparent;
  border-color: #c4c8ca;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn.btn-toggle-light {
  color: #c4c8ca;
  background-color: transparent;
  border-color: #c4c8ca;
}
.btn.btn-toggle-light:hover {
  background-color: rgba(196, 200, 202, 0.2);
}
.btn.btn-toggle-light:not([disabled]):not(.disabled):active {
  background-color: rgba(196, 200, 202, 0.3);
}
.btn.btn-toggle-light.disabled, .btn.btn-toggle-light:disabled {
  background-color: transparent;
  color: #c4c8ca;
}
.btn.btn-toggle-light.active {
  color: #fff;
  background-color: #c4c8ca;
}
.btn.btn-toggle-light.active:hover, .btn.btn-toggle-light.active:focus, .btn.btn-toggle-light.active.focus {
  background-color: #c8d1d6;
  border-color: #c8d1d6;
}
.btn.btn-toggle-light.active:not([disabled]):not(.disabled):active {
  color: rgba(33, 37, 41, 0.75);
  background-color: #c4c8ca;
  border-color: #c4c8ca;
}

.btn.btn-flat-dark {
  color: #414949;
  background-color: transparent;
}
.btn.btn-flat-dark:hover {
  text-decoration: none;
  background-color: rgba(65, 73, 73, 0.1);
}
.btn.btn-flat-dark:focus, .btn.btn-flat-dark.focus {
  background-color: rgba(65, 73, 73, 0.1);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn.btn-flat-dark:not([disabled]):not(.disabled).active, .btn.btn-flat-dark:not([disabled]):not(.disabled):active {
  background-color: rgba(65, 73, 73, 0.2);
}
.btn.btn-flat-dark:disabled, .btn.btn-flat-dark.disabled {
  color: #414949;
  background-color: transparent;
}
.btn.btn-flat {
  color: #000;
  background-color: transparent;
}
.btn.btn-flat:hover {
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.1);
}
.btn.btn-flat:focus, .btn.btn-flat.focus {
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn.btn-flat:not([disabled]):not(.disabled).active, .btn.btn-flat:not([disabled]):not(.disabled):active {
  background-color: rgba(0, 0, 0, 0.2);
}
.btn.btn-flat:disabled, .btn.btn-flat.disabled {
  color: #000;
  background-color: transparent;
}
.btn.btn-flat:not(:hover):not(:active):not(.active):not(:focus):not(.focus):not([disabled]):not(.disabled) {
  color: #6e777a;
}
.btn.btn-discreet-dark {
  color: #414949;
  background-color: rgba(65, 73, 73, 0.1);
}
.btn.btn-discreet-dark:hover:not([disabled]):not(.disabled), .btn.btn-discreet-dark:focus:not([disabled]):not(.disabled) {
  color: #fff;
  background-color: #414949;
  border-color: #414949;
}
.btn.btn-discreet-dark:not([disabled]):not(.disabled):active, .btn.btn-discreet-dark:not([disabled]):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75);
  background-color: #414949;
  border-color: #414949;
}
.btn.btn-discreet-dark:disabled, .btn.btn-discreet-dark.disabled {
  color: #414949;
  background-color: transparent;
}
.btn.btn-outline-dark {
  background-color: transparent;
  border-color: #414949;
  color: #414949;
}
.btn.btn-outline-dark:hover {
  background-color: #414949;
  color: #fff;
}
.btn.btn-outline-dark:focus, .btn.btn-outline-dark.focus {
  background-color: #414949;
  color: #fff;
}
.btn.btn-outline-dark:not(:disabled):not(.disabled):active, .btn.btn-outline-dark:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75);
}
.btn.btn-outline-dark.disabled, .btn.btn-outline-dark:disabled {
  color: #414949;
  background-color: transparent;
  border-color: #414949;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn.btn-toggle-dark {
  color: #414949;
  background-color: transparent;
  border-color: #414949;
}
.btn.btn-toggle-dark:hover {
  background-color: rgba(65, 73, 73, 0.2);
}
.btn.btn-toggle-dark:not([disabled]):not(.disabled):active {
  background-color: rgba(65, 73, 73, 0.3);
}
.btn.btn-toggle-dark.disabled, .btn.btn-toggle-dark:disabled {
  background-color: transparent;
  color: #414949;
}
.btn.btn-toggle-dark.active {
  color: #fff;
  background-color: #414949;
}
.btn.btn-toggle-dark.active:hover, .btn.btn-toggle-dark.active:focus, .btn.btn-toggle-dark.active.focus {
  background-color: #414e4e;
  border-color: #414e4e;
}
.btn.btn-toggle-dark.active:not([disabled]):not(.disabled):active {
  color: rgba(255, 255, 255, 0.75);
  background-color: #414949;
  border-color: #414949;
}

.btn.btn-flat-black {
  color: #231f20;
  background-color: transparent;
}
.btn.btn-flat-black:hover {
  text-decoration: none;
  background-color: rgba(35, 31, 32, 0.1);
}
.btn.btn-flat-black:focus, .btn.btn-flat-black.focus {
  background-color: rgba(35, 31, 32, 0.1);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn.btn-flat-black:not([disabled]):not(.disabled).active, .btn.btn-flat-black:not([disabled]):not(.disabled):active {
  background-color: rgba(35, 31, 32, 0.2);
}
.btn.btn-flat-black:disabled, .btn.btn-flat-black.disabled {
  color: #231f20;
  background-color: transparent;
}
.btn.btn-flat {
  color: #000;
  background-color: transparent;
}
.btn.btn-flat:hover {
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.1);
}
.btn.btn-flat:focus, .btn.btn-flat.focus {
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn.btn-flat:not([disabled]):not(.disabled).active, .btn.btn-flat:not([disabled]):not(.disabled):active {
  background-color: rgba(0, 0, 0, 0.2);
}
.btn.btn-flat:disabled, .btn.btn-flat.disabled {
  color: #000;
  background-color: transparent;
}
.btn.btn-flat:not(:hover):not(:active):not(.active):not(:focus):not(.focus):not([disabled]):not(.disabled) {
  color: #6e777a;
}
.btn.btn-discreet-black {
  color: #231f20;
  background-color: rgba(35, 31, 32, 0.1);
}
.btn.btn-discreet-black:hover:not([disabled]):not(.disabled), .btn.btn-discreet-black:focus:not([disabled]):not(.disabled) {
  color: #fff;
  background-color: #231f20;
  border-color: #231f20;
}
.btn.btn-discreet-black:not([disabled]):not(.disabled):active, .btn.btn-discreet-black:not([disabled]):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75);
  background-color: #231f20;
  border-color: #231f20;
}
.btn.btn-discreet-black:disabled, .btn.btn-discreet-black.disabled {
  color: #231f20;
  background-color: transparent;
}
.btn.btn-outline-black {
  background-color: transparent;
  border-color: #231f20;
  color: #231f20;
}
.btn.btn-outline-black:hover {
  background-color: #231f20;
  color: #fff;
}
.btn.btn-outline-black:focus, .btn.btn-outline-black.focus {
  background-color: #231f20;
  color: #fff;
}
.btn.btn-outline-black:not(:disabled):not(.disabled):active, .btn.btn-outline-black:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75);
}
.btn.btn-outline-black.disabled, .btn.btn-outline-black:disabled {
  color: #231f20;
  background-color: transparent;
  border-color: #231f20;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn.btn-toggle-black {
  color: #231f20;
  background-color: transparent;
  border-color: #231f20;
}
.btn.btn-toggle-black:hover {
  background-color: rgba(35, 31, 32, 0.2);
}
.btn.btn-toggle-black:not([disabled]):not(.disabled):active {
  background-color: rgba(35, 31, 32, 0.3);
}
.btn.btn-toggle-black.disabled, .btn.btn-toggle-black:disabled {
  background-color: transparent;
  color: #231f20;
}
.btn.btn-toggle-black.active {
  color: #fff;
  background-color: #231f20;
}
.btn.btn-toggle-black.active:hover, .btn.btn-toggle-black.active:focus, .btn.btn-toggle-black.active.focus {
  background-color: #312b2c;
  border-color: #312b2c;
}
.btn.btn-toggle-black.active:not([disabled]):not(.disabled):active {
  color: rgba(255, 255, 255, 0.75);
  background-color: #231f20;
  border-color: #231f20;
}

.btn-apricot {
  color: #fff;
  background-color: #e88e5e;
  border-color: #e88e5e;
}
.btn-apricot:hover:not([disabled]):not(.disabled), .btn-apricot:focus:not([disabled]):not(.disabled) {
  color: #fff;
  background-color: #e88f5f;
  border-color: #e88f5f;
}
.btn-apricot.disabled, .btn-apricot:disabled {
  background-color: #e88e5e;
  border-color: #e88e5e;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-apricot:not([disabled]):not(.disabled):active, .btn-apricot:not([disabled]):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75);
  background-color: #e88e5e;
  border-color: #e88e5e;
}

.btn-flat-apricot {
  color: #e88e5e;
  background-color: transparent;
}
.btn-flat-apricot:hover {
  text-decoration: none;
  background-color: rgba(232, 142, 94, 0.1);
}
.btn-flat-apricot:focus, .btn-flat-apricot.focus {
  background-color: rgba(232, 142, 94, 0.1);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-flat-apricot:not([disabled]):not(.disabled).active, .btn-flat-apricot:not([disabled]):not(.disabled):active {
  background-color: rgba(232, 142, 94, 0.2);
}
.btn-flat-apricot:disabled, .btn-flat-apricot.disabled {
  color: #e88e5e;
  background-color: transparent;
}

.btn-discreet-apricot {
  color: #e88e5e;
  background-color: rgba(232, 142, 94, 0.1);
}
.btn-discreet-apricot:hover:not([disabled]):not(.disabled), .btn-discreet-apricot:focus:not([disabled]):not(.disabled) {
  color: #fff;
  background-color: #e88e5e;
  border-color: #e88e5e;
}
.btn-discreet-apricot:not([disabled]):not(.disabled):active, .btn-discreet-apricot:not([disabled]):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75);
  background-color: #e88e5e;
  border-color: #e88e5e;
}
.btn-discreet-apricot:disabled, .btn-discreet-apricot.disabled {
  color: #e88e5e;
  background-color: transparent;
}

.btn-outline-apricot {
  background-color: transparent;
  border-color: #e88e5e;
  color: #e88e5e;
}
.btn-outline-apricot:hover {
  background-color: #e88e5e;
  color: #fff;
}
.btn-outline-apricot:focus, .btn-outline-apricot.focus {
  background-color: #e88e5e;
  color: #fff;
}
.btn-outline-apricot:not(:disabled):not(.disabled):active, .btn-outline-apricot:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75);
}
.btn-outline-apricot.disabled, .btn-outline-apricot:disabled {
  color: #e88e5e;
  background-color: transparent;
  border-color: #e88e5e;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-toggle-apricot {
  color: #e88e5e;
  background-color: transparent;
  border-color: #e88e5e;
}
.btn-toggle-apricot:hover {
  background-color: rgba(232, 142, 94, 0.2);
}
.btn-toggle-apricot:not([disabled]):not(.disabled):active {
  background-color: rgba(232, 142, 94, 0.3);
}
.btn-toggle-apricot.disabled, .btn-toggle-apricot:disabled {
  background-color: transparent;
  color: #e88e5e;
}
.btn-toggle-apricot.active {
  color: #fff;
  background-color: #e88e5e;
}
.btn-toggle-apricot.active:hover, .btn-toggle-apricot.active:focus, .btn-toggle-apricot.active.focus {
  background-color: #e88f5f;
  border-color: #e88f5f;
}
.btn-toggle-apricot.active:not([disabled]):not(.disabled):active {
  color: rgba(255, 255, 255, 0.75);
  background-color: #e88e5e;
  border-color: #e88e5e;
}

.btn-azure {
  color: #fff;
  background-color: #38699f;
  border-color: #38699f;
}
.btn-azure:hover:not([disabled]):not(.disabled), .btn-azure:focus:not([disabled]):not(.disabled) {
  color: #fff;
  background-color: #3869a0;
  border-color: #3869a0;
}
.btn-azure.disabled, .btn-azure:disabled {
  background-color: #38699f;
  border-color: #38699f;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-azure:not([disabled]):not(.disabled):active, .btn-azure:not([disabled]):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75);
  background-color: #38699f;
  border-color: #38699f;
}

.btn-flat-azure {
  color: #38699f;
  background-color: transparent;
}
.btn-flat-azure:hover {
  text-decoration: none;
  background-color: rgba(56, 105, 159, 0.1);
}
.btn-flat-azure:focus, .btn-flat-azure.focus {
  background-color: rgba(56, 105, 159, 0.1);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-flat-azure:not([disabled]):not(.disabled).active, .btn-flat-azure:not([disabled]):not(.disabled):active {
  background-color: rgba(56, 105, 159, 0.2);
}
.btn-flat-azure:disabled, .btn-flat-azure.disabled {
  color: #38699f;
  background-color: transparent;
}

.btn-discreet-azure {
  color: #38699f;
  background-color: rgba(56, 105, 159, 0.1);
}
.btn-discreet-azure:hover:not([disabled]):not(.disabled), .btn-discreet-azure:focus:not([disabled]):not(.disabled) {
  color: #fff;
  background-color: #38699f;
  border-color: #38699f;
}
.btn-discreet-azure:not([disabled]):not(.disabled):active, .btn-discreet-azure:not([disabled]):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75);
  background-color: #38699f;
  border-color: #38699f;
}
.btn-discreet-azure:disabled, .btn-discreet-azure.disabled {
  color: #38699f;
  background-color: transparent;
}

.btn-outline-azure {
  background-color: transparent;
  border-color: #38699f;
  color: #38699f;
}
.btn-outline-azure:hover {
  background-color: #38699f;
  color: #fff;
}
.btn-outline-azure:focus, .btn-outline-azure.focus {
  background-color: #38699f;
  color: #fff;
}
.btn-outline-azure:not(:disabled):not(.disabled):active, .btn-outline-azure:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75);
}
.btn-outline-azure.disabled, .btn-outline-azure:disabled {
  color: #38699f;
  background-color: transparent;
  border-color: #38699f;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-toggle-azure {
  color: #38699f;
  background-color: transparent;
  border-color: #38699f;
}
.btn-toggle-azure:hover {
  background-color: rgba(56, 105, 159, 0.2);
}
.btn-toggle-azure:not([disabled]):not(.disabled):active {
  background-color: rgba(56, 105, 159, 0.3);
}
.btn-toggle-azure.disabled, .btn-toggle-azure:disabled {
  background-color: transparent;
  color: #38699f;
}
.btn-toggle-azure.active {
  color: #fff;
  background-color: #38699f;
}
.btn-toggle-azure.active:hover, .btn-toggle-azure.active:focus, .btn-toggle-azure.active.focus {
  background-color: #3869a0;
  border-color: #3869a0;
}
.btn-toggle-azure.active:not([disabled]):not(.disabled):active {
  color: rgba(255, 255, 255, 0.75);
  background-color: #38699f;
  border-color: #38699f;
}

.btn-cornflower-blue {
  color: #fff;
  background-color: #6488ff;
  border-color: #6488ff;
}
.btn-cornflower-blue:hover:not([disabled]):not(.disabled), .btn-cornflower-blue:focus:not([disabled]):not(.disabled) {
  color: #fff;
  background-color: #6589ff;
  border-color: #6589ff;
}
.btn-cornflower-blue.disabled, .btn-cornflower-blue:disabled {
  background-color: #6488ff;
  border-color: #6488ff;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-cornflower-blue:not([disabled]):not(.disabled):active, .btn-cornflower-blue:not([disabled]):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75);
  background-color: #6488ff;
  border-color: #6488ff;
}

.btn-flat-cornflower-blue {
  color: #6488ff;
  background-color: transparent;
}
.btn-flat-cornflower-blue:hover {
  text-decoration: none;
  background-color: rgba(100, 136, 255, 0.1);
}
.btn-flat-cornflower-blue:focus, .btn-flat-cornflower-blue.focus {
  background-color: rgba(100, 136, 255, 0.1);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-flat-cornflower-blue:not([disabled]):not(.disabled).active, .btn-flat-cornflower-blue:not([disabled]):not(.disabled):active {
  background-color: rgba(100, 136, 255, 0.2);
}
.btn-flat-cornflower-blue:disabled, .btn-flat-cornflower-blue.disabled {
  color: #6488ff;
  background-color: transparent;
}

.btn-discreet-cornflower-blue {
  color: #6488ff;
  background-color: rgba(100, 136, 255, 0.1);
}
.btn-discreet-cornflower-blue:hover:not([disabled]):not(.disabled), .btn-discreet-cornflower-blue:focus:not([disabled]):not(.disabled) {
  color: #fff;
  background-color: #6488ff;
  border-color: #6488ff;
}
.btn-discreet-cornflower-blue:not([disabled]):not(.disabled):active, .btn-discreet-cornflower-blue:not([disabled]):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75);
  background-color: #6488ff;
  border-color: #6488ff;
}
.btn-discreet-cornflower-blue:disabled, .btn-discreet-cornflower-blue.disabled {
  color: #6488ff;
  background-color: transparent;
}

.btn-outline-cornflower-blue {
  background-color: transparent;
  border-color: #6488ff;
  color: #6488ff;
}
.btn-outline-cornflower-blue:hover {
  background-color: #6488ff;
  color: #fff;
}
.btn-outline-cornflower-blue:focus, .btn-outline-cornflower-blue.focus {
  background-color: #6488ff;
  color: #fff;
}
.btn-outline-cornflower-blue:not(:disabled):not(.disabled):active, .btn-outline-cornflower-blue:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75);
}
.btn-outline-cornflower-blue.disabled, .btn-outline-cornflower-blue:disabled {
  color: #6488ff;
  background-color: transparent;
  border-color: #6488ff;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-toggle-cornflower-blue {
  color: #6488ff;
  background-color: transparent;
  border-color: #6488ff;
}
.btn-toggle-cornflower-blue:hover {
  background-color: rgba(100, 136, 255, 0.2);
}
.btn-toggle-cornflower-blue:not([disabled]):not(.disabled):active {
  background-color: rgba(100, 136, 255, 0.3);
}
.btn-toggle-cornflower-blue.disabled, .btn-toggle-cornflower-blue:disabled {
  background-color: transparent;
  color: #6488ff;
}
.btn-toggle-cornflower-blue.active {
  color: #fff;
  background-color: #6488ff;
}
.btn-toggle-cornflower-blue.active:hover, .btn-toggle-cornflower-blue.active:focus, .btn-toggle-cornflower-blue.active.focus {
  background-color: #6589ff;
  border-color: #6589ff;
}
.btn-toggle-cornflower-blue.active:not([disabled]):not(.disabled):active {
  color: rgba(255, 255, 255, 0.75);
  background-color: #6488ff;
  border-color: #6488ff;
}

.btn-faded-jade {
  color: #fff;
  background-color: #408182;
  border-color: #408182;
}
.btn-faded-jade:hover:not([disabled]):not(.disabled), .btn-faded-jade:focus:not([disabled]):not(.disabled) {
  color: #fff;
  background-color: #408283;
  border-color: #408283;
}
.btn-faded-jade.disabled, .btn-faded-jade:disabled {
  background-color: #408182;
  border-color: #408182;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-faded-jade:not([disabled]):not(.disabled):active, .btn-faded-jade:not([disabled]):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75);
  background-color: #408182;
  border-color: #408182;
}

.btn-flat-faded-jade {
  color: #408182;
  background-color: transparent;
}
.btn-flat-faded-jade:hover {
  text-decoration: none;
  background-color: rgba(64, 129, 130, 0.1);
}
.btn-flat-faded-jade:focus, .btn-flat-faded-jade.focus {
  background-color: rgba(64, 129, 130, 0.1);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-flat-faded-jade:not([disabled]):not(.disabled).active, .btn-flat-faded-jade:not([disabled]):not(.disabled):active {
  background-color: rgba(64, 129, 130, 0.2);
}
.btn-flat-faded-jade:disabled, .btn-flat-faded-jade.disabled {
  color: #408182;
  background-color: transparent;
}

.btn-discreet-faded-jade {
  color: #408182;
  background-color: rgba(64, 129, 130, 0.1);
}
.btn-discreet-faded-jade:hover:not([disabled]):not(.disabled), .btn-discreet-faded-jade:focus:not([disabled]):not(.disabled) {
  color: #fff;
  background-color: #408182;
  border-color: #408182;
}
.btn-discreet-faded-jade:not([disabled]):not(.disabled):active, .btn-discreet-faded-jade:not([disabled]):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75);
  background-color: #408182;
  border-color: #408182;
}
.btn-discreet-faded-jade:disabled, .btn-discreet-faded-jade.disabled {
  color: #408182;
  background-color: transparent;
}

.btn-outline-faded-jade {
  background-color: transparent;
  border-color: #408182;
  color: #408182;
}
.btn-outline-faded-jade:hover {
  background-color: #408182;
  color: #fff;
}
.btn-outline-faded-jade:focus, .btn-outline-faded-jade.focus {
  background-color: #408182;
  color: #fff;
}
.btn-outline-faded-jade:not(:disabled):not(.disabled):active, .btn-outline-faded-jade:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75);
}
.btn-outline-faded-jade.disabled, .btn-outline-faded-jade:disabled {
  color: #408182;
  background-color: transparent;
  border-color: #408182;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-toggle-faded-jade {
  color: #408182;
  background-color: transparent;
  border-color: #408182;
}
.btn-toggle-faded-jade:hover {
  background-color: rgba(64, 129, 130, 0.2);
}
.btn-toggle-faded-jade:not([disabled]):not(.disabled):active {
  background-color: rgba(64, 129, 130, 0.3);
}
.btn-toggle-faded-jade.disabled, .btn-toggle-faded-jade:disabled {
  background-color: transparent;
  color: #408182;
}
.btn-toggle-faded-jade.active {
  color: #fff;
  background-color: #408182;
}
.btn-toggle-faded-jade.active:hover, .btn-toggle-faded-jade.active:focus, .btn-toggle-faded-jade.active.focus {
  background-color: #408283;
  border-color: #408283;
}
.btn-toggle-faded-jade.active:not([disabled]):not(.disabled):active {
  color: rgba(255, 255, 255, 0.75);
  background-color: #408182;
  border-color: #408182;
}

.btn-heather {
  color: #fff;
  background-color: #b4bbcb;
  border-color: #b4bbcb;
}
.btn-heather:hover:not([disabled]):not(.disabled), .btn-heather:focus:not([disabled]):not(.disabled) {
  color: #fff;
  background-color: #b5bccb;
  border-color: #b5bccb;
}
.btn-heather.disabled, .btn-heather:disabled {
  background-color: #b4bbcb;
  border-color: #b4bbcb;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-heather:not([disabled]):not(.disabled):active, .btn-heather:not([disabled]):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75);
  background-color: #b4bbcb;
  border-color: #b4bbcb;
}

.btn-flat-heather {
  color: #b4bbcb;
  background-color: transparent;
}
.btn-flat-heather:hover {
  text-decoration: none;
  background-color: rgba(180, 187, 203, 0.1);
}
.btn-flat-heather:focus, .btn-flat-heather.focus {
  background-color: rgba(180, 187, 203, 0.1);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-flat-heather:not([disabled]):not(.disabled).active, .btn-flat-heather:not([disabled]):not(.disabled):active {
  background-color: rgba(180, 187, 203, 0.2);
}
.btn-flat-heather:disabled, .btn-flat-heather.disabled {
  color: #b4bbcb;
  background-color: transparent;
}

.btn-discreet-heather {
  color: #b4bbcb;
  background-color: rgba(180, 187, 203, 0.1);
}
.btn-discreet-heather:hover:not([disabled]):not(.disabled), .btn-discreet-heather:focus:not([disabled]):not(.disabled) {
  color: #fff;
  background-color: #b4bbcb;
  border-color: #b4bbcb;
}
.btn-discreet-heather:not([disabled]):not(.disabled):active, .btn-discreet-heather:not([disabled]):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75);
  background-color: #b4bbcb;
  border-color: #b4bbcb;
}
.btn-discreet-heather:disabled, .btn-discreet-heather.disabled {
  color: #b4bbcb;
  background-color: transparent;
}

.btn-outline-heather {
  background-color: transparent;
  border-color: #b4bbcb;
  color: #b4bbcb;
}
.btn-outline-heather:hover {
  background-color: #b4bbcb;
  color: #fff;
}
.btn-outline-heather:focus, .btn-outline-heather.focus {
  background-color: #b4bbcb;
  color: #fff;
}
.btn-outline-heather:not(:disabled):not(.disabled):active, .btn-outline-heather:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75);
}
.btn-outline-heather.disabled, .btn-outline-heather:disabled {
  color: #b4bbcb;
  background-color: transparent;
  border-color: #b4bbcb;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-toggle-heather {
  color: #b4bbcb;
  background-color: transparent;
  border-color: #b4bbcb;
}
.btn-toggle-heather:hover {
  background-color: rgba(180, 187, 203, 0.2);
}
.btn-toggle-heather:not([disabled]):not(.disabled):active {
  background-color: rgba(180, 187, 203, 0.3);
}
.btn-toggle-heather.disabled, .btn-toggle-heather:disabled {
  background-color: transparent;
  color: #b4bbcb;
}
.btn-toggle-heather.active {
  color: #fff;
  background-color: #b4bbcb;
}
.btn-toggle-heather.active:hover, .btn-toggle-heather.active:focus, .btn-toggle-heather.active.focus {
  background-color: #b5bccb;
  border-color: #b5bccb;
}
.btn-toggle-heather.active:not([disabled]):not(.disabled):active {
  color: rgba(33, 37, 41, 0.75);
  background-color: #b4bbcb;
  border-color: #b4bbcb;
}

.btn-mako {
  color: #fff;
  background-color: #444852;
  border-color: #444852;
}
.btn-mako:hover:not([disabled]):not(.disabled), .btn-mako:focus:not([disabled]):not(.disabled) {
  color: #fff;
  background-color: #444853;
  border-color: #444853;
}
.btn-mako.disabled, .btn-mako:disabled {
  background-color: #444852;
  border-color: #444852;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-mako:not([disabled]):not(.disabled):active, .btn-mako:not([disabled]):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75);
  background-color: #444852;
  border-color: #444852;
}

.btn-flat-mako {
  color: #444852;
  background-color: transparent;
}
.btn-flat-mako:hover {
  text-decoration: none;
  background-color: rgba(68, 72, 82, 0.1);
}
.btn-flat-mako:focus, .btn-flat-mako.focus {
  background-color: rgba(68, 72, 82, 0.1);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-flat-mako:not([disabled]):not(.disabled).active, .btn-flat-mako:not([disabled]):not(.disabled):active {
  background-color: rgba(68, 72, 82, 0.2);
}
.btn-flat-mako:disabled, .btn-flat-mako.disabled {
  color: #444852;
  background-color: transparent;
}

.btn-discreet-mako {
  color: #444852;
  background-color: rgba(68, 72, 82, 0.1);
}
.btn-discreet-mako:hover:not([disabled]):not(.disabled), .btn-discreet-mako:focus:not([disabled]):not(.disabled) {
  color: #fff;
  background-color: #444852;
  border-color: #444852;
}
.btn-discreet-mako:not([disabled]):not(.disabled):active, .btn-discreet-mako:not([disabled]):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75);
  background-color: #444852;
  border-color: #444852;
}
.btn-discreet-mako:disabled, .btn-discreet-mako.disabled {
  color: #444852;
  background-color: transparent;
}

.btn-outline-mako {
  background-color: transparent;
  border-color: #444852;
  color: #444852;
}
.btn-outline-mako:hover {
  background-color: #444852;
  color: #fff;
}
.btn-outline-mako:focus, .btn-outline-mako.focus {
  background-color: #444852;
  color: #fff;
}
.btn-outline-mako:not(:disabled):not(.disabled):active, .btn-outline-mako:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75);
}
.btn-outline-mako.disabled, .btn-outline-mako:disabled {
  color: #444852;
  background-color: transparent;
  border-color: #444852;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-toggle-mako {
  color: #444852;
  background-color: transparent;
  border-color: #444852;
}
.btn-toggle-mako:hover {
  background-color: rgba(68, 72, 82, 0.2);
}
.btn-toggle-mako:not([disabled]):not(.disabled):active {
  background-color: rgba(68, 72, 82, 0.3);
}
.btn-toggle-mako.disabled, .btn-toggle-mako:disabled {
  background-color: transparent;
  color: #444852;
}
.btn-toggle-mako.active {
  color: #fff;
  background-color: #444852;
}
.btn-toggle-mako.active:hover, .btn-toggle-mako.active:focus, .btn-toggle-mako.active.focus {
  background-color: #444853;
  border-color: #444853;
}
.btn-toggle-mako.active:not([disabled]):not(.disabled):active {
  color: rgba(255, 255, 255, 0.75);
  background-color: #444852;
  border-color: #444852;
}

.btn-puerto-rico {
  color: #fff;
  background-color: #41c49d;
  border-color: #41c49d;
}
.btn-puerto-rico:hover:not([disabled]):not(.disabled), .btn-puerto-rico:focus:not([disabled]):not(.disabled) {
  color: #fff;
  background-color: #42c49d;
  border-color: #42c49d;
}
.btn-puerto-rico.disabled, .btn-puerto-rico:disabled {
  background-color: #41c49d;
  border-color: #41c49d;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-puerto-rico:not([disabled]):not(.disabled):active, .btn-puerto-rico:not([disabled]):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75);
  background-color: #41c49d;
  border-color: #41c49d;
}

.btn-flat-puerto-rico {
  color: #41c49d;
  background-color: transparent;
}
.btn-flat-puerto-rico:hover {
  text-decoration: none;
  background-color: rgba(65, 196, 157, 0.1);
}
.btn-flat-puerto-rico:focus, .btn-flat-puerto-rico.focus {
  background-color: rgba(65, 196, 157, 0.1);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-flat-puerto-rico:not([disabled]):not(.disabled).active, .btn-flat-puerto-rico:not([disabled]):not(.disabled):active {
  background-color: rgba(65, 196, 157, 0.2);
}
.btn-flat-puerto-rico:disabled, .btn-flat-puerto-rico.disabled {
  color: #41c49d;
  background-color: transparent;
}

.btn-discreet-puerto-rico {
  color: #41c49d;
  background-color: rgba(65, 196, 157, 0.1);
}
.btn-discreet-puerto-rico:hover:not([disabled]):not(.disabled), .btn-discreet-puerto-rico:focus:not([disabled]):not(.disabled) {
  color: #fff;
  background-color: #41c49d;
  border-color: #41c49d;
}
.btn-discreet-puerto-rico:not([disabled]):not(.disabled):active, .btn-discreet-puerto-rico:not([disabled]):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75);
  background-color: #41c49d;
  border-color: #41c49d;
}
.btn-discreet-puerto-rico:disabled, .btn-discreet-puerto-rico.disabled {
  color: #41c49d;
  background-color: transparent;
}

.btn-outline-puerto-rico {
  background-color: transparent;
  border-color: #41c49d;
  color: #41c49d;
}
.btn-outline-puerto-rico:hover {
  background-color: #41c49d;
  color: #fff;
}
.btn-outline-puerto-rico:focus, .btn-outline-puerto-rico.focus {
  background-color: #41c49d;
  color: #fff;
}
.btn-outline-puerto-rico:not(:disabled):not(.disabled):active, .btn-outline-puerto-rico:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75);
}
.btn-outline-puerto-rico.disabled, .btn-outline-puerto-rico:disabled {
  color: #41c49d;
  background-color: transparent;
  border-color: #41c49d;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-toggle-puerto-rico {
  color: #41c49d;
  background-color: transparent;
  border-color: #41c49d;
}
.btn-toggle-puerto-rico:hover {
  background-color: rgba(65, 196, 157, 0.2);
}
.btn-toggle-puerto-rico:not([disabled]):not(.disabled):active {
  background-color: rgba(65, 196, 157, 0.3);
}
.btn-toggle-puerto-rico.disabled, .btn-toggle-puerto-rico:disabled {
  background-color: transparent;
  color: #41c49d;
}
.btn-toggle-puerto-rico.active {
  color: #fff;
  background-color: #41c49d;
}
.btn-toggle-puerto-rico.active:hover, .btn-toggle-puerto-rico.active:focus, .btn-toggle-puerto-rico.active.focus {
  background-color: #42c49d;
  border-color: #42c49d;
}
.btn-toggle-puerto-rico.active:not([disabled]):not(.disabled):active {
  color: rgba(255, 255, 255, 0.75);
  background-color: #41c49d;
  border-color: #41c49d;
}

.btn-royal-blue {
  color: #fff;
  background-color: #5c56e8;
  border-color: #5c56e8;
}
.btn-royal-blue:hover:not([disabled]):not(.disabled), .btn-royal-blue:focus:not([disabled]):not(.disabled) {
  color: #fff;
  background-color: #5d57e8;
  border-color: #5d57e8;
}
.btn-royal-blue.disabled, .btn-royal-blue:disabled {
  background-color: #5c56e8;
  border-color: #5c56e8;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-royal-blue:not([disabled]):not(.disabled):active, .btn-royal-blue:not([disabled]):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75);
  background-color: #5c56e8;
  border-color: #5c56e8;
}

.btn-flat-royal-blue {
  color: #5c56e8;
  background-color: transparent;
}
.btn-flat-royal-blue:hover {
  text-decoration: none;
  background-color: rgba(92, 86, 232, 0.1);
}
.btn-flat-royal-blue:focus, .btn-flat-royal-blue.focus {
  background-color: rgba(92, 86, 232, 0.1);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-flat-royal-blue:not([disabled]):not(.disabled).active, .btn-flat-royal-blue:not([disabled]):not(.disabled):active {
  background-color: rgba(92, 86, 232, 0.2);
}
.btn-flat-royal-blue:disabled, .btn-flat-royal-blue.disabled {
  color: #5c56e8;
  background-color: transparent;
}

.btn-discreet-royal-blue {
  color: #5c56e8;
  background-color: rgba(92, 86, 232, 0.1);
}
.btn-discreet-royal-blue:hover:not([disabled]):not(.disabled), .btn-discreet-royal-blue:focus:not([disabled]):not(.disabled) {
  color: #fff;
  background-color: #5c56e8;
  border-color: #5c56e8;
}
.btn-discreet-royal-blue:not([disabled]):not(.disabled):active, .btn-discreet-royal-blue:not([disabled]):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75);
  background-color: #5c56e8;
  border-color: #5c56e8;
}
.btn-discreet-royal-blue:disabled, .btn-discreet-royal-blue.disabled {
  color: #5c56e8;
  background-color: transparent;
}

.btn-outline-royal-blue {
  background-color: transparent;
  border-color: #5c56e8;
  color: #5c56e8;
}
.btn-outline-royal-blue:hover {
  background-color: #5c56e8;
  color: #fff;
}
.btn-outline-royal-blue:focus, .btn-outline-royal-blue.focus {
  background-color: #5c56e8;
  color: #fff;
}
.btn-outline-royal-blue:not(:disabled):not(.disabled):active, .btn-outline-royal-blue:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75);
}
.btn-outline-royal-blue.disabled, .btn-outline-royal-blue:disabled {
  color: #5c56e8;
  background-color: transparent;
  border-color: #5c56e8;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-toggle-royal-blue {
  color: #5c56e8;
  background-color: transparent;
  border-color: #5c56e8;
}
.btn-toggle-royal-blue:hover {
  background-color: rgba(92, 86, 232, 0.2);
}
.btn-toggle-royal-blue:not([disabled]):not(.disabled):active {
  background-color: rgba(92, 86, 232, 0.3);
}
.btn-toggle-royal-blue.disabled, .btn-toggle-royal-blue:disabled {
  background-color: transparent;
  color: #5c56e8;
}
.btn-toggle-royal-blue.active {
  color: #fff;
  background-color: #5c56e8;
}
.btn-toggle-royal-blue.active:hover, .btn-toggle-royal-blue.active:focus, .btn-toggle-royal-blue.active.focus {
  background-color: #5d57e8;
  border-color: #5d57e8;
}
.btn-toggle-royal-blue.active:not([disabled]):not(.disabled):active {
  color: rgba(255, 255, 255, 0.75);
  background-color: #5c56e8;
  border-color: #5c56e8;
}

.btn-spicy-mix {
  color: #fff;
  background-color: #854b4b;
  border-color: #854b4b;
}
.btn-spicy-mix:hover:not([disabled]):not(.disabled), .btn-spicy-mix:focus:not([disabled]):not(.disabled) {
  color: #fff;
  background-color: #864b4b;
  border-color: #864b4b;
}
.btn-spicy-mix.disabled, .btn-spicy-mix:disabled {
  background-color: #854b4b;
  border-color: #854b4b;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-spicy-mix:not([disabled]):not(.disabled):active, .btn-spicy-mix:not([disabled]):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75);
  background-color: #854b4b;
  border-color: #854b4b;
}

.btn-flat-spicy-mix {
  color: #854b4b;
  background-color: transparent;
}
.btn-flat-spicy-mix:hover {
  text-decoration: none;
  background-color: rgba(133, 75, 75, 0.1);
}
.btn-flat-spicy-mix:focus, .btn-flat-spicy-mix.focus {
  background-color: rgba(133, 75, 75, 0.1);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-flat-spicy-mix:not([disabled]):not(.disabled).active, .btn-flat-spicy-mix:not([disabled]):not(.disabled):active {
  background-color: rgba(133, 75, 75, 0.2);
}
.btn-flat-spicy-mix:disabled, .btn-flat-spicy-mix.disabled {
  color: #854b4b;
  background-color: transparent;
}

.btn-discreet-spicy-mix {
  color: #854b4b;
  background-color: rgba(133, 75, 75, 0.1);
}
.btn-discreet-spicy-mix:hover:not([disabled]):not(.disabled), .btn-discreet-spicy-mix:focus:not([disabled]):not(.disabled) {
  color: #fff;
  background-color: #854b4b;
  border-color: #854b4b;
}
.btn-discreet-spicy-mix:not([disabled]):not(.disabled):active, .btn-discreet-spicy-mix:not([disabled]):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75);
  background-color: #854b4b;
  border-color: #854b4b;
}
.btn-discreet-spicy-mix:disabled, .btn-discreet-spicy-mix.disabled {
  color: #854b4b;
  background-color: transparent;
}

.btn-outline-spicy-mix {
  background-color: transparent;
  border-color: #854b4b;
  color: #854b4b;
}
.btn-outline-spicy-mix:hover {
  background-color: #854b4b;
  color: #fff;
}
.btn-outline-spicy-mix:focus, .btn-outline-spicy-mix.focus {
  background-color: #854b4b;
  color: #fff;
}
.btn-outline-spicy-mix:not(:disabled):not(.disabled):active, .btn-outline-spicy-mix:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75);
}
.btn-outline-spicy-mix.disabled, .btn-outline-spicy-mix:disabled {
  color: #854b4b;
  background-color: transparent;
  border-color: #854b4b;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-toggle-spicy-mix {
  color: #854b4b;
  background-color: transparent;
  border-color: #854b4b;
}
.btn-toggle-spicy-mix:hover {
  background-color: rgba(133, 75, 75, 0.2);
}
.btn-toggle-spicy-mix:not([disabled]):not(.disabled):active {
  background-color: rgba(133, 75, 75, 0.3);
}
.btn-toggle-spicy-mix.disabled, .btn-toggle-spicy-mix:disabled {
  background-color: transparent;
  color: #854b4b;
}
.btn-toggle-spicy-mix.active {
  color: #fff;
  background-color: #854b4b;
}
.btn-toggle-spicy-mix.active:hover, .btn-toggle-spicy-mix.active:focus, .btn-toggle-spicy-mix.active.focus {
  background-color: #864b4b;
  border-color: #864b4b;
}
.btn-toggle-spicy-mix.active:not([disabled]):not(.disabled):active {
  color: rgba(255, 255, 255, 0.75);
  background-color: #854b4b;
  border-color: #854b4b;
}

.btn-valencia {
  color: #fff;
  background-color: #dc4941;
  border-color: #dc4941;
}
.btn-valencia:hover:not([disabled]):not(.disabled), .btn-valencia:focus:not([disabled]):not(.disabled) {
  color: #fff;
  background-color: #dc4a42;
  border-color: #dc4a42;
}
.btn-valencia.disabled, .btn-valencia:disabled {
  background-color: #dc4941;
  border-color: #dc4941;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-valencia:not([disabled]):not(.disabled):active, .btn-valencia:not([disabled]):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75);
  background-color: #dc4941;
  border-color: #dc4941;
}

.btn-flat-valencia {
  color: #dc4941;
  background-color: transparent;
}
.btn-flat-valencia:hover {
  text-decoration: none;
  background-color: rgba(220, 73, 65, 0.1);
}
.btn-flat-valencia:focus, .btn-flat-valencia.focus {
  background-color: rgba(220, 73, 65, 0.1);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-flat-valencia:not([disabled]):not(.disabled).active, .btn-flat-valencia:not([disabled]):not(.disabled):active {
  background-color: rgba(220, 73, 65, 0.2);
}
.btn-flat-valencia:disabled, .btn-flat-valencia.disabled {
  color: #dc4941;
  background-color: transparent;
}

.btn-discreet-valencia {
  color: #dc4941;
  background-color: rgba(220, 73, 65, 0.1);
}
.btn-discreet-valencia:hover:not([disabled]):not(.disabled), .btn-discreet-valencia:focus:not([disabled]):not(.disabled) {
  color: #fff;
  background-color: #dc4941;
  border-color: #dc4941;
}
.btn-discreet-valencia:not([disabled]):not(.disabled):active, .btn-discreet-valencia:not([disabled]):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75);
  background-color: #dc4941;
  border-color: #dc4941;
}
.btn-discreet-valencia:disabled, .btn-discreet-valencia.disabled {
  color: #dc4941;
  background-color: transparent;
}

.btn-outline-valencia {
  background-color: transparent;
  border-color: #dc4941;
  color: #dc4941;
}
.btn-outline-valencia:hover {
  background-color: #dc4941;
  color: #fff;
}
.btn-outline-valencia:focus, .btn-outline-valencia.focus {
  background-color: #dc4941;
  color: #fff;
}
.btn-outline-valencia:not(:disabled):not(.disabled):active, .btn-outline-valencia:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75);
}
.btn-outline-valencia.disabled, .btn-outline-valencia:disabled {
  color: #dc4941;
  background-color: transparent;
  border-color: #dc4941;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-toggle-valencia {
  color: #dc4941;
  background-color: transparent;
  border-color: #dc4941;
}
.btn-toggle-valencia:hover {
  background-color: rgba(220, 73, 65, 0.2);
}
.btn-toggle-valencia:not([disabled]):not(.disabled):active {
  background-color: rgba(220, 73, 65, 0.3);
}
.btn-toggle-valencia.disabled, .btn-toggle-valencia:disabled {
  background-color: transparent;
  color: #dc4941;
}
.btn-toggle-valencia.active {
  color: #fff;
  background-color: #dc4941;
}
.btn-toggle-valencia.active:hover, .btn-toggle-valencia.active:focus, .btn-toggle-valencia.active.focus {
  background-color: #dc4a42;
  border-color: #dc4a42;
}
.btn-toggle-valencia.active:not([disabled]):not(.disabled):active {
  color: rgba(255, 255, 255, 0.75);
  background-color: #dc4941;
  border-color: #dc4941;
}

.btn-victoria {
  color: #fff;
  background-color: #514b90;
  border-color: #514b90;
}
.btn-victoria:hover:not([disabled]):not(.disabled), .btn-victoria:focus:not([disabled]):not(.disabled) {
  color: #fff;
  background-color: #514b91;
  border-color: #514b91;
}
.btn-victoria.disabled, .btn-victoria:disabled {
  background-color: #514b90;
  border-color: #514b90;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-victoria:not([disabled]):not(.disabled):active, .btn-victoria:not([disabled]):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75);
  background-color: #514b90;
  border-color: #514b90;
}

.btn-flat-victoria {
  color: #514b90;
  background-color: transparent;
}
.btn-flat-victoria:hover {
  text-decoration: none;
  background-color: rgba(81, 75, 144, 0.1);
}
.btn-flat-victoria:focus, .btn-flat-victoria.focus {
  background-color: rgba(81, 75, 144, 0.1);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-flat-victoria:not([disabled]):not(.disabled).active, .btn-flat-victoria:not([disabled]):not(.disabled):active {
  background-color: rgba(81, 75, 144, 0.2);
}
.btn-flat-victoria:disabled, .btn-flat-victoria.disabled {
  color: #514b90;
  background-color: transparent;
}

.btn-discreet-victoria {
  color: #514b90;
  background-color: rgba(81, 75, 144, 0.1);
}
.btn-discreet-victoria:hover:not([disabled]):not(.disabled), .btn-discreet-victoria:focus:not([disabled]):not(.disabled) {
  color: #fff;
  background-color: #514b90;
  border-color: #514b90;
}
.btn-discreet-victoria:not([disabled]):not(.disabled):active, .btn-discreet-victoria:not([disabled]):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75);
  background-color: #514b90;
  border-color: #514b90;
}
.btn-discreet-victoria:disabled, .btn-discreet-victoria.disabled {
  color: #514b90;
  background-color: transparent;
}

.btn-outline-victoria {
  background-color: transparent;
  border-color: #514b90;
  color: #514b90;
}
.btn-outline-victoria:hover {
  background-color: #514b90;
  color: #fff;
}
.btn-outline-victoria:focus, .btn-outline-victoria.focus {
  background-color: #514b90;
  color: #fff;
}
.btn-outline-victoria:not(:disabled):not(.disabled):active, .btn-outline-victoria:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75);
}
.btn-outline-victoria.disabled, .btn-outline-victoria:disabled {
  color: #514b90;
  background-color: transparent;
  border-color: #514b90;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-toggle-victoria {
  color: #514b90;
  background-color: transparent;
  border-color: #514b90;
}
.btn-toggle-victoria:hover {
  background-color: rgba(81, 75, 144, 0.2);
}
.btn-toggle-victoria:not([disabled]):not(.disabled):active {
  background-color: rgba(81, 75, 144, 0.3);
}
.btn-toggle-victoria.disabled, .btn-toggle-victoria:disabled {
  background-color: transparent;
  color: #514b90;
}
.btn-toggle-victoria.active {
  color: #fff;
  background-color: #514b90;
}
.btn-toggle-victoria.active:hover, .btn-toggle-victoria.active:focus, .btn-toggle-victoria.active.focus {
  background-color: #514b91;
  border-color: #514b91;
}
.btn-toggle-victoria.active:not([disabled]):not(.disabled):active {
  color: rgba(255, 255, 255, 0.75);
  background-color: #514b90;
  border-color: #514b90;
}

.btn-mars {
  color: #fff;
  background-color: #d53229;
  border-color: #d53229;
}
.btn-mars:hover:not([disabled]):not(.disabled), .btn-mars:focus:not([disabled]):not(.disabled) {
  color: #fff;
  background-color: #d63229;
  border-color: #d63229;
}
.btn-mars.disabled, .btn-mars:disabled {
  background-color: #d53229;
  border-color: #d53229;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-mars:not([disabled]):not(.disabled):active, .btn-mars:not([disabled]):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75);
  background-color: #d53229;
  border-color: #d53229;
}

.btn-flat-mars {
  color: #d53229;
  background-color: transparent;
}
.btn-flat-mars:hover {
  text-decoration: none;
  background-color: rgba(213, 50, 41, 0.1);
}
.btn-flat-mars:focus, .btn-flat-mars.focus {
  background-color: rgba(213, 50, 41, 0.1);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-flat-mars:not([disabled]):not(.disabled).active, .btn-flat-mars:not([disabled]):not(.disabled):active {
  background-color: rgba(213, 50, 41, 0.2);
}
.btn-flat-mars:disabled, .btn-flat-mars.disabled {
  color: #d53229;
  background-color: transparent;
}

.btn-discreet-mars {
  color: #d53229;
  background-color: rgba(213, 50, 41, 0.1);
}
.btn-discreet-mars:hover:not([disabled]):not(.disabled), .btn-discreet-mars:focus:not([disabled]):not(.disabled) {
  color: #fff;
  background-color: #d53229;
  border-color: #d53229;
}
.btn-discreet-mars:not([disabled]):not(.disabled):active, .btn-discreet-mars:not([disabled]):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75);
  background-color: #d53229;
  border-color: #d53229;
}
.btn-discreet-mars:disabled, .btn-discreet-mars.disabled {
  color: #d53229;
  background-color: transparent;
}

.btn-outline-mars {
  background-color: transparent;
  border-color: #d53229;
  color: #d53229;
}
.btn-outline-mars:hover {
  background-color: #d53229;
  color: #fff;
}
.btn-outline-mars:focus, .btn-outline-mars.focus {
  background-color: #d53229;
  color: #fff;
}
.btn-outline-mars:not(:disabled):not(.disabled):active, .btn-outline-mars:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75);
}
.btn-outline-mars.disabled, .btn-outline-mars:disabled {
  color: #d53229;
  background-color: transparent;
  border-color: #d53229;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-toggle-mars {
  color: #d53229;
  background-color: transparent;
  border-color: #d53229;
}
.btn-toggle-mars:hover {
  background-color: rgba(213, 50, 41, 0.2);
}
.btn-toggle-mars:not([disabled]):not(.disabled):active {
  background-color: rgba(213, 50, 41, 0.3);
}
.btn-toggle-mars.disabled, .btn-toggle-mars:disabled {
  background-color: transparent;
  color: #d53229;
}
.btn-toggle-mars.active {
  color: #fff;
  background-color: #d53229;
}
.btn-toggle-mars.active:hover, .btn-toggle-mars.active:focus, .btn-toggle-mars.active.focus {
  background-color: #d63229;
  border-color: #d63229;
}
.btn-toggle-mars.active:not([disabled]):not(.disabled):active {
  color: rgba(255, 255, 255, 0.75);
  background-color: #d53229;
  border-color: #d53229;
}

.btn-mercury {
  color: #fff;
  background-color: #e8e8e8;
  border-color: #e8e8e8;
}
.btn-mercury:hover:not([disabled]):not(.disabled), .btn-mercury:focus:not([disabled]):not(.disabled) {
  color: #fff;
  background-color: #e9e9e9;
  border-color: #e9e9e9;
}
.btn-mercury.disabled, .btn-mercury:disabled {
  background-color: #e8e8e8;
  border-color: #e8e8e8;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-mercury:not([disabled]):not(.disabled):active, .btn-mercury:not([disabled]):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75);
  background-color: #e8e8e8;
  border-color: #e8e8e8;
}

.btn-flat-mercury {
  color: #e8e8e8;
  background-color: transparent;
}
.btn-flat-mercury:hover {
  text-decoration: none;
  background-color: rgba(232, 232, 232, 0.1);
}
.btn-flat-mercury:focus, .btn-flat-mercury.focus {
  background-color: rgba(232, 232, 232, 0.1);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-flat-mercury:not([disabled]):not(.disabled).active, .btn-flat-mercury:not([disabled]):not(.disabled):active {
  background-color: rgba(232, 232, 232, 0.2);
}
.btn-flat-mercury:disabled, .btn-flat-mercury.disabled {
  color: #e8e8e8;
  background-color: transparent;
}

.btn-discreet-mercury {
  color: #e8e8e8;
  background-color: rgba(232, 232, 232, 0.1);
}
.btn-discreet-mercury:hover:not([disabled]):not(.disabled), .btn-discreet-mercury:focus:not([disabled]):not(.disabled) {
  color: #fff;
  background-color: #e8e8e8;
  border-color: #e8e8e8;
}
.btn-discreet-mercury:not([disabled]):not(.disabled):active, .btn-discreet-mercury:not([disabled]):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75);
  background-color: #e8e8e8;
  border-color: #e8e8e8;
}
.btn-discreet-mercury:disabled, .btn-discreet-mercury.disabled {
  color: #e8e8e8;
  background-color: transparent;
}

.btn-outline-mercury {
  background-color: transparent;
  border-color: #e8e8e8;
  color: #e8e8e8;
}
.btn-outline-mercury:hover {
  background-color: #e8e8e8;
  color: #fff;
}
.btn-outline-mercury:focus, .btn-outline-mercury.focus {
  background-color: #e8e8e8;
  color: #fff;
}
.btn-outline-mercury:not(:disabled):not(.disabled):active, .btn-outline-mercury:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75);
}
.btn-outline-mercury.disabled, .btn-outline-mercury:disabled {
  color: #e8e8e8;
  background-color: transparent;
  border-color: #e8e8e8;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-toggle-mercury {
  color: #e8e8e8;
  background-color: transparent;
  border-color: #e8e8e8;
}
.btn-toggle-mercury:hover {
  background-color: rgba(232, 232, 232, 0.2);
}
.btn-toggle-mercury:not([disabled]):not(.disabled):active {
  background-color: rgba(232, 232, 232, 0.3);
}
.btn-toggle-mercury.disabled, .btn-toggle-mercury:disabled {
  background-color: transparent;
  color: #e8e8e8;
}
.btn-toggle-mercury.active {
  color: #fff;
  background-color: #e8e8e8;
}
.btn-toggle-mercury.active:hover, .btn-toggle-mercury.active:focus, .btn-toggle-mercury.active.focus {
  background-color: #e9e9e9;
  border-color: #e9e9e9;
}
.btn-toggle-mercury.active:not([disabled]):not(.disabled):active {
  color: rgba(33, 37, 41, 0.75);
  background-color: #e8e8e8;
  border-color: #e8e8e8;
}

.btn-manatee {
  color: #fff;
  background-color: #8b93a8;
  border-color: #8b93a8;
}
.btn-manatee:hover:not([disabled]):not(.disabled), .btn-manatee:focus:not([disabled]):not(.disabled) {
  color: #fff;
  background-color: #8c94a8;
  border-color: #8c94a8;
}
.btn-manatee.disabled, .btn-manatee:disabled {
  background-color: #8b93a8;
  border-color: #8b93a8;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-manatee:not([disabled]):not(.disabled):active, .btn-manatee:not([disabled]):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75);
  background-color: #8b93a8;
  border-color: #8b93a8;
}

.btn-flat-manatee {
  color: #8b93a8;
  background-color: transparent;
}
.btn-flat-manatee:hover {
  text-decoration: none;
  background-color: rgba(139, 147, 168, 0.1);
}
.btn-flat-manatee:focus, .btn-flat-manatee.focus {
  background-color: rgba(139, 147, 168, 0.1);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-flat-manatee:not([disabled]):not(.disabled).active, .btn-flat-manatee:not([disabled]):not(.disabled):active {
  background-color: rgba(139, 147, 168, 0.2);
}
.btn-flat-manatee:disabled, .btn-flat-manatee.disabled {
  color: #8b93a8;
  background-color: transparent;
}

.btn-discreet-manatee {
  color: #8b93a8;
  background-color: rgba(139, 147, 168, 0.1);
}
.btn-discreet-manatee:hover:not([disabled]):not(.disabled), .btn-discreet-manatee:focus:not([disabled]):not(.disabled) {
  color: #fff;
  background-color: #8b93a8;
  border-color: #8b93a8;
}
.btn-discreet-manatee:not([disabled]):not(.disabled):active, .btn-discreet-manatee:not([disabled]):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75);
  background-color: #8b93a8;
  border-color: #8b93a8;
}
.btn-discreet-manatee:disabled, .btn-discreet-manatee.disabled {
  color: #8b93a8;
  background-color: transparent;
}

.btn-outline-manatee {
  background-color: transparent;
  border-color: #8b93a8;
  color: #8b93a8;
}
.btn-outline-manatee:hover {
  background-color: #8b93a8;
  color: #fff;
}
.btn-outline-manatee:focus, .btn-outline-manatee.focus {
  background-color: #8b93a8;
  color: #fff;
}
.btn-outline-manatee:not(:disabled):not(.disabled):active, .btn-outline-manatee:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75);
}
.btn-outline-manatee.disabled, .btn-outline-manatee:disabled {
  color: #8b93a8;
  background-color: transparent;
  border-color: #8b93a8;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-toggle-manatee {
  color: #8b93a8;
  background-color: transparent;
  border-color: #8b93a8;
}
.btn-toggle-manatee:hover {
  background-color: rgba(139, 147, 168, 0.2);
}
.btn-toggle-manatee:not([disabled]):not(.disabled):active {
  background-color: rgba(139, 147, 168, 0.3);
}
.btn-toggle-manatee.disabled, .btn-toggle-manatee:disabled {
  background-color: transparent;
  color: #8b93a8;
}
.btn-toggle-manatee.active {
  color: #fff;
  background-color: #8b93a8;
}
.btn-toggle-manatee.active:hover, .btn-toggle-manatee.active:focus, .btn-toggle-manatee.active.focus {
  background-color: #8c94a8;
  border-color: #8c94a8;
}
.btn-toggle-manatee.active:not([disabled]):not(.disabled):active {
  color: rgba(255, 255, 255, 0.75);
  background-color: #8b93a8;
  border-color: #8b93a8;
}

.btn-dodger-blue {
  color: #fff;
  background-color: #5d55ff;
  border-color: #5d55ff;
}
.btn-dodger-blue:hover:not([disabled]):not(.disabled), .btn-dodger-blue:focus:not([disabled]):not(.disabled) {
  color: #fff;
  background-color: #5e56ff;
  border-color: #5e56ff;
}
.btn-dodger-blue.disabled, .btn-dodger-blue:disabled {
  background-color: #5d55ff;
  border-color: #5d55ff;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-dodger-blue:not([disabled]):not(.disabled):active, .btn-dodger-blue:not([disabled]):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75);
  background-color: #5d55ff;
  border-color: #5d55ff;
}

.btn-flat-dodger-blue {
  color: #5d55ff;
  background-color: transparent;
}
.btn-flat-dodger-blue:hover {
  text-decoration: none;
  background-color: rgba(93, 85, 255, 0.1);
}
.btn-flat-dodger-blue:focus, .btn-flat-dodger-blue.focus {
  background-color: rgba(93, 85, 255, 0.1);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-flat-dodger-blue:not([disabled]):not(.disabled).active, .btn-flat-dodger-blue:not([disabled]):not(.disabled):active {
  background-color: rgba(93, 85, 255, 0.2);
}
.btn-flat-dodger-blue:disabled, .btn-flat-dodger-blue.disabled {
  color: #5d55ff;
  background-color: transparent;
}

.btn-discreet-dodger-blue {
  color: #5d55ff;
  background-color: rgba(93, 85, 255, 0.1);
}
.btn-discreet-dodger-blue:hover:not([disabled]):not(.disabled), .btn-discreet-dodger-blue:focus:not([disabled]):not(.disabled) {
  color: #fff;
  background-color: #5d55ff;
  border-color: #5d55ff;
}
.btn-discreet-dodger-blue:not([disabled]):not(.disabled):active, .btn-discreet-dodger-blue:not([disabled]):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75);
  background-color: #5d55ff;
  border-color: #5d55ff;
}
.btn-discreet-dodger-blue:disabled, .btn-discreet-dodger-blue.disabled {
  color: #5d55ff;
  background-color: transparent;
}

.btn-outline-dodger-blue {
  background-color: transparent;
  border-color: #5d55ff;
  color: #5d55ff;
}
.btn-outline-dodger-blue:hover {
  background-color: #5d55ff;
  color: #fff;
}
.btn-outline-dodger-blue:focus, .btn-outline-dodger-blue.focus {
  background-color: #5d55ff;
  color: #fff;
}
.btn-outline-dodger-blue:not(:disabled):not(.disabled):active, .btn-outline-dodger-blue:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75);
}
.btn-outline-dodger-blue.disabled, .btn-outline-dodger-blue:disabled {
  color: #5d55ff;
  background-color: transparent;
  border-color: #5d55ff;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-toggle-dodger-blue {
  color: #5d55ff;
  background-color: transparent;
  border-color: #5d55ff;
}
.btn-toggle-dodger-blue:hover {
  background-color: rgba(93, 85, 255, 0.2);
}
.btn-toggle-dodger-blue:not([disabled]):not(.disabled):active {
  background-color: rgba(93, 85, 255, 0.3);
}
.btn-toggle-dodger-blue.disabled, .btn-toggle-dodger-blue:disabled {
  background-color: transparent;
  color: #5d55ff;
}
.btn-toggle-dodger-blue.active {
  color: #fff;
  background-color: #5d55ff;
}
.btn-toggle-dodger-blue.active:hover, .btn-toggle-dodger-blue.active:focus, .btn-toggle-dodger-blue.active.focus {
  background-color: #5e56ff;
  border-color: #5e56ff;
}
.btn-toggle-dodger-blue.active:not([disabled]):not(.disabled):active {
  color: rgba(255, 255, 255, 0.75);
  background-color: #5d55ff;
  border-color: #5d55ff;
}

.btn-pacific-blue {
  color: #fff;
  background-color: #00b4bb;
  border-color: #00b4bb;
}
.btn-pacific-blue:hover:not([disabled]):not(.disabled), .btn-pacific-blue:focus:not([disabled]):not(.disabled) {
  color: #fff;
  background-color: #00b5bc;
  border-color: #00b5bc;
}
.btn-pacific-blue.disabled, .btn-pacific-blue:disabled {
  background-color: #00b4bb;
  border-color: #00b4bb;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-pacific-blue:not([disabled]):not(.disabled):active, .btn-pacific-blue:not([disabled]):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75);
  background-color: #00b4bb;
  border-color: #00b4bb;
}

.btn-flat-pacific-blue {
  color: #00b4bb;
  background-color: transparent;
}
.btn-flat-pacific-blue:hover {
  text-decoration: none;
  background-color: rgba(0, 180, 187, 0.1);
}
.btn-flat-pacific-blue:focus, .btn-flat-pacific-blue.focus {
  background-color: rgba(0, 180, 187, 0.1);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-flat-pacific-blue:not([disabled]):not(.disabled).active, .btn-flat-pacific-blue:not([disabled]):not(.disabled):active {
  background-color: rgba(0, 180, 187, 0.2);
}
.btn-flat-pacific-blue:disabled, .btn-flat-pacific-blue.disabled {
  color: #00b4bb;
  background-color: transparent;
}

.btn-discreet-pacific-blue {
  color: #00b4bb;
  background-color: rgba(0, 180, 187, 0.1);
}
.btn-discreet-pacific-blue:hover:not([disabled]):not(.disabled), .btn-discreet-pacific-blue:focus:not([disabled]):not(.disabled) {
  color: #fff;
  background-color: #00b4bb;
  border-color: #00b4bb;
}
.btn-discreet-pacific-blue:not([disabled]):not(.disabled):active, .btn-discreet-pacific-blue:not([disabled]):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75);
  background-color: #00b4bb;
  border-color: #00b4bb;
}
.btn-discreet-pacific-blue:disabled, .btn-discreet-pacific-blue.disabled {
  color: #00b4bb;
  background-color: transparent;
}

.btn-outline-pacific-blue {
  background-color: transparent;
  border-color: #00b4bb;
  color: #00b4bb;
}
.btn-outline-pacific-blue:hover {
  background-color: #00b4bb;
  color: #fff;
}
.btn-outline-pacific-blue:focus, .btn-outline-pacific-blue.focus {
  background-color: #00b4bb;
  color: #fff;
}
.btn-outline-pacific-blue:not(:disabled):not(.disabled):active, .btn-outline-pacific-blue:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75);
}
.btn-outline-pacific-blue.disabled, .btn-outline-pacific-blue:disabled {
  color: #00b4bb;
  background-color: transparent;
  border-color: #00b4bb;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-toggle-pacific-blue {
  color: #00b4bb;
  background-color: transparent;
  border-color: #00b4bb;
}
.btn-toggle-pacific-blue:hover {
  background-color: rgba(0, 180, 187, 0.2);
}
.btn-toggle-pacific-blue:not([disabled]):not(.disabled):active {
  background-color: rgba(0, 180, 187, 0.3);
}
.btn-toggle-pacific-blue.disabled, .btn-toggle-pacific-blue:disabled {
  background-color: transparent;
  color: #00b4bb;
}
.btn-toggle-pacific-blue.active {
  color: #fff;
  background-color: #00b4bb;
}
.btn-toggle-pacific-blue.active:hover, .btn-toggle-pacific-blue.active:focus, .btn-toggle-pacific-blue.active.focus {
  background-color: #00b5bc;
  border-color: #00b5bc;
}
.btn-toggle-pacific-blue.active:not([disabled]):not(.disabled):active {
  color: rgba(255, 255, 255, 0.75);
  background-color: #00b4bb;
  border-color: #00b4bb;
}

.btn-turquoise-blue {
  color: #fff;
  background-color: #68efad;
  border-color: #68efad;
}
.btn-turquoise-blue:hover:not([disabled]):not(.disabled), .btn-turquoise-blue:focus:not([disabled]):not(.disabled) {
  color: #fff;
  background-color: #69efae;
  border-color: #69efae;
}
.btn-turquoise-blue.disabled, .btn-turquoise-blue:disabled {
  background-color: #68efad;
  border-color: #68efad;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-turquoise-blue:not([disabled]):not(.disabled):active, .btn-turquoise-blue:not([disabled]):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75);
  background-color: #68efad;
  border-color: #68efad;
}

.btn-flat-turquoise-blue {
  color: #68efad;
  background-color: transparent;
}
.btn-flat-turquoise-blue:hover {
  text-decoration: none;
  background-color: rgba(104, 239, 173, 0.1);
}
.btn-flat-turquoise-blue:focus, .btn-flat-turquoise-blue.focus {
  background-color: rgba(104, 239, 173, 0.1);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-flat-turquoise-blue:not([disabled]):not(.disabled).active, .btn-flat-turquoise-blue:not([disabled]):not(.disabled):active {
  background-color: rgba(104, 239, 173, 0.2);
}
.btn-flat-turquoise-blue:disabled, .btn-flat-turquoise-blue.disabled {
  color: #68efad;
  background-color: transparent;
}

.btn-discreet-turquoise-blue {
  color: #68efad;
  background-color: rgba(104, 239, 173, 0.1);
}
.btn-discreet-turquoise-blue:hover:not([disabled]):not(.disabled), .btn-discreet-turquoise-blue:focus:not([disabled]):not(.disabled) {
  color: #fff;
  background-color: #68efad;
  border-color: #68efad;
}
.btn-discreet-turquoise-blue:not([disabled]):not(.disabled):active, .btn-discreet-turquoise-blue:not([disabled]):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75);
  background-color: #68efad;
  border-color: #68efad;
}
.btn-discreet-turquoise-blue:disabled, .btn-discreet-turquoise-blue.disabled {
  color: #68efad;
  background-color: transparent;
}

.btn-outline-turquoise-blue {
  background-color: transparent;
  border-color: #68efad;
  color: #68efad;
}
.btn-outline-turquoise-blue:hover {
  background-color: #68efad;
  color: #fff;
}
.btn-outline-turquoise-blue:focus, .btn-outline-turquoise-blue.focus {
  background-color: #68efad;
  color: #fff;
}
.btn-outline-turquoise-blue:not(:disabled):not(.disabled):active, .btn-outline-turquoise-blue:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75);
}
.btn-outline-turquoise-blue.disabled, .btn-outline-turquoise-blue:disabled {
  color: #68efad;
  background-color: transparent;
  border-color: #68efad;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-toggle-turquoise-blue {
  color: #68efad;
  background-color: transparent;
  border-color: #68efad;
}
.btn-toggle-turquoise-blue:hover {
  background-color: rgba(104, 239, 173, 0.2);
}
.btn-toggle-turquoise-blue:not([disabled]):not(.disabled):active {
  background-color: rgba(104, 239, 173, 0.3);
}
.btn-toggle-turquoise-blue.disabled, .btn-toggle-turquoise-blue:disabled {
  background-color: transparent;
  color: #68efad;
}
.btn-toggle-turquoise-blue.active {
  color: #fff;
  background-color: #68efad;
}
.btn-toggle-turquoise-blue.active:hover, .btn-toggle-turquoise-blue.active:focus, .btn-toggle-turquoise-blue.active.focus {
  background-color: #69efae;
  border-color: #69efae;
}
.btn-toggle-turquoise-blue.active:not([disabled]):not(.disabled):active {
  color: rgba(33, 37, 41, 0.75);
  background-color: #68efad;
  border-color: #68efad;
}

.btn-buttercup {
  color: #fff;
  background-color: #f6a416;
  border-color: #f6a416;
}
.btn-buttercup:hover:not([disabled]):not(.disabled), .btn-buttercup:focus:not([disabled]):not(.disabled) {
  color: #fff;
  background-color: #f6a417;
  border-color: #f6a417;
}
.btn-buttercup.disabled, .btn-buttercup:disabled {
  background-color: #f6a416;
  border-color: #f6a416;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-buttercup:not([disabled]):not(.disabled):active, .btn-buttercup:not([disabled]):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75);
  background-color: #f6a416;
  border-color: #f6a416;
}

.btn-flat-buttercup {
  color: #f6a416;
  background-color: transparent;
}
.btn-flat-buttercup:hover {
  text-decoration: none;
  background-color: rgba(246, 164, 22, 0.1);
}
.btn-flat-buttercup:focus, .btn-flat-buttercup.focus {
  background-color: rgba(246, 164, 22, 0.1);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-flat-buttercup:not([disabled]):not(.disabled).active, .btn-flat-buttercup:not([disabled]):not(.disabled):active {
  background-color: rgba(246, 164, 22, 0.2);
}
.btn-flat-buttercup:disabled, .btn-flat-buttercup.disabled {
  color: #f6a416;
  background-color: transparent;
}

.btn-discreet-buttercup {
  color: #f6a416;
  background-color: rgba(246, 164, 22, 0.1);
}
.btn-discreet-buttercup:hover:not([disabled]):not(.disabled), .btn-discreet-buttercup:focus:not([disabled]):not(.disabled) {
  color: #fff;
  background-color: #f6a416;
  border-color: #f6a416;
}
.btn-discreet-buttercup:not([disabled]):not(.disabled):active, .btn-discreet-buttercup:not([disabled]):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75);
  background-color: #f6a416;
  border-color: #f6a416;
}
.btn-discreet-buttercup:disabled, .btn-discreet-buttercup.disabled {
  color: #f6a416;
  background-color: transparent;
}

.btn-outline-buttercup {
  background-color: transparent;
  border-color: #f6a416;
  color: #f6a416;
}
.btn-outline-buttercup:hover {
  background-color: #f6a416;
  color: #fff;
}
.btn-outline-buttercup:focus, .btn-outline-buttercup.focus {
  background-color: #f6a416;
  color: #fff;
}
.btn-outline-buttercup:not(:disabled):not(.disabled):active, .btn-outline-buttercup:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75);
}
.btn-outline-buttercup.disabled, .btn-outline-buttercup:disabled {
  color: #f6a416;
  background-color: transparent;
  border-color: #f6a416;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-toggle-buttercup {
  color: #f6a416;
  background-color: transparent;
  border-color: #f6a416;
}
.btn-toggle-buttercup:hover {
  background-color: rgba(246, 164, 22, 0.2);
}
.btn-toggle-buttercup:not([disabled]):not(.disabled):active {
  background-color: rgba(246, 164, 22, 0.3);
}
.btn-toggle-buttercup.disabled, .btn-toggle-buttercup:disabled {
  background-color: transparent;
  color: #f6a416;
}
.btn-toggle-buttercup.active {
  color: #fff;
  background-color: #f6a416;
}
.btn-toggle-buttercup.active:hover, .btn-toggle-buttercup.active:focus, .btn-toggle-buttercup.active.focus {
  background-color: #f6a417;
  border-color: #f6a417;
}
.btn-toggle-buttercup.active:not([disabled]):not(.disabled):active {
  color: rgba(255, 255, 255, 0.75);
  background-color: #f6a416;
  border-color: #f6a416;
}

.btn-copper-rust {
  color: #fff;
  background-color: #974a5a;
  border-color: #974a5a;
}
.btn-copper-rust:hover:not([disabled]):not(.disabled), .btn-copper-rust:focus:not([disabled]):not(.disabled) {
  color: #fff;
  background-color: #984a5a;
  border-color: #984a5a;
}
.btn-copper-rust.disabled, .btn-copper-rust:disabled {
  background-color: #974a5a;
  border-color: #974a5a;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-copper-rust:not([disabled]):not(.disabled):active, .btn-copper-rust:not([disabled]):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75);
  background-color: #974a5a;
  border-color: #974a5a;
}

.btn-flat-copper-rust {
  color: #974a5a;
  background-color: transparent;
}
.btn-flat-copper-rust:hover {
  text-decoration: none;
  background-color: rgba(151, 74, 90, 0.1);
}
.btn-flat-copper-rust:focus, .btn-flat-copper-rust.focus {
  background-color: rgba(151, 74, 90, 0.1);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-flat-copper-rust:not([disabled]):not(.disabled).active, .btn-flat-copper-rust:not([disabled]):not(.disabled):active {
  background-color: rgba(151, 74, 90, 0.2);
}
.btn-flat-copper-rust:disabled, .btn-flat-copper-rust.disabled {
  color: #974a5a;
  background-color: transparent;
}

.btn-discreet-copper-rust {
  color: #974a5a;
  background-color: rgba(151, 74, 90, 0.1);
}
.btn-discreet-copper-rust:hover:not([disabled]):not(.disabled), .btn-discreet-copper-rust:focus:not([disabled]):not(.disabled) {
  color: #fff;
  background-color: #974a5a;
  border-color: #974a5a;
}
.btn-discreet-copper-rust:not([disabled]):not(.disabled):active, .btn-discreet-copper-rust:not([disabled]):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75);
  background-color: #974a5a;
  border-color: #974a5a;
}
.btn-discreet-copper-rust:disabled, .btn-discreet-copper-rust.disabled {
  color: #974a5a;
  background-color: transparent;
}

.btn-outline-copper-rust {
  background-color: transparent;
  border-color: #974a5a;
  color: #974a5a;
}
.btn-outline-copper-rust:hover {
  background-color: #974a5a;
  color: #fff;
}
.btn-outline-copper-rust:focus, .btn-outline-copper-rust.focus {
  background-color: #974a5a;
  color: #fff;
}
.btn-outline-copper-rust:not(:disabled):not(.disabled):active, .btn-outline-copper-rust:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75);
}
.btn-outline-copper-rust.disabled, .btn-outline-copper-rust:disabled {
  color: #974a5a;
  background-color: transparent;
  border-color: #974a5a;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-toggle-copper-rust {
  color: #974a5a;
  background-color: transparent;
  border-color: #974a5a;
}
.btn-toggle-copper-rust:hover {
  background-color: rgba(151, 74, 90, 0.2);
}
.btn-toggle-copper-rust:not([disabled]):not(.disabled):active {
  background-color: rgba(151, 74, 90, 0.3);
}
.btn-toggle-copper-rust.disabled, .btn-toggle-copper-rust:disabled {
  background-color: transparent;
  color: #974a5a;
}
.btn-toggle-copper-rust.active {
  color: #fff;
  background-color: #974a5a;
}
.btn-toggle-copper-rust.active:hover, .btn-toggle-copper-rust.active:focus, .btn-toggle-copper-rust.active.focus {
  background-color: #984a5a;
  border-color: #984a5a;
}
.btn-toggle-copper-rust.active:not([disabled]):not(.disabled):active {
  color: rgba(255, 255, 255, 0.75);
  background-color: #974a5a;
  border-color: #974a5a;
}

.btn-east-bay {
  color: #fff;
  background-color: #4a5885;
  border-color: #4a5885;
}
.btn-east-bay:hover:not([disabled]):not(.disabled), .btn-east-bay:focus:not([disabled]):not(.disabled) {
  color: #fff;
  background-color: #4a5886;
  border-color: #4a5886;
}
.btn-east-bay.disabled, .btn-east-bay:disabled {
  background-color: #4a5885;
  border-color: #4a5885;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-east-bay:not([disabled]):not(.disabled):active, .btn-east-bay:not([disabled]):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75);
  background-color: #4a5885;
  border-color: #4a5885;
}

.btn-flat-east-bay {
  color: #4a5885;
  background-color: transparent;
}
.btn-flat-east-bay:hover {
  text-decoration: none;
  background-color: rgba(74, 88, 133, 0.1);
}
.btn-flat-east-bay:focus, .btn-flat-east-bay.focus {
  background-color: rgba(74, 88, 133, 0.1);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-flat-east-bay:not([disabled]):not(.disabled).active, .btn-flat-east-bay:not([disabled]):not(.disabled):active {
  background-color: rgba(74, 88, 133, 0.2);
}
.btn-flat-east-bay:disabled, .btn-flat-east-bay.disabled {
  color: #4a5885;
  background-color: transparent;
}

.btn-discreet-east-bay {
  color: #4a5885;
  background-color: rgba(74, 88, 133, 0.1);
}
.btn-discreet-east-bay:hover:not([disabled]):not(.disabled), .btn-discreet-east-bay:focus:not([disabled]):not(.disabled) {
  color: #fff;
  background-color: #4a5885;
  border-color: #4a5885;
}
.btn-discreet-east-bay:not([disabled]):not(.disabled):active, .btn-discreet-east-bay:not([disabled]):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75);
  background-color: #4a5885;
  border-color: #4a5885;
}
.btn-discreet-east-bay:disabled, .btn-discreet-east-bay.disabled {
  color: #4a5885;
  background-color: transparent;
}

.btn-outline-east-bay {
  background-color: transparent;
  border-color: #4a5885;
  color: #4a5885;
}
.btn-outline-east-bay:hover {
  background-color: #4a5885;
  color: #fff;
}
.btn-outline-east-bay:focus, .btn-outline-east-bay.focus {
  background-color: #4a5885;
  color: #fff;
}
.btn-outline-east-bay:not(:disabled):not(.disabled):active, .btn-outline-east-bay:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75);
}
.btn-outline-east-bay.disabled, .btn-outline-east-bay:disabled {
  color: #4a5885;
  background-color: transparent;
  border-color: #4a5885;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-toggle-east-bay {
  color: #4a5885;
  background-color: transparent;
  border-color: #4a5885;
}
.btn-toggle-east-bay:hover {
  background-color: rgba(74, 88, 133, 0.2);
}
.btn-toggle-east-bay:not([disabled]):not(.disabled):active {
  background-color: rgba(74, 88, 133, 0.3);
}
.btn-toggle-east-bay.disabled, .btn-toggle-east-bay:disabled {
  background-color: transparent;
  color: #4a5885;
}
.btn-toggle-east-bay.active {
  color: #fff;
  background-color: #4a5885;
}
.btn-toggle-east-bay.active:hover, .btn-toggle-east-bay.active:focus, .btn-toggle-east-bay.active.focus {
  background-color: #4a5886;
  border-color: #4a5886;
}
.btn-toggle-east-bay.active:not([disabled]):not(.disabled):active {
  color: rgba(255, 255, 255, 0.75);
  background-color: #4a5885;
  border-color: #4a5885;
}

.btn-plantation {
  color: #fff;
  background-color: #275855;
  border-color: #275855;
}
.btn-plantation:hover:not([disabled]):not(.disabled), .btn-plantation:focus:not([disabled]):not(.disabled) {
  color: #fff;
  background-color: #275956;
  border-color: #275956;
}
.btn-plantation.disabled, .btn-plantation:disabled {
  background-color: #275855;
  border-color: #275855;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-plantation:not([disabled]):not(.disabled):active, .btn-plantation:not([disabled]):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75);
  background-color: #275855;
  border-color: #275855;
}

.btn-flat-plantation {
  color: #275855;
  background-color: transparent;
}
.btn-flat-plantation:hover {
  text-decoration: none;
  background-color: rgba(39, 88, 85, 0.1);
}
.btn-flat-plantation:focus, .btn-flat-plantation.focus {
  background-color: rgba(39, 88, 85, 0.1);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-flat-plantation:not([disabled]):not(.disabled).active, .btn-flat-plantation:not([disabled]):not(.disabled):active {
  background-color: rgba(39, 88, 85, 0.2);
}
.btn-flat-plantation:disabled, .btn-flat-plantation.disabled {
  color: #275855;
  background-color: transparent;
}

.btn-discreet-plantation {
  color: #275855;
  background-color: rgba(39, 88, 85, 0.1);
}
.btn-discreet-plantation:hover:not([disabled]):not(.disabled), .btn-discreet-plantation:focus:not([disabled]):not(.disabled) {
  color: #fff;
  background-color: #275855;
  border-color: #275855;
}
.btn-discreet-plantation:not([disabled]):not(.disabled):active, .btn-discreet-plantation:not([disabled]):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75);
  background-color: #275855;
  border-color: #275855;
}
.btn-discreet-plantation:disabled, .btn-discreet-plantation.disabled {
  color: #275855;
  background-color: transparent;
}

.btn-outline-plantation {
  background-color: transparent;
  border-color: #275855;
  color: #275855;
}
.btn-outline-plantation:hover {
  background-color: #275855;
  color: #fff;
}
.btn-outline-plantation:focus, .btn-outline-plantation.focus {
  background-color: #275855;
  color: #fff;
}
.btn-outline-plantation:not(:disabled):not(.disabled):active, .btn-outline-plantation:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75);
}
.btn-outline-plantation.disabled, .btn-outline-plantation:disabled {
  color: #275855;
  background-color: transparent;
  border-color: #275855;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-toggle-plantation {
  color: #275855;
  background-color: transparent;
  border-color: #275855;
}
.btn-toggle-plantation:hover {
  background-color: rgba(39, 88, 85, 0.2);
}
.btn-toggle-plantation:not([disabled]):not(.disabled):active {
  background-color: rgba(39, 88, 85, 0.3);
}
.btn-toggle-plantation.disabled, .btn-toggle-plantation:disabled {
  background-color: transparent;
  color: #275855;
}
.btn-toggle-plantation.active {
  color: #fff;
  background-color: #275855;
}
.btn-toggle-plantation.active:hover, .btn-toggle-plantation.active:focus, .btn-toggle-plantation.active.focus {
  background-color: #275956;
  border-color: #275956;
}
.btn-toggle-plantation.active:not([disabled]):not(.disabled):active {
  color: rgba(255, 255, 255, 0.75);
  background-color: #275855;
  border-color: #275855;
}

.btn-default,
.sgbs-btn-default {
  border: 1px solid #c4c8ca;
  background-color: transparent;
  color: #000;
}
.btn-default:not(:disabled):not(.disabled):hover,
.sgbs-btn-default:not(:disabled):not(.disabled):hover {
  background-color: #f5f6f7;
}
.btn-default:not(:disabled):not(.disabled):focus,
.sgbs-btn-default:not(:disabled):not(.disabled):focus {
  background-color: #f5f6f7;
}
.btn-default:not(:disabled):not(.disabled):active,
.sgbs-btn-default:not(:disabled):not(.disabled):active {
  background-color: #eceeef;
}

.btn:disabled .icon, .btn.disabled .icon {
  cursor: default;
}

*:not(.btn-group):not(.input-group-prepend):not(.input-group-append) > .btn.btn-xl:not([data-toggle=collapse]) > .icon, *:not(.btn-group):not(.input-group-prepend):not(.input-group-append) > .btn.btn-xl.sgbs-square-collapse > .icon {
  margin-left: -0.75rem;
  margin-right: -0.75rem;
  font-size: 1.5rem;
  line-height: 1;
}
*:not(.btn-group):not(.input-group-prepend):not(.input-group-append) > .btn.btn-xl.btn-icon-text > .icon {
  margin-top: -0.0625rem;
  margin-right: 0.375rem;
}
*:not(.btn-group):not(.input-group-prepend):not(.input-group-append) > .btn.btn-xl.btn-text-icon > .icon {
  margin-top: -0.0625rem;
  margin-left: 0.375rem;
}
*:not(.btn-group):not(.input-group-prepend):not(.input-group-append) > .btn.btn-lg:not([data-toggle=collapse]) > .icon, .btn-group-lg:not(.btn-group):not(.input-group-prepend):not(.input-group-append) > .btn:not([data-toggle=collapse]) > .icon, *:not(.btn-group):not(.input-group-prepend):not(.input-group-append) > .btn.btn-lg.sgbs-square-collapse > .icon, .btn-group-lg:not(.btn-group):not(.input-group-prepend):not(.input-group-append) > .btn.sgbs-square-collapse > .icon {
  margin-left: -0.6875rem;
  margin-right: -0.6875rem;
  font-size: 1.3rem;
  line-height: 1;
}
*:not(.btn-group):not(.input-group-prepend):not(.input-group-append) > .btn.btn-lg.btn-icon-text > .icon, .btn-group-lg:not(.btn-group):not(.input-group-prepend):not(.input-group-append) > .btn.btn-icon-text > .icon {
  margin-top: -0.0625rem;
  margin-right: 0.34375rem;
}
*:not(.btn-group):not(.input-group-prepend):not(.input-group-append) > .btn.btn-lg.btn-text-icon > .icon, .btn-group-lg:not(.btn-group):not(.input-group-prepend):not(.input-group-append) > .btn.btn-text-icon > .icon {
  margin-top: -0.0625rem;
  margin-left: 0.34375rem;
}
*:not(.btn-group):not(.input-group-prepend):not(.input-group-append) > .btn.btn-sm:not([data-toggle=collapse]) > .icon, .btn-group-sm:not(.btn-group):not(.input-group-prepend):not(.input-group-append) > .btn:not([data-toggle=collapse]) > .icon, *:not(.btn-group):not(.input-group-prepend):not(.input-group-append) > .btn.btn-sm.sgbs-square-collapse > .icon, .btn-group-sm:not(.btn-group):not(.input-group-prepend):not(.input-group-append) > .btn.sgbs-square-collapse > .icon {
  margin-left: -0.4375rem;
  margin-right: -0.4375rem;
  font-size: 1.125rem;
  line-height: 1;
}
*:not(.btn-group):not(.input-group-prepend):not(.input-group-append) > .btn.btn-sm.btn-icon-text > .icon, .btn-group-sm:not(.btn-group):not(.input-group-prepend):not(.input-group-append) > .btn.btn-icon-text > .icon {
  margin-top: -0.0625rem;
  margin-right: 0.21875rem;
}
*:not(.btn-group):not(.input-group-prepend):not(.input-group-append) > .btn.btn-sm.btn-text-icon > .icon, .btn-group-sm:not(.btn-group):not(.input-group-prepend):not(.input-group-append) > .btn.btn-text-icon > .icon {
  margin-top: -0.0625rem;
  margin-left: 0.21875rem;
}
*:not(.btn-group):not(.input-group-prepend):not(.input-group-append) > .btn:not([data-toggle=collapse]) > .icon, *:not(.btn-group):not(.input-group-prepend):not(.input-group-append) > .btn.sgbs-square-collapse > .icon {
  margin-left: -0.625rem;
  margin-right: -0.625rem;
  font-size: 1.2rem;
  line-height: 1;
}
*:not(.btn-group):not(.input-group-prepend):not(.input-group-append) > .btn.btn-icon-text > .icon {
  margin-top: -0.0625rem;
  margin-right: 0.3125rem;
}
*:not(.btn-group):not(.input-group-prepend):not(.input-group-append) > .btn.btn-text-icon > .icon {
  margin-top: -0.0625rem;
  margin-left: 0.3125rem;
}

.bg-lvl4 .btn.btn-outline-secondary {
  border-color: #5f6668;
  color: #5f6668;
}

.bg-lvl4 .btn.btn-outline-light {
  border-color: #b1b7b9;
  color: #b1b7b9;
}

.bg-lvl4 .btn.btn-outline-dark {
  border-color: #323939;
  color: #323939;
}

.bg-lvl4 .btn.btn-outline-info {
  border-color: #0554ff;
  color: #0554ff;
}

.bg-lvl4 .btn.btn-outline-success {
  border-color: #008a09;
  color: #008a09;
}

.bg-lvl4 .btn.btn-outline-warning {
  border-color: #bc6500;
  color: #bc6500;
}

.bg-lvl4 .btn.btn-outline-danger {
  border-color: #c22f1f;
  color: #c22f1f;
}

.bg-lvl3 .btn.btn-outline-secondary {
  border-color: #666e70;
  color: #666e70;
}

.bg-lvl3 .btn.btn-outline-light {
  border-color: #b9bec0;
  color: #b9bec0;
}

.bg-lvl3 .btn.btn-outline-dark {
  border-color: #373e3e;
  color: #373e3e;
}

.bg-lvl3 .btn.btn-outline-info {
  border-color: #0f5bff;
  color: #0f5bff;
}

.bg-lvl3 .btn.btn-outline-success {
  border-color: #00940a;
  color: #00940a;
}

.bg-lvl3 .btn.btn-outline-warning {
  border-color: #c66a00;
  color: #c66a00;
}

.bg-lvl3 .btn.btn-outline-danger {
  border-color: #cb3120;
  color: #cb3120;
}

.bg-lvl2 .btn.btn-outline-secondary {
  border-color: #687073;
  color: #687073;
}

.bg-lvl2 .btn.btn-outline-light {
  border-color: #c1c5c7;
  color: #c1c5c7;
}

.bg-lvl2 .btn.btn-outline-dark {
  border-color: #3c4343;
  color: #3c4343;
}

.bg-lvl2 .btn.btn-outline-info {
  border-color: #1a62ff;
  color: #1a62ff;
}

.bg-lvl2 .btn.btn-outline-success {
  border-color: #009e0b;
  color: #009e0b;
}

.bg-lvl2 .btn.btn-outline-warning {
  border-color: #d06f00;
  color: #d06f00;
}

.bg-lvl2 .btn.btn-outline-danger {
  border-color: #d43321;
  color: #d43321;
}

.bg-lvl1 .btn.btn-outline-secondary {
  border-color: #6e777a;
  color: #6e777a;
}

.bg-lvl1 .btn.btn-outline-light {
  border-color: #c4c8ca;
  color: #c4c8ca;
}

.bg-lvl1 .btn.btn-outline-dark {
  border-color: #414949;
  color: #414949;
}

.bg-lvl1 .btn.btn-outline-info {
  border-color: #2469ff;
  color: #2469ff;
}

.bg-lvl1 .btn.btn-outline-success {
  border-color: #00aa0c;
  color: #00aa0c;
}

.bg-lvl1 .btn.btn-outline-warning {
  border-color: #da7400;
  color: #da7400;
}

.bg-lvl1 .btn.btn-outline-danger {
  border-color: #dd3724;
  color: #dd3724;
}

.bg-alt-lvl4 .btn.btn-outline-secondary {
  border-color: #70797a;
  color: #70797a;
}

.bg-alt-lvl4 .btn.btn-outline-light {
  border-color: #a3afb3;
  color: #a3afb3;
}

.bg-alt-lvl4 .btn.btn-outline-dark {
  border-color: #343737;
  color: #343737;
}

.bg-alt-lvl4 .btn.btn-outline-info {
  border-color: #1476ff;
  color: #1476ff;
}

.bg-alt-lvl4 .btn.btn-outline-success {
  border-color: #00a83e;
  color: #00a83e;
}

.bg-alt-lvl4 .btn.btn-outline-warning {
  border-color: #cc7400;
  color: #cc7400;
}

.bg-alt-lvl4 .btn.btn-outline-danger {
  border-color: #d93d26;
  color: #d93d26;
}

.bg-alt-lvl3 .btn.btn-outline-secondary {
  border-color: #7a8385;
  color: #7a8385;
}

.bg-alt-lvl3 .btn.btn-outline-light {
  border-color: #b1bbbe;
  color: #b1bbbe;
}

.bg-alt-lvl3 .btn.btn-outline-dark {
  border-color: #3b3f3f;
  color: #3b3f3f;
}

.bg-alt-lvl3 .btn.btn-outline-info {
  border-color: #2982ff;
  color: #2982ff;
}

.bg-alt-lvl3 .btn.btn-outline-success {
  border-color: #00b844;
  color: #00b844;
}

.bg-alt-lvl3 .btn.btn-outline-warning {
  border-color: #db7c00;
  color: #db7c00;
}

.bg-alt-lvl3 .btn.btn-outline-danger {
  border-color: #e8442c;
  color: #e8442c;
}

.bg-alt-lvl2 .btn.btn-outline-secondary {
  border-color: #848d8f;
  color: #848d8f;
}

.bg-alt-lvl2 .btn.btn-outline-light {
  border-color: #c2cacc;
  color: #c2cacc;
}

.bg-alt-lvl2 .btn.btn-outline-dark {
  border-color: #454a4a;
  color: #454a4a;
}

.bg-alt-lvl2 .btn.btn-outline-info {
  border-color: #3d8eff;
  color: #3d8eff;
}

.bg-alt-lvl2 .btn.btn-outline-success {
  border-color: #00c74a;
  color: #00c74a;
}

.bg-alt-lvl2 .btn.btn-outline-warning {
  border-color: #eb8500;
  color: #eb8500;
}

.bg-alt-lvl2 .btn.btn-outline-danger {
  border-color: #f44d34;
  color: #f44d34;
}

.bg-alt-lvl1 .btn.btn-outline-secondary {
  border-color: #949c9e;
  color: #949c9e;
}

.bg-alt-lvl1 .btn.btn-outline-light {
  border-color: #d8ddde;
  color: #d8ddde;
}

.bg-alt-lvl1 .btn.btn-outline-dark {
  border-color: #515757;
  color: #515757;
}

.bg-alt-lvl1 .btn.btn-outline-info {
  border-color: #529aff;
  color: #529aff;
}

.bg-alt-lvl1 .btn.btn-outline-success {
  border-color: #00d750;
  color: #00d750;
}

.bg-alt-lvl1 .btn.btn-outline-warning {
  border-color: #f98d00;
  color: #f98d00;
}

.bg-alt-lvl1 .btn.btn-outline-danger {
  border-color: #ff563c;
  color: #ff563c;
}

.bg-lvl4 .btn.btn-outline-secondary:hover:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #505658;
  border-color: #505658;
}
.bg-lvl4 .btn.btn-outline-secondary:focus, .bg-lvl4 .btn.btn-outline-secondary.focus {
  background: #5f6668;
  color: #fff;
}
.bg-lvl4 .btn.btn-outline-secondary:not(:disabled):not(.disabled):active, .bg-lvl4 .btn.btn-outline-secondary:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
}

.bg-lvl4 .btn.btn-outline-light:hover:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #a1a8ab;
  border-color: #a1a8ab;
}
.bg-lvl4 .btn.btn-outline-light:focus, .bg-lvl4 .btn.btn-outline-light.focus {
  background: #b1b7b9;
  color: #fff;
}
.bg-lvl4 .btn.btn-outline-light:not(:disabled):not(.disabled):active, .bg-lvl4 .btn.btn-outline-light:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
}

.bg-lvl4 .btn.btn-outline-dark:hover:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #242929;
  border-color: #242929;
}
.bg-lvl4 .btn.btn-outline-dark:focus, .bg-lvl4 .btn.btn-outline-dark.focus {
  background: #323939;
  color: #fff;
}
.bg-lvl4 .btn.btn-outline-dark:not(:disabled):not(.disabled):active, .bg-lvl4 .btn.btn-outline-dark:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
}

.bg-lvl4 .btn.btn-outline-info:hover:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #0048e5;
  border-color: #0048e5;
}
.bg-lvl4 .btn.btn-outline-info:focus, .bg-lvl4 .btn.btn-outline-info.focus {
  background: #0554ff;
  color: #fff;
}
.bg-lvl4 .btn.btn-outline-info:not(:disabled):not(.disabled):active, .bg-lvl4 .btn.btn-outline-info:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
}

.bg-lvl4 .btn.btn-outline-success:hover:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #006b07;
  border-color: #006b07;
}
.bg-lvl4 .btn.btn-outline-success:focus, .bg-lvl4 .btn.btn-outline-success.focus {
  background: #008a09;
  color: #fff;
}
.bg-lvl4 .btn.btn-outline-success:not(:disabled):not(.disabled):active, .bg-lvl4 .btn.btn-outline-success:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
}

.bg-lvl4 .btn.btn-outline-warning:hover:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #9d5500;
  border-color: #9d5500;
}
.bg-lvl4 .btn.btn-outline-warning:focus, .bg-lvl4 .btn.btn-outline-warning.focus {
  background: #bc6500;
  color: #fff;
}
.bg-lvl4 .btn.btn-outline-warning:not(:disabled):not(.disabled):active, .bg-lvl4 .btn.btn-outline-warning:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
}

.bg-lvl4 .btn.btn-outline-danger:hover:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #a8291b;
  border-color: #a8291b;
}
.bg-lvl4 .btn.btn-outline-danger:focus, .bg-lvl4 .btn.btn-outline-danger.focus {
  background: #c22f1f;
  color: #fff;
}
.bg-lvl4 .btn.btn-outline-danger:not(:disabled):not(.disabled):active, .bg-lvl4 .btn.btn-outline-danger:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
}

.bg-lvl3 .btn.btn-outline-secondary:hover:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #575e60;
  border-color: #575e60;
}
.bg-lvl3 .btn.btn-outline-secondary:focus, .bg-lvl3 .btn.btn-outline-secondary.focus {
  background: #666e70;
  color: #fff;
}
.bg-lvl3 .btn.btn-outline-secondary:not(:disabled):not(.disabled):active, .bg-lvl3 .btn.btn-outline-secondary:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
}

.bg-lvl3 .btn.btn-outline-light:hover:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #a9afb2;
  border-color: #a9afb2;
}
.bg-lvl3 .btn.btn-outline-light:focus, .bg-lvl3 .btn.btn-outline-light.focus {
  background: #b9bec0;
  color: #fff;
}
.bg-lvl3 .btn.btn-outline-light:not(:disabled):not(.disabled):active, .bg-lvl3 .btn.btn-outline-light:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
}

.bg-lvl3 .btn.btn-outline-dark:hover:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #292e2e;
  border-color: #292e2e;
}
.bg-lvl3 .btn.btn-outline-dark:focus, .bg-lvl3 .btn.btn-outline-dark.focus {
  background: #373e3e;
  color: #fff;
}
.bg-lvl3 .btn.btn-outline-dark:not(:disabled):not(.disabled):active, .bg-lvl3 .btn.btn-outline-dark:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
}

.bg-lvl3 .btn.btn-outline-info:hover:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #004cef;
  border-color: #004cef;
}
.bg-lvl3 .btn.btn-outline-info:focus, .bg-lvl3 .btn.btn-outline-info.focus {
  background: #0f5bff;
  color: #fff;
}
.bg-lvl3 .btn.btn-outline-info:not(:disabled):not(.disabled):active, .bg-lvl3 .btn.btn-outline-info:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
}

.bg-lvl3 .btn.btn-outline-success:hover:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #007508;
  border-color: #007508;
}
.bg-lvl3 .btn.btn-outline-success:focus, .bg-lvl3 .btn.btn-outline-success.focus {
  background: #00940a;
  color: #fff;
}
.bg-lvl3 .btn.btn-outline-success:not(:disabled):not(.disabled):active, .bg-lvl3 .btn.btn-outline-success:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
}

.bg-lvl3 .btn.btn-outline-warning:hover:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #a75a00;
  border-color: #a75a00;
}
.bg-lvl3 .btn.btn-outline-warning:focus, .bg-lvl3 .btn.btn-outline-warning.focus {
  background: #c66a00;
  color: #fff;
}
.bg-lvl3 .btn.btn-outline-warning:not(:disabled):not(.disabled):active, .bg-lvl3 .btn.btn-outline-warning:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
}

.bg-lvl3 .btn.btn-outline-danger:hover:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #b12b1c;
  border-color: #b12b1c;
}
.bg-lvl3 .btn.btn-outline-danger:focus, .bg-lvl3 .btn.btn-outline-danger.focus {
  background: #cb3120;
  color: #fff;
}
.bg-lvl3 .btn.btn-outline-danger:not(:disabled):not(.disabled):active, .bg-lvl3 .btn.btn-outline-danger:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
}

.bg-lvl2 .btn.btn-outline-secondary:hover:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #596063;
  border-color: #596063;
}
.bg-lvl2 .btn.btn-outline-secondary:focus, .bg-lvl2 .btn.btn-outline-secondary.focus {
  background: #687073;
  color: #fff;
}
.bg-lvl2 .btn.btn-outline-secondary:not(:disabled):not(.disabled):active, .bg-lvl2 .btn.btn-outline-secondary:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
}

.bg-lvl2 .btn.btn-outline-light:hover:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #b1b6b8;
  border-color: #b1b6b8;
}
.bg-lvl2 .btn.btn-outline-light:focus, .bg-lvl2 .btn.btn-outline-light.focus {
  background: #c1c5c7;
  color: #fff;
}
.bg-lvl2 .btn.btn-outline-light:not(:disabled):not(.disabled):active, .bg-lvl2 .btn.btn-outline-light:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
}

.bg-lvl2 .btn.btn-outline-dark:hover:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #2e3333;
  border-color: #2e3333;
}
.bg-lvl2 .btn.btn-outline-dark:focus, .bg-lvl2 .btn.btn-outline-dark.focus {
  background: #3c4343;
  color: #fff;
}
.bg-lvl2 .btn.btn-outline-dark:not(:disabled):not(.disabled):active, .bg-lvl2 .btn.btn-outline-dark:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
}

.bg-lvl2 .btn.btn-outline-info:hover:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #004ffa;
  border-color: #004ffa;
}
.bg-lvl2 .btn.btn-outline-info:focus, .bg-lvl2 .btn.btn-outline-info.focus {
  background: #1a62ff;
  color: #fff;
}
.bg-lvl2 .btn.btn-outline-info:not(:disabled):not(.disabled):active, .bg-lvl2 .btn.btn-outline-info:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
}

.bg-lvl2 .btn.btn-outline-success:hover:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #007f09;
  border-color: #007f09;
}
.bg-lvl2 .btn.btn-outline-success:focus, .bg-lvl2 .btn.btn-outline-success.focus {
  background: #009e0b;
  color: #fff;
}
.bg-lvl2 .btn.btn-outline-success:not(:disabled):not(.disabled):active, .bg-lvl2 .btn.btn-outline-success:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
}

.bg-lvl2 .btn.btn-outline-warning:hover:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #b15f00;
  border-color: #b15f00;
}
.bg-lvl2 .btn.btn-outline-warning:focus, .bg-lvl2 .btn.btn-outline-warning.focus {
  background: #d06f00;
  color: #fff;
}
.bg-lvl2 .btn.btn-outline-warning:not(:disabled):not(.disabled):active, .bg-lvl2 .btn.btn-outline-warning:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
}

.bg-lvl2 .btn.btn-outline-danger:hover:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #ba2d1d;
  border-color: #ba2d1d;
}
.bg-lvl2 .btn.btn-outline-danger:focus, .bg-lvl2 .btn.btn-outline-danger.focus {
  background: #d43321;
  color: #fff;
}
.bg-lvl2 .btn.btn-outline-danger:not(:disabled):not(.disabled):active, .bg-lvl2 .btn.btn-outline-danger:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
}

.bg-lvl1 .btn.btn-outline-secondary:hover:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #5f676a;
  border-color: #5f676a;
}
.bg-lvl1 .btn.btn-outline-secondary:focus, .bg-lvl1 .btn.btn-outline-secondary.focus {
  background: #6e777a;
  color: #fff;
}
.bg-lvl1 .btn.btn-outline-secondary:not(:disabled):not(.disabled):active, .bg-lvl1 .btn.btn-outline-secondary:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
}

.bg-lvl1 .btn.btn-outline-light:hover:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #b4b9bc;
  border-color: #b4b9bc;
}
.bg-lvl1 .btn.btn-outline-light:focus, .bg-lvl1 .btn.btn-outline-light.focus {
  background: #c4c8ca;
  color: #fff;
}
.bg-lvl1 .btn.btn-outline-light:not(:disabled):not(.disabled):active, .bg-lvl1 .btn.btn-outline-light:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
}

.bg-lvl1 .btn.btn-outline-dark:hover:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #333939;
  border-color: #333939;
}
.bg-lvl1 .btn.btn-outline-dark:focus, .bg-lvl1 .btn.btn-outline-dark.focus {
  background: #414949;
  color: #fff;
}
.bg-lvl1 .btn.btn-outline-dark:not(:disabled):not(.disabled):active, .bg-lvl1 .btn.btn-outline-dark:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
}

.bg-lvl1 .btn.btn-outline-info:hover:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #0554ff;
  border-color: #0554ff;
}
.bg-lvl1 .btn.btn-outline-info:focus, .bg-lvl1 .btn.btn-outline-info.focus {
  background: #2469ff;
  color: #fff;
}
.bg-lvl1 .btn.btn-outline-info:not(:disabled):not(.disabled):active, .bg-lvl1 .btn.btn-outline-info:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
}

.bg-lvl1 .btn.btn-outline-success:hover:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #008b0a;
  border-color: #008b0a;
}
.bg-lvl1 .btn.btn-outline-success:focus, .bg-lvl1 .btn.btn-outline-success.focus {
  background: #00aa0c;
  color: #fff;
}
.bg-lvl1 .btn.btn-outline-success:not(:disabled):not(.disabled):active, .bg-lvl1 .btn.btn-outline-success:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
}

.bg-lvl1 .btn.btn-outline-warning:hover:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #bb6400;
  border-color: #bb6400;
}
.bg-lvl1 .btn.btn-outline-warning:focus, .bg-lvl1 .btn.btn-outline-warning.focus {
  background: #da7400;
  color: #fff;
}
.bg-lvl1 .btn.btn-outline-warning:not(:disabled):not(.disabled):active, .bg-lvl1 .btn.btn-outline-warning:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
}

.bg-lvl1 .btn.btn-outline-danger:hover:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #c42f1e;
  border-color: #c42f1e;
}
.bg-lvl1 .btn.btn-outline-danger:focus, .bg-lvl1 .btn.btn-outline-danger.focus {
  background: #dd3724;
  color: #fff;
}
.bg-lvl1 .btn.btn-outline-danger:not(:disabled):not(.disabled):active, .bg-lvl1 .btn.btn-outline-danger:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
}

.bg-alt-lvl4 .btn.btn-outline-secondary:hover:not(:disabled):not(.disabled) {
  color: #000;
  background-color: #7a8485;
  border-color: #7a8485;
}
.bg-alt-lvl4 .btn.btn-outline-secondary:focus, .bg-alt-lvl4 .btn.btn-outline-secondary.focus {
  background: #70797a;
  color: #000;
}
.bg-alt-lvl4 .btn.btn-outline-secondary:not(:disabled):not(.disabled):active, .bg-alt-lvl4 .btn.btn-outline-secondary:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
}

.bg-alt-lvl4 .btn.btn-outline-light:hover:not(:disabled):not(.disabled) {
  color: #000;
  background-color: #aeb9bc;
  border-color: #aeb9bc;
}
.bg-alt-lvl4 .btn.btn-outline-light:focus, .bg-alt-lvl4 .btn.btn-outline-light.focus {
  background: #a3afb3;
  color: #000;
}
.bg-alt-lvl4 .btn.btn-outline-light:not(:disabled):not(.disabled):active, .bg-alt-lvl4 .btn.btn-outline-light:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
}

.bg-alt-lvl4 .btn.btn-outline-dark:hover:not(:disabled):not(.disabled) {
  color: #000;
  background-color: #3e4141;
  border-color: #3e4141;
}
.bg-alt-lvl4 .btn.btn-outline-dark:focus, .bg-alt-lvl4 .btn.btn-outline-dark.focus {
  background: #343737;
  color: #000;
}
.bg-alt-lvl4 .btn.btn-outline-dark:not(:disabled):not(.disabled):active, .bg-alt-lvl4 .btn.btn-outline-dark:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
}

.bg-alt-lvl4 .btn.btn-outline-info:hover:not(:disabled):not(.disabled) {
  color: #000;
  background-color: #2882ff;
  border-color: #2882ff;
}
.bg-alt-lvl4 .btn.btn-outline-info:focus, .bg-alt-lvl4 .btn.btn-outline-info.focus {
  background: #1476ff;
  color: #000;
}
.bg-alt-lvl4 .btn.btn-outline-info:not(:disabled):not(.disabled):active, .bg-alt-lvl4 .btn.btn-outline-info:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
}

.bg-alt-lvl4 .btn.btn-outline-success:hover:not(:disabled):not(.disabled) {
  color: #000;
  background-color: #00bc46;
  border-color: #00bc46;
}
.bg-alt-lvl4 .btn.btn-outline-success:focus, .bg-alt-lvl4 .btn.btn-outline-success.focus {
  background: #00a83e;
  color: #000;
}
.bg-alt-lvl4 .btn.btn-outline-success:not(:disabled):not(.disabled):active, .bg-alt-lvl4 .btn.btn-outline-success:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
}

.bg-alt-lvl4 .btn.btn-outline-warning:hover:not(:disabled):not(.disabled) {
  color: #000;
  background-color: #e08000;
  border-color: #e08000;
}
.bg-alt-lvl4 .btn.btn-outline-warning:focus, .bg-alt-lvl4 .btn.btn-outline-warning.focus {
  background: #cc7400;
  color: #000;
}
.bg-alt-lvl4 .btn.btn-outline-warning:not(:disabled):not(.disabled):active, .bg-alt-lvl4 .btn.btn-outline-warning:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
}

.bg-alt-lvl4 .btn.btn-outline-danger:hover:not(:disabled):not(.disabled) {
  color: #000;
  background-color: #dc4d37;
  border-color: #dc4d37;
}
.bg-alt-lvl4 .btn.btn-outline-danger:focus, .bg-alt-lvl4 .btn.btn-outline-danger.focus {
  background: #d93d26;
  color: #000;
}
.bg-alt-lvl4 .btn.btn-outline-danger:not(:disabled):not(.disabled):active, .bg-alt-lvl4 .btn.btn-outline-danger:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
}

.bg-alt-lvl3 .btn.btn-outline-secondary:hover:not(:disabled):not(.disabled) {
  color: #000;
  background-color: #858d8f;
  border-color: #858d8f;
}
.bg-alt-lvl3 .btn.btn-outline-secondary:focus, .bg-alt-lvl3 .btn.btn-outline-secondary.focus {
  background: #7a8385;
  color: #000;
}
.bg-alt-lvl3 .btn.btn-outline-secondary:not(:disabled):not(.disabled):active, .bg-alt-lvl3 .btn.btn-outline-secondary:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
}

.bg-alt-lvl3 .btn.btn-outline-light:hover:not(:disabled):not(.disabled) {
  color: #000;
  background-color: #bcc5c7;
  border-color: #bcc5c7;
}
.bg-alt-lvl3 .btn.btn-outline-light:focus, .bg-alt-lvl3 .btn.btn-outline-light.focus {
  background: #b1bbbe;
  color: #000;
}
.bg-alt-lvl3 .btn.btn-outline-light:not(:disabled):not(.disabled):active, .bg-alt-lvl3 .btn.btn-outline-light:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
}

.bg-alt-lvl3 .btn.btn-outline-dark:hover:not(:disabled):not(.disabled) {
  color: #000;
  background-color: #454a4a;
  border-color: #454a4a;
}
.bg-alt-lvl3 .btn.btn-outline-dark:focus, .bg-alt-lvl3 .btn.btn-outline-dark.focus {
  background: #3b3f3f;
  color: #000;
}
.bg-alt-lvl3 .btn.btn-outline-dark:not(:disabled):not(.disabled):active, .bg-alt-lvl3 .btn.btn-outline-dark:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
}

.bg-alt-lvl3 .btn.btn-outline-info:hover:not(:disabled):not(.disabled) {
  color: #000;
  background-color: #3d8eff;
  border-color: #3d8eff;
}
.bg-alt-lvl3 .btn.btn-outline-info:focus, .bg-alt-lvl3 .btn.btn-outline-info.focus {
  background: #2982ff;
  color: #000;
}
.bg-alt-lvl3 .btn.btn-outline-info:not(:disabled):not(.disabled):active, .bg-alt-lvl3 .btn.btn-outline-info:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
}

.bg-alt-lvl3 .btn.btn-outline-success:hover:not(:disabled):not(.disabled) {
  color: #000;
  background-color: #00cc4c;
  border-color: #00cc4c;
}
.bg-alt-lvl3 .btn.btn-outline-success:focus, .bg-alt-lvl3 .btn.btn-outline-success.focus {
  background: #00b844;
  color: #000;
}
.bg-alt-lvl3 .btn.btn-outline-success:not(:disabled):not(.disabled):active, .bg-alt-lvl3 .btn.btn-outline-success:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
}

.bg-alt-lvl3 .btn.btn-outline-warning:hover:not(:disabled):not(.disabled) {
  color: #000;
  background-color: #ef8800;
  border-color: #ef8800;
}
.bg-alt-lvl3 .btn.btn-outline-warning:focus, .bg-alt-lvl3 .btn.btn-outline-warning.focus {
  background: #db7c00;
  color: #000;
}
.bg-alt-lvl3 .btn.btn-outline-warning:not(:disabled):not(.disabled):active, .bg-alt-lvl3 .btn.btn-outline-warning:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
}

.bg-alt-lvl3 .btn.btn-outline-danger:hover:not(:disabled):not(.disabled) {
  color: #000;
  background-color: #ea543e;
  border-color: #ea543e;
}
.bg-alt-lvl3 .btn.btn-outline-danger:focus, .bg-alt-lvl3 .btn.btn-outline-danger.focus {
  background: #e8442c;
  color: #000;
}
.bg-alt-lvl3 .btn.btn-outline-danger:not(:disabled):not(.disabled):active, .bg-alt-lvl3 .btn.btn-outline-danger:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
}

.bg-alt-lvl2 .btn.btn-outline-secondary:hover:not(:disabled):not(.disabled) {
  color: #000;
  background-color: #8f9799;
  border-color: #8f9799;
}
.bg-alt-lvl2 .btn.btn-outline-secondary:focus, .bg-alt-lvl2 .btn.btn-outline-secondary.focus {
  background: #848d8f;
  color: #000;
}
.bg-alt-lvl2 .btn.btn-outline-secondary:not(:disabled):not(.disabled):active, .bg-alt-lvl2 .btn.btn-outline-secondary:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
}

.bg-alt-lvl2 .btn.btn-outline-light:hover:not(:disabled):not(.disabled) {
  color: #000;
  background-color: #cdd4d5;
  border-color: #cdd4d5;
}
.bg-alt-lvl2 .btn.btn-outline-light:focus, .bg-alt-lvl2 .btn.btn-outline-light.focus {
  background: #c2cacc;
  color: #000;
}
.bg-alt-lvl2 .btn.btn-outline-light:not(:disabled):not(.disabled):active, .bg-alt-lvl2 .btn.btn-outline-light:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
}

.bg-alt-lvl2 .btn.btn-outline-dark:hover:not(:disabled):not(.disabled) {
  color: #000;
  background-color: #4f5555;
  border-color: #4f5555;
}
.bg-alt-lvl2 .btn.btn-outline-dark:focus, .bg-alt-lvl2 .btn.btn-outline-dark.focus {
  background: #454a4a;
  color: #000;
}
.bg-alt-lvl2 .btn.btn-outline-dark:not(:disabled):not(.disabled):active, .bg-alt-lvl2 .btn.btn-outline-dark:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
}

.bg-alt-lvl2 .btn.btn-outline-info:hover:not(:disabled):not(.disabled) {
  color: #000;
  background-color: #519aff;
  border-color: #519aff;
}
.bg-alt-lvl2 .btn.btn-outline-info:focus, .bg-alt-lvl2 .btn.btn-outline-info.focus {
  background: #3d8eff;
  color: #000;
}
.bg-alt-lvl2 .btn.btn-outline-info:not(:disabled):not(.disabled):active, .bg-alt-lvl2 .btn.btn-outline-info:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
}

.bg-alt-lvl2 .btn.btn-outline-success:hover:not(:disabled):not(.disabled) {
  color: #000;
  background-color: #00db52;
  border-color: #00db52;
}
.bg-alt-lvl2 .btn.btn-outline-success:focus, .bg-alt-lvl2 .btn.btn-outline-success.focus {
  background: #00c74a;
  color: #000;
}
.bg-alt-lvl2 .btn.btn-outline-success:not(:disabled):not(.disabled):active, .bg-alt-lvl2 .btn.btn-outline-success:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
}

.bg-alt-lvl2 .btn.btn-outline-warning:hover:not(:disabled):not(.disabled) {
  color: #000;
  background-color: #ff9000;
  border-color: #ff9000;
}
.bg-alt-lvl2 .btn.btn-outline-warning:focus, .bg-alt-lvl2 .btn.btn-outline-warning.focus {
  background: #eb8500;
  color: #000;
}
.bg-alt-lvl2 .btn.btn-outline-warning:not(:disabled):not(.disabled):active, .bg-alt-lvl2 .btn.btn-outline-warning:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
}

.bg-alt-lvl2 .btn.btn-outline-danger:hover:not(:disabled):not(.disabled) {
  color: #000;
  background-color: #f55e47;
  border-color: #f55e47;
}
.bg-alt-lvl2 .btn.btn-outline-danger:focus, .bg-alt-lvl2 .btn.btn-outline-danger.focus {
  background: #f44d34;
  color: #000;
}
.bg-alt-lvl2 .btn.btn-outline-danger:not(:disabled):not(.disabled):active, .bg-alt-lvl2 .btn.btn-outline-danger:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
}

.bg-alt-lvl1 .btn.btn-outline-secondary:hover:not(:disabled):not(.disabled) {
  color: #000;
  background-color: #9fa6a8;
  border-color: #9fa6a8;
}
.bg-alt-lvl1 .btn.btn-outline-secondary:focus, .bg-alt-lvl1 .btn.btn-outline-secondary.focus {
  background: #949c9e;
  color: #000;
}
.bg-alt-lvl1 .btn.btn-outline-secondary:not(:disabled):not(.disabled):active, .bg-alt-lvl1 .btn.btn-outline-secondary:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
}

.bg-alt-lvl1 .btn.btn-outline-light:hover:not(:disabled):not(.disabled) {
  color: #000;
  background-color: #e3e7e7;
  border-color: #e3e7e7;
}
.bg-alt-lvl1 .btn.btn-outline-light:focus, .bg-alt-lvl1 .btn.btn-outline-light.focus {
  background: #d8ddde;
  color: #000;
}
.bg-alt-lvl1 .btn.btn-outline-light:not(:disabled):not(.disabled):active, .bg-alt-lvl1 .btn.btn-outline-light:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
}

.bg-alt-lvl1 .btn.btn-outline-dark:hover:not(:disabled):not(.disabled) {
  color: #000;
  background-color: #5b6262;
  border-color: #5b6262;
}
.bg-alt-lvl1 .btn.btn-outline-dark:focus, .bg-alt-lvl1 .btn.btn-outline-dark.focus {
  background: #515757;
  color: #000;
}
.bg-alt-lvl1 .btn.btn-outline-dark:not(:disabled):not(.disabled):active, .bg-alt-lvl1 .btn.btn-outline-dark:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
}

.bg-alt-lvl1 .btn.btn-outline-info:hover:not(:disabled):not(.disabled) {
  color: #000;
  background-color: #66a6ff;
  border-color: #66a6ff;
}
.bg-alt-lvl1 .btn.btn-outline-info:focus, .bg-alt-lvl1 .btn.btn-outline-info.focus {
  background: #529aff;
  color: #000;
}
.bg-alt-lvl1 .btn.btn-outline-info:not(:disabled):not(.disabled):active, .bg-alt-lvl1 .btn.btn-outline-info:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
}

.bg-alt-lvl1 .btn.btn-outline-success:hover:not(:disabled):not(.disabled) {
  color: #000;
  background-color: #00eb58;
  border-color: #00eb58;
}
.bg-alt-lvl1 .btn.btn-outline-success:focus, .bg-alt-lvl1 .btn.btn-outline-success.focus {
  background: #00d750;
  color: #000;
}
.bg-alt-lvl1 .btn.btn-outline-success:not(:disabled):not(.disabled):active, .bg-alt-lvl1 .btn.btn-outline-success:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
}

.bg-alt-lvl1 .btn.btn-outline-warning:hover:not(:disabled):not(.disabled) {
  color: #000;
  background-color: #ff970e;
  border-color: #ff970e;
}
.bg-alt-lvl1 .btn.btn-outline-warning:focus, .bg-alt-lvl1 .btn.btn-outline-warning.focus {
  background: #f98d00;
  color: #000;
}
.bg-alt-lvl1 .btn.btn-outline-warning:not(:disabled):not(.disabled):active, .bg-alt-lvl1 .btn.btn-outline-warning:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
}

.bg-alt-lvl1 .btn.btn-outline-danger:hover:not(:disabled):not(.disabled) {
  color: #000;
  background-color: #ff6850;
  border-color: #ff6850;
}
.bg-alt-lvl1 .btn.btn-outline-danger:focus, .bg-alt-lvl1 .btn.btn-outline-danger.focus {
  background: #ff563c;
  color: #000;
}
.bg-alt-lvl1 .btn.btn-outline-danger:not(:disabled):not(.disabled):active, .bg-alt-lvl1 .btn.btn-outline-danger:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
}

.btn.btn-primary:not(:disabled):not(.disabled):hover {
  background-color: #333333;
  border-color: #333333;
}

.btn.btn-outline-primary-alt:not(:disabled):not(.disabled):hover, .btn.btn-outline-primary-alt:not(:disabled):not(.disabled).hover {
  color: #000 !important;
}

.btn.btn-toggle-primary-alt.active {
  color: #000 !important;
}

.btn.btn-primary-alt:not(:disabled):not(.disabled):active, .btn.btn-primary-alt:not(:disabled):not(.disabled).active {
  background-color: #e6e6e6 !important;
}

.btn.btn-dark, .btn.btn-black {
  color: #fff !important;
}
.btn.btn-dark:hover, .btn.btn-black:hover {
  color: #fff !important;
}
.btn.btn-dark:not(:disabled):not(.disabled):active, .btn.btn-dark:not(:disabled):not(.disabled).active, .btn.btn-black:not(:disabled):not(.disabled):active, .btn.btn-black:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
}
.btn.btn-white, .btn.btn-light {
  color: #000 !important;
}
.btn.btn-white:hover, .btn.btn-light:hover {
  color: #000 !important;
}
.btn.btn-white:not(:disabled):not(.disabled):active, .btn.btn-white:not(:disabled):not(.disabled).active, .btn.btn-light:not(:disabled):not(.disabled):active, .btn.btn-light:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
}
.btn.btn-white:not(:disabled):not(.disabled):active, .btn.btn-white:not(:disabled):not(.disabled).active, .btn.btn-light:not(:disabled):not(.disabled):active, .btn.btn-light:not(:disabled):not(.disabled).active {
  color: rgba(0, 0, 0, 0.75) !important;
}

.btn.btn-discreet-primary-alt:not(:disabled):not(.disabled):hover {
  color: #000;
}

.btn.btn-outline-primary-alt:not(:disabled):not(.disabled):focus {
  color: #000;
}

.fade {
  -webkit-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    -webkit-transition: none;
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition: height 0.35s ease;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    -webkit-transition: none;
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 8rem;
  padding: 0 0;
  margin: 0 0 0;
  font-size: 0.875rem;
  color: #000;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(196, 200, 202, 0.7);
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1600px) {
  .dropdown-menu-xxl-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xxl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0 0;
  overflow: hidden;
  border-top: 1px solid rgba(196, 200, 202, 0.7);
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.625rem 1rem;
  clear: both;
  font-weight: 400;
  color: #000;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #000;
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.08);
}
.dropdown-item.active, .dropdown-item:active {
  color: #000;
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.15);
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: rgba(0, 0, 0, 0.2);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0 1rem;
  margin-bottom: 0;
  font-size: 0.75rem;
  color: #6e777a;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.625rem 1rem;
  color: #000;
}

.dropdown-header {
  padding: 1rem 1rem 0.5rem;
  font-weight: 500;
}

.dropdown-menu {
  -webkit-box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.1), 0 8px 10px 1px rgba(0, 0, 0, 0.07), 0 3px 14px 2px rgba(0, 0, 0, 0.06);
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.1), 0 8px 10px 1px rgba(0, 0, 0, 0.07), 0 3px 14px 2px rgba(0, 0, 0, 0.06);
  margin: 1px 0 0 -2px;
}

.dropdown-item:active:not(.active) {
  color: #000;
  background-color: rgba(0, 0, 0, 0.2);
}
.dropdown-item.active {
  font-weight: 500;
  background-color: rgba(0, 0, 0, 0.15);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.dropdown-item.active::after {
  display: inline-block;
  vertical-align: middle;
  content: "";
  width: 1.25rem;
  height: 1.25rem;
  margin-left: 1rem;
  background: transparent url("data:image/svg+xml;charset=UTF-8, %3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 26 26' %3e%3cpath fill='%23000' d='M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z'/%3e%3c/svg%3e") no-repeat center;
  background-size: cover;
}

.btn-group .btn-xl + .dropdown-menu {
  font-size: 1rem;
}
.btn-group .btn-xl + .dropdown-menu .dropdown-item {
  padding: 0.907rem 1.4375rem;
}
.btn-group .btn-xl + .dropdown-menu .dropdown-item.active.active::after {
  width: 1.5rem;
  height: 1.5rem;
  margin-left: 1.4375rem;
}
.btn-group .btn-xl + .dropdown-menu .dropdown-header {
  padding: 1.5rem 1.4375rem 1rem;
  font-size: 0.875rem;
}
.btn-group .btn-lg + .dropdown-menu, .btn-group .btn-group-lg > .btn + .dropdown-menu {
  font-size: 0.875rem;
}
.btn-group .btn-lg + .dropdown-menu .dropdown-item, .btn-group .btn-group-lg > .btn + .dropdown-menu .dropdown-item {
  padding: 0.875rem 1.1875rem;
}
.btn-group .btn-lg + .dropdown-menu .dropdown-item.active::after, .btn-group .btn-group-lg > .btn + .dropdown-menu .dropdown-item.active::after {
  width: 1.375rem;
  height: 1.375rem;
  margin-left: 1.1875rem;
}
.btn-group .btn-lg + .dropdown-menu .dropdown-header, .btn-group .btn-group-lg > .btn + .dropdown-menu .dropdown-header {
  padding: 1.25rem 1.1875rem 0.5rem;
  font-size: 0.75rem;
}
.btn-group .btn-sm + .dropdown-menu, .btn-group .btn-group-sm > .btn + .dropdown-menu {
  font-size: 0.75rem;
}
.btn-group .btn-sm + .dropdown-menu .dropdown-item, .btn-group .btn-group-sm > .btn + .dropdown-menu .dropdown-item {
  padding: 0.469rem 0.4375rem;
}
.btn-group .btn-sm + .dropdown-menu .dropdown-item.active.active::after, .btn-group .btn-group-sm > .btn + .dropdown-menu .dropdown-item.active.active::after {
  width: 1.125rem;
  height: 1.125rem;
  margin-left: 0.4375rem;
}
.btn-group .btn-sm + .dropdown-menu .dropdown-header, .btn-group .btn-group-sm > .btn + .dropdown-menu .dropdown-header {
  padding: 0.5rem 0.4375rem 0.25rem;
  font-size: 0.625rem;
}

.dropdown-toggle-split {
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
}
.dropdown-toggle-split.btn-sm, .btn-group-sm > .dropdown-toggle-split.btn {
  padding-right: 0.4375rem;
  padding-left: 0.4375rem;
}
.dropdown-toggle-split.btn-lg, .btn-group-lg > .dropdown-toggle-split.btn {
  padding-right: 1.1875rem;
  padding-left: 1.1875rem;
}
.dropdown-toggle-split.btn-xl {
  padding-right: 1.00625rem;
  padding-left: 1.00625rem;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.4375rem;
  padding-left: 0.4375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 1.1875rem;
  padding-left: 1.1875rem;
}

.btn-xl + .dropdown-toggle-split {
  padding-right: 1.00625rem;
  padding-left: 1.00625rem;
}

.dropdown-toggle::after {
  vertical-align: middle;
}

.dropdown-caret-primary::after {
  color: #000;
}

.dropdown-caret-secondary::after {
  color: #6e777a;
}

.dropdown-caret-success::after {
  color: #00aa0c;
}

.dropdown-caret-info::after {
  color: #2469ff;
}

.dropdown-caret-warning::after {
  color: #da7400;
}

.dropdown-caret-danger::after {
  color: #dd3724;
}

.dropdown-caret-light::after {
  color: #c4c8ca;
}

.dropdown-caret-dark::after {
  color: #414949;
}

.dropdown-caret-white::after {
  color: white;
}

.dropdown-caret-black::after {
  color: #231f20;
}

.dropdown-caret-socgen::after {
  color: #e60028;
}

.dropdown-caret-primary-alt::after {
  color: #fff;
}

.dropdown-toggle::after {
  margin-top: -0.0625rem;
  font-size: 1.2rem;
  line-height: 1;
}
.dropdown-toggle:not(.dropdown-toggle-split)::after {
  margin-left: 0.3125rem;
}
.dropdown-toggle.btn-xl::after {
  margin-top: -0.0625rem;
  font-size: 1.5rem;
  line-height: 1;
}
.dropdown-toggle.btn-xl:not(.dropdown-toggle-split)::after {
  margin-left: 0.375rem;
}
.dropdown-toggle.btn-lg::after, .btn-group-lg > .dropdown-toggle.btn::after {
  margin-top: -0.0625rem;
  font-size: 1.3rem;
  line-height: 1;
}
.dropdown-toggle.btn-lg:not(.dropdown-toggle-split)::after, .btn-group-lg > .dropdown-toggle.btn:not(.dropdown-toggle-split)::after {
  margin-left: 0.34375rem;
}
.dropdown-toggle.btn-sm::after, .btn-group-sm > .dropdown-toggle.btn::after {
  margin-top: -0.0625rem;
  font-size: 1.125rem;
  line-height: 1;
}
.dropdown-toggle.btn-sm:not(.dropdown-toggle-split)::after, .btn-group-sm > .dropdown-toggle.btn:not(.dropdown-toggle-split)::after {
  margin-left: 0.21875rem;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -0.0625rem;
}
.dropdown-toggle-split {
  padding-right: 0.703125rem;
  padding-left: 0.703125rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.328125rem;
  padding-left: 0.328125rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.890625rem;
  padding-left: 0.890625rem;
}

.btn-group-vertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -0.0625rem;
}
.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 2;
}
.btn-group > .btn:not(:disabled):not(.disabled),
.btn-group-vertical > .btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.btn-group .btn-toggle-primary:not([disabled]):not(.disabled),
.btn-group-vertical .btn-toggle-primary:not([disabled]):not(.disabled) {
  border-color: #c4c8ca;
  color: #000;
}
.btn-group .btn-toggle-primary:not([disabled]):not(.disabled).active:active, .btn-group .btn-toggle-primary:not([disabled]):not(.disabled).active,
.btn-group-vertical .btn-toggle-primary:not([disabled]):not(.disabled).active:active,
.btn-group-vertical .btn-toggle-primary:not([disabled]):not(.disabled).active {
  color: #000;
  background-color: rgba(0, 0, 0, 0.1);
  border-color: #000;
}
.btn-group .btn-toggle-primary:not([disabled]):not(.disabled).active:active:hover, .btn-group .btn-toggle-primary:not([disabled]):not(.disabled).active:hover,
.btn-group-vertical .btn-toggle-primary:not([disabled]):not(.disabled).active:active:hover,
.btn-group-vertical .btn-toggle-primary:not([disabled]):not(.disabled).active:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
.btn-group .btn-toggle-primary:not([disabled]):not(.disabled):active,
.btn-group-vertical .btn-toggle-primary:not([disabled]):not(.disabled):active {
  color: #000;
  background-color: rgba(0, 0, 0, 0.15);
}
.btn-group .btn-toggle-primary:not([disabled]):not(.disabled):hover,
.btn-group-vertical .btn-toggle-primary:not([disabled]):not(.disabled):hover {
  background: transparent;
}
.btn-group .btn-toggle-primary:not([disabled]):not(.disabled):hover, .btn-group .btn-toggle-primary:not([disabled]):not(.disabled):focus, .btn-group .btn-toggle-primary:not([disabled]):not(.disabled):active, .btn-group .btn-toggle-primary:not([disabled]):not(.disabled).active,
.btn-group-vertical .btn-toggle-primary:not([disabled]):not(.disabled):hover,
.btn-group-vertical .btn-toggle-primary:not([disabled]):not(.disabled):focus,
.btn-group-vertical .btn-toggle-primary:not([disabled]):not(.disabled):active,
.btn-group-vertical .btn-toggle-primary:not([disabled]):not(.disabled).active {
  border-color: #000;
}
.btn-group .btn-toggle-info:not([disabled]):not(.disabled),
.btn-group-vertical .btn-toggle-info:not([disabled]):not(.disabled) {
  border-color: #c4c8ca;
  color: #000;
}
.btn-group .btn-toggle-info:not([disabled]):not(.disabled).active,
.btn-group-vertical .btn-toggle-info:not([disabled]):not(.disabled).active {
  color: #2469ff;
  background-color: rgba(36, 105, 255, 0.1);
}
.btn-group .btn-toggle-info:not([disabled]):not(.disabled).active:hover,
.btn-group-vertical .btn-toggle-info:not([disabled]):not(.disabled).active:hover {
  background-color: rgba(36, 105, 255, 0.05);
}
.btn-group .btn-toggle-info:not([disabled]):not(.disabled):active,
.btn-group-vertical .btn-toggle-info:not([disabled]):not(.disabled):active {
  color: #2469ff;
  background-color: rgba(36, 105, 255, 0.15);
}
.btn-group .btn-toggle-info:not([disabled]):not(.disabled):hover,
.btn-group-vertical .btn-toggle-info:not([disabled]):not(.disabled):hover {
  background: transparent;
}
.btn-group .btn-toggle-info:not([disabled]):not(.disabled):focus,
.btn-group-vertical .btn-toggle-info:not([disabled]):not(.disabled):focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(36, 105, 255, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(36, 105, 255, 0.25);
}
.btn-group .btn-toggle-info:not([disabled]):not(.disabled):hover, .btn-group .btn-toggle-info:not([disabled]):not(.disabled):focus, .btn-group .btn-toggle-info:not([disabled]):not(.disabled):active, .btn-group .btn-toggle-info:not([disabled]):not(.disabled).active,
.btn-group-vertical .btn-toggle-info:not([disabled]):not(.disabled):hover,
.btn-group-vertical .btn-toggle-info:not([disabled]):not(.disabled):focus,
.btn-group-vertical .btn-toggle-info:not([disabled]):not(.disabled):active,
.btn-group-vertical .btn-toggle-info:not([disabled]):not(.disabled).active {
  border-color: #2469ff;
}

.input-group {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: 0rem;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .custom-file {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.input-group-prepend,
.input-group-append {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: 0rem;
}

.input-group-prepend {
  margin-right: 0rem;
}

.input-group-append {
  margin-left: 0rem;
}

.input-group-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.375rem 0.625rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.4285714286;
  color: #5f676a;
  text-align: center;
  white-space: nowrap;
  background-color: rgba(110, 119, 122, 0.2);
  border: 0rem solid transparent;
}
.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc( 2.5714285714rem + 0.125rem + 0rem );
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5625rem 0.875rem;
  font-size: 1rem;
  line-height: 1.5714285714;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc( 1.125rem + 0.125rem + 0rem );
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0rem 0.5rem;
  font-size: 0.75rem;
  line-height: 1.5;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.625rem;
}

.input-group-prepend,
.input-group-append {
  height: calc(1.4285714286em + 0.75rem);
}

.input-group-merged.input-group-prepend {
  left: 0.625rem;
}
.input-group-merged.input-group-prepend ~ .form-control {
  padding-left: 1.875rem;
}
.input-group-merged.input-group-append {
  right: 0;
}
.input-group-merged.input-group-append > .icon {
  padding: 0 0.625rem;
}
.input-group-merged.input-group-append ~ .form-control {
  padding-right: 2.5rem;
}

.input-group-merged {
  position: absolute;
  top: 0;
  bottom: 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.input-group-merged .icon {
  color: #6e777a;
}
.input-group-merged + .form-control:focus {
  z-index: unset;
}

.input-group-sm {
  font-size: 0.75rem;
}
.input-group-sm .input-group-prepend,
.input-group-sm .input-group-append {
  height: calc( 1.125rem + 0.125rem + 0rem );
}
.input-group-sm .input-group-merged.input-group-prepend {
  left: 0.5rem;
}
.input-group-sm .input-group-merged.input-group-prepend ~ .form-control {
  padding-left: 1.5rem;
}
.input-group-sm .input-group-merged.input-group-append {
  right: 0;
}
.input-group-sm .input-group-merged.input-group-append > .icon {
  padding: 0 0.5rem;
}
.input-group-sm .input-group-merged.input-group-append ~ .form-control {
  padding-right: 2rem;
}

.input-group-lg {
  font-size: 1rem;
}
.input-group-lg .input-group-prepend,
.input-group-lg .input-group-append {
  height: calc( 2.5714285714rem + 0.125rem + 0rem );
}
.input-group-lg .input-group-merged.input-group-prepend {
  left: 0.875rem;
}
.input-group-lg .input-group-merged.input-group-prepend ~ .form-control {
  padding-left: 2.625rem;
}
.input-group-lg .input-group-merged.input-group-append {
  right: 0;
}
.input-group-lg .input-group-merged.input-group-append > .icon {
  padding: 0 0.875rem;
}
.input-group-lg .input-group-merged.input-group-append ~ .form-control {
  padding-right: 3.5rem;
}

.input-group-lg > .form-control,
.form-control-lg {
  font-size: 0.875rem;
}

.input-group-xl {
  font-size: 1rem;
}
.input-group-xl .input-group-prepend,
.input-group-xl .input-group-append {
  height: 3rem;
}
.input-group-xl > .form-control,
.input-group-xl > .input-group-prepend > .input-group-text,
.input-group-xl > .input-group-append > .input-group-text,
.input-group-xl > .input-group-prepend > .btn,
.input-group-xl > .input-group-append > .btn {
  height: 3rem;
  padding: 0.75rem 1rem;
  font-size: 1rem;
  line-height: 1.5;
}
.input-group-xl .input-group-prepend,
.input-group-xl .input-group-append {
  height: 3rem;
}
.input-group-xl .input-group-merged.input-group-prepend {
  left: 1rem;
}
.input-group-xl .input-group-merged.input-group-prepend ~ .form-control {
  padding-left: 3rem;
}
.input-group-xl .input-group-merged.input-group-append {
  right: 0;
}
.input-group-xl .input-group-merged.input-group-append > .icon {
  padding: 0 1rem;
}
.input-group-xl .input-group-merged.input-group-append ~ .form-control {
  padding-right: 4rem;
}

.input-group-append.input-group-merged {
  position: absolute;
}
.input-group-append.input-group-merged .input-group-text {
  background: transparent;
}

.input-group-text {
  padding: 0.375rem 0.5rem;
  opacity: 1;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.25rem;
  padding-left: 1.5rem;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
}

.custom-control-inline {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.125rem;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: transparent;
  background-color: transparent;
}
.custom-control-input:focus ~ .custom-control-label::before {
  -webkit-box-shadow: inset 0 0 0 2px #000;
  box-shadow: inset 0 0 0 2px #000;
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #6e7575;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #878e8e;
  border-color: #878e8e;
}
.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: rgba(0, 0, 0, 0.4);
}
.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: rgba(110, 119, 122, 0.15);
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.125rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: rgba(110, 119, 122, 0.15);
  border: #adb5bd solid 0rem;
}
.custom-control-label::after {
  position: absolute;
  top: 0.125rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: no-repeat 50%/100%;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=UTF-8, %3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='3 3 18 18' fill='%23000'%3e%3cpath d='M0 0h24v24H0V0z' fill='none'/%3e%3cpath d='M21 3H3v18h18V3zM10 17l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: rgba(110, 119, 122, 0.15);
  background-color: rgba(110, 119, 122, 0.15);
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=UTF-8, %3csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='16' height='16' viewBox='3 3 18 18' fill='%23000'%3e%3cdefs%3e%3cpath id='a' d='M0 0h24v24H0z'/%3e%3c/defs%3e%3cclipPath id='b'%3e%3cuse xlink:href='%23a' overflow='visible'/%3e%3c/clipPath%3e%3cpath clip-path='url%28%23b%29' d='M21 3H3v18h18V3zm-4 10H7v-2h10v2z'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: transparent;
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: transparent;
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=UTF-8, %3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='2 2 20 20' fill='%23000'%3e%3cpath d='M12 7c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5zm0-5C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z'/%3e%3cpath d='M0 0h24v24H0z' fill='none'/%3e%3c/svg%3e");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: transparent;
}

.custom-switch {
  padding-left: 3rem;
}
.custom-switch .custom-control-label::before {
  left: -3rem;
  width: 2.5rem;
  pointer-events: all;
  border-radius: 0;
}
.custom-switch .custom-control-label::after {
  top: 0.125rem;
  left: -3rem;
  width: 10px;
  height: 10px;
  background-color: #adb5bd;
  border-radius: 0;
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    -webkit-transition: none;
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: rgba(110, 119, 122, 0.15);
  -webkit-transform: translateX(1.5rem);
  transform: translateX(1.5rem);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: transparent;
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.4285714286em + 0.75rem);
  padding: 0.375rem 1.625rem 0.375rem 0.625rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.4285714286;
  color: #000;
  vertical-align: middle;
  background: rgba(110, 119, 122, 0.15) url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.625rem center/8px 10px;
  border: 0rem solid transparent;
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.custom-select:focus {
  border-color: #6e7575;
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(48, 51, 51, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(48, 51, 51, 0.25);
}
.custom-select:focus::-ms-value {
  color: #000;
  background-color: rgba(110, 119, 122, 0.15);
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.625rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

.custom-select-sm {
  height: calc( 1.125rem + 0.125rem + 0rem );
  padding-top: 0rem;
  padding-bottom: 0rem;
  padding-left: 0.5rem;
  font-size: 0.75rem;
}

.custom-select-lg {
  height: calc( 2.5714285714rem + 0.125rem + 0rem );
  padding-top: 0.5625rem;
  padding-bottom: 0.5625rem;
  padding-left: 0.875rem;
  font-size: 1rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.4285714286em + 0.75rem);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.4285714286em + 0.75rem);
  margin: 0;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #6e7575;
  -webkit-box-shadow: inset 0 0 0 2px #000;
  box-shadow: inset 0 0 0 2px #000;
}
.custom-file-input[disabled] ~ .custom-file-label, .custom-file-input:disabled ~ .custom-file-label {
  background-color: transparent;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.4285714286em + 0.75rem);
  padding: 0.375rem 0.625rem;
  font-weight: 400;
  line-height: 1.4285714286;
  color: #000;
  background-color: rgba(110, 119, 122, 0.15);
  border: 0rem solid transparent;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.4285714286em + 0.75rem);
  padding: 0.375rem 0.625rem;
  line-height: 1.4285714286;
  color: #000;
  content: "Browse";
  background-color: rgba(110, 119, 122, 0.2);
  border-left: inherit;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.custom-range:focus {
  outline: none;
}
.custom-range:focus::-webkit-slider-thumb {
  -webkit-box-shadow: 0 0 0 1px #fff, inset 0 0 0 2px #000;
  box-shadow: 0 0 0 1px #fff, inset 0 0 0 2px #000;
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, inset 0 0 0 2px #000;
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, inset 0 0 0 2px #000;
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #303333;
  border: 0;
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #878e8e;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #303333;
  border: 0;
  -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -moz-appearance: none;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #878e8e;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #303333;
  border: 0;
  -ms-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    -ms-transition: none;
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #878e8e;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
.custom-file-label,
.custom-select {
    -webkit-transition: none;
    transition: none;
  }
}

.custom-checkbox .custom-control-label {
  cursor: pointer;
}
.custom-checkbox .custom-control-label::before {
  background-image: url("data:image/svg+xml;charset=UTF-8, %3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='3 3 18 18' fill='%236e777a'%3e%3cpath d='M19 5v14H5V5h14m2-2H3v18h18V3z'/%3e%3cpath d='M0 0h24v24H0z' fill='none'/%3e%3c/svg%3e");
  background-color: transparent;
}
.custom-checkbox .custom-control-label::after {
  cursor: pointer;
}

.custom-radio .custom-control-label::after {
  cursor: pointer;
}
.custom-radio .custom-control-label::before {
  background-image: url("data:image/svg+xml;charset=UTF-8, %3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='2 2 20 20' fill='%236e777a'%3e%3cpath d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z'/%3e%3cpath d='M0 0h24v24H0z' fill='none'/%3e%3c/svg%3e");
}

.disabled .custom-control-label::before, .disabled .custom-control-label::after,
:disabled .custom-control-label::before,
:disabled .custom-control-label::after {
  cursor: default;
}

.custom-control .custom-control-input:disabled ~ .custom-control-label {
  cursor: default;
}
.custom-control .custom-control-input:disabled ~ .custom-control-label::before, .custom-control .custom-control-input:disabled ~ .custom-control-label::after {
  opacity: 0.2;
  cursor: default;
}
.custom-control.custom-control-primary.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=UTF-8, %3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='3 3 18 18' fill='%23000'%3e%3cpath d='M0 0h24v24H0z' fill='none'/%3e%3cpath d='M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z'/%3e%3c/svg%3e");
}
.custom-control.custom-control-primary.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=UTF-8, %3csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='16' height='16' viewBox='3 3 18 18' fill='%23000'%3e%3cdefs%3e%3cpath id='a' d='M0 0h24v24H0z'/%3e%3c/defs%3e%3cclipPath id='b'%3e%3cuse xlink:href='%23a' overflow='visible'/%3e%3c/clipPath%3e%3cpath clip-path='url(%23b)' d='M21 3H3v18h18V3zm-4 10H7v-2h10v2z'/%3e%3c/svg%3e");
}
.custom-control.custom-control-primary.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=UTF-8, %3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='2 2 20 20' fill='%23000'%3e%3cpath d='M12 7c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5zm0-5C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z'/%3e%3cpath d='M0 0h24v24H0z' fill='none'/%3e%3c/svg%3e");
}
.custom-control.custom-control-secondary.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=UTF-8, %3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='3 3 18 18' fill='%236e777a'%3e%3cpath d='M0 0h24v24H0z' fill='none'/%3e%3cpath d='M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z'/%3e%3c/svg%3e");
}
.custom-control.custom-control-secondary.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=UTF-8, %3csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='16' height='16' viewBox='3 3 18 18' fill='%236e777a'%3e%3cdefs%3e%3cpath id='a' d='M0 0h24v24H0z'/%3e%3c/defs%3e%3cclipPath id='b'%3e%3cuse xlink:href='%23a' overflow='visible'/%3e%3c/clipPath%3e%3cpath clip-path='url(%23b)' d='M21 3H3v18h18V3zm-4 10H7v-2h10v2z'/%3e%3c/svg%3e");
}
.custom-control.custom-control-secondary.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=UTF-8, %3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='2 2 20 20' fill='%236e777a'%3e%3cpath d='M12 7c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5zm0-5C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z'/%3e%3cpath d='M0 0h24v24H0z' fill='none'/%3e%3c/svg%3e");
}
.custom-control.custom-control-success.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=UTF-8, %3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='3 3 18 18' fill='%2300aa0c'%3e%3cpath d='M0 0h24v24H0z' fill='none'/%3e%3cpath d='M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z'/%3e%3c/svg%3e");
}
.custom-control.custom-control-success.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=UTF-8, %3csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='16' height='16' viewBox='3 3 18 18' fill='%2300aa0c'%3e%3cdefs%3e%3cpath id='a' d='M0 0h24v24H0z'/%3e%3c/defs%3e%3cclipPath id='b'%3e%3cuse xlink:href='%23a' overflow='visible'/%3e%3c/clipPath%3e%3cpath clip-path='url(%23b)' d='M21 3H3v18h18V3zm-4 10H7v-2h10v2z'/%3e%3c/svg%3e");
}
.custom-control.custom-control-success.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=UTF-8, %3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='2 2 20 20' fill='%2300aa0c'%3e%3cpath d='M12 7c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5zm0-5C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z'/%3e%3cpath d='M0 0h24v24H0z' fill='none'/%3e%3c/svg%3e");
}
.custom-control.custom-control-info.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=UTF-8, %3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='3 3 18 18' fill='%232469ff'%3e%3cpath d='M0 0h24v24H0z' fill='none'/%3e%3cpath d='M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z'/%3e%3c/svg%3e");
}
.custom-control.custom-control-info.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=UTF-8, %3csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='16' height='16' viewBox='3 3 18 18' fill='%232469ff'%3e%3cdefs%3e%3cpath id='a' d='M0 0h24v24H0z'/%3e%3c/defs%3e%3cclipPath id='b'%3e%3cuse xlink:href='%23a' overflow='visible'/%3e%3c/clipPath%3e%3cpath clip-path='url(%23b)' d='M21 3H3v18h18V3zm-4 10H7v-2h10v2z'/%3e%3c/svg%3e");
}
.custom-control.custom-control-info.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=UTF-8, %3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='2 2 20 20' fill='%232469ff'%3e%3cpath d='M12 7c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5zm0-5C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z'/%3e%3cpath d='M0 0h24v24H0z' fill='none'/%3e%3c/svg%3e");
}
.custom-control.custom-control-warning.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=UTF-8, %3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='3 3 18 18' fill='%23da7400'%3e%3cpath d='M0 0h24v24H0z' fill='none'/%3e%3cpath d='M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z'/%3e%3c/svg%3e");
}
.custom-control.custom-control-warning.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=UTF-8, %3csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='16' height='16' viewBox='3 3 18 18' fill='%23da7400'%3e%3cdefs%3e%3cpath id='a' d='M0 0h24v24H0z'/%3e%3c/defs%3e%3cclipPath id='b'%3e%3cuse xlink:href='%23a' overflow='visible'/%3e%3c/clipPath%3e%3cpath clip-path='url(%23b)' d='M21 3H3v18h18V3zm-4 10H7v-2h10v2z'/%3e%3c/svg%3e");
}
.custom-control.custom-control-warning.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=UTF-8, %3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='2 2 20 20' fill='%23da7400'%3e%3cpath d='M12 7c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5zm0-5C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z'/%3e%3cpath d='M0 0h24v24H0z' fill='none'/%3e%3c/svg%3e");
}
.custom-control.custom-control-danger.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=UTF-8, %3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='3 3 18 18' fill='%23dd3724'%3e%3cpath d='M0 0h24v24H0z' fill='none'/%3e%3cpath d='M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z'/%3e%3c/svg%3e");
}
.custom-control.custom-control-danger.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=UTF-8, %3csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='16' height='16' viewBox='3 3 18 18' fill='%23dd3724'%3e%3cdefs%3e%3cpath id='a' d='M0 0h24v24H0z'/%3e%3c/defs%3e%3cclipPath id='b'%3e%3cuse xlink:href='%23a' overflow='visible'/%3e%3c/clipPath%3e%3cpath clip-path='url(%23b)' d='M21 3H3v18h18V3zm-4 10H7v-2h10v2z'/%3e%3c/svg%3e");
}
.custom-control.custom-control-danger.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=UTF-8, %3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='2 2 20 20' fill='%23dd3724'%3e%3cpath d='M12 7c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5zm0-5C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z'/%3e%3cpath d='M0 0h24v24H0z' fill='none'/%3e%3c/svg%3e");
}
.custom-control.custom-control-light.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=UTF-8, %3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='3 3 18 18' fill='%23c4c8ca'%3e%3cpath d='M0 0h24v24H0z' fill='none'/%3e%3cpath d='M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z'/%3e%3c/svg%3e");
}
.custom-control.custom-control-light.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=UTF-8, %3csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='16' height='16' viewBox='3 3 18 18' fill='%23c4c8ca'%3e%3cdefs%3e%3cpath id='a' d='M0 0h24v24H0z'/%3e%3c/defs%3e%3cclipPath id='b'%3e%3cuse xlink:href='%23a' overflow='visible'/%3e%3c/clipPath%3e%3cpath clip-path='url(%23b)' d='M21 3H3v18h18V3zm-4 10H7v-2h10v2z'/%3e%3c/svg%3e");
}
.custom-control.custom-control-light.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=UTF-8, %3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='2 2 20 20' fill='%23c4c8ca'%3e%3cpath d='M12 7c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5zm0-5C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z'/%3e%3cpath d='M0 0h24v24H0z' fill='none'/%3e%3c/svg%3e");
}
.custom-control.custom-control-dark.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=UTF-8, %3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='3 3 18 18' fill='%23414949'%3e%3cpath d='M0 0h24v24H0z' fill='none'/%3e%3cpath d='M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z'/%3e%3c/svg%3e");
}
.custom-control.custom-control-dark.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=UTF-8, %3csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='16' height='16' viewBox='3 3 18 18' fill='%23414949'%3e%3cdefs%3e%3cpath id='a' d='M0 0h24v24H0z'/%3e%3c/defs%3e%3cclipPath id='b'%3e%3cuse xlink:href='%23a' overflow='visible'/%3e%3c/clipPath%3e%3cpath clip-path='url(%23b)' d='M21 3H3v18h18V3zm-4 10H7v-2h10v2z'/%3e%3c/svg%3e");
}
.custom-control.custom-control-dark.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=UTF-8, %3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='2 2 20 20' fill='%23414949'%3e%3cpath d='M12 7c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5zm0-5C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z'/%3e%3cpath d='M0 0h24v24H0z' fill='none'/%3e%3c/svg%3e");
}
.custom-control.custom-control-white.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=UTF-8, %3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='3 3 18 18' fill='white'%3e%3cpath d='M0 0h24v24H0z' fill='none'/%3e%3cpath d='M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z'/%3e%3c/svg%3e");
}
.custom-control.custom-control-white.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=UTF-8, %3csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='16' height='16' viewBox='3 3 18 18' fill='white'%3e%3cdefs%3e%3cpath id='a' d='M0 0h24v24H0z'/%3e%3c/defs%3e%3cclipPath id='b'%3e%3cuse xlink:href='%23a' overflow='visible'/%3e%3c/clipPath%3e%3cpath clip-path='url(%23b)' d='M21 3H3v18h18V3zm-4 10H7v-2h10v2z'/%3e%3c/svg%3e");
}
.custom-control.custom-control-white.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=UTF-8, %3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='2 2 20 20' fill='white'%3e%3cpath d='M12 7c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5zm0-5C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z'/%3e%3cpath d='M0 0h24v24H0z' fill='none'/%3e%3c/svg%3e");
}
.custom-control.custom-control-black.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=UTF-8, %3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='3 3 18 18' fill='%23231f20'%3e%3cpath d='M0 0h24v24H0z' fill='none'/%3e%3cpath d='M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z'/%3e%3c/svg%3e");
}
.custom-control.custom-control-black.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=UTF-8, %3csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='16' height='16' viewBox='3 3 18 18' fill='%23231f20'%3e%3cdefs%3e%3cpath id='a' d='M0 0h24v24H0z'/%3e%3c/defs%3e%3cclipPath id='b'%3e%3cuse xlink:href='%23a' overflow='visible'/%3e%3c/clipPath%3e%3cpath clip-path='url(%23b)' d='M21 3H3v18h18V3zm-4 10H7v-2h10v2z'/%3e%3c/svg%3e");
}
.custom-control.custom-control-black.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=UTF-8, %3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='2 2 20 20' fill='%23231f20'%3e%3cpath d='M12 7c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5zm0-5C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z'/%3e%3cpath d='M0 0h24v24H0z' fill='none'/%3e%3c/svg%3e");
}
.custom-control.custom-control-socgen.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=UTF-8, %3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='3 3 18 18' fill='%23e60028'%3e%3cpath d='M0 0h24v24H0z' fill='none'/%3e%3cpath d='M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z'/%3e%3c/svg%3e");
}
.custom-control.custom-control-socgen.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=UTF-8, %3csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='16' height='16' viewBox='3 3 18 18' fill='%23e60028'%3e%3cdefs%3e%3cpath id='a' d='M0 0h24v24H0z'/%3e%3c/defs%3e%3cclipPath id='b'%3e%3cuse xlink:href='%23a' overflow='visible'/%3e%3c/clipPath%3e%3cpath clip-path='url(%23b)' d='M21 3H3v18h18V3zm-4 10H7v-2h10v2z'/%3e%3c/svg%3e");
}
.custom-control.custom-control-socgen.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=UTF-8, %3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='2 2 20 20' fill='%23e60028'%3e%3cpath d='M12 7c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5zm0-5C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z'/%3e%3cpath d='M0 0h24v24H0z' fill='none'/%3e%3c/svg%3e");
}
.custom-control.custom-control-primary-alt.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=UTF-8, %3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='3 3 18 18' fill='%23fff'%3e%3cpath d='M0 0h24v24H0z' fill='none'/%3e%3cpath d='M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z'/%3e%3c/svg%3e");
}
.custom-control.custom-control-primary-alt.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=UTF-8, %3csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='16' height='16' viewBox='3 3 18 18' fill='%23fff'%3e%3cdefs%3e%3cpath id='a' d='M0 0h24v24H0z'/%3e%3c/defs%3e%3cclipPath id='b'%3e%3cuse xlink:href='%23a' overflow='visible'/%3e%3c/clipPath%3e%3cpath clip-path='url(%23b)' d='M21 3H3v18h18V3zm-4 10H7v-2h10v2z'/%3e%3c/svg%3e");
}
.custom-control.custom-control-primary-alt.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=UTF-8, %3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='2 2 20 20' fill='%23fff'%3e%3cpath d='M12 7c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5zm0-5C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z'/%3e%3cpath d='M0 0h24v24H0z' fill='none'/%3e%3c/svg%3e");
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  -webkit-transform: translateX(1.125rem);
  transform: translateX(1.125rem);
}
.custom-switch .custom-control-input ~ .custom-control-label,
.custom-switch .custom-control-input:checked ~ .custom-control-label,
.custom-switch .custom-control-input:focus ~ .custom-control-label,
.custom-switch .custom-control-input:active ~ .custom-control-label {
  white-space: nowrap;
}
.custom-switch .custom-control-input ~ .custom-control-label::before,
.custom-switch .custom-control-input:checked ~ .custom-control-label::before,
.custom-switch .custom-control-input:focus ~ .custom-control-label::before,
.custom-switch .custom-control-input:active ~ .custom-control-label::before {
  height: calc( 10px + 2 * 6px );
  top: -2px;
  border: 2px solid #c4c8ca;
  cursor: pointer;
}
.custom-switch .custom-control-input ~ .custom-control-label::after,
.custom-switch .custom-control-input:checked ~ .custom-control-label::after,
.custom-switch .custom-control-input:focus ~ .custom-control-label::after,
.custom-switch .custom-control-input:active ~ .custom-control-label::after {
  width: 10px;
  height: 10px;
  margin-left: 6px;
  top: calc(10px - 6px);
  -webkit-transition: background-color 90ms, -webkit-transform 150ms cubic-bezier(0.87, -0.41, 0.19, 1.44);
  transition: background-color 90ms, -webkit-transform 150ms cubic-bezier(0.87, -0.41, 0.19, 1.44);
  transition: transform 150ms cubic-bezier(0.87, -0.41, 0.19, 1.44), background-color 90ms;
  transition: transform 150ms cubic-bezier(0.87, -0.41, 0.19, 1.44), background-color 90ms, -webkit-transform 150ms cubic-bezier(0.87, -0.41, 0.19, 1.44);
  cursor: pointer;
}
.custom-switch .custom-control-input:not(:disabled) + .custom-control-label::before,
.custom-switch .custom-control-input:checked:not(:disabled) + .custom-control-label::before,
.custom-switch .custom-control-input:focus:not(:disabled) + .custom-control-label::before,
.custom-switch .custom-control-input:active:not(:disabled) + .custom-control-label::before {
  background: transparent;
}
.custom-switch .custom-control-input:not(:disabled) + .custom-control-label::after,
.custom-switch .custom-control-input:checked:not(:disabled) + .custom-control-label::after,
.custom-switch .custom-control-input:focus:not(:disabled) + .custom-control-label::after,
.custom-switch .custom-control-input:active:not(:disabled) + .custom-control-label::after {
  background: #6e777a;
}
.custom-switch .custom-control-input:disabled ~ .custom-control-label::before {
  background: transparent;
}
.custom-switch .custom-control-input:disabled ~ .custom-control-label::after {
  background: #6e777a;
}
.custom-switch .custom-control-input:disabled ~ .custom-control-label::before, .custom-switch .custom-control-input:disabled ~ .custom-control-label::after {
  opacity: 0.4;
}
.custom-switch .custom-control-input:checked:not(:disabled) ~ .custom-control-label::before {
  border-color: #000;
}
.custom-switch.sgbs-switch-colored .custom-control-input:checked ~ .custom-control-label::before {
  border-color: rgba(36, 105, 255, 0.7);
}
.custom-switch.sgbs-switch-colored .custom-control-input:checked ~ .custom-control-label::after {
  background: #2469ff;
}

.custom-switch .custom-control-input:focus:not(:disabled) + .custom-control-label::before {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(48, 51, 51, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(48, 51, 51, 0.25);
}
.custom-switch .custom-control-input:checked:not(:disabled) + .custom-control-label::before {
  border-color: rgba(0, 0, 0, 0.7);
}
.custom-switch .custom-control-input:checked:not(:disabled) + .custom-control-label::after {
  background: #000;
}
.custom-switch.sgbs-switch-colored .custom-control-input:checked ~ .custom-control-label::before {
  border-color: rgba(36, 105, 255, 0.7);
}
.custom-switch.sgbs-switch-colored .custom-control-input:checked ~ .custom-control-label::after {
  background: #2469ff;
}

.nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: rgba(110, 119, 122, 0.4);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid rgba(196, 200, 202, 0.7);
}
.nav-tabs .nav-item {
  margin-bottom: -1px;
}
.nav-tabs .nav-link {
  border: 1px solid transparent;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: none;
}
.nav-tabs .nav-link.disabled {
  color: rgba(110, 119, 122, 0.4);
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #000;
  background-color: #fff;
  border-color: rgba(196, 200, 202, 0.7) rgba(196, 200, 202, 0.7) #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #000;
  background-color: transparent;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.nav-link {
  line-height: 1.5rem;
  font-weight: 500;
}
.nav-link.active {
  color: #000;
  font-weight: 600;
}
.nav-link:hover, .nav-link:focus {
  color: #000;
}
.nav-link:disabled, .nav-link.disabled {
  cursor: default;
  pointer-events: none;
  color: rgba(110, 119, 122, 0.4);
}

.nav-tabs.border-primary .nav-link.active,
.nav-tabs.border-primary .nav-item.show .nav-link {
  border-color: #000 #000 #fff;
}
.nav-tabs.border-secondary .nav-link.active,
.nav-tabs.border-secondary .nav-item.show .nav-link {
  border-color: #6e777a #6e777a #fff;
}
.nav-tabs.border-success .nav-link.active,
.nav-tabs.border-success .nav-item.show .nav-link {
  border-color: #00aa0c #00aa0c #fff;
}
.nav-tabs.border-info .nav-link.active,
.nav-tabs.border-info .nav-item.show .nav-link {
  border-color: #2469ff #2469ff #fff;
}
.nav-tabs.border-warning .nav-link.active,
.nav-tabs.border-warning .nav-item.show .nav-link {
  border-color: #da7400 #da7400 #fff;
}
.nav-tabs.border-danger .nav-link.active,
.nav-tabs.border-danger .nav-item.show .nav-link {
  border-color: #dd3724 #dd3724 #fff;
}
.nav-tabs.border-light .nav-link.active,
.nav-tabs.border-light .nav-item.show .nav-link {
  border-color: #c4c8ca #c4c8ca #fff;
}
.nav-tabs.border-dark .nav-link.active,
.nav-tabs.border-dark .nav-item.show .nav-link {
  border-color: #414949 #414949 #fff;
}
.nav-tabs.border-white .nav-link.active,
.nav-tabs.border-white .nav-item.show .nav-link {
  border-color: white white #fff;
}
.nav-tabs.border-black .nav-link.active,
.nav-tabs.border-black .nav-item.show .nav-link {
  border-color: #231f20 #231f20 #fff;
}
.nav-tabs.border-socgen .nav-link.active,
.nav-tabs.border-socgen .nav-item.show .nav-link {
  border-color: #e60028 #e60028 #fff;
}
.nav-tabs.border-primary-alt .nav-link.active,
.nav-tabs.border-primary-alt .nav-item.show .nav-link {
  border-color: #fff #fff #fff;
}

.nav-pills .nav-link {
  padding: 0.5rem 1rem calc(0.5rem - 3px);
  border-bottom: 2px solid transparent;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  border-bottom: 3px solid #000;
  margin-bottom: -1px;
}

.nav-compact-pills .nav-link {
  padding: 0.5rem 1rem calc(0.5rem - 3px);
  border-bottom: 2px solid transparent;
}
.nav-compact-pills .nav-link.active {
  padding-left: 0;
  padding-right: 0;
  margin: 0 1rem;
}
.nav-compact-pills .nav-link.active,
.nav-compact-pills .show > .nav-link {
  border-bottom: 3px solid #000;
  margin-bottom: -1px;
}

.nav-vertical-pills {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.nav-vertical-pills .nav-link {
  padding-left: 1.5rem;
}
.nav-vertical-pills .nav-link.active,
.nav-vertical-pills .show > .nav-link {
  border-left: 3px solid #000;
  padding-left: calc( 1.5rem - 3px );
}
.nav-vertical-pills .nav-vertical-pills {
  border-left: 1px solid rgba(196, 200, 202, 0.7);
  margin: 0.5rem 0 0.5rem 1.5rem;
}
.nav-vertical-pills .nav-vertical-pills .nav-link.active {
  background-color: transparent !important;
  border-left: none;
  color: #2469ff;
  font-weight: 600;
  padding-left: calc(1.5rem - 3px);
}

.nav-hover .nav-link:hover, .nav-hover .nav-link:focus {
  background-color: rgba(110, 119, 122, 0.05);
}
.nav-hover .nav-link:not([disabled]):not(.disabled).active, .nav-hover .nav-link:not([disabled]):not(.disabled):active {
  background-color: rgba(110, 119, 122, 0.1);
}
.nav-compact-pills.nav-hover .nav-link:not([disabled]):not(.disabled).active {
  position: relative;
}
.nav-compact-pills.nav-hover .nav-link:not([disabled]):not(.disabled).active::before, .nav-compact-pills.nav-hover .nav-link:not([disabled]):not(.disabled).active::after {
  content: "";
  width: 1rem;
  position: absolute;
  top: 0;
  bottom: -3px;
  background-color: rgba(110, 119, 122, 0.1);
}
.nav-compact-pills.nav-hover .nav-link:not([disabled]):not(.disabled).active::before {
  left: -1rem;
}
.nav-compact-pills.nav-hover .nav-link:not([disabled]):not(.disabled).active::after {
  right: -1rem;
}

.navbar {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem 1.5rem;
}
.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl,
.navbar .container-xxl {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0;
  padding-bottom: 0;
  margin-right: 1.5rem;
  font-size: 1.125rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.navbar-toggler {
  padding: 0 1rem;
  font-size: 1.125rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
}
.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid,
.navbar-expand-sm > .container-sm,
.navbar-expand-sm > .container-md,
.navbar-expand-sm > .container-lg,
.navbar-expand-sm > .container-xl,
.navbar-expand-sm > .container-xxl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid,
.navbar-expand-sm > .container-sm,
.navbar-expand-sm > .container-md,
.navbar-expand-sm > .container-lg,
.navbar-expand-sm > .container-xl,
.navbar-expand-sm > .container-xxl {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid,
.navbar-expand-md > .container-sm,
.navbar-expand-md > .container-md,
.navbar-expand-md > .container-lg,
.navbar-expand-md > .container-xl,
.navbar-expand-md > .container-xxl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid,
.navbar-expand-md > .container-sm,
.navbar-expand-md > .container-md,
.navbar-expand-md > .container-lg,
.navbar-expand-md > .container-xl,
.navbar-expand-md > .container-xxl {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid,
.navbar-expand-lg > .container-sm,
.navbar-expand-lg > .container-md,
.navbar-expand-lg > .container-lg,
.navbar-expand-lg > .container-xl,
.navbar-expand-lg > .container-xxl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid,
.navbar-expand-lg > .container-sm,
.navbar-expand-lg > .container-md,
.navbar-expand-lg > .container-lg,
.navbar-expand-lg > .container-xl,
.navbar-expand-lg > .container-xxl {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid,
.navbar-expand-xl > .container-sm,
.navbar-expand-xl > .container-md,
.navbar-expand-xl > .container-lg,
.navbar-expand-xl > .container-xl,
.navbar-expand-xl > .container-xxl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid,
.navbar-expand-xl > .container-sm,
.navbar-expand-xl > .container-md,
.navbar-expand-xl > .container-lg,
.navbar-expand-xl > .container-xl,
.navbar-expand-xl > .container-xxl {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1599.98px) {
  .navbar-expand-xxl > .container,
.navbar-expand-xxl > .container-fluid,
.navbar-expand-xxl > .container-sm,
.navbar-expand-xxl > .container-md,
.navbar-expand-xxl > .container-lg,
.navbar-expand-xxl > .container-xl,
.navbar-expand-xxl > .container-xxl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1600px) {
  .navbar-expand-xxl {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xxl > .container,
.navbar-expand-xxl > .container-fluid,
.navbar-expand-xxl > .container-sm,
.navbar-expand-xxl > .container-md,
.navbar-expand-xxl > .container-lg,
.navbar-expand-xxl > .container-xl,
.navbar-expand-xxl > .container-xxl {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl,
.navbar-expand > .container-xxl {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl,
.navbar-expand > .container-xxl {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.navbar-expand .navbar-collapse {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: #e60028;
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: #e60028;
}
.navbar-light .navbar-nav .nav-link {
  color: #303333;
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: #e60028;
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(110, 119, 122, 0.4);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: #e60028;
}
.navbar-light .navbar-toggler {
  color: #303333;
  border-color: transparent;
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='%23303333' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: #303333;
}
.navbar-light .navbar-text a {
  color: #e60028;
}
.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: #e60028;
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #fff;
}
.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.navbar {
  padding: 0.5rem;
}
@media (min-width: 992px) {
  .navbar {
    padding: 1rem 1.5rem;
  }
}

.navbar-nav .nav-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #000;
  font-weight: 600;
}
.navbar-nav .nav-link:hover {
  color: #e60028;
}

.navbar-nav .active .nav-link,
.navbar-nav .nav-link.active {
  color: #e60028;
}

.navbar .sgwt-account-center .btn-flat-black {
  color: #000;
}
.navbar .sgwt-account-center .btn-flat-black:hover {
  color: #000 !important;
  background: rgba(0, 0, 0, 0.1);
}
.navbar .sgwt-account-center .btn-flat-black:focus, .navbar .sgwt-account-center .btn-flat-black.focus {
  color: #000 !important;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.navbar .sgwt-account-center .btn-flat-black:not([disabled]):not(.disabled).active, .navbar .sgwt-account-center .btn-flat-black:not([disabled]):not(.disabled):active {
  background-color: rgba(0, 0, 0, 0.2);
}

.navbar div.sgwt-account-center-user-info > a:hover {
  color: #000 !important;
}

.sgwt-account-center-user {
  color: #000;
  font-weight: 500;
}

.navbar .d-flex:nth-child(2) > svg path,
.navbar .d-flex:nth-child(2) > svg text {
  fill: #000;
}

.navbar-toggler {
  font-weight: 500;
  min-height: 40px;
  color: #000;
}

.navbar-brand {
  margin-right: 0;
}
.navbar-brand svg {
  border: 1px solid transparent;
}
@media (min-width: 1200px) {
  .navbar-brand {
    margin-right: 1.5rem;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm .navbar-nav .nav-link {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: start;
    padding-right: 1rem;
    padding-left: 1rem;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md .navbar-nav .nav-link {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: start;
    padding-right: 1rem;
    padding-left: 1rem;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: start;
    padding-right: 1rem;
    padding-left: 1rem;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl .navbar-nav .nav-link {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: start;
    padding-right: 1rem;
    padding-left: 1rem;
  }
}
@media (min-width: 1600px) {
  .navbar-expand-xxl .navbar-nav .nav-link {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: start;
    padding-right: 1rem;
    padding-left: 1rem;
  }
}
.navbar-expand .navbar-nav .nav-link {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: start;
  padding-right: 1rem;
  padding-left: 1rem;
}

.navbar-light {
  color: #303333;
}
.navbar-light .navbar-brand {
  color: #303333;
}

.sgbs-navbar-interactive {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 30px 32px;
  background: transparent;
  -webkit-transition: padding 200ms linear, background 200ms linear;
  transition: padding 200ms linear, background 200ms linear;
  z-index: 999999;
}
.sgbs-navbar-interactive.sgbs-navbar-interactive-animated {
  padding: 1rem 1.5rem;
  background: #fff;
}

.container > .navbar,
.navbar.container {
  padding-left: 12px;
  padding-right: 12px;
}

.card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid rgba(196, 200, 202, 0.7);
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
}
.card > .list-group:last-child {
  border-bottom-width: 0;
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1rem;
}

.card-title {
  margin-bottom: 1rem;
}

.card-subtitle {
  margin-top: -0.5rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1rem;
}

.card-header {
  padding: 1rem 1rem;
  margin-bottom: 0;
  background-color: transparent;
  border-bottom: 0 solid rgba(196, 200, 202, 0.7);
}
.card-footer {
  padding: 1rem 1rem;
  background-color: transparent;
  border-top: 0 solid rgba(196, 200, 202, 0.7);
}
.card-header-tabs {
  margin-right: -0.5rem;
  margin-bottom: -1rem;
  margin-left: -0.5rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img,
.card-img-top,
.card-img-bottom {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 100%;
}

.card-deck .card {
  margin-bottom: 12px;
}
@media (min-width: 576px) {
  .card-deck {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    margin-right: -12px;
    margin-left: -12px;
  }
  .card-deck .card {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    margin-right: 12px;
    margin-bottom: 0;
    margin-left: 12px;
  }
}

.card-group > .card {
  margin-bottom: 12px;
}
@media (min-width: 576px) {
  .card-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
  }
  .card-group > .card {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
}

.card-columns .card {
  margin-bottom: 1rem;
}
@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}
.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
}
.accordion > .card > .card-header {
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .card-body {
    padding: 1.5rem;
  }

  .card-header {
    padding: 1.5rem;
  }

  .card-footer {
    padding: 1.5rem;
  }
}
@media (min-width: 1600px) {
  .card-body {
    padding: 2rem;
  }

  .card-header {
    padding: 2rem;
  }

  .card-footer {
    padding: 2rem;
  }
}
.card-header + .card-body {
  padding-top: 0;
}

.card-body + .card-footer {
  padding-top: 0;
}

.card.card-primary {
  color: #000;
  border-width: 0;
  will-change: background-color;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
.card.card-primary.card-bordered::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border: 0.0625rem solid #000;
  z-index: 1;
  pointer-events: none;
}
.card.card-primary.card-raised {
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 2px 1px -1px rgba(0, 0, 0, 0.06);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 2px 1px -1px rgba(0, 0, 0, 0.06);
}
.card.card-primary.card-filling:hover {
  color: #000;
  background-color: rgba(0, 0, 0, 0.08);
}
.card.card-primary.card-filling:not(.disabled):not(:disabled):active, .card.card-primary.card-filling:not(.disabled):not(:disabled).active {
  color: #000;
  background-color: rgba(0, 0, 0, 0.15);
}
.card.card-primary.card-bordering:hover {
  color: #000;
}
.card.card-primary.card-bordering:hover::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border: 0.0625rem solid #000;
  z-index: 1;
  pointer-events: none;
}
.card.card-primary.card-bordering:not(.disabled):not(:disabled):active, .card.card-primary.card-bordering:not(.disabled):not(:disabled).active {
  color: #000;
}
.card.card-primary.card-bordering:not(.disabled):not(:disabled):active::before, .card.card-primary.card-bordering:not(.disabled):not(:disabled).active::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border: 0.125rem solid #000;
  z-index: 1;
  pointer-events: none;
}

.card.card-secondary {
  color: #6e777a;
  border-width: 0;
  will-change: background-color;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
.card.card-secondary.card-bordered::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border: 0.0625rem solid #6e777a;
  z-index: 1;
  pointer-events: none;
}
.card.card-secondary.card-raised {
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 2px 1px -1px rgba(0, 0, 0, 0.06);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 2px 1px -1px rgba(0, 0, 0, 0.06);
}
.card.card-secondary.card-filling:hover {
  color: #6e777a;
  background-color: rgba(110, 119, 122, 0.08);
}
.card.card-secondary.card-filling:not(.disabled):not(:disabled):active, .card.card-secondary.card-filling:not(.disabled):not(:disabled).active {
  color: #6e777a;
  background-color: rgba(110, 119, 122, 0.15);
}
.card.card-secondary.card-bordering:hover {
  color: #6e777a;
}
.card.card-secondary.card-bordering:hover::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border: 0.0625rem solid #6e777a;
  z-index: 1;
  pointer-events: none;
}
.card.card-secondary.card-bordering:not(.disabled):not(:disabled):active, .card.card-secondary.card-bordering:not(.disabled):not(:disabled).active {
  color: #6e777a;
}
.card.card-secondary.card-bordering:not(.disabled):not(:disabled):active::before, .card.card-secondary.card-bordering:not(.disabled):not(:disabled).active::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border: 0.125rem solid #6e777a;
  z-index: 1;
  pointer-events: none;
}

.card.card-success {
  color: #00aa0c;
  border-width: 0;
  will-change: background-color;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
.card.card-success.card-bordered::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border: 0.0625rem solid #00aa0c;
  z-index: 1;
  pointer-events: none;
}
.card.card-success.card-raised {
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 2px 1px -1px rgba(0, 0, 0, 0.06);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 2px 1px -1px rgba(0, 0, 0, 0.06);
}
.card.card-success.card-filling:hover {
  color: #00aa0c;
  background-color: rgba(0, 170, 12, 0.08);
}
.card.card-success.card-filling:not(.disabled):not(:disabled):active, .card.card-success.card-filling:not(.disabled):not(:disabled).active {
  color: #00aa0c;
  background-color: rgba(0, 170, 12, 0.15);
}
.card.card-success.card-bordering:hover {
  color: #00aa0c;
}
.card.card-success.card-bordering:hover::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border: 0.0625rem solid #00aa0c;
  z-index: 1;
  pointer-events: none;
}
.card.card-success.card-bordering:not(.disabled):not(:disabled):active, .card.card-success.card-bordering:not(.disabled):not(:disabled).active {
  color: #00aa0c;
}
.card.card-success.card-bordering:not(.disabled):not(:disabled):active::before, .card.card-success.card-bordering:not(.disabled):not(:disabled).active::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border: 0.125rem solid #00aa0c;
  z-index: 1;
  pointer-events: none;
}

.card.card-info {
  color: #2469ff;
  border-width: 0;
  will-change: background-color;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
.card.card-info.card-bordered::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border: 0.0625rem solid #2469ff;
  z-index: 1;
  pointer-events: none;
}
.card.card-info.card-raised {
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 2px 1px -1px rgba(0, 0, 0, 0.06);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 2px 1px -1px rgba(0, 0, 0, 0.06);
}
.card.card-info.card-filling:hover {
  color: #2469ff;
  background-color: rgba(36, 105, 255, 0.08);
}
.card.card-info.card-filling:not(.disabled):not(:disabled):active, .card.card-info.card-filling:not(.disabled):not(:disabled).active {
  color: #2469ff;
  background-color: rgba(36, 105, 255, 0.15);
}
.card.card-info.card-bordering:hover {
  color: #2469ff;
}
.card.card-info.card-bordering:hover::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border: 0.0625rem solid #2469ff;
  z-index: 1;
  pointer-events: none;
}
.card.card-info.card-bordering:not(.disabled):not(:disabled):active, .card.card-info.card-bordering:not(.disabled):not(:disabled).active {
  color: #2469ff;
}
.card.card-info.card-bordering:not(.disabled):not(:disabled):active::before, .card.card-info.card-bordering:not(.disabled):not(:disabled).active::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border: 0.125rem solid #2469ff;
  z-index: 1;
  pointer-events: none;
}

.card.card-warning {
  color: #da7400;
  border-width: 0;
  will-change: background-color;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
.card.card-warning.card-bordered::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border: 0.0625rem solid #da7400;
  z-index: 1;
  pointer-events: none;
}
.card.card-warning.card-raised {
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 2px 1px -1px rgba(0, 0, 0, 0.06);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 2px 1px -1px rgba(0, 0, 0, 0.06);
}
.card.card-warning.card-filling:hover {
  color: #da7400;
  background-color: rgba(218, 116, 0, 0.08);
}
.card.card-warning.card-filling:not(.disabled):not(:disabled):active, .card.card-warning.card-filling:not(.disabled):not(:disabled).active {
  color: #da7400;
  background-color: rgba(218, 116, 0, 0.15);
}
.card.card-warning.card-bordering:hover {
  color: #da7400;
}
.card.card-warning.card-bordering:hover::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border: 0.0625rem solid #da7400;
  z-index: 1;
  pointer-events: none;
}
.card.card-warning.card-bordering:not(.disabled):not(:disabled):active, .card.card-warning.card-bordering:not(.disabled):not(:disabled).active {
  color: #da7400;
}
.card.card-warning.card-bordering:not(.disabled):not(:disabled):active::before, .card.card-warning.card-bordering:not(.disabled):not(:disabled).active::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border: 0.125rem solid #da7400;
  z-index: 1;
  pointer-events: none;
}

.card.card-danger {
  color: #dd3724;
  border-width: 0;
  will-change: background-color;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
.card.card-danger.card-bordered::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border: 0.0625rem solid #dd3724;
  z-index: 1;
  pointer-events: none;
}
.card.card-danger.card-raised {
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 2px 1px -1px rgba(0, 0, 0, 0.06);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 2px 1px -1px rgba(0, 0, 0, 0.06);
}
.card.card-danger.card-filling:hover {
  color: #dd3724;
  background-color: rgba(221, 55, 36, 0.08);
}
.card.card-danger.card-filling:not(.disabled):not(:disabled):active, .card.card-danger.card-filling:not(.disabled):not(:disabled).active {
  color: #dd3724;
  background-color: rgba(221, 55, 36, 0.15);
}
.card.card-danger.card-bordering:hover {
  color: #dd3724;
}
.card.card-danger.card-bordering:hover::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border: 0.0625rem solid #dd3724;
  z-index: 1;
  pointer-events: none;
}
.card.card-danger.card-bordering:not(.disabled):not(:disabled):active, .card.card-danger.card-bordering:not(.disabled):not(:disabled).active {
  color: #dd3724;
}
.card.card-danger.card-bordering:not(.disabled):not(:disabled):active::before, .card.card-danger.card-bordering:not(.disabled):not(:disabled).active::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border: 0.125rem solid #dd3724;
  z-index: 1;
  pointer-events: none;
}

.card.card-light {
  color: #c4c8ca;
  border-width: 0;
  will-change: background-color;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
.card.card-light.card-bordered::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border: 0.0625rem solid #c4c8ca;
  z-index: 1;
  pointer-events: none;
}
.card.card-light.card-raised {
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 2px 1px -1px rgba(0, 0, 0, 0.06);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 2px 1px -1px rgba(0, 0, 0, 0.06);
}
.card.card-light.card-filling:hover {
  color: #c4c8ca;
  background-color: rgba(196, 200, 202, 0.08);
}
.card.card-light.card-filling:not(.disabled):not(:disabled):active, .card.card-light.card-filling:not(.disabled):not(:disabled).active {
  color: #c4c8ca;
  background-color: rgba(196, 200, 202, 0.15);
}
.card.card-light.card-bordering:hover {
  color: #c4c8ca;
}
.card.card-light.card-bordering:hover::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border: 0.0625rem solid #c4c8ca;
  z-index: 1;
  pointer-events: none;
}
.card.card-light.card-bordering:not(.disabled):not(:disabled):active, .card.card-light.card-bordering:not(.disabled):not(:disabled).active {
  color: #c4c8ca;
}
.card.card-light.card-bordering:not(.disabled):not(:disabled):active::before, .card.card-light.card-bordering:not(.disabled):not(:disabled).active::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border: 0.125rem solid #c4c8ca;
  z-index: 1;
  pointer-events: none;
}

.card.card-dark {
  color: #414949;
  border-width: 0;
  will-change: background-color;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
.card.card-dark.card-bordered::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border: 0.0625rem solid #414949;
  z-index: 1;
  pointer-events: none;
}
.card.card-dark.card-raised {
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 2px 1px -1px rgba(0, 0, 0, 0.06);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 2px 1px -1px rgba(0, 0, 0, 0.06);
}
.card.card-dark.card-filling:hover {
  color: #414949;
  background-color: rgba(65, 73, 73, 0.08);
}
.card.card-dark.card-filling:not(.disabled):not(:disabled):active, .card.card-dark.card-filling:not(.disabled):not(:disabled).active {
  color: #414949;
  background-color: rgba(65, 73, 73, 0.15);
}
.card.card-dark.card-bordering:hover {
  color: #414949;
}
.card.card-dark.card-bordering:hover::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border: 0.0625rem solid #414949;
  z-index: 1;
  pointer-events: none;
}
.card.card-dark.card-bordering:not(.disabled):not(:disabled):active, .card.card-dark.card-bordering:not(.disabled):not(:disabled).active {
  color: #414949;
}
.card.card-dark.card-bordering:not(.disabled):not(:disabled):active::before, .card.card-dark.card-bordering:not(.disabled):not(:disabled).active::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border: 0.125rem solid #414949;
  z-index: 1;
  pointer-events: none;
}

.card.card-white {
  color: white;
  border-width: 0;
  will-change: background-color;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
.card.card-white.card-bordered::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border: 0.0625rem solid white;
  z-index: 1;
  pointer-events: none;
}
.card.card-white.card-raised {
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 2px 1px -1px rgba(0, 0, 0, 0.06);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 2px 1px -1px rgba(0, 0, 0, 0.06);
}
.card.card-white.card-filling:hover {
  color: white;
  background-color: rgba(255, 255, 255, 0.08);
}
.card.card-white.card-filling:not(.disabled):not(:disabled):active, .card.card-white.card-filling:not(.disabled):not(:disabled).active {
  color: white;
  background-color: rgba(255, 255, 255, 0.15);
}
.card.card-white.card-bordering:hover {
  color: white;
}
.card.card-white.card-bordering:hover::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border: 0.0625rem solid white;
  z-index: 1;
  pointer-events: none;
}
.card.card-white.card-bordering:not(.disabled):not(:disabled):active, .card.card-white.card-bordering:not(.disabled):not(:disabled).active {
  color: white;
}
.card.card-white.card-bordering:not(.disabled):not(:disabled):active::before, .card.card-white.card-bordering:not(.disabled):not(:disabled).active::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border: 0.125rem solid white;
  z-index: 1;
  pointer-events: none;
}

.card.card-black {
  color: #231f20;
  border-width: 0;
  will-change: background-color;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
.card.card-black.card-bordered::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border: 0.0625rem solid #231f20;
  z-index: 1;
  pointer-events: none;
}
.card.card-black.card-raised {
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 2px 1px -1px rgba(0, 0, 0, 0.06);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 2px 1px -1px rgba(0, 0, 0, 0.06);
}
.card.card-black.card-filling:hover {
  color: #231f20;
  background-color: rgba(35, 31, 32, 0.08);
}
.card.card-black.card-filling:not(.disabled):not(:disabled):active, .card.card-black.card-filling:not(.disabled):not(:disabled).active {
  color: #231f20;
  background-color: rgba(35, 31, 32, 0.15);
}
.card.card-black.card-bordering:hover {
  color: #231f20;
}
.card.card-black.card-bordering:hover::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border: 0.0625rem solid #231f20;
  z-index: 1;
  pointer-events: none;
}
.card.card-black.card-bordering:not(.disabled):not(:disabled):active, .card.card-black.card-bordering:not(.disabled):not(:disabled).active {
  color: #231f20;
}
.card.card-black.card-bordering:not(.disabled):not(:disabled):active::before, .card.card-black.card-bordering:not(.disabled):not(:disabled).active::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border: 0.125rem solid #231f20;
  z-index: 1;
  pointer-events: none;
}

.card.card-socgen {
  color: #e60028;
  border-width: 0;
  will-change: background-color;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
.card.card-socgen.card-bordered::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border: 0.0625rem solid #e60028;
  z-index: 1;
  pointer-events: none;
}
.card.card-socgen.card-raised {
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 2px 1px -1px rgba(0, 0, 0, 0.06);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 2px 1px -1px rgba(0, 0, 0, 0.06);
}
.card.card-socgen.card-filling:hover {
  color: #e60028;
  background-color: rgba(230, 0, 40, 0.08);
}
.card.card-socgen.card-filling:not(.disabled):not(:disabled):active, .card.card-socgen.card-filling:not(.disabled):not(:disabled).active {
  color: #e60028;
  background-color: rgba(230, 0, 40, 0.15);
}
.card.card-socgen.card-bordering:hover {
  color: #e60028;
}
.card.card-socgen.card-bordering:hover::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border: 0.0625rem solid #e60028;
  z-index: 1;
  pointer-events: none;
}
.card.card-socgen.card-bordering:not(.disabled):not(:disabled):active, .card.card-socgen.card-bordering:not(.disabled):not(:disabled).active {
  color: #e60028;
}
.card.card-socgen.card-bordering:not(.disabled):not(:disabled):active::before, .card.card-socgen.card-bordering:not(.disabled):not(:disabled).active::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border: 0.125rem solid #e60028;
  z-index: 1;
  pointer-events: none;
}

.card.card-primary-alt {
  color: #fff;
  border-width: 0;
  will-change: background-color;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
.card.card-primary-alt.card-bordered::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border: 0.0625rem solid #fff;
  z-index: 1;
  pointer-events: none;
}
.card.card-primary-alt.card-raised {
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 2px 1px -1px rgba(0, 0, 0, 0.06);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 2px 1px -1px rgba(0, 0, 0, 0.06);
}
.card.card-primary-alt.card-filling:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.08);
}
.card.card-primary-alt.card-filling:not(.disabled):not(:disabled):active, .card.card-primary-alt.card-filling:not(.disabled):not(:disabled).active {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.15);
}
.card.card-primary-alt.card-bordering:hover {
  color: #fff;
}
.card.card-primary-alt.card-bordering:hover::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border: 0.0625rem solid #fff;
  z-index: 1;
  pointer-events: none;
}
.card.card-primary-alt.card-bordering:not(.disabled):not(:disabled):active, .card.card-primary-alt.card-bordering:not(.disabled):not(:disabled).active {
  color: #fff;
}
.card.card-primary-alt.card-bordering:not(.disabled):not(:disabled):active::before, .card.card-primary-alt.card-bordering:not(.disabled):not(:disabled).active::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border: 0.125rem solid #fff;
  z-index: 1;
  pointer-events: none;
}

.card-bordered::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border: 0.0625rem solid rgba(196, 200, 202, 0.7);
  z-index: 1;
  pointer-events: none;
}
.card-bordered.active::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border: 0.125rem solid #000;
  z-index: 1;
  pointer-events: none;
}

.card-bordering:hover {
  color: #000;
}
.card-bordering:hover::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border: 0.0625rem solid #000;
  z-index: 1;
  pointer-events: none;
}
.card-bordering:not(.disabled):not(:disabled):active, .card-bordering:not(.disabled):not(:disabled).active {
  color: #000;
}
.card-bordering:not(.disabled):not(:disabled):active::before, .card-bordering:not(.disabled):not(:disabled).active::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border: 0.125rem solid #000;
  z-index: 1;
  pointer-events: none;
}

.card-raised {
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 2px 1px -1px rgba(0, 0, 0, 0.06);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 2px 1px -1px rgba(0, 0, 0, 0.06);
}
.card-raised.active {
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 0 8px 0px rgba(0, 0, 0, 0.06), 0 8px 15px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 0 8px 0px rgba(0, 0, 0, 0.06), 0 8px 15px 0 rgba(0, 0, 0, 0.12);
}

.card-filled {
  background-color: rgba(0, 0, 0, 0.08);
}
.card-filled.active {
  background-color: rgba(0, 0, 0, 0.15);
}

.card-filling:hover {
  color: #000;
  background-color: rgba(0, 0, 0, 0.08);
}
.card-filling:not(.disabled):not(:disabled):active, .card-filling:not(.disabled):not(:disabled).active {
  color: #000;
  background-color: rgba(0, 0, 0, 0.15);
}

.card-raising {
  will-change: box-shadow;
  -webkit-transition: -webkit-box-shadow 0.3s;
  transition: -webkit-box-shadow 0.3s;
  transition: box-shadow 0.3s;
  transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
}
.card-raising:hover {
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 0 8px 0px rgba(0, 0, 0, 0.06), 0 8px 15px 0 rgba(0, 0, 0, 0.12) !important;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 0 8px 0px rgba(0, 0, 0, 0.06), 0 8px 15px 0 rgba(0, 0, 0, 0.12) !important;
}
.card-raising:not(.disabled):not(:disabled):active, .card-raising:not(.disabled):not(:disabled).active, .card-raising:not(.disabled):not(:disabled):focus, .card-raising:not(.disabled):not(:disabled).focus {
  -webkit-box-shadow: 0 10px 14px -6px rgba(0, 0, 0, 0.1), 0 22px 35px 3px rgba(0, 0, 0, 0.07), 0 8px 42px 7px rgba(0, 0, 0, 0.06);
  box-shadow: 0 10px 14px -6px rgba(0, 0, 0, 0.1), 0 22px 35px 3px rgba(0, 0, 0, 0.07), 0 8px 42px 7px rgba(0, 0, 0, 0.06);
}

.card.card-bordering-primary:hover {
  color: #000;
}
.card.card-bordering-primary:hover::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border: 0.0625rem solid #000;
  z-index: 1;
  pointer-events: none;
}
.card.card-bordering-primary:not(.disabled):not(:disabled):active, .card.card-bordering-primary:not(.disabled):not(:disabled).active {
  color: #000;
}
.card.card-bordering-primary:not(.disabled):not(:disabled):active::before, .card.card-bordering-primary:not(.disabled):not(:disabled).active::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border: 0.125rem solid #000;
  z-index: 1;
  pointer-events: none;
}

.card.card-filling-primary:hover {
  color: #000;
  background-color: rgba(0, 0, 0, 0.08);
}
.card.card-filling-primary:not(.disabled):not(:disabled):active, .card.card-filling-primary:not(.disabled):not(:disabled).active {
  color: #000;
  background-color: rgba(0, 0, 0, 0.15);
}

.card.card-bordering-secondary:hover {
  color: #6e777a;
}
.card.card-bordering-secondary:hover::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border: 0.0625rem solid #6e777a;
  z-index: 1;
  pointer-events: none;
}
.card.card-bordering-secondary:not(.disabled):not(:disabled):active, .card.card-bordering-secondary:not(.disabled):not(:disabled).active {
  color: #6e777a;
}
.card.card-bordering-secondary:not(.disabled):not(:disabled):active::before, .card.card-bordering-secondary:not(.disabled):not(:disabled).active::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border: 0.125rem solid #6e777a;
  z-index: 1;
  pointer-events: none;
}

.card.card-filling-secondary:hover {
  color: #6e777a;
  background-color: rgba(110, 119, 122, 0.08);
}
.card.card-filling-secondary:not(.disabled):not(:disabled):active, .card.card-filling-secondary:not(.disabled):not(:disabled).active {
  color: #6e777a;
  background-color: rgba(110, 119, 122, 0.15);
}

.card.card-bordering-success:hover {
  color: #00aa0c;
}
.card.card-bordering-success:hover::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border: 0.0625rem solid #00aa0c;
  z-index: 1;
  pointer-events: none;
}
.card.card-bordering-success:not(.disabled):not(:disabled):active, .card.card-bordering-success:not(.disabled):not(:disabled).active {
  color: #00aa0c;
}
.card.card-bordering-success:not(.disabled):not(:disabled):active::before, .card.card-bordering-success:not(.disabled):not(:disabled).active::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border: 0.125rem solid #00aa0c;
  z-index: 1;
  pointer-events: none;
}

.card.card-filling-success:hover {
  color: #00aa0c;
  background-color: rgba(0, 170, 12, 0.08);
}
.card.card-filling-success:not(.disabled):not(:disabled):active, .card.card-filling-success:not(.disabled):not(:disabled).active {
  color: #00aa0c;
  background-color: rgba(0, 170, 12, 0.15);
}

.card.card-bordering-info:hover {
  color: #2469ff;
}
.card.card-bordering-info:hover::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border: 0.0625rem solid #2469ff;
  z-index: 1;
  pointer-events: none;
}
.card.card-bordering-info:not(.disabled):not(:disabled):active, .card.card-bordering-info:not(.disabled):not(:disabled).active {
  color: #2469ff;
}
.card.card-bordering-info:not(.disabled):not(:disabled):active::before, .card.card-bordering-info:not(.disabled):not(:disabled).active::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border: 0.125rem solid #2469ff;
  z-index: 1;
  pointer-events: none;
}

.card.card-filling-info:hover {
  color: #2469ff;
  background-color: rgba(36, 105, 255, 0.08);
}
.card.card-filling-info:not(.disabled):not(:disabled):active, .card.card-filling-info:not(.disabled):not(:disabled).active {
  color: #2469ff;
  background-color: rgba(36, 105, 255, 0.15);
}

.card.card-bordering-warning:hover {
  color: #da7400;
}
.card.card-bordering-warning:hover::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border: 0.0625rem solid #da7400;
  z-index: 1;
  pointer-events: none;
}
.card.card-bordering-warning:not(.disabled):not(:disabled):active, .card.card-bordering-warning:not(.disabled):not(:disabled).active {
  color: #da7400;
}
.card.card-bordering-warning:not(.disabled):not(:disabled):active::before, .card.card-bordering-warning:not(.disabled):not(:disabled).active::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border: 0.125rem solid #da7400;
  z-index: 1;
  pointer-events: none;
}

.card.card-filling-warning:hover {
  color: #da7400;
  background-color: rgba(218, 116, 0, 0.08);
}
.card.card-filling-warning:not(.disabled):not(:disabled):active, .card.card-filling-warning:not(.disabled):not(:disabled).active {
  color: #da7400;
  background-color: rgba(218, 116, 0, 0.15);
}

.card.card-bordering-danger:hover {
  color: #dd3724;
}
.card.card-bordering-danger:hover::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border: 0.0625rem solid #dd3724;
  z-index: 1;
  pointer-events: none;
}
.card.card-bordering-danger:not(.disabled):not(:disabled):active, .card.card-bordering-danger:not(.disabled):not(:disabled).active {
  color: #dd3724;
}
.card.card-bordering-danger:not(.disabled):not(:disabled):active::before, .card.card-bordering-danger:not(.disabled):not(:disabled).active::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border: 0.125rem solid #dd3724;
  z-index: 1;
  pointer-events: none;
}

.card.card-filling-danger:hover {
  color: #dd3724;
  background-color: rgba(221, 55, 36, 0.08);
}
.card.card-filling-danger:not(.disabled):not(:disabled):active, .card.card-filling-danger:not(.disabled):not(:disabled).active {
  color: #dd3724;
  background-color: rgba(221, 55, 36, 0.15);
}

.card.card-bordering-light:hover {
  color: #c4c8ca;
}
.card.card-bordering-light:hover::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border: 0.0625rem solid #c4c8ca;
  z-index: 1;
  pointer-events: none;
}
.card.card-bordering-light:not(.disabled):not(:disabled):active, .card.card-bordering-light:not(.disabled):not(:disabled).active {
  color: #c4c8ca;
}
.card.card-bordering-light:not(.disabled):not(:disabled):active::before, .card.card-bordering-light:not(.disabled):not(:disabled).active::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border: 0.125rem solid #c4c8ca;
  z-index: 1;
  pointer-events: none;
}

.card.card-filling-light:hover {
  color: #c4c8ca;
  background-color: rgba(196, 200, 202, 0.08);
}
.card.card-filling-light:not(.disabled):not(:disabled):active, .card.card-filling-light:not(.disabled):not(:disabled).active {
  color: #c4c8ca;
  background-color: rgba(196, 200, 202, 0.15);
}

.card.card-bordering-dark:hover {
  color: #414949;
}
.card.card-bordering-dark:hover::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border: 0.0625rem solid #414949;
  z-index: 1;
  pointer-events: none;
}
.card.card-bordering-dark:not(.disabled):not(:disabled):active, .card.card-bordering-dark:not(.disabled):not(:disabled).active {
  color: #414949;
}
.card.card-bordering-dark:not(.disabled):not(:disabled):active::before, .card.card-bordering-dark:not(.disabled):not(:disabled).active::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border: 0.125rem solid #414949;
  z-index: 1;
  pointer-events: none;
}

.card.card-filling-dark:hover {
  color: #414949;
  background-color: rgba(65, 73, 73, 0.08);
}
.card.card-filling-dark:not(.disabled):not(:disabled):active, .card.card-filling-dark:not(.disabled):not(:disabled).active {
  color: #414949;
  background-color: rgba(65, 73, 73, 0.15);
}

.card.card-bordering-white:hover {
  color: white;
}
.card.card-bordering-white:hover::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border: 0.0625rem solid white;
  z-index: 1;
  pointer-events: none;
}
.card.card-bordering-white:not(.disabled):not(:disabled):active, .card.card-bordering-white:not(.disabled):not(:disabled).active {
  color: white;
}
.card.card-bordering-white:not(.disabled):not(:disabled):active::before, .card.card-bordering-white:not(.disabled):not(:disabled).active::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border: 0.125rem solid white;
  z-index: 1;
  pointer-events: none;
}

.card.card-filling-white:hover {
  color: white;
  background-color: rgba(255, 255, 255, 0.08);
}
.card.card-filling-white:not(.disabled):not(:disabled):active, .card.card-filling-white:not(.disabled):not(:disabled).active {
  color: white;
  background-color: rgba(255, 255, 255, 0.15);
}

.card.card-bordering-black:hover {
  color: #231f20;
}
.card.card-bordering-black:hover::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border: 0.0625rem solid #231f20;
  z-index: 1;
  pointer-events: none;
}
.card.card-bordering-black:not(.disabled):not(:disabled):active, .card.card-bordering-black:not(.disabled):not(:disabled).active {
  color: #231f20;
}
.card.card-bordering-black:not(.disabled):not(:disabled):active::before, .card.card-bordering-black:not(.disabled):not(:disabled).active::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border: 0.125rem solid #231f20;
  z-index: 1;
  pointer-events: none;
}

.card.card-filling-black:hover {
  color: #231f20;
  background-color: rgba(35, 31, 32, 0.08);
}
.card.card-filling-black:not(.disabled):not(:disabled):active, .card.card-filling-black:not(.disabled):not(:disabled).active {
  color: #231f20;
  background-color: rgba(35, 31, 32, 0.15);
}

.card.card-bordering-socgen:hover {
  color: #e60028;
}
.card.card-bordering-socgen:hover::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border: 0.0625rem solid #e60028;
  z-index: 1;
  pointer-events: none;
}
.card.card-bordering-socgen:not(.disabled):not(:disabled):active, .card.card-bordering-socgen:not(.disabled):not(:disabled).active {
  color: #e60028;
}
.card.card-bordering-socgen:not(.disabled):not(:disabled):active::before, .card.card-bordering-socgen:not(.disabled):not(:disabled).active::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border: 0.125rem solid #e60028;
  z-index: 1;
  pointer-events: none;
}

.card.card-filling-socgen:hover {
  color: #e60028;
  background-color: rgba(230, 0, 40, 0.08);
}
.card.card-filling-socgen:not(.disabled):not(:disabled):active, .card.card-filling-socgen:not(.disabled):not(:disabled).active {
  color: #e60028;
  background-color: rgba(230, 0, 40, 0.15);
}

.card.card-bordering-primary-alt:hover {
  color: #fff;
}
.card.card-bordering-primary-alt:hover::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border: 0.0625rem solid #fff;
  z-index: 1;
  pointer-events: none;
}
.card.card-bordering-primary-alt:not(.disabled):not(:disabled):active, .card.card-bordering-primary-alt:not(.disabled):not(:disabled).active {
  color: #fff;
}
.card.card-bordering-primary-alt:not(.disabled):not(:disabled):active::before, .card.card-bordering-primary-alt:not(.disabled):not(:disabled).active::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border: 0.125rem solid #fff;
  z-index: 1;
  pointer-events: none;
}

.card.card-filling-primary-alt:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.08);
}
.card.card-filling-primary-alt:not(.disabled):not(:disabled):active, .card.card-filling-primary-alt:not(.disabled):not(:disabled).active {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.15);
}

.card-img-overlay {
  background-color: rgba(0, 0, 0, 0.7);
}

.newsstand .card-body::before {
  content: "";
  background: #e60028;
  width: 1rem;
  height: 1rem;
  display: block;
  margin-bottom: 1rem;
}

.card[href] {
  color: inherit;
}
.card[href]:hover {
  text-decoration: none;
}

.card-header {
  border-bottom: none;
}

.card-footer {
  border-top: none;
}

/*
 * No adapted colors inside `.card` 
 */
[class*=bg-lvl] .card .btn.btn-secondary,
[class*=bg-alt-lvl] .card .btn.btn-secondary {
  color: #fff;
  background-color: #6e777a;
  border: none;
}
[class*=bg-lvl] .card .btn.btn-secondary:hover:not(:disabled):not(.disabled),
[class*=bg-alt-lvl] .card .btn.btn-secondary:hover:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #5f676a;
  border-color: #5f676a;
}
[class*=bg-lvl] .card .btn.btn-secondary:focus, [class*=bg-lvl] .card .btn.btn-secondary.focus,
[class*=bg-alt-lvl] .card .btn.btn-secondary:focus,
[class*=bg-alt-lvl] .card .btn.btn-secondary.focus {
  color: #fff;
}
[class*=bg-lvl] .card .btn.btn-secondary:not(:disabled):not(.disabled):active, [class*=bg-lvl] .card .btn.btn-secondary:not(:disabled):not(.disabled).active,
[class*=bg-alt-lvl] .card .btn.btn-secondary:not(:disabled):not(.disabled):active,
[class*=bg-alt-lvl] .card .btn.btn-secondary:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #6e777a;
  border-color: #6e777a;
}
[class*=bg-lvl] .card .btn.btn-outline-secondary,
[class*=bg-alt-lvl] .card .btn.btn-outline-secondary {
  color: #6e777a;
  border-color: #6e777a;
}
[class*=bg-lvl] .card .btn.btn-outline-secondary:hover:not(:disabled):not(.disabled),
[class*=bg-alt-lvl] .card .btn.btn-outline-secondary:hover:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #5f676a;
  border-color: #5f676a;
}
[class*=bg-lvl] .card .btn.btn-outline-secondary:focus, [class*=bg-lvl] .card .btn.btn-outline-secondary.focus,
[class*=bg-alt-lvl] .card .btn.btn-outline-secondary:focus,
[class*=bg-alt-lvl] .card .btn.btn-outline-secondary.focus {
  color: #fff;
}
[class*=bg-lvl] .card .btn.btn-outline-secondary:not(:disabled):not(.disabled):active, [class*=bg-lvl] .card .btn.btn-outline-secondary:not(:disabled):not(.disabled).active,
[class*=bg-alt-lvl] .card .btn.btn-outline-secondary:not(:disabled):not(.disabled):active,
[class*=bg-alt-lvl] .card .btn.btn-outline-secondary:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #6e777a;
  border-color: #6e777a;
}
[class*=bg-lvl] .card .btn.btn-discreet-secondary,
[class*=bg-alt-lvl] .card .btn.btn-discreet-secondary {
  color: #6e777a;
}
[class*=bg-lvl] .card .btn.btn-discreet-secondary:hover:not(:disabled):not(.disabled),
[class*=bg-alt-lvl] .card .btn.btn-discreet-secondary:hover:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #5f676a;
  border-color: #5f676a;
}
[class*=bg-lvl] .card .btn.btn-discreet-secondary:focus, [class*=bg-lvl] .card .btn.btn-discreet-secondary.focus,
[class*=bg-alt-lvl] .card .btn.btn-discreet-secondary:focus,
[class*=bg-alt-lvl] .card .btn.btn-discreet-secondary.focus {
  color: #fff;
}
[class*=bg-lvl] .card .btn.btn-discreet-secondary:not(:disabled):not(.disabled):active, [class*=bg-lvl] .card .btn.btn-discreet-secondary:not(:disabled):not(.disabled).active,
[class*=bg-alt-lvl] .card .btn.btn-discreet-secondary:not(:disabled):not(.disabled):active,
[class*=bg-alt-lvl] .card .btn.btn-discreet-secondary:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #6e777a;
  border-color: #6e777a;
}
[class*=bg-lvl] .card .btn.btn-discreet-secondary,
[class*=bg-alt-lvl] .card .btn.btn-discreet-secondary {
  color: #6e777a;
}
[class*=bg-lvl] .card .btn.btn-discreet-secondary:hover:not(:disabled):not(.disabled),
[class*=bg-alt-lvl] .card .btn.btn-discreet-secondary:hover:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #5f676a;
  border-color: #5f676a;
}
[class*=bg-lvl] .card .btn.btn-discreet-secondary:focus, [class*=bg-lvl] .card .btn.btn-discreet-secondary.focus,
[class*=bg-alt-lvl] .card .btn.btn-discreet-secondary:focus,
[class*=bg-alt-lvl] .card .btn.btn-discreet-secondary.focus {
  color: #fff;
}
[class*=bg-lvl] .card .btn.btn-discreet-secondary:not(:disabled):not(.disabled):active, [class*=bg-lvl] .card .btn.btn-discreet-secondary:not(:disabled):not(.disabled).active,
[class*=bg-alt-lvl] .card .btn.btn-discreet-secondary:not(:disabled):not(.disabled):active,
[class*=bg-alt-lvl] .card .btn.btn-discreet-secondary:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #6e777a;
  border-color: #6e777a;
}
[class*=bg-lvl] .card .badge.badge-secondary,
[class*=bg-alt-lvl] .card .badge.badge-secondary {
  color: #fff;
  background-color: #6e777a;
}
[class*=bg-lvl] .card .badge.badge-outline-secondary,
[class*=bg-alt-lvl] .card .badge.badge-outline-secondary {
  color: #6e777a;
  border-color: #6e777a;
}
[class*=bg-lvl] .card .badge.badge-discreet-secondary,
[class*=bg-alt-lvl] .card .badge.badge-discreet-secondary {
  color: #6e777a;
}
[class*=bg-lvl] .card .text-secondary,
[class*=bg-alt-lvl] .card .text-secondary {
  color: #6e777a !important;
}
[class*=bg-lvl] .card .btn.btn-light,
[class*=bg-alt-lvl] .card .btn.btn-light {
  color: #fff;
  background-color: #c4c8ca;
  border: none;
}
[class*=bg-lvl] .card .btn.btn-light:hover:not(:disabled):not(.disabled),
[class*=bg-alt-lvl] .card .btn.btn-light:hover:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #b4b9bc;
  border-color: #b4b9bc;
}
[class*=bg-lvl] .card .btn.btn-light:focus, [class*=bg-lvl] .card .btn.btn-light.focus,
[class*=bg-alt-lvl] .card .btn.btn-light:focus,
[class*=bg-alt-lvl] .card .btn.btn-light.focus {
  color: #fff;
}
[class*=bg-lvl] .card .btn.btn-light:not(:disabled):not(.disabled):active, [class*=bg-lvl] .card .btn.btn-light:not(:disabled):not(.disabled).active,
[class*=bg-alt-lvl] .card .btn.btn-light:not(:disabled):not(.disabled):active,
[class*=bg-alt-lvl] .card .btn.btn-light:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #c4c8ca;
  border-color: #c4c8ca;
}
[class*=bg-lvl] .card .btn.btn-outline-light,
[class*=bg-alt-lvl] .card .btn.btn-outline-light {
  color: #c4c8ca;
  border-color: #c4c8ca;
}
[class*=bg-lvl] .card .btn.btn-outline-light:hover:not(:disabled):not(.disabled),
[class*=bg-alt-lvl] .card .btn.btn-outline-light:hover:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #b4b9bc;
  border-color: #b4b9bc;
}
[class*=bg-lvl] .card .btn.btn-outline-light:focus, [class*=bg-lvl] .card .btn.btn-outline-light.focus,
[class*=bg-alt-lvl] .card .btn.btn-outline-light:focus,
[class*=bg-alt-lvl] .card .btn.btn-outline-light.focus {
  color: #fff;
}
[class*=bg-lvl] .card .btn.btn-outline-light:not(:disabled):not(.disabled):active, [class*=bg-lvl] .card .btn.btn-outline-light:not(:disabled):not(.disabled).active,
[class*=bg-alt-lvl] .card .btn.btn-outline-light:not(:disabled):not(.disabled):active,
[class*=bg-alt-lvl] .card .btn.btn-outline-light:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #c4c8ca;
  border-color: #c4c8ca;
}
[class*=bg-lvl] .card .btn.btn-discreet-light,
[class*=bg-alt-lvl] .card .btn.btn-discreet-light {
  color: #c4c8ca;
}
[class*=bg-lvl] .card .btn.btn-discreet-light:hover:not(:disabled):not(.disabled),
[class*=bg-alt-lvl] .card .btn.btn-discreet-light:hover:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #b4b9bc;
  border-color: #b4b9bc;
}
[class*=bg-lvl] .card .btn.btn-discreet-light:focus, [class*=bg-lvl] .card .btn.btn-discreet-light.focus,
[class*=bg-alt-lvl] .card .btn.btn-discreet-light:focus,
[class*=bg-alt-lvl] .card .btn.btn-discreet-light.focus {
  color: #fff;
}
[class*=bg-lvl] .card .btn.btn-discreet-light:not(:disabled):not(.disabled):active, [class*=bg-lvl] .card .btn.btn-discreet-light:not(:disabled):not(.disabled).active,
[class*=bg-alt-lvl] .card .btn.btn-discreet-light:not(:disabled):not(.disabled):active,
[class*=bg-alt-lvl] .card .btn.btn-discreet-light:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #c4c8ca;
  border-color: #c4c8ca;
}
[class*=bg-lvl] .card .btn.btn-discreet-light,
[class*=bg-alt-lvl] .card .btn.btn-discreet-light {
  color: #c4c8ca;
}
[class*=bg-lvl] .card .btn.btn-discreet-light:hover:not(:disabled):not(.disabled),
[class*=bg-alt-lvl] .card .btn.btn-discreet-light:hover:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #b4b9bc;
  border-color: #b4b9bc;
}
[class*=bg-lvl] .card .btn.btn-discreet-light:focus, [class*=bg-lvl] .card .btn.btn-discreet-light.focus,
[class*=bg-alt-lvl] .card .btn.btn-discreet-light:focus,
[class*=bg-alt-lvl] .card .btn.btn-discreet-light.focus {
  color: #fff;
}
[class*=bg-lvl] .card .btn.btn-discreet-light:not(:disabled):not(.disabled):active, [class*=bg-lvl] .card .btn.btn-discreet-light:not(:disabled):not(.disabled).active,
[class*=bg-alt-lvl] .card .btn.btn-discreet-light:not(:disabled):not(.disabled):active,
[class*=bg-alt-lvl] .card .btn.btn-discreet-light:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #c4c8ca;
  border-color: #c4c8ca;
}
[class*=bg-lvl] .card .badge.badge-light,
[class*=bg-alt-lvl] .card .badge.badge-light {
  color: #fff;
  background-color: #c4c8ca;
}
[class*=bg-lvl] .card .badge.badge-outline-light,
[class*=bg-alt-lvl] .card .badge.badge-outline-light {
  color: #c4c8ca;
  border-color: #c4c8ca;
}
[class*=bg-lvl] .card .badge.badge-discreet-light,
[class*=bg-alt-lvl] .card .badge.badge-discreet-light {
  color: #c4c8ca;
}
[class*=bg-lvl] .card .text-light,
[class*=bg-alt-lvl] .card .text-light {
  color: #c4c8ca !important;
}
[class*=bg-lvl] .card .btn.btn-dark,
[class*=bg-alt-lvl] .card .btn.btn-dark {
  color: #fff;
  background-color: #414949;
  border: none;
}
[class*=bg-lvl] .card .btn.btn-dark:hover:not(:disabled):not(.disabled),
[class*=bg-alt-lvl] .card .btn.btn-dark:hover:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #333939;
  border-color: #333939;
}
[class*=bg-lvl] .card .btn.btn-dark:focus, [class*=bg-lvl] .card .btn.btn-dark.focus,
[class*=bg-alt-lvl] .card .btn.btn-dark:focus,
[class*=bg-alt-lvl] .card .btn.btn-dark.focus {
  color: #fff;
}
[class*=bg-lvl] .card .btn.btn-dark:not(:disabled):not(.disabled):active, [class*=bg-lvl] .card .btn.btn-dark:not(:disabled):not(.disabled).active,
[class*=bg-alt-lvl] .card .btn.btn-dark:not(:disabled):not(.disabled):active,
[class*=bg-alt-lvl] .card .btn.btn-dark:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #414949;
  border-color: #414949;
}
[class*=bg-lvl] .card .btn.btn-outline-dark,
[class*=bg-alt-lvl] .card .btn.btn-outline-dark {
  color: #414949;
  border-color: #414949;
}
[class*=bg-lvl] .card .btn.btn-outline-dark:hover:not(:disabled):not(.disabled),
[class*=bg-alt-lvl] .card .btn.btn-outline-dark:hover:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #333939;
  border-color: #333939;
}
[class*=bg-lvl] .card .btn.btn-outline-dark:focus, [class*=bg-lvl] .card .btn.btn-outline-dark.focus,
[class*=bg-alt-lvl] .card .btn.btn-outline-dark:focus,
[class*=bg-alt-lvl] .card .btn.btn-outline-dark.focus {
  color: #fff;
}
[class*=bg-lvl] .card .btn.btn-outline-dark:not(:disabled):not(.disabled):active, [class*=bg-lvl] .card .btn.btn-outline-dark:not(:disabled):not(.disabled).active,
[class*=bg-alt-lvl] .card .btn.btn-outline-dark:not(:disabled):not(.disabled):active,
[class*=bg-alt-lvl] .card .btn.btn-outline-dark:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #414949;
  border-color: #414949;
}
[class*=bg-lvl] .card .btn.btn-discreet-dark,
[class*=bg-alt-lvl] .card .btn.btn-discreet-dark {
  color: #414949;
}
[class*=bg-lvl] .card .btn.btn-discreet-dark:hover:not(:disabled):not(.disabled),
[class*=bg-alt-lvl] .card .btn.btn-discreet-dark:hover:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #333939;
  border-color: #333939;
}
[class*=bg-lvl] .card .btn.btn-discreet-dark:focus, [class*=bg-lvl] .card .btn.btn-discreet-dark.focus,
[class*=bg-alt-lvl] .card .btn.btn-discreet-dark:focus,
[class*=bg-alt-lvl] .card .btn.btn-discreet-dark.focus {
  color: #fff;
}
[class*=bg-lvl] .card .btn.btn-discreet-dark:not(:disabled):not(.disabled):active, [class*=bg-lvl] .card .btn.btn-discreet-dark:not(:disabled):not(.disabled).active,
[class*=bg-alt-lvl] .card .btn.btn-discreet-dark:not(:disabled):not(.disabled):active,
[class*=bg-alt-lvl] .card .btn.btn-discreet-dark:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #414949;
  border-color: #414949;
}
[class*=bg-lvl] .card .btn.btn-discreet-dark,
[class*=bg-alt-lvl] .card .btn.btn-discreet-dark {
  color: #414949;
}
[class*=bg-lvl] .card .btn.btn-discreet-dark:hover:not(:disabled):not(.disabled),
[class*=bg-alt-lvl] .card .btn.btn-discreet-dark:hover:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #333939;
  border-color: #333939;
}
[class*=bg-lvl] .card .btn.btn-discreet-dark:focus, [class*=bg-lvl] .card .btn.btn-discreet-dark.focus,
[class*=bg-alt-lvl] .card .btn.btn-discreet-dark:focus,
[class*=bg-alt-lvl] .card .btn.btn-discreet-dark.focus {
  color: #fff;
}
[class*=bg-lvl] .card .btn.btn-discreet-dark:not(:disabled):not(.disabled):active, [class*=bg-lvl] .card .btn.btn-discreet-dark:not(:disabled):not(.disabled).active,
[class*=bg-alt-lvl] .card .btn.btn-discreet-dark:not(:disabled):not(.disabled):active,
[class*=bg-alt-lvl] .card .btn.btn-discreet-dark:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #414949;
  border-color: #414949;
}
[class*=bg-lvl] .card .badge.badge-dark,
[class*=bg-alt-lvl] .card .badge.badge-dark {
  color: #fff;
  background-color: #414949;
}
[class*=bg-lvl] .card .badge.badge-outline-dark,
[class*=bg-alt-lvl] .card .badge.badge-outline-dark {
  color: #414949;
  border-color: #414949;
}
[class*=bg-lvl] .card .badge.badge-discreet-dark,
[class*=bg-alt-lvl] .card .badge.badge-discreet-dark {
  color: #414949;
}
[class*=bg-lvl] .card .text-dark,
[class*=bg-alt-lvl] .card .text-dark {
  color: #414949 !important;
}
[class*=bg-lvl] .card .btn.btn-info,
[class*=bg-alt-lvl] .card .btn.btn-info {
  color: #fff;
  background-color: #2469ff;
  border: none;
}
[class*=bg-lvl] .card .btn.btn-info:hover:not(:disabled):not(.disabled),
[class*=bg-alt-lvl] .card .btn.btn-info:hover:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #0554ff;
  border-color: #0554ff;
}
[class*=bg-lvl] .card .btn.btn-info:focus, [class*=bg-lvl] .card .btn.btn-info.focus,
[class*=bg-alt-lvl] .card .btn.btn-info:focus,
[class*=bg-alt-lvl] .card .btn.btn-info.focus {
  color: #fff;
}
[class*=bg-lvl] .card .btn.btn-info:not(:disabled):not(.disabled):active, [class*=bg-lvl] .card .btn.btn-info:not(:disabled):not(.disabled).active,
[class*=bg-alt-lvl] .card .btn.btn-info:not(:disabled):not(.disabled):active,
[class*=bg-alt-lvl] .card .btn.btn-info:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #2469ff;
  border-color: #2469ff;
}
[class*=bg-lvl] .card .btn.btn-outline-info,
[class*=bg-alt-lvl] .card .btn.btn-outline-info {
  color: #2469ff;
  border-color: #2469ff;
}
[class*=bg-lvl] .card .btn.btn-outline-info:hover:not(:disabled):not(.disabled),
[class*=bg-alt-lvl] .card .btn.btn-outline-info:hover:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #0554ff;
  border-color: #0554ff;
}
[class*=bg-lvl] .card .btn.btn-outline-info:focus, [class*=bg-lvl] .card .btn.btn-outline-info.focus,
[class*=bg-alt-lvl] .card .btn.btn-outline-info:focus,
[class*=bg-alt-lvl] .card .btn.btn-outline-info.focus {
  color: #fff;
}
[class*=bg-lvl] .card .btn.btn-outline-info:not(:disabled):not(.disabled):active, [class*=bg-lvl] .card .btn.btn-outline-info:not(:disabled):not(.disabled).active,
[class*=bg-alt-lvl] .card .btn.btn-outline-info:not(:disabled):not(.disabled):active,
[class*=bg-alt-lvl] .card .btn.btn-outline-info:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #2469ff;
  border-color: #2469ff;
}
[class*=bg-lvl] .card .btn.btn-discreet-info,
[class*=bg-alt-lvl] .card .btn.btn-discreet-info {
  color: #2469ff;
}
[class*=bg-lvl] .card .btn.btn-discreet-info:hover:not(:disabled):not(.disabled),
[class*=bg-alt-lvl] .card .btn.btn-discreet-info:hover:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #0554ff;
  border-color: #0554ff;
}
[class*=bg-lvl] .card .btn.btn-discreet-info:focus, [class*=bg-lvl] .card .btn.btn-discreet-info.focus,
[class*=bg-alt-lvl] .card .btn.btn-discreet-info:focus,
[class*=bg-alt-lvl] .card .btn.btn-discreet-info.focus {
  color: #fff;
}
[class*=bg-lvl] .card .btn.btn-discreet-info:not(:disabled):not(.disabled):active, [class*=bg-lvl] .card .btn.btn-discreet-info:not(:disabled):not(.disabled).active,
[class*=bg-alt-lvl] .card .btn.btn-discreet-info:not(:disabled):not(.disabled):active,
[class*=bg-alt-lvl] .card .btn.btn-discreet-info:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #2469ff;
  border-color: #2469ff;
}
[class*=bg-lvl] .card .btn.btn-discreet-info,
[class*=bg-alt-lvl] .card .btn.btn-discreet-info {
  color: #2469ff;
}
[class*=bg-lvl] .card .btn.btn-discreet-info:hover:not(:disabled):not(.disabled),
[class*=bg-alt-lvl] .card .btn.btn-discreet-info:hover:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #0554ff;
  border-color: #0554ff;
}
[class*=bg-lvl] .card .btn.btn-discreet-info:focus, [class*=bg-lvl] .card .btn.btn-discreet-info.focus,
[class*=bg-alt-lvl] .card .btn.btn-discreet-info:focus,
[class*=bg-alt-lvl] .card .btn.btn-discreet-info.focus {
  color: #fff;
}
[class*=bg-lvl] .card .btn.btn-discreet-info:not(:disabled):not(.disabled):active, [class*=bg-lvl] .card .btn.btn-discreet-info:not(:disabled):not(.disabled).active,
[class*=bg-alt-lvl] .card .btn.btn-discreet-info:not(:disabled):not(.disabled):active,
[class*=bg-alt-lvl] .card .btn.btn-discreet-info:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #2469ff;
  border-color: #2469ff;
}
[class*=bg-lvl] .card .badge.badge-info,
[class*=bg-alt-lvl] .card .badge.badge-info {
  color: #fff;
  background-color: #2469ff;
}
[class*=bg-lvl] .card .badge.badge-outline-info,
[class*=bg-alt-lvl] .card .badge.badge-outline-info {
  color: #2469ff;
  border-color: #2469ff;
}
[class*=bg-lvl] .card .badge.badge-discreet-info,
[class*=bg-alt-lvl] .card .badge.badge-discreet-info {
  color: #2469ff;
}
[class*=bg-lvl] .card .text-info,
[class*=bg-alt-lvl] .card .text-info {
  color: #2469ff !important;
}
[class*=bg-lvl] .card .btn.btn-success,
[class*=bg-alt-lvl] .card .btn.btn-success {
  color: #fff;
  background-color: #00aa0c;
  border: none;
}
[class*=bg-lvl] .card .btn.btn-success:hover:not(:disabled):not(.disabled),
[class*=bg-alt-lvl] .card .btn.btn-success:hover:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #008b0a;
  border-color: #008b0a;
}
[class*=bg-lvl] .card .btn.btn-success:focus, [class*=bg-lvl] .card .btn.btn-success.focus,
[class*=bg-alt-lvl] .card .btn.btn-success:focus,
[class*=bg-alt-lvl] .card .btn.btn-success.focus {
  color: #fff;
}
[class*=bg-lvl] .card .btn.btn-success:not(:disabled):not(.disabled):active, [class*=bg-lvl] .card .btn.btn-success:not(:disabled):not(.disabled).active,
[class*=bg-alt-lvl] .card .btn.btn-success:not(:disabled):not(.disabled):active,
[class*=bg-alt-lvl] .card .btn.btn-success:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #00aa0c;
  border-color: #00aa0c;
}
[class*=bg-lvl] .card .btn.btn-outline-success,
[class*=bg-alt-lvl] .card .btn.btn-outline-success {
  color: #00aa0c;
  border-color: #00aa0c;
}
[class*=bg-lvl] .card .btn.btn-outline-success:hover:not(:disabled):not(.disabled),
[class*=bg-alt-lvl] .card .btn.btn-outline-success:hover:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #008b0a;
  border-color: #008b0a;
}
[class*=bg-lvl] .card .btn.btn-outline-success:focus, [class*=bg-lvl] .card .btn.btn-outline-success.focus,
[class*=bg-alt-lvl] .card .btn.btn-outline-success:focus,
[class*=bg-alt-lvl] .card .btn.btn-outline-success.focus {
  color: #fff;
}
[class*=bg-lvl] .card .btn.btn-outline-success:not(:disabled):not(.disabled):active, [class*=bg-lvl] .card .btn.btn-outline-success:not(:disabled):not(.disabled).active,
[class*=bg-alt-lvl] .card .btn.btn-outline-success:not(:disabled):not(.disabled):active,
[class*=bg-alt-lvl] .card .btn.btn-outline-success:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #00aa0c;
  border-color: #00aa0c;
}
[class*=bg-lvl] .card .btn.btn-discreet-success,
[class*=bg-alt-lvl] .card .btn.btn-discreet-success {
  color: #00aa0c;
}
[class*=bg-lvl] .card .btn.btn-discreet-success:hover:not(:disabled):not(.disabled),
[class*=bg-alt-lvl] .card .btn.btn-discreet-success:hover:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #008b0a;
  border-color: #008b0a;
}
[class*=bg-lvl] .card .btn.btn-discreet-success:focus, [class*=bg-lvl] .card .btn.btn-discreet-success.focus,
[class*=bg-alt-lvl] .card .btn.btn-discreet-success:focus,
[class*=bg-alt-lvl] .card .btn.btn-discreet-success.focus {
  color: #fff;
}
[class*=bg-lvl] .card .btn.btn-discreet-success:not(:disabled):not(.disabled):active, [class*=bg-lvl] .card .btn.btn-discreet-success:not(:disabled):not(.disabled).active,
[class*=bg-alt-lvl] .card .btn.btn-discreet-success:not(:disabled):not(.disabled):active,
[class*=bg-alt-lvl] .card .btn.btn-discreet-success:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #00aa0c;
  border-color: #00aa0c;
}
[class*=bg-lvl] .card .btn.btn-discreet-success,
[class*=bg-alt-lvl] .card .btn.btn-discreet-success {
  color: #00aa0c;
}
[class*=bg-lvl] .card .btn.btn-discreet-success:hover:not(:disabled):not(.disabled),
[class*=bg-alt-lvl] .card .btn.btn-discreet-success:hover:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #008b0a;
  border-color: #008b0a;
}
[class*=bg-lvl] .card .btn.btn-discreet-success:focus, [class*=bg-lvl] .card .btn.btn-discreet-success.focus,
[class*=bg-alt-lvl] .card .btn.btn-discreet-success:focus,
[class*=bg-alt-lvl] .card .btn.btn-discreet-success.focus {
  color: #fff;
}
[class*=bg-lvl] .card .btn.btn-discreet-success:not(:disabled):not(.disabled):active, [class*=bg-lvl] .card .btn.btn-discreet-success:not(:disabled):not(.disabled).active,
[class*=bg-alt-lvl] .card .btn.btn-discreet-success:not(:disabled):not(.disabled):active,
[class*=bg-alt-lvl] .card .btn.btn-discreet-success:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #00aa0c;
  border-color: #00aa0c;
}
[class*=bg-lvl] .card .badge.badge-success,
[class*=bg-alt-lvl] .card .badge.badge-success {
  color: #fff;
  background-color: #00aa0c;
}
[class*=bg-lvl] .card .badge.badge-outline-success,
[class*=bg-alt-lvl] .card .badge.badge-outline-success {
  color: #00aa0c;
  border-color: #00aa0c;
}
[class*=bg-lvl] .card .badge.badge-discreet-success,
[class*=bg-alt-lvl] .card .badge.badge-discreet-success {
  color: #00aa0c;
}
[class*=bg-lvl] .card .text-success,
[class*=bg-alt-lvl] .card .text-success {
  color: #00aa0c !important;
}
[class*=bg-lvl] .card .btn.btn-warning,
[class*=bg-alt-lvl] .card .btn.btn-warning {
  color: #fff;
  background-color: #da7400;
  border: none;
}
[class*=bg-lvl] .card .btn.btn-warning:hover:not(:disabled):not(.disabled),
[class*=bg-alt-lvl] .card .btn.btn-warning:hover:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #bb6400;
  border-color: #bb6400;
}
[class*=bg-lvl] .card .btn.btn-warning:focus, [class*=bg-lvl] .card .btn.btn-warning.focus,
[class*=bg-alt-lvl] .card .btn.btn-warning:focus,
[class*=bg-alt-lvl] .card .btn.btn-warning.focus {
  color: #fff;
}
[class*=bg-lvl] .card .btn.btn-warning:not(:disabled):not(.disabled):active, [class*=bg-lvl] .card .btn.btn-warning:not(:disabled):not(.disabled).active,
[class*=bg-alt-lvl] .card .btn.btn-warning:not(:disabled):not(.disabled):active,
[class*=bg-alt-lvl] .card .btn.btn-warning:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #da7400;
  border-color: #da7400;
}
[class*=bg-lvl] .card .btn.btn-outline-warning,
[class*=bg-alt-lvl] .card .btn.btn-outline-warning {
  color: #da7400;
  border-color: #da7400;
}
[class*=bg-lvl] .card .btn.btn-outline-warning:hover:not(:disabled):not(.disabled),
[class*=bg-alt-lvl] .card .btn.btn-outline-warning:hover:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #bb6400;
  border-color: #bb6400;
}
[class*=bg-lvl] .card .btn.btn-outline-warning:focus, [class*=bg-lvl] .card .btn.btn-outline-warning.focus,
[class*=bg-alt-lvl] .card .btn.btn-outline-warning:focus,
[class*=bg-alt-lvl] .card .btn.btn-outline-warning.focus {
  color: #fff;
}
[class*=bg-lvl] .card .btn.btn-outline-warning:not(:disabled):not(.disabled):active, [class*=bg-lvl] .card .btn.btn-outline-warning:not(:disabled):not(.disabled).active,
[class*=bg-alt-lvl] .card .btn.btn-outline-warning:not(:disabled):not(.disabled):active,
[class*=bg-alt-lvl] .card .btn.btn-outline-warning:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #da7400;
  border-color: #da7400;
}
[class*=bg-lvl] .card .btn.btn-discreet-warning,
[class*=bg-alt-lvl] .card .btn.btn-discreet-warning {
  color: #da7400;
}
[class*=bg-lvl] .card .btn.btn-discreet-warning:hover:not(:disabled):not(.disabled),
[class*=bg-alt-lvl] .card .btn.btn-discreet-warning:hover:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #bb6400;
  border-color: #bb6400;
}
[class*=bg-lvl] .card .btn.btn-discreet-warning:focus, [class*=bg-lvl] .card .btn.btn-discreet-warning.focus,
[class*=bg-alt-lvl] .card .btn.btn-discreet-warning:focus,
[class*=bg-alt-lvl] .card .btn.btn-discreet-warning.focus {
  color: #fff;
}
[class*=bg-lvl] .card .btn.btn-discreet-warning:not(:disabled):not(.disabled):active, [class*=bg-lvl] .card .btn.btn-discreet-warning:not(:disabled):not(.disabled).active,
[class*=bg-alt-lvl] .card .btn.btn-discreet-warning:not(:disabled):not(.disabled):active,
[class*=bg-alt-lvl] .card .btn.btn-discreet-warning:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #da7400;
  border-color: #da7400;
}
[class*=bg-lvl] .card .btn.btn-discreet-warning,
[class*=bg-alt-lvl] .card .btn.btn-discreet-warning {
  color: #da7400;
}
[class*=bg-lvl] .card .btn.btn-discreet-warning:hover:not(:disabled):not(.disabled),
[class*=bg-alt-lvl] .card .btn.btn-discreet-warning:hover:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #bb6400;
  border-color: #bb6400;
}
[class*=bg-lvl] .card .btn.btn-discreet-warning:focus, [class*=bg-lvl] .card .btn.btn-discreet-warning.focus,
[class*=bg-alt-lvl] .card .btn.btn-discreet-warning:focus,
[class*=bg-alt-lvl] .card .btn.btn-discreet-warning.focus {
  color: #fff;
}
[class*=bg-lvl] .card .btn.btn-discreet-warning:not(:disabled):not(.disabled):active, [class*=bg-lvl] .card .btn.btn-discreet-warning:not(:disabled):not(.disabled).active,
[class*=bg-alt-lvl] .card .btn.btn-discreet-warning:not(:disabled):not(.disabled):active,
[class*=bg-alt-lvl] .card .btn.btn-discreet-warning:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #da7400;
  border-color: #da7400;
}
[class*=bg-lvl] .card .badge.badge-warning,
[class*=bg-alt-lvl] .card .badge.badge-warning {
  color: #fff;
  background-color: #da7400;
}
[class*=bg-lvl] .card .badge.badge-outline-warning,
[class*=bg-alt-lvl] .card .badge.badge-outline-warning {
  color: #da7400;
  border-color: #da7400;
}
[class*=bg-lvl] .card .badge.badge-discreet-warning,
[class*=bg-alt-lvl] .card .badge.badge-discreet-warning {
  color: #da7400;
}
[class*=bg-lvl] .card .text-warning,
[class*=bg-alt-lvl] .card .text-warning {
  color: #da7400 !important;
}
[class*=bg-lvl] .card .btn.btn-danger,
[class*=bg-alt-lvl] .card .btn.btn-danger {
  color: #fff;
  background-color: #dd3724;
  border: none;
}
[class*=bg-lvl] .card .btn.btn-danger:hover:not(:disabled):not(.disabled),
[class*=bg-alt-lvl] .card .btn.btn-danger:hover:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #c42f1e;
  border-color: #c42f1e;
}
[class*=bg-lvl] .card .btn.btn-danger:focus, [class*=bg-lvl] .card .btn.btn-danger.focus,
[class*=bg-alt-lvl] .card .btn.btn-danger:focus,
[class*=bg-alt-lvl] .card .btn.btn-danger.focus {
  color: #fff;
}
[class*=bg-lvl] .card .btn.btn-danger:not(:disabled):not(.disabled):active, [class*=bg-lvl] .card .btn.btn-danger:not(:disabled):not(.disabled).active,
[class*=bg-alt-lvl] .card .btn.btn-danger:not(:disabled):not(.disabled):active,
[class*=bg-alt-lvl] .card .btn.btn-danger:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #dd3724;
  border-color: #dd3724;
}
[class*=bg-lvl] .card .btn.btn-outline-danger,
[class*=bg-alt-lvl] .card .btn.btn-outline-danger {
  color: #dd3724;
  border-color: #dd3724;
}
[class*=bg-lvl] .card .btn.btn-outline-danger:hover:not(:disabled):not(.disabled),
[class*=bg-alt-lvl] .card .btn.btn-outline-danger:hover:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #c42f1e;
  border-color: #c42f1e;
}
[class*=bg-lvl] .card .btn.btn-outline-danger:focus, [class*=bg-lvl] .card .btn.btn-outline-danger.focus,
[class*=bg-alt-lvl] .card .btn.btn-outline-danger:focus,
[class*=bg-alt-lvl] .card .btn.btn-outline-danger.focus {
  color: #fff;
}
[class*=bg-lvl] .card .btn.btn-outline-danger:not(:disabled):not(.disabled):active, [class*=bg-lvl] .card .btn.btn-outline-danger:not(:disabled):not(.disabled).active,
[class*=bg-alt-lvl] .card .btn.btn-outline-danger:not(:disabled):not(.disabled):active,
[class*=bg-alt-lvl] .card .btn.btn-outline-danger:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #dd3724;
  border-color: #dd3724;
}
[class*=bg-lvl] .card .btn.btn-discreet-danger,
[class*=bg-alt-lvl] .card .btn.btn-discreet-danger {
  color: #dd3724;
}
[class*=bg-lvl] .card .btn.btn-discreet-danger:hover:not(:disabled):not(.disabled),
[class*=bg-alt-lvl] .card .btn.btn-discreet-danger:hover:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #c42f1e;
  border-color: #c42f1e;
}
[class*=bg-lvl] .card .btn.btn-discreet-danger:focus, [class*=bg-lvl] .card .btn.btn-discreet-danger.focus,
[class*=bg-alt-lvl] .card .btn.btn-discreet-danger:focus,
[class*=bg-alt-lvl] .card .btn.btn-discreet-danger.focus {
  color: #fff;
}
[class*=bg-lvl] .card .btn.btn-discreet-danger:not(:disabled):not(.disabled):active, [class*=bg-lvl] .card .btn.btn-discreet-danger:not(:disabled):not(.disabled).active,
[class*=bg-alt-lvl] .card .btn.btn-discreet-danger:not(:disabled):not(.disabled):active,
[class*=bg-alt-lvl] .card .btn.btn-discreet-danger:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #dd3724;
  border-color: #dd3724;
}
[class*=bg-lvl] .card .btn.btn-discreet-danger,
[class*=bg-alt-lvl] .card .btn.btn-discreet-danger {
  color: #dd3724;
}
[class*=bg-lvl] .card .btn.btn-discreet-danger:hover:not(:disabled):not(.disabled),
[class*=bg-alt-lvl] .card .btn.btn-discreet-danger:hover:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #c42f1e;
  border-color: #c42f1e;
}
[class*=bg-lvl] .card .btn.btn-discreet-danger:focus, [class*=bg-lvl] .card .btn.btn-discreet-danger.focus,
[class*=bg-alt-lvl] .card .btn.btn-discreet-danger:focus,
[class*=bg-alt-lvl] .card .btn.btn-discreet-danger.focus {
  color: #fff;
}
[class*=bg-lvl] .card .btn.btn-discreet-danger:not(:disabled):not(.disabled):active, [class*=bg-lvl] .card .btn.btn-discreet-danger:not(:disabled):not(.disabled).active,
[class*=bg-alt-lvl] .card .btn.btn-discreet-danger:not(:disabled):not(.disabled):active,
[class*=bg-alt-lvl] .card .btn.btn-discreet-danger:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
  background-color: #dd3724;
  border-color: #dd3724;
}
[class*=bg-lvl] .card .badge.badge-danger,
[class*=bg-alt-lvl] .card .badge.badge-danger {
  color: #fff;
  background-color: #dd3724;
}
[class*=bg-lvl] .card .badge.badge-outline-danger,
[class*=bg-alt-lvl] .card .badge.badge-outline-danger {
  color: #dd3724;
  border-color: #dd3724;
}
[class*=bg-lvl] .card .badge.badge-discreet-danger,
[class*=bg-alt-lvl] .card .badge.badge-discreet-danger {
  color: #dd3724;
}
[class*=bg-lvl] .card .text-danger,
[class*=bg-alt-lvl] .card .text-danger {
  color: #dd3724 !important;
}

.breadcrumb {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0.5rem 0;
  margin-bottom: 1rem;
  list-style: none;
  background-color: transparent;
}

.breadcrumb-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.75rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.75rem;
  color: #6e777a;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #000;
}

.breadcrumb-item {
  font-weight: 500;
}

.pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.3125rem 0.9375rem;
  margin-left: -1px;
  line-height: 1.4285714286;
  color: #000;
  background-color: transparent;
  border: 1px solid #000;
}
.page-link:hover {
  z-index: 2;
  color: #fff;
  text-decoration: none;
  background-color: #000;
  border-color: #000;
}
.page-link:focus {
  z-index: 3;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.page-item:first-child .page-link {
  margin-left: 0;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #000;
  border-color: #000;
}
.page-item.disabled .page-link {
  color: #000;
  pointer-events: none;
  cursor: auto;
  background-color: transparent;
  border-color: #000;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  line-height: 1.5;
}
.pagination-sm .page-link {
  padding: 0rem 0.5rem;
  font-size: 0.75rem;
  line-height: 1.3333333333;
}
.pagination .page-link {
  padding: 0.3125rem 0.9375rem;
  font-size: 0.875rem;
  line-height: 1.4285714286;
}
.pagination .page-item.disabled {
  opacity: 0.4;
}
.pagination .page-item.disabled .page-link {
  background: transparent;
}

.page-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  min-height: 2rem;
}
.page-link:focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(48, 51, 51, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(48, 51, 51, 0.25);
  background: #000;
}

.pagination-borderless .page-item.active .page-link {
  color: #000;
  border-color: transparent;
  background: rgba(0, 0, 0, 0.2);
}
.pagination-borderless .page-link {
  margin-left: 0;
  color: #000;
  border-color: transparent;
}
.pagination-borderless .page-link:focus, .pagination-borderless .page-link:hover {
  background: rgba(0, 0, 0, 0.1);
}
.pagination-borderless .page-link:active {
  background: rgba(0, 0, 0, 0.2);
}
.pagination-borderless .page-item.disabled .page-link {
  border-color: transparent;
}

.pagination-outline .page-item.active .page-link,
.pagination-outline .page-link:focus,
.pagination-outline .page-link:active,
.pagination-outline .page-link:hover {
  background: transparent;
  border-color: #000;
  color: #000;
}

.pagination-outline-primary .page-item.active .page-link,
.pagination-outline-primary .page-link:focus,
.pagination-outline-primary .page-link:active,
.pagination-outline-primary .page-link:hover {
  background: transparent;
  border-color: #000;
  color: #000;
}
.pagination-outline-primary .page-link {
  border-color: transparent;
  background: transparent;
}

.pagination-primary:not(.pagination-borderless) .page-item .page-link {
  border-color: #000;
}
.pagination-primary.pagination-borderless .page-item.active .page-link {
  border-color: #000;
}
.pagination-primary.pagination-borderless .page-item .page-link:hover,
.pagination-primary.pagination-borderless .page-item .page-link:focus,
.pagination-primary.pagination-borderless .page-item .page-link:active {
  border-color: #000;
}
.pagination-primary .page-item.active .page-link,
.pagination-primary .page-link:focus,
.pagination-primary .page-link:active,
.pagination-primary .page-link:hover {
  background: #000;
  border-color: #000;
  color: #fff;
}

.pagination-outline-secondary .page-item.active .page-link,
.pagination-outline-secondary .page-link:focus,
.pagination-outline-secondary .page-link:active,
.pagination-outline-secondary .page-link:hover {
  background: transparent;
  border-color: #6e777a;
  color: #6e777a;
}
.pagination-outline-secondary .page-link {
  border-color: transparent;
  background: transparent;
}

.pagination-secondary:not(.pagination-borderless) .page-item .page-link {
  border-color: #6e777a;
}
.pagination-secondary.pagination-borderless .page-item.active .page-link {
  border-color: #6e777a;
}
.pagination-secondary.pagination-borderless .page-item .page-link:hover,
.pagination-secondary.pagination-borderless .page-item .page-link:focus,
.pagination-secondary.pagination-borderless .page-item .page-link:active {
  border-color: #6e777a;
}
.pagination-secondary .page-item.active .page-link,
.pagination-secondary .page-link:focus,
.pagination-secondary .page-link:active,
.pagination-secondary .page-link:hover {
  background: #6e777a;
  border-color: #6e777a;
  color: #fff;
}

.pagination-outline-success .page-item.active .page-link,
.pagination-outline-success .page-link:focus,
.pagination-outline-success .page-link:active,
.pagination-outline-success .page-link:hover {
  background: transparent;
  border-color: #00aa0c;
  color: #00aa0c;
}
.pagination-outline-success .page-link {
  border-color: transparent;
  background: transparent;
}

.pagination-success:not(.pagination-borderless) .page-item .page-link {
  border-color: #00aa0c;
}
.pagination-success.pagination-borderless .page-item.active .page-link {
  border-color: #00aa0c;
}
.pagination-success.pagination-borderless .page-item .page-link:hover,
.pagination-success.pagination-borderless .page-item .page-link:focus,
.pagination-success.pagination-borderless .page-item .page-link:active {
  border-color: #00aa0c;
}
.pagination-success .page-item.active .page-link,
.pagination-success .page-link:focus,
.pagination-success .page-link:active,
.pagination-success .page-link:hover {
  background: #00aa0c;
  border-color: #00aa0c;
  color: #fff;
}

.pagination-outline-info .page-item.active .page-link,
.pagination-outline-info .page-link:focus,
.pagination-outline-info .page-link:active,
.pagination-outline-info .page-link:hover {
  background: transparent;
  border-color: #2469ff;
  color: #2469ff;
}
.pagination-outline-info .page-link {
  border-color: transparent;
  background: transparent;
}

.pagination-info:not(.pagination-borderless) .page-item .page-link {
  border-color: #2469ff;
}
.pagination-info.pagination-borderless .page-item.active .page-link {
  border-color: #2469ff;
}
.pagination-info.pagination-borderless .page-item .page-link:hover,
.pagination-info.pagination-borderless .page-item .page-link:focus,
.pagination-info.pagination-borderless .page-item .page-link:active {
  border-color: #2469ff;
}
.pagination-info .page-item.active .page-link,
.pagination-info .page-link:focus,
.pagination-info .page-link:active,
.pagination-info .page-link:hover {
  background: #2469ff;
  border-color: #2469ff;
  color: #fff;
}

.pagination-outline-warning .page-item.active .page-link,
.pagination-outline-warning .page-link:focus,
.pagination-outline-warning .page-link:active,
.pagination-outline-warning .page-link:hover {
  background: transparent;
  border-color: #da7400;
  color: #da7400;
}
.pagination-outline-warning .page-link {
  border-color: transparent;
  background: transparent;
}

.pagination-warning:not(.pagination-borderless) .page-item .page-link {
  border-color: #da7400;
}
.pagination-warning.pagination-borderless .page-item.active .page-link {
  border-color: #da7400;
}
.pagination-warning.pagination-borderless .page-item .page-link:hover,
.pagination-warning.pagination-borderless .page-item .page-link:focus,
.pagination-warning.pagination-borderless .page-item .page-link:active {
  border-color: #da7400;
}
.pagination-warning .page-item.active .page-link,
.pagination-warning .page-link:focus,
.pagination-warning .page-link:active,
.pagination-warning .page-link:hover {
  background: #da7400;
  border-color: #da7400;
  color: #fff;
}

.pagination-outline-danger .page-item.active .page-link,
.pagination-outline-danger .page-link:focus,
.pagination-outline-danger .page-link:active,
.pagination-outline-danger .page-link:hover {
  background: transparent;
  border-color: #dd3724;
  color: #dd3724;
}
.pagination-outline-danger .page-link {
  border-color: transparent;
  background: transparent;
}

.pagination-danger:not(.pagination-borderless) .page-item .page-link {
  border-color: #dd3724;
}
.pagination-danger.pagination-borderless .page-item.active .page-link {
  border-color: #dd3724;
}
.pagination-danger.pagination-borderless .page-item .page-link:hover,
.pagination-danger.pagination-borderless .page-item .page-link:focus,
.pagination-danger.pagination-borderless .page-item .page-link:active {
  border-color: #dd3724;
}
.pagination-danger .page-item.active .page-link,
.pagination-danger .page-link:focus,
.pagination-danger .page-link:active,
.pagination-danger .page-link:hover {
  background: #dd3724;
  border-color: #dd3724;
  color: #fff;
}

.pagination-outline-light .page-item.active .page-link,
.pagination-outline-light .page-link:focus,
.pagination-outline-light .page-link:active,
.pagination-outline-light .page-link:hover {
  background: transparent;
  border-color: #c4c8ca;
  color: #c4c8ca;
}
.pagination-outline-light .page-link {
  border-color: transparent;
  background: transparent;
}

.pagination-light:not(.pagination-borderless) .page-item .page-link {
  border-color: #c4c8ca;
}
.pagination-light.pagination-borderless .page-item.active .page-link {
  border-color: #c4c8ca;
}
.pagination-light.pagination-borderless .page-item .page-link:hover,
.pagination-light.pagination-borderless .page-item .page-link:focus,
.pagination-light.pagination-borderless .page-item .page-link:active {
  border-color: #c4c8ca;
}
.pagination-light .page-item.active .page-link,
.pagination-light .page-link:focus,
.pagination-light .page-link:active,
.pagination-light .page-link:hover {
  background: #c4c8ca;
  border-color: #c4c8ca;
  color: #fff;
}

.pagination-outline-dark .page-item.active .page-link,
.pagination-outline-dark .page-link:focus,
.pagination-outline-dark .page-link:active,
.pagination-outline-dark .page-link:hover {
  background: transparent;
  border-color: #414949;
  color: #414949;
}
.pagination-outline-dark .page-link {
  border-color: transparent;
  background: transparent;
}

.pagination-dark:not(.pagination-borderless) .page-item .page-link {
  border-color: #414949;
}
.pagination-dark.pagination-borderless .page-item.active .page-link {
  border-color: #414949;
}
.pagination-dark.pagination-borderless .page-item .page-link:hover,
.pagination-dark.pagination-borderless .page-item .page-link:focus,
.pagination-dark.pagination-borderless .page-item .page-link:active {
  border-color: #414949;
}
.pagination-dark .page-item.active .page-link,
.pagination-dark .page-link:focus,
.pagination-dark .page-link:active,
.pagination-dark .page-link:hover {
  background: #414949;
  border-color: #414949;
  color: #fff;
}

.pagination-outline-white .page-item.active .page-link,
.pagination-outline-white .page-link:focus,
.pagination-outline-white .page-link:active,
.pagination-outline-white .page-link:hover {
  background: transparent;
  border-color: white;
  color: white;
}
.pagination-outline-white .page-link {
  border-color: transparent;
  background: transparent;
}

.pagination-white:not(.pagination-borderless) .page-item .page-link {
  border-color: white;
}
.pagination-white.pagination-borderless .page-item.active .page-link {
  border-color: white;
}
.pagination-white.pagination-borderless .page-item .page-link:hover,
.pagination-white.pagination-borderless .page-item .page-link:focus,
.pagination-white.pagination-borderless .page-item .page-link:active {
  border-color: white;
}
.pagination-white .page-item.active .page-link,
.pagination-white .page-link:focus,
.pagination-white .page-link:active,
.pagination-white .page-link:hover {
  background: white;
  border-color: white;
  color: #fff;
}

.pagination-outline-black .page-item.active .page-link,
.pagination-outline-black .page-link:focus,
.pagination-outline-black .page-link:active,
.pagination-outline-black .page-link:hover {
  background: transparent;
  border-color: #231f20;
  color: #231f20;
}
.pagination-outline-black .page-link {
  border-color: transparent;
  background: transparent;
}

.pagination-black:not(.pagination-borderless) .page-item .page-link {
  border-color: #231f20;
}
.pagination-black.pagination-borderless .page-item.active .page-link {
  border-color: #231f20;
}
.pagination-black.pagination-borderless .page-item .page-link:hover,
.pagination-black.pagination-borderless .page-item .page-link:focus,
.pagination-black.pagination-borderless .page-item .page-link:active {
  border-color: #231f20;
}
.pagination-black .page-item.active .page-link,
.pagination-black .page-link:focus,
.pagination-black .page-link:active,
.pagination-black .page-link:hover {
  background: #231f20;
  border-color: #231f20;
  color: #fff;
}

.pagination-outline-socgen .page-item.active .page-link,
.pagination-outline-socgen .page-link:focus,
.pagination-outline-socgen .page-link:active,
.pagination-outline-socgen .page-link:hover {
  background: transparent;
  border-color: #e60028;
  color: #e60028;
}
.pagination-outline-socgen .page-link {
  border-color: transparent;
  background: transparent;
}

.pagination-socgen:not(.pagination-borderless) .page-item .page-link {
  border-color: #e60028;
}
.pagination-socgen.pagination-borderless .page-item.active .page-link {
  border-color: #e60028;
}
.pagination-socgen.pagination-borderless .page-item .page-link:hover,
.pagination-socgen.pagination-borderless .page-item .page-link:focus,
.pagination-socgen.pagination-borderless .page-item .page-link:active {
  border-color: #e60028;
}
.pagination-socgen .page-item.active .page-link,
.pagination-socgen .page-link:focus,
.pagination-socgen .page-link:active,
.pagination-socgen .page-link:hover {
  background: #e60028;
  border-color: #e60028;
  color: #fff;
}

.pagination-outline-primary-alt .page-item.active .page-link,
.pagination-outline-primary-alt .page-link:focus,
.pagination-outline-primary-alt .page-link:active,
.pagination-outline-primary-alt .page-link:hover {
  background: transparent;
  border-color: #fff;
  color: #fff;
}
.pagination-outline-primary-alt .page-link {
  border-color: transparent;
  background: transparent;
}

.pagination-primary-alt:not(.pagination-borderless) .page-item .page-link {
  border-color: #fff;
}
.pagination-primary-alt.pagination-borderless .page-item.active .page-link {
  border-color: #fff;
}
.pagination-primary-alt.pagination-borderless .page-item .page-link:hover,
.pagination-primary-alt.pagination-borderless .page-item .page-link:focus,
.pagination-primary-alt.pagination-borderless .page-item .page-link:active {
  border-color: #fff;
}
.pagination-primary-alt .page-item.active .page-link,
.pagination-primary-alt .page-link:focus,
.pagination-primary-alt .page-link:active,
.pagination-primary-alt .page-link:hover {
  background: #fff;
  border-color: #fff;
  color: #fff;
}

.pagination-sm .page-link {
  padding: 0rem 0.5rem;
  font-size: 0.75rem;
  line-height: 1.5;
}
.pagination-sm .page-link {
  min-height: 1.25rem;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 0.875rem;
  line-height: 1.5714285714;
}
.pagination-lg .page-link {
  min-height: 3rem;
}

.pagination-xl .page-link {
  padding: 0.6875rem 1.4375rem;
  font-size: 1rem;
  line-height: 1.5;
}
.pagination-xl .page-link {
  min-height: 3rem;
}

.badge {
  display: inline-block;
  padding: 0.125rem 0.3125rem;
  font-size: auto;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    -webkit-transition: none;
    transition: none;
  }
}
a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.46em;
  padding-left: 0.46em;
}

.badge-primary {
  color: #fff;
  background-color: #000;
}
a.badge-primary:hover, a.badge-primary:focus {
  color: #fff;
  background-color: black;
}
a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #6e777a;
}
a.badge-secondary:hover, a.badge-secondary:focus {
  color: #fff;
  background-color: #565d5f;
}
a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(110, 119, 122, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(110, 119, 122, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #00aa0c;
}
a.badge-success:hover, a.badge-success:focus {
  color: #fff;
  background-color: #007708;
}
a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 170, 12, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(0, 170, 12, 0.5);
}

.badge-info {
  color: #fff;
  background-color: #2469ff;
}
a.badge-info:hover, a.badge-info:focus {
  color: #fff;
  background-color: #004cf0;
}
a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(36, 105, 255, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(36, 105, 255, 0.5);
}

.badge-warning {
  color: #fff;
  background-color: #da7400;
}
a.badge-warning:hover, a.badge-warning:focus {
  color: #fff;
  background-color: #a75900;
}
a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(218, 116, 0, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(218, 116, 0, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #dd3724;
}
a.badge-danger:hover, a.badge-danger:focus {
  color: #fff;
  background-color: #b22b1c;
}
a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(221, 55, 36, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(221, 55, 36, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #c4c8ca;
}
a.badge-light:hover, a.badge-light:focus {
  color: #212529;
  background-color: #a9afb2;
}
a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(196, 200, 202, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(196, 200, 202, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #414949;
}
a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: #292e2e;
}
a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(65, 73, 73, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(65, 73, 73, 0.5);
}

.badge-white {
  color: #212529;
  background-color: white;
}
a.badge-white:hover, a.badge-white:focus {
  color: #212529;
  background-color: #e6e6e6;
}
a.badge-white:focus, a.badge-white.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.badge-black {
  color: #fff;
  background-color: #231f20;
}
a.badge-black:hover, a.badge-black:focus {
  color: #fff;
  background-color: #080707;
}
a.badge-black:focus, a.badge-black.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(35, 31, 32, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(35, 31, 32, 0.5);
}

.badge-socgen {
  color: #fff;
  background-color: #e60028;
}
a.badge-socgen:hover, a.badge-socgen:focus {
  color: #fff;
  background-color: #b3001f;
}
a.badge-socgen:focus, a.badge-socgen.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(230, 0, 40, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(230, 0, 40, 0.5);
}

.badge-primary-alt {
  color: #212529;
  background-color: #fff;
}
a.badge-primary-alt:hover, a.badge-primary-alt:focus {
  color: #212529;
  background-color: #e6e6e6;
}
a.badge-primary-alt:focus, a.badge-primary-alt.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.badge {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  border: 0.0625rem solid transparent;
  color: #fff;
  font-weight: 500;
}

a[href].badge:hover, a[href].badge:focus {
  text-decoration: none;
  color: #fff;
}

.badge-pill {
  border-radius: 10rem;
}

.badge-prepend-square {
  position: relative;
  padding-left: 1rem;
}
.badge-prepend-square::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0.3125rem;
  margin: auto;
  width: 0.375rem;
  height: 0.375rem;
  background: currentColor;
}

.badge-prepend-icon {
  width: 1.05em;
  height: 1.05em;
  margin: -2px;
  margin-right: 0.05em;
}

.badge-icon {
  width: 1.05em;
  height: 1.05em;
  margin: -0.525em;
}

.badge-sm {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  padding: 0.125rem 0.375rem;
  height: 1.125rem;
  font-size: 0.75rem;
}
.badge-sm.badge-prepend-square::before {
  position: relative;
  margin-right: 0.375rem;
  width: 0.375rem;
  height: 0.375rem;
  left: auto;
}

.badge-lg {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  padding: 0.125rem 0.59375rem;
  height: 1.5625rem;
  font-size: 0.875rem;
}
.badge-lg.badge-prepend-square::before {
  position: relative;
  margin-right: 0.59375rem;
  width: 0.375rem;
  height: 0.375rem;
  left: auto;
}

.badge-xl {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  padding: 0.125rem 0.78125rem;
  height: 2rem;
  font-size: 1rem;
}
.badge-xl.badge-prepend-square::before {
  position: relative;
  margin-right: 0.78125rem;
  width: 0.4375rem;
  height: 0.4375rem;
  left: auto;
}

.bg-lvl4 .badge.badge-secondary {
  background-color: #5f6668;
  border-color: #5f6668;
}

.bg-lvl4 .badge.badge-light {
  background-color: #b1b7b9;
  border-color: #b1b7b9;
}

.bg-lvl4 .badge.badge-dark {
  background-color: #323939;
  border-color: #323939;
}

.bg-lvl4 .badge.badge-info {
  background-color: #0554ff;
  border-color: #0554ff;
}

.bg-lvl4 .badge.badge-success {
  background-color: #008a09;
  border-color: #008a09;
}

.bg-lvl4 .badge.badge-warning {
  background-color: #bc6500;
  border-color: #bc6500;
}

.bg-lvl4 .badge.badge-danger {
  background-color: #c22f1f;
  border-color: #c22f1f;
}

.bg-lvl3 .badge.badge-secondary {
  background-color: #666e70;
  border-color: #666e70;
}

.bg-lvl3 .badge.badge-light {
  background-color: #b9bec0;
  border-color: #b9bec0;
}

.bg-lvl3 .badge.badge-dark {
  background-color: #373e3e;
  border-color: #373e3e;
}

.bg-lvl3 .badge.badge-info {
  background-color: #0f5bff;
  border-color: #0f5bff;
}

.bg-lvl3 .badge.badge-success {
  background-color: #00940a;
  border-color: #00940a;
}

.bg-lvl3 .badge.badge-warning {
  background-color: #c66a00;
  border-color: #c66a00;
}

.bg-lvl3 .badge.badge-danger {
  background-color: #cb3120;
  border-color: #cb3120;
}

.bg-lvl2 .badge.badge-secondary {
  background-color: #687073;
  border-color: #687073;
}

.bg-lvl2 .badge.badge-light {
  background-color: #c1c5c7;
  border-color: #c1c5c7;
}

.bg-lvl2 .badge.badge-dark {
  background-color: #3c4343;
  border-color: #3c4343;
}

.bg-lvl2 .badge.badge-info {
  background-color: #1a62ff;
  border-color: #1a62ff;
}

.bg-lvl2 .badge.badge-success {
  background-color: #009e0b;
  border-color: #009e0b;
}

.bg-lvl2 .badge.badge-warning {
  background-color: #d06f00;
  border-color: #d06f00;
}

.bg-lvl2 .badge.badge-danger {
  background-color: #d43321;
  border-color: #d43321;
}

.bg-lvl1 .badge.badge-secondary {
  background-color: #6e777a;
  border-color: #6e777a;
}

.bg-lvl1 .badge.badge-light {
  background-color: #c4c8ca;
  border-color: #c4c8ca;
}

.bg-lvl1 .badge.badge-dark {
  background-color: #414949;
  border-color: #414949;
}

.bg-lvl1 .badge.badge-info {
  background-color: #2469ff;
  border-color: #2469ff;
}

.bg-lvl1 .badge.badge-success {
  background-color: #00aa0c;
  border-color: #00aa0c;
}

.bg-lvl1 .badge.badge-warning {
  background-color: #da7400;
  border-color: #da7400;
}

.bg-lvl1 .badge.badge-danger {
  background-color: #dd3724;
  border-color: #dd3724;
}

.bg-alt-lvl4 .badge.badge-secondary {
  background-color: #70797a;
  border-color: #70797a;
}

.bg-alt-lvl4 .badge.badge-light {
  background-color: #a3afb3;
  border-color: #a3afb3;
}

.bg-alt-lvl4 .badge.badge-dark {
  background-color: #343737;
  border-color: #343737;
}

.bg-alt-lvl4 .badge.badge-info {
  background-color: #1476ff;
  border-color: #1476ff;
}

.bg-alt-lvl4 .badge.badge-success {
  background-color: #00a83e;
  border-color: #00a83e;
}

.bg-alt-lvl4 .badge.badge-warning {
  background-color: #cc7400;
  border-color: #cc7400;
}

.bg-alt-lvl4 .badge.badge-danger {
  background-color: #d93d26;
  border-color: #d93d26;
}

.bg-alt-lvl3 .badge.badge-secondary {
  background-color: #7a8385;
  border-color: #7a8385;
}

.bg-alt-lvl3 .badge.badge-light {
  background-color: #b1bbbe;
  border-color: #b1bbbe;
}

.bg-alt-lvl3 .badge.badge-dark {
  background-color: #3b3f3f;
  border-color: #3b3f3f;
}

.bg-alt-lvl3 .badge.badge-info {
  background-color: #2982ff;
  border-color: #2982ff;
}

.bg-alt-lvl3 .badge.badge-success {
  background-color: #00b844;
  border-color: #00b844;
}

.bg-alt-lvl3 .badge.badge-warning {
  background-color: #db7c00;
  border-color: #db7c00;
}

.bg-alt-lvl3 .badge.badge-danger {
  background-color: #e8442c;
  border-color: #e8442c;
}

.bg-alt-lvl2 .badge.badge-secondary {
  background-color: #848d8f;
  border-color: #848d8f;
}

.bg-alt-lvl2 .badge.badge-light {
  background-color: #c2cacc;
  border-color: #c2cacc;
}

.bg-alt-lvl2 .badge.badge-dark {
  background-color: #454a4a;
  border-color: #454a4a;
}

.bg-alt-lvl2 .badge.badge-info {
  background-color: #3d8eff;
  border-color: #3d8eff;
}

.bg-alt-lvl2 .badge.badge-success {
  background-color: #00c74a;
  border-color: #00c74a;
}

.bg-alt-lvl2 .badge.badge-warning {
  background-color: #eb8500;
  border-color: #eb8500;
}

.bg-alt-lvl2 .badge.badge-danger {
  background-color: #f44d34;
  border-color: #f44d34;
}

.bg-alt-lvl1 .badge.badge-secondary {
  background-color: #949c9e;
  border-color: #949c9e;
}

.bg-alt-lvl1 .badge.badge-light {
  background-color: #d8ddde;
  border-color: #d8ddde;
}

.bg-alt-lvl1 .badge.badge-dark {
  background-color: #515757;
  border-color: #515757;
}

.bg-alt-lvl1 .badge.badge-info {
  background-color: #529aff;
  border-color: #529aff;
}

.bg-alt-lvl1 .badge.badge-success {
  background-color: #00d750;
  border-color: #00d750;
}

.bg-alt-lvl1 .badge.badge-warning {
  background-color: #f98d00;
  border-color: #f98d00;
}

.bg-alt-lvl1 .badge.badge-danger {
  background-color: #ff563c;
  border-color: #ff563c;
}

.bg-alt-lvl4 .badge.badge-secondary {
  color: #000;
}

.bg-alt-lvl4 .badge.badge-light {
  color: #000;
}

.bg-alt-lvl4 .badge.badge-dark {
  color: #000;
}

.bg-alt-lvl4 .badge.badge-info {
  color: #000;
}

.bg-alt-lvl4 .badge.badge-success {
  color: #000;
}

.bg-alt-lvl4 .badge.badge-warning {
  color: #000;
}

.bg-alt-lvl4 .badge.badge-danger {
  color: #000;
}

.bg-alt-lvl3 .badge.badge-secondary {
  color: #000;
}

.bg-alt-lvl3 .badge.badge-light {
  color: #000;
}

.bg-alt-lvl3 .badge.badge-dark {
  color: #000;
}

.bg-alt-lvl3 .badge.badge-info {
  color: #000;
}

.bg-alt-lvl3 .badge.badge-success {
  color: #000;
}

.bg-alt-lvl3 .badge.badge-warning {
  color: #000;
}

.bg-alt-lvl3 .badge.badge-danger {
  color: #000;
}

.bg-alt-lvl2 .badge.badge-secondary {
  color: #000;
}

.bg-alt-lvl2 .badge.badge-light {
  color: #000;
}

.bg-alt-lvl2 .badge.badge-dark {
  color: #000;
}

.bg-alt-lvl2 .badge.badge-info {
  color: #000;
}

.bg-alt-lvl2 .badge.badge-success {
  color: #000;
}

.bg-alt-lvl2 .badge.badge-warning {
  color: #000;
}

.bg-alt-lvl2 .badge.badge-danger {
  color: #000;
}

.bg-alt-lvl1 .badge.badge-secondary {
  color: #000;
}

.bg-alt-lvl1 .badge.badge-light {
  color: #000;
}

.bg-alt-lvl1 .badge.badge-dark {
  color: #000;
}

.bg-alt-lvl1 .badge.badge-info {
  color: #000;
}

.bg-alt-lvl1 .badge.badge-success {
  color: #000;
}

.bg-alt-lvl1 .badge.badge-warning {
  color: #000;
}

.bg-alt-lvl1 .badge.badge-danger {
  color: #000;
}

.bg-lvl4 .badge.badge-secondary {
  color: #fff;
}

.bg-lvl4 .badge.badge-light {
  color: #fff;
}

.bg-lvl4 .badge.badge-dark {
  color: #fff;
}

.bg-lvl4 .badge.badge-info {
  color: #fff;
}

.bg-lvl4 .badge.badge-success {
  color: #fff;
}

.bg-lvl4 .badge.badge-warning {
  color: #fff;
}

.bg-lvl4 .badge.badge-danger {
  color: #fff;
}

.bg-lvl3 .badge.badge-secondary {
  color: #fff;
}

.bg-lvl3 .badge.badge-light {
  color: #fff;
}

.bg-lvl3 .badge.badge-dark {
  color: #fff;
}

.bg-lvl3 .badge.badge-info {
  color: #fff;
}

.bg-lvl3 .badge.badge-success {
  color: #fff;
}

.bg-lvl3 .badge.badge-warning {
  color: #fff;
}

.bg-lvl3 .badge.badge-danger {
  color: #fff;
}

.bg-lvl2 .badge.badge-secondary {
  color: #fff;
}

.bg-lvl2 .badge.badge-light {
  color: #fff;
}

.bg-lvl2 .badge.badge-dark {
  color: #fff;
}

.bg-lvl2 .badge.badge-info {
  color: #fff;
}

.bg-lvl2 .badge.badge-success {
  color: #fff;
}

.bg-lvl2 .badge.badge-warning {
  color: #fff;
}

.bg-lvl2 .badge.badge-danger {
  color: #fff;
}

.bg-lvl1 .badge.badge-secondary {
  color: #fff;
}

.bg-lvl1 .badge.badge-light {
  color: #fff;
}

.bg-lvl1 .badge.badge-dark {
  color: #fff;
}

.bg-lvl1 .badge.badge-info {
  color: #fff;
}

.bg-lvl1 .badge.badge-success {
  color: #fff;
}

.bg-lvl1 .badge.badge-warning {
  color: #fff;
}

.bg-lvl1 .badge.badge-danger {
  color: #fff;
}

.badge-primary {
  background-color: #000;
  border-color: #000;
}
.badge-primary.badge-primary-alt {
  color: #000;
}
.badge-primary.badge-prepend-square::before {
  opacity: 0.4;
}

.badge-outline-primary {
  background-color: transparent;
  border-color: #000;
  color: #000;
}

.badge-discreet-primary {
  color: #000;
  background-color: rgba(0, 0, 0, 0.1);
}

.badge-flat-primary {
  color: #000;
  background-color: transparent;
}

.badge-secondary {
  background-color: #6e777a;
  border-color: #6e777a;
}
.badge-secondary.badge-primary-alt {
  color: #000;
}
.badge-secondary.badge-prepend-square::before {
  opacity: 0.4;
}

.badge-outline-secondary {
  background-color: transparent;
  border-color: #6e777a;
  color: #6e777a;
}

.badge-discreet-secondary {
  color: #6e777a;
  background-color: rgba(110, 119, 122, 0.1);
}

.badge-flat-secondary {
  color: #6e777a;
  background-color: transparent;
}

.badge-success {
  background-color: #00aa0c;
  border-color: #00aa0c;
}
.badge-success.badge-primary-alt {
  color: #000;
}
.badge-success.badge-prepend-square::before {
  opacity: 0.4;
}

.badge-outline-success {
  background-color: transparent;
  border-color: #00aa0c;
  color: #00aa0c;
}

.badge-discreet-success {
  color: #00aa0c;
  background-color: rgba(0, 170, 12, 0.1);
}

.badge-flat-success {
  color: #00aa0c;
  background-color: transparent;
}

.badge-info {
  background-color: #2469ff;
  border-color: #2469ff;
}
.badge-info.badge-primary-alt {
  color: #000;
}
.badge-info.badge-prepend-square::before {
  opacity: 0.4;
}

.badge-outline-info {
  background-color: transparent;
  border-color: #2469ff;
  color: #2469ff;
}

.badge-discreet-info {
  color: #2469ff;
  background-color: rgba(36, 105, 255, 0.1);
}

.badge-flat-info {
  color: #2469ff;
  background-color: transparent;
}

.badge-warning {
  background-color: #da7400;
  border-color: #da7400;
}
.badge-warning.badge-primary-alt {
  color: #000;
}
.badge-warning.badge-prepend-square::before {
  opacity: 0.4;
}

.badge-outline-warning {
  background-color: transparent;
  border-color: #da7400;
  color: #da7400;
}

.badge-discreet-warning {
  color: #da7400;
  background-color: rgba(218, 116, 0, 0.1);
}

.badge-flat-warning {
  color: #da7400;
  background-color: transparent;
}

.badge-danger {
  background-color: #dd3724;
  border-color: #dd3724;
}
.badge-danger.badge-primary-alt {
  color: #000;
}
.badge-danger.badge-prepend-square::before {
  opacity: 0.4;
}

.badge-outline-danger {
  background-color: transparent;
  border-color: #dd3724;
  color: #dd3724;
}

.badge-discreet-danger {
  color: #dd3724;
  background-color: rgba(221, 55, 36, 0.1);
}

.badge-flat-danger {
  color: #dd3724;
  background-color: transparent;
}

.badge-light {
  background-color: #c4c8ca;
  border-color: #c4c8ca;
}
.badge-light.badge-primary-alt {
  color: #000;
}
.badge-light.badge-prepend-square::before {
  opacity: 0.4;
}

.badge-outline-light {
  background-color: transparent;
  border-color: #c4c8ca;
  color: #c4c8ca;
}

.badge-discreet-light {
  color: #c4c8ca;
  background-color: rgba(196, 200, 202, 0.1);
}

.badge-flat-light {
  color: #c4c8ca;
  background-color: transparent;
}

.badge-dark {
  background-color: #414949;
  border-color: #414949;
}
.badge-dark.badge-primary-alt {
  color: #000;
}
.badge-dark.badge-prepend-square::before {
  opacity: 0.4;
}

.badge-outline-dark {
  background-color: transparent;
  border-color: #414949;
  color: #414949;
}

.badge-discreet-dark {
  color: #414949;
  background-color: rgba(65, 73, 73, 0.1);
}

.badge-flat-dark {
  color: #414949;
  background-color: transparent;
}

.badge-white {
  background-color: white;
  border-color: white;
}
.badge-white.badge-primary-alt {
  color: #000;
}
.badge-white.badge-prepend-square::before {
  opacity: 0.4;
}

.badge-outline-white {
  background-color: transparent;
  border-color: white;
  color: white;
}

.badge-discreet-white {
  color: white;
  background-color: rgba(255, 255, 255, 0.1);
}

.badge-flat-white {
  color: white;
  background-color: transparent;
}

.badge-black {
  background-color: #231f20;
  border-color: #231f20;
}
.badge-black.badge-primary-alt {
  color: #000;
}
.badge-black.badge-prepend-square::before {
  opacity: 0.4;
}

.badge-outline-black {
  background-color: transparent;
  border-color: #231f20;
  color: #231f20;
}

.badge-discreet-black {
  color: #231f20;
  background-color: rgba(35, 31, 32, 0.1);
}

.badge-flat-black {
  color: #231f20;
  background-color: transparent;
}

.badge-socgen {
  background-color: #e60028;
  border-color: #e60028;
}
.badge-socgen.badge-primary-alt {
  color: #000;
}
.badge-socgen.badge-prepend-square::before {
  opacity: 0.4;
}

.badge-outline-socgen {
  background-color: transparent;
  border-color: #e60028;
  color: #e60028;
}

.badge-discreet-socgen {
  color: #e60028;
  background-color: rgba(230, 0, 40, 0.1);
}

.badge-flat-socgen {
  color: #e60028;
  background-color: transparent;
}

.badge-primary-alt {
  background-color: #fff;
  border-color: #fff;
}
.badge-primary-alt.badge-primary-alt {
  color: #000;
}
.badge-primary-alt.badge-prepend-square::before {
  opacity: 0.4;
}

.badge-outline-primary-alt {
  background-color: transparent;
  border-color: #fff;
  color: #fff;
}

.badge-discreet-primary-alt {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
}

.badge-flat-primary-alt {
  color: #fff;
  background-color: transparent;
}

.badge-apricot {
  background-color: #e88e5e;
  border-color: #e88e5e;
}
.badge-apricot.badge-primary-alt {
  color: #000;
}
.badge-apricot.badge-prepend-square::before {
  opacity: 0.4;
}

.badge-outline-apricot {
  background-color: transparent;
  border-color: #e88e5e;
  color: #e88e5e;
}

.badge-discreet-apricot {
  color: #e88e5e;
  background-color: rgba(232, 142, 94, 0.1);
}

.badge-flat-apricot {
  color: #e88e5e;
  background-color: transparent;
}

.badge-azure {
  background-color: #38699f;
  border-color: #38699f;
}
.badge-azure.badge-primary-alt {
  color: #000;
}
.badge-azure.badge-prepend-square::before {
  opacity: 0.4;
}

.badge-outline-azure {
  background-color: transparent;
  border-color: #38699f;
  color: #38699f;
}

.badge-discreet-azure {
  color: #38699f;
  background-color: rgba(56, 105, 159, 0.1);
}

.badge-flat-azure {
  color: #38699f;
  background-color: transparent;
}

.badge-cornflower-blue {
  background-color: #6488ff;
  border-color: #6488ff;
}
.badge-cornflower-blue.badge-primary-alt {
  color: #000;
}
.badge-cornflower-blue.badge-prepend-square::before {
  opacity: 0.4;
}

.badge-outline-cornflower-blue {
  background-color: transparent;
  border-color: #6488ff;
  color: #6488ff;
}

.badge-discreet-cornflower-blue {
  color: #6488ff;
  background-color: rgba(100, 136, 255, 0.1);
}

.badge-flat-cornflower-blue {
  color: #6488ff;
  background-color: transparent;
}

.badge-faded-jade {
  background-color: #408182;
  border-color: #408182;
}
.badge-faded-jade.badge-primary-alt {
  color: #000;
}
.badge-faded-jade.badge-prepend-square::before {
  opacity: 0.4;
}

.badge-outline-faded-jade {
  background-color: transparent;
  border-color: #408182;
  color: #408182;
}

.badge-discreet-faded-jade {
  color: #408182;
  background-color: rgba(64, 129, 130, 0.1);
}

.badge-flat-faded-jade {
  color: #408182;
  background-color: transparent;
}

.badge-heather {
  background-color: #b4bbcb;
  border-color: #b4bbcb;
}
.badge-heather.badge-primary-alt {
  color: #000;
}
.badge-heather.badge-prepend-square::before {
  opacity: 0.4;
}

.badge-outline-heather {
  background-color: transparent;
  border-color: #b4bbcb;
  color: #b4bbcb;
}

.badge-discreet-heather {
  color: #b4bbcb;
  background-color: rgba(180, 187, 203, 0.1);
}

.badge-flat-heather {
  color: #b4bbcb;
  background-color: transparent;
}

.badge-mako {
  background-color: #444852;
  border-color: #444852;
}
.badge-mako.badge-primary-alt {
  color: #000;
}
.badge-mako.badge-prepend-square::before {
  opacity: 0.4;
}

.badge-outline-mako {
  background-color: transparent;
  border-color: #444852;
  color: #444852;
}

.badge-discreet-mako {
  color: #444852;
  background-color: rgba(68, 72, 82, 0.1);
}

.badge-flat-mako {
  color: #444852;
  background-color: transparent;
}

.badge-puerto-rico {
  background-color: #41c49d;
  border-color: #41c49d;
}
.badge-puerto-rico.badge-primary-alt {
  color: #000;
}
.badge-puerto-rico.badge-prepend-square::before {
  opacity: 0.4;
}

.badge-outline-puerto-rico {
  background-color: transparent;
  border-color: #41c49d;
  color: #41c49d;
}

.badge-discreet-puerto-rico {
  color: #41c49d;
  background-color: rgba(65, 196, 157, 0.1);
}

.badge-flat-puerto-rico {
  color: #41c49d;
  background-color: transparent;
}

.badge-royal-blue {
  background-color: #5c56e8;
  border-color: #5c56e8;
}
.badge-royal-blue.badge-primary-alt {
  color: #000;
}
.badge-royal-blue.badge-prepend-square::before {
  opacity: 0.4;
}

.badge-outline-royal-blue {
  background-color: transparent;
  border-color: #5c56e8;
  color: #5c56e8;
}

.badge-discreet-royal-blue {
  color: #5c56e8;
  background-color: rgba(92, 86, 232, 0.1);
}

.badge-flat-royal-blue {
  color: #5c56e8;
  background-color: transparent;
}

.badge-spicy-mix {
  background-color: #854b4b;
  border-color: #854b4b;
}
.badge-spicy-mix.badge-primary-alt {
  color: #000;
}
.badge-spicy-mix.badge-prepend-square::before {
  opacity: 0.4;
}

.badge-outline-spicy-mix {
  background-color: transparent;
  border-color: #854b4b;
  color: #854b4b;
}

.badge-discreet-spicy-mix {
  color: #854b4b;
  background-color: rgba(133, 75, 75, 0.1);
}

.badge-flat-spicy-mix {
  color: #854b4b;
  background-color: transparent;
}

.badge-valencia {
  background-color: #dc4941;
  border-color: #dc4941;
}
.badge-valencia.badge-primary-alt {
  color: #000;
}
.badge-valencia.badge-prepend-square::before {
  opacity: 0.4;
}

.badge-outline-valencia {
  background-color: transparent;
  border-color: #dc4941;
  color: #dc4941;
}

.badge-discreet-valencia {
  color: #dc4941;
  background-color: rgba(220, 73, 65, 0.1);
}

.badge-flat-valencia {
  color: #dc4941;
  background-color: transparent;
}

.badge-victoria {
  background-color: #514b90;
  border-color: #514b90;
}
.badge-victoria.badge-primary-alt {
  color: #000;
}
.badge-victoria.badge-prepend-square::before {
  opacity: 0.4;
}

.badge-outline-victoria {
  background-color: transparent;
  border-color: #514b90;
  color: #514b90;
}

.badge-discreet-victoria {
  color: #514b90;
  background-color: rgba(81, 75, 144, 0.1);
}

.badge-flat-victoria {
  color: #514b90;
  background-color: transparent;
}

.badge-mars {
  background-color: #d53229;
  border-color: #d53229;
}
.badge-mars.badge-primary-alt {
  color: #000;
}
.badge-mars.badge-prepend-square::before {
  opacity: 0.4;
}

.badge-outline-mars {
  background-color: transparent;
  border-color: #d53229;
  color: #d53229;
}

.badge-discreet-mars {
  color: #d53229;
  background-color: rgba(213, 50, 41, 0.1);
}

.badge-flat-mars {
  color: #d53229;
  background-color: transparent;
}

.badge-mercury {
  background-color: #e8e8e8;
  border-color: #e8e8e8;
}
.badge-mercury.badge-primary-alt {
  color: #000;
}
.badge-mercury.badge-prepend-square::before {
  opacity: 0.4;
}

.badge-outline-mercury {
  background-color: transparent;
  border-color: #e8e8e8;
  color: #e8e8e8;
}

.badge-discreet-mercury {
  color: #e8e8e8;
  background-color: rgba(232, 232, 232, 0.1);
}

.badge-flat-mercury {
  color: #e8e8e8;
  background-color: transparent;
}

.badge-manatee {
  background-color: #8b93a8;
  border-color: #8b93a8;
}
.badge-manatee.badge-primary-alt {
  color: #000;
}
.badge-manatee.badge-prepend-square::before {
  opacity: 0.4;
}

.badge-outline-manatee {
  background-color: transparent;
  border-color: #8b93a8;
  color: #8b93a8;
}

.badge-discreet-manatee {
  color: #8b93a8;
  background-color: rgba(139, 147, 168, 0.1);
}

.badge-flat-manatee {
  color: #8b93a8;
  background-color: transparent;
}

.badge-dodger-blue {
  background-color: #5d55ff;
  border-color: #5d55ff;
}
.badge-dodger-blue.badge-primary-alt {
  color: #000;
}
.badge-dodger-blue.badge-prepend-square::before {
  opacity: 0.4;
}

.badge-outline-dodger-blue {
  background-color: transparent;
  border-color: #5d55ff;
  color: #5d55ff;
}

.badge-discreet-dodger-blue {
  color: #5d55ff;
  background-color: rgba(93, 85, 255, 0.1);
}

.badge-flat-dodger-blue {
  color: #5d55ff;
  background-color: transparent;
}

.badge-pacific-blue {
  background-color: #00b4bb;
  border-color: #00b4bb;
}
.badge-pacific-blue.badge-primary-alt {
  color: #000;
}
.badge-pacific-blue.badge-prepend-square::before {
  opacity: 0.4;
}

.badge-outline-pacific-blue {
  background-color: transparent;
  border-color: #00b4bb;
  color: #00b4bb;
}

.badge-discreet-pacific-blue {
  color: #00b4bb;
  background-color: rgba(0, 180, 187, 0.1);
}

.badge-flat-pacific-blue {
  color: #00b4bb;
  background-color: transparent;
}

.badge-turquoise-blue {
  background-color: #68efad;
  border-color: #68efad;
}
.badge-turquoise-blue.badge-primary-alt {
  color: #000;
}
.badge-turquoise-blue.badge-prepend-square::before {
  opacity: 0.4;
}

.badge-outline-turquoise-blue {
  background-color: transparent;
  border-color: #68efad;
  color: #68efad;
}

.badge-discreet-turquoise-blue {
  color: #68efad;
  background-color: rgba(104, 239, 173, 0.1);
}

.badge-flat-turquoise-blue {
  color: #68efad;
  background-color: transparent;
}

.badge-buttercup {
  background-color: #f6a416;
  border-color: #f6a416;
}
.badge-buttercup.badge-primary-alt {
  color: #000;
}
.badge-buttercup.badge-prepend-square::before {
  opacity: 0.4;
}

.badge-outline-buttercup {
  background-color: transparent;
  border-color: #f6a416;
  color: #f6a416;
}

.badge-discreet-buttercup {
  color: #f6a416;
  background-color: rgba(246, 164, 22, 0.1);
}

.badge-flat-buttercup {
  color: #f6a416;
  background-color: transparent;
}

.badge-copper-rust {
  background-color: #974a5a;
  border-color: #974a5a;
}
.badge-copper-rust.badge-primary-alt {
  color: #000;
}
.badge-copper-rust.badge-prepend-square::before {
  opacity: 0.4;
}

.badge-outline-copper-rust {
  background-color: transparent;
  border-color: #974a5a;
  color: #974a5a;
}

.badge-discreet-copper-rust {
  color: #974a5a;
  background-color: rgba(151, 74, 90, 0.1);
}

.badge-flat-copper-rust {
  color: #974a5a;
  background-color: transparent;
}

.badge-east-bay {
  background-color: #4a5885;
  border-color: #4a5885;
}
.badge-east-bay.badge-primary-alt {
  color: #000;
}
.badge-east-bay.badge-prepend-square::before {
  opacity: 0.4;
}

.badge-outline-east-bay {
  background-color: transparent;
  border-color: #4a5885;
  color: #4a5885;
}

.badge-discreet-east-bay {
  color: #4a5885;
  background-color: rgba(74, 88, 133, 0.1);
}

.badge-flat-east-bay {
  color: #4a5885;
  background-color: transparent;
}

.badge-plantation {
  background-color: #275855;
  border-color: #275855;
}
.badge-plantation.badge-primary-alt {
  color: #000;
}
.badge-plantation.badge-prepend-square::before {
  opacity: 0.4;
}

.badge-outline-plantation {
  background-color: transparent;
  border-color: #275855;
  color: #275855;
}

.badge-discreet-plantation {
  color: #275855;
  background-color: rgba(39, 88, 85, 0.1);
}

.badge-flat-plantation {
  color: #275855;
  background-color: transparent;
}

.sgbs-badge-default {
  border: 1px solid #c4c8ca;
  background: transparent;
  color: #000;
}
.sgbs-badge-default.badge-prepend-square::before {
  opacity: 0.4;
}

.bg-lvl4 .badge.badge-outline-secondary {
  border-color: #5f6668;
  color: #5f6668;
}

.bg-lvl4 .badge.badge-outline-light {
  border-color: #b1b7b9;
  color: #b1b7b9;
}

.bg-lvl4 .badge.badge-outline-dark {
  border-color: #323939;
  color: #323939;
}

.bg-lvl4 .badge.badge-outline-info {
  border-color: #0554ff;
  color: #0554ff;
}

.bg-lvl4 .badge.badge-outline-success {
  border-color: #008a09;
  color: #008a09;
}

.bg-lvl4 .badge.badge-outline-warning {
  border-color: #bc6500;
  color: #bc6500;
}

.bg-lvl4 .badge.badge-outline-danger {
  border-color: #c22f1f;
  color: #c22f1f;
}

.bg-lvl3 .badge.badge-outline-secondary {
  border-color: #666e70;
  color: #666e70;
}

.bg-lvl3 .badge.badge-outline-light {
  border-color: #b9bec0;
  color: #b9bec0;
}

.bg-lvl3 .badge.badge-outline-dark {
  border-color: #373e3e;
  color: #373e3e;
}

.bg-lvl3 .badge.badge-outline-info {
  border-color: #0f5bff;
  color: #0f5bff;
}

.bg-lvl3 .badge.badge-outline-success {
  border-color: #00940a;
  color: #00940a;
}

.bg-lvl3 .badge.badge-outline-warning {
  border-color: #c66a00;
  color: #c66a00;
}

.bg-lvl3 .badge.badge-outline-danger {
  border-color: #cb3120;
  color: #cb3120;
}

.bg-lvl2 .badge.badge-outline-secondary {
  border-color: #687073;
  color: #687073;
}

.bg-lvl2 .badge.badge-outline-light {
  border-color: #c1c5c7;
  color: #c1c5c7;
}

.bg-lvl2 .badge.badge-outline-dark {
  border-color: #3c4343;
  color: #3c4343;
}

.bg-lvl2 .badge.badge-outline-info {
  border-color: #1a62ff;
  color: #1a62ff;
}

.bg-lvl2 .badge.badge-outline-success {
  border-color: #009e0b;
  color: #009e0b;
}

.bg-lvl2 .badge.badge-outline-warning {
  border-color: #d06f00;
  color: #d06f00;
}

.bg-lvl2 .badge.badge-outline-danger {
  border-color: #d43321;
  color: #d43321;
}

.bg-lvl1 .badge.badge-outline-secondary {
  border-color: #6e777a;
  color: #6e777a;
}

.bg-lvl1 .badge.badge-outline-light {
  border-color: #c4c8ca;
  color: #c4c8ca;
}

.bg-lvl1 .badge.badge-outline-dark {
  border-color: #414949;
  color: #414949;
}

.bg-lvl1 .badge.badge-outline-info {
  border-color: #2469ff;
  color: #2469ff;
}

.bg-lvl1 .badge.badge-outline-success {
  border-color: #00aa0c;
  color: #00aa0c;
}

.bg-lvl1 .badge.badge-outline-warning {
  border-color: #da7400;
  color: #da7400;
}

.bg-lvl1 .badge.badge-outline-danger {
  border-color: #dd3724;
  color: #dd3724;
}

.bg-alt-lvl4 .badge.badge-outline-secondary {
  border-color: #70797a;
  color: #70797a;
}

.bg-alt-lvl4 .badge.badge-outline-light {
  border-color: #a3afb3;
  color: #a3afb3;
}

.bg-alt-lvl4 .badge.badge-outline-dark {
  border-color: #343737;
  color: #343737;
}

.bg-alt-lvl4 .badge.badge-outline-info {
  border-color: #1476ff;
  color: #1476ff;
}

.bg-alt-lvl4 .badge.badge-outline-success {
  border-color: #00a83e;
  color: #00a83e;
}

.bg-alt-lvl4 .badge.badge-outline-warning {
  border-color: #cc7400;
  color: #cc7400;
}

.bg-alt-lvl4 .badge.badge-outline-danger {
  border-color: #d93d26;
  color: #d93d26;
}

.bg-alt-lvl3 .badge.badge-outline-secondary {
  border-color: #7a8385;
  color: #7a8385;
}

.bg-alt-lvl3 .badge.badge-outline-light {
  border-color: #b1bbbe;
  color: #b1bbbe;
}

.bg-alt-lvl3 .badge.badge-outline-dark {
  border-color: #3b3f3f;
  color: #3b3f3f;
}

.bg-alt-lvl3 .badge.badge-outline-info {
  border-color: #2982ff;
  color: #2982ff;
}

.bg-alt-lvl3 .badge.badge-outline-success {
  border-color: #00b844;
  color: #00b844;
}

.bg-alt-lvl3 .badge.badge-outline-warning {
  border-color: #db7c00;
  color: #db7c00;
}

.bg-alt-lvl3 .badge.badge-outline-danger {
  border-color: #e8442c;
  color: #e8442c;
}

.bg-alt-lvl2 .badge.badge-outline-secondary {
  border-color: #848d8f;
  color: #848d8f;
}

.bg-alt-lvl2 .badge.badge-outline-light {
  border-color: #c2cacc;
  color: #c2cacc;
}

.bg-alt-lvl2 .badge.badge-outline-dark {
  border-color: #454a4a;
  color: #454a4a;
}

.bg-alt-lvl2 .badge.badge-outline-info {
  border-color: #3d8eff;
  color: #3d8eff;
}

.bg-alt-lvl2 .badge.badge-outline-success {
  border-color: #00c74a;
  color: #00c74a;
}

.bg-alt-lvl2 .badge.badge-outline-warning {
  border-color: #eb8500;
  color: #eb8500;
}

.bg-alt-lvl2 .badge.badge-outline-danger {
  border-color: #f44d34;
  color: #f44d34;
}

.bg-alt-lvl1 .badge.badge-outline-secondary {
  border-color: #949c9e;
  color: #949c9e;
}

.bg-alt-lvl1 .badge.badge-outline-light {
  border-color: #d8ddde;
  color: #d8ddde;
}

.bg-alt-lvl1 .badge.badge-outline-dark {
  border-color: #515757;
  color: #515757;
}

.bg-alt-lvl1 .badge.badge-outline-info {
  border-color: #529aff;
  color: #529aff;
}

.bg-alt-lvl1 .badge.badge-outline-success {
  border-color: #00d750;
  color: #00d750;
}

.bg-alt-lvl1 .badge.badge-outline-warning {
  border-color: #f98d00;
  color: #f98d00;
}

.bg-alt-lvl1 .badge.badge-outline-danger {
  border-color: #ff563c;
  color: #ff563c;
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #eceeef;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
}

.alert {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 0 solid transparent;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3.5rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1rem 1rem;
  color: inherit;
}

.alert-primary {
  color: white;
  background-color: black;
  border-color: #b8b8b8;
}
.alert-primary hr {
  border-top-color: #ababab;
}
.alert-primary .alert-link {
  color: #e6e6e6;
}

.alert-secondary {
  color: white;
  background-color: #6e777a;
  border-color: #d6d9da;
}
.alert-secondary hr {
  border-top-color: #c9cdce;
}
.alert-secondary .alert-link {
  color: #e6e6e6;
}

.alert-success {
  color: white;
  background-color: #00aa0c;
  border-color: #b8e7bb;
}
.alert-success hr {
  border-top-color: #a5e1a9;
}
.alert-success .alert-link {
  color: #e6e6e6;
}

.alert-info {
  color: white;
  background-color: #2469ff;
  border-color: #c2d5ff;
}
.alert-info hr {
  border-top-color: #a9c3ff;
}
.alert-info .alert-link {
  color: #e6e6e6;
}

.alert-warning {
  color: white;
  background-color: #da7400;
  border-color: #f5d8b8;
}
.alert-warning hr {
  border-top-color: #f2cca2;
}
.alert-warning .alert-link {
  color: #e6e6e6;
}

.alert-danger {
  color: white;
  background-color: #dd3724;
  border-color: #f5c7c2;
}
.alert-danger hr {
  border-top-color: #f1b3ac;
}
.alert-danger .alert-link {
  color: #e6e6e6;
}

.alert-light {
  color: white;
  background-color: #c4c8ca;
  border-color: #eef0f0;
}
.alert-light hr {
  border-top-color: #e0e4e4;
}
.alert-light .alert-link {
  color: #e6e6e6;
}

.alert-dark {
  color: white;
  background-color: #414949;
  border-color: #cacccc;
}
.alert-dark hr {
  border-top-color: #bdbfbf;
}
.alert-dark .alert-link {
  color: #e6e6e6;
}

.alert-white {
  color: white;
  background-color: white;
  border-color: white;
}
.alert-white hr {
  border-top-color: #f2f2f2;
}
.alert-white .alert-link {
  color: #e6e6e6;
}

.alert-black {
  color: white;
  background-color: #231f20;
  border-color: #c1c0c1;
}
.alert-black hr {
  border-top-color: #b4b3b4;
}
.alert-black .alert-link {
  color: #e6e6e6;
}

.alert-socgen {
  color: white;
  background-color: #e60028;
  border-color: #f8b8c3;
}
.alert-socgen hr {
  border-top-color: #f6a1af;
}
.alert-socgen .alert-link {
  color: #e6e6e6;
}

.alert-primary-alt {
  color: white;
  background-color: white;
  border-color: white;
}
.alert-primary-alt hr {
  border-top-color: #f2f2f2;
}
.alert-primary-alt .alert-link {
  color: #e6e6e6;
}

.alert-dismissible .close {
  padding: 0 calc(1rem - 5px);
  top: 0;
  bottom: 0;
  max-height: 3rem;
}
.alert-dismissible .close > svg {
  fill: currentColor;
  width: 1.5rem;
  height: 1.5rem;
}

.alert-primary {
  color: #fff;
}
.alert-primary .alert-link {
  color: currentColor;
  text-decoration: none;
  text-underline-position: under;
  text-decoration: underline;
}
.alert-primary .alert-link:hover:not(:disabled):not(.disabled) {
  background-color: rgba(255, 255, 255, 0.08);
}
.alert-primary .alert-link:not(:disabled):not(.disabled):active, .alert-primary .alert-link:not(:disabled):not(.disabled).active {
  background-color: rgba(255, 255, 255, 0.15);
}
.alert-primary .close:hover {
  color: currentColor;
}
.alert-primary.alert-dark, .alert-primary.alert-black {
  color: #fff;
}
.alert-primary.alert-light {
  color: #000;
}

.alert-outline-primary {
  color: #000;
  border: #000 1px solid;
  background: transparent;
}
.alert-outline-primary .close {
  color: #6e777a;
}

.alert-secondary {
  color: #fff;
}
.alert-secondary .alert-link {
  color: currentColor;
  text-decoration: none;
  text-underline-position: under;
  text-decoration: underline;
}
.alert-secondary .alert-link:hover:not(:disabled):not(.disabled) {
  background-color: rgba(255, 255, 255, 0.08);
}
.alert-secondary .alert-link:not(:disabled):not(.disabled):active, .alert-secondary .alert-link:not(:disabled):not(.disabled).active {
  background-color: rgba(255, 255, 255, 0.15);
}
.alert-secondary .close:hover {
  color: currentColor;
}
.alert-secondary.alert-dark, .alert-secondary.alert-black {
  color: #fff;
}
.alert-secondary.alert-light {
  color: #000;
}

.alert-outline-secondary {
  color: #6e777a;
  border: #6e777a 1px solid;
  background: transparent;
}
.alert-outline-secondary .close {
  color: #6e777a;
}

.alert-success {
  color: #fff;
}
.alert-success .alert-link {
  color: currentColor;
  text-decoration: none;
  text-underline-position: under;
  text-decoration: underline;
}
.alert-success .alert-link:hover:not(:disabled):not(.disabled) {
  background-color: rgba(255, 255, 255, 0.08);
}
.alert-success .alert-link:not(:disabled):not(.disabled):active, .alert-success .alert-link:not(:disabled):not(.disabled).active {
  background-color: rgba(255, 255, 255, 0.15);
}
.alert-success .close:hover {
  color: currentColor;
}
.alert-success.alert-dark, .alert-success.alert-black {
  color: #fff;
}
.alert-success.alert-light {
  color: #000;
}

.alert-outline-success {
  color: #00aa0c;
  border: #00aa0c 1px solid;
  background: transparent;
}
.alert-outline-success .close {
  color: #6e777a;
}

.alert-info {
  color: #fff;
}
.alert-info .alert-link {
  color: currentColor;
  text-decoration: none;
  text-underline-position: under;
  text-decoration: underline;
}
.alert-info .alert-link:hover:not(:disabled):not(.disabled) {
  background-color: rgba(255, 255, 255, 0.08);
}
.alert-info .alert-link:not(:disabled):not(.disabled):active, .alert-info .alert-link:not(:disabled):not(.disabled).active {
  background-color: rgba(255, 255, 255, 0.15);
}
.alert-info .close:hover {
  color: currentColor;
}
.alert-info.alert-dark, .alert-info.alert-black {
  color: #fff;
}
.alert-info.alert-light {
  color: #000;
}

.alert-outline-info {
  color: #2469ff;
  border: #2469ff 1px solid;
  background: transparent;
}
.alert-outline-info .close {
  color: #6e777a;
}

.alert-warning {
  color: #fff;
}
.alert-warning .alert-link {
  color: currentColor;
  text-decoration: none;
  text-underline-position: under;
  text-decoration: underline;
}
.alert-warning .alert-link:hover:not(:disabled):not(.disabled) {
  background-color: rgba(255, 255, 255, 0.08);
}
.alert-warning .alert-link:not(:disabled):not(.disabled):active, .alert-warning .alert-link:not(:disabled):not(.disabled).active {
  background-color: rgba(255, 255, 255, 0.15);
}
.alert-warning .close:hover {
  color: currentColor;
}
.alert-warning.alert-dark, .alert-warning.alert-black {
  color: #fff;
}
.alert-warning.alert-light {
  color: #000;
}

.alert-outline-warning {
  color: #da7400;
  border: #da7400 1px solid;
  background: transparent;
}
.alert-outline-warning .close {
  color: #6e777a;
}

.alert-danger {
  color: #fff;
}
.alert-danger .alert-link {
  color: currentColor;
  text-decoration: none;
  text-underline-position: under;
  text-decoration: underline;
}
.alert-danger .alert-link:hover:not(:disabled):not(.disabled) {
  background-color: rgba(255, 255, 255, 0.08);
}
.alert-danger .alert-link:not(:disabled):not(.disabled):active, .alert-danger .alert-link:not(:disabled):not(.disabled).active {
  background-color: rgba(255, 255, 255, 0.15);
}
.alert-danger .close:hover {
  color: currentColor;
}
.alert-danger.alert-dark, .alert-danger.alert-black {
  color: #fff;
}
.alert-danger.alert-light {
  color: #000;
}

.alert-outline-danger {
  color: #dd3724;
  border: #dd3724 1px solid;
  background: transparent;
}
.alert-outline-danger .close {
  color: #6e777a;
}

.alert-light {
  color: #fff;
}
.alert-light .alert-link {
  color: currentColor;
  text-decoration: none;
  text-underline-position: under;
  text-decoration: underline;
}
.alert-light .alert-link:hover:not(:disabled):not(.disabled) {
  background-color: rgba(255, 255, 255, 0.08);
}
.alert-light .alert-link:not(:disabled):not(.disabled):active, .alert-light .alert-link:not(:disabled):not(.disabled).active {
  background-color: rgba(255, 255, 255, 0.15);
}
.alert-light .close:hover {
  color: currentColor;
}
.alert-light.alert-dark, .alert-light.alert-black {
  color: #fff;
}
.alert-light.alert-light {
  color: #000;
}

.alert-outline-light {
  color: #c4c8ca;
  border: #c4c8ca 1px solid;
  background: transparent;
}
.alert-outline-light .close {
  color: #6e777a;
}

.alert-dark {
  color: #fff;
}
.alert-dark .alert-link {
  color: currentColor;
  text-decoration: none;
  text-underline-position: under;
  text-decoration: underline;
}
.alert-dark .alert-link:hover:not(:disabled):not(.disabled) {
  background-color: rgba(255, 255, 255, 0.08);
}
.alert-dark .alert-link:not(:disabled):not(.disabled):active, .alert-dark .alert-link:not(:disabled):not(.disabled).active {
  background-color: rgba(255, 255, 255, 0.15);
}
.alert-dark .close:hover {
  color: currentColor;
}
.alert-dark.alert-dark, .alert-dark.alert-black {
  color: #fff;
}
.alert-dark.alert-light {
  color: #000;
}

.alert-outline-dark {
  color: #414949;
  border: #414949 1px solid;
  background: transparent;
}
.alert-outline-dark .close {
  color: #6e777a;
}

.alert-white {
  color: #fff;
}
.alert-white .alert-link {
  color: currentColor;
  text-decoration: none;
  text-underline-position: under;
  text-decoration: underline;
}
.alert-white .alert-link:hover:not(:disabled):not(.disabled) {
  background-color: rgba(255, 255, 255, 0.08);
}
.alert-white .alert-link:not(:disabled):not(.disabled):active, .alert-white .alert-link:not(:disabled):not(.disabled).active {
  background-color: rgba(255, 255, 255, 0.15);
}
.alert-white .close:hover {
  color: currentColor;
}
.alert-white.alert-dark, .alert-white.alert-black {
  color: #fff;
}
.alert-white.alert-light {
  color: #000;
}

.alert-outline-white {
  color: white;
  border: white 1px solid;
  background: transparent;
}
.alert-outline-white .close {
  color: #6e777a;
}

.alert-black {
  color: #fff;
}
.alert-black .alert-link {
  color: currentColor;
  text-decoration: none;
  text-underline-position: under;
  text-decoration: underline;
}
.alert-black .alert-link:hover:not(:disabled):not(.disabled) {
  background-color: rgba(255, 255, 255, 0.08);
}
.alert-black .alert-link:not(:disabled):not(.disabled):active, .alert-black .alert-link:not(:disabled):not(.disabled).active {
  background-color: rgba(255, 255, 255, 0.15);
}
.alert-black .close:hover {
  color: currentColor;
}
.alert-black.alert-dark, .alert-black.alert-black {
  color: #fff;
}
.alert-black.alert-light {
  color: #000;
}

.alert-outline-black {
  color: #231f20;
  border: #231f20 1px solid;
  background: transparent;
}
.alert-outline-black .close {
  color: #6e777a;
}

.alert-socgen {
  color: #fff;
}
.alert-socgen .alert-link {
  color: currentColor;
  text-decoration: none;
  text-underline-position: under;
  text-decoration: underline;
}
.alert-socgen .alert-link:hover:not(:disabled):not(.disabled) {
  background-color: rgba(255, 255, 255, 0.08);
}
.alert-socgen .alert-link:not(:disabled):not(.disabled):active, .alert-socgen .alert-link:not(:disabled):not(.disabled).active {
  background-color: rgba(255, 255, 255, 0.15);
}
.alert-socgen .close:hover {
  color: currentColor;
}
.alert-socgen.alert-dark, .alert-socgen.alert-black {
  color: #fff;
}
.alert-socgen.alert-light {
  color: #000;
}

.alert-outline-socgen {
  color: #e60028;
  border: #e60028 1px solid;
  background: transparent;
}
.alert-outline-socgen .close {
  color: #6e777a;
}

.alert-primary-alt {
  color: #fff;
}
.alert-primary-alt .alert-link {
  color: currentColor;
  text-decoration: none;
  text-underline-position: under;
  text-decoration: underline;
}
.alert-primary-alt .alert-link:hover:not(:disabled):not(.disabled) {
  background-color: rgba(255, 255, 255, 0.08);
}
.alert-primary-alt .alert-link:not(:disabled):not(.disabled):active, .alert-primary-alt .alert-link:not(:disabled):not(.disabled).active {
  background-color: rgba(255, 255, 255, 0.15);
}
.alert-primary-alt .close:hover {
  color: currentColor;
}
.alert-primary-alt.alert-dark, .alert-primary-alt.alert-black {
  color: #fff;
}
.alert-primary-alt.alert-light {
  color: #000;
}

.alert-outline-primary-alt {
  color: #fff;
  border: #fff 1px solid;
  background: transparent;
}
.alert-outline-primary-alt .close {
  color: #6e777a;
}

.bg-lvl4 .alert.alert-secondary {
  background-color: #5f6668;
}

.bg-lvl4 .alert.alert-light {
  background-color: #b1b7b9;
}

.bg-lvl4 .alert.alert-dark {
  background-color: #323939;
}

.bg-lvl4 .alert.alert-info {
  background-color: #0554ff;
}

.bg-lvl4 .alert.alert-success {
  background-color: #008a09;
}

.bg-lvl4 .alert.alert-warning {
  background-color: #bc6500;
}

.bg-lvl4 .alert.alert-danger {
  background-color: #c22f1f;
}

.bg-lvl3 .alert.alert-secondary {
  background-color: #666e70;
}

.bg-lvl3 .alert.alert-light {
  background-color: #b9bec0;
}

.bg-lvl3 .alert.alert-dark {
  background-color: #373e3e;
}

.bg-lvl3 .alert.alert-info {
  background-color: #0f5bff;
}

.bg-lvl3 .alert.alert-success {
  background-color: #00940a;
}

.bg-lvl3 .alert.alert-warning {
  background-color: #c66a00;
}

.bg-lvl3 .alert.alert-danger {
  background-color: #cb3120;
}

.bg-lvl2 .alert.alert-secondary {
  background-color: #687073;
}

.bg-lvl2 .alert.alert-light {
  background-color: #c1c5c7;
}

.bg-lvl2 .alert.alert-dark {
  background-color: #3c4343;
}

.bg-lvl2 .alert.alert-info {
  background-color: #1a62ff;
}

.bg-lvl2 .alert.alert-success {
  background-color: #009e0b;
}

.bg-lvl2 .alert.alert-warning {
  background-color: #d06f00;
}

.bg-lvl2 .alert.alert-danger {
  background-color: #d43321;
}

.bg-lvl1 .alert.alert-secondary {
  background-color: #6e777a;
}

.bg-lvl1 .alert.alert-light {
  background-color: #c4c8ca;
}

.bg-lvl1 .alert.alert-dark {
  background-color: #414949;
}

.bg-lvl1 .alert.alert-info {
  background-color: #2469ff;
}

.bg-lvl1 .alert.alert-success {
  background-color: #00aa0c;
}

.bg-lvl1 .alert.alert-warning {
  background-color: #da7400;
}

.bg-lvl1 .alert.alert-danger {
  background-color: #dd3724;
}

.bg-alt-lvl4 .alert.alert-secondary {
  background-color: #70797a;
}

.bg-alt-lvl4 .alert.alert-light {
  background-color: #a3afb3;
}

.bg-alt-lvl4 .alert.alert-dark {
  background-color: #343737;
}

.bg-alt-lvl4 .alert.alert-info {
  background-color: #1476ff;
}

.bg-alt-lvl4 .alert.alert-success {
  background-color: #00a83e;
}

.bg-alt-lvl4 .alert.alert-warning {
  background-color: #cc7400;
}

.bg-alt-lvl4 .alert.alert-danger {
  background-color: #d93d26;
}

.bg-alt-lvl3 .alert.alert-secondary {
  background-color: #7a8385;
}

.bg-alt-lvl3 .alert.alert-light {
  background-color: #b1bbbe;
}

.bg-alt-lvl3 .alert.alert-dark {
  background-color: #3b3f3f;
}

.bg-alt-lvl3 .alert.alert-info {
  background-color: #2982ff;
}

.bg-alt-lvl3 .alert.alert-success {
  background-color: #00b844;
}

.bg-alt-lvl3 .alert.alert-warning {
  background-color: #db7c00;
}

.bg-alt-lvl3 .alert.alert-danger {
  background-color: #e8442c;
}

.bg-alt-lvl2 .alert.alert-secondary {
  background-color: #848d8f;
}

.bg-alt-lvl2 .alert.alert-light {
  background-color: #c2cacc;
}

.bg-alt-lvl2 .alert.alert-dark {
  background-color: #454a4a;
}

.bg-alt-lvl2 .alert.alert-info {
  background-color: #3d8eff;
}

.bg-alt-lvl2 .alert.alert-success {
  background-color: #00c74a;
}

.bg-alt-lvl2 .alert.alert-warning {
  background-color: #eb8500;
}

.bg-alt-lvl2 .alert.alert-danger {
  background-color: #f44d34;
}

.bg-alt-lvl1 .alert.alert-secondary {
  background-color: #949c9e;
}

.bg-alt-lvl1 .alert.alert-light {
  background-color: #d8ddde;
}

.bg-alt-lvl1 .alert.alert-dark {
  background-color: #515757;
}

.bg-alt-lvl1 .alert.alert-info {
  background-color: #529aff;
}

.bg-alt-lvl1 .alert.alert-success {
  background-color: #00d750;
}

.bg-alt-lvl1 .alert.alert-warning {
  background-color: #f98d00;
}

.bg-alt-lvl1 .alert.alert-danger {
  background-color: #ff563c;
}

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 0.375rem 0;
  }
  to {
    background-position: 0 0;
  }
}

@keyframes progress-bar-stripes {
  from {
    background-position: 0.375rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 0.375rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.875rem;
  background-color: rgba(110, 119, 122, 0.2);
}

.progress-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #000;
  -webkit-transition: width 0.6s ease;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    -webkit-transition: none;
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 0.375rem 0.375rem;
}

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
  animation: progress-bar-stripes 1s linear infinite;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    -webkit-animation: none;
    animation: none;
  }
}

.progress {
  overflow: visible;
}

.progress-sm {
  height: 0.125rem;
}

.progress-lg {
  height: 1.25rem;
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, #fff 25%, rgba(110, 119, 122, 0.2) 25%, rgba(110, 119, 122, 0.2) 50%, #fff 50%, #fff 75%, rgba(110, 119, 122, 0.2) 75%, rgba(110, 119, 122, 0.2));
}

.progress-bar {
  text-align: right;
  overflow: visible;
}

.progress-bar-label {
  padding-right: 0.5rem;
}

.progress-bar-label-over {
  color: #000;
  margin-top: -2rem;
}

.media {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.media-body {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.list-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.list-group-item-action {
  width: 100%;
  color: #000;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #000;
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.05);
}
.list-group-item-action:active {
  color: #000;
  background-color: rgba(0, 0, 0, 0.1);
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: transparent;
  border: 1px solid transparent;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: rgba(0, 0, 0, 0.4);
  pointer-events: none;
  background-color: transparent;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.1);
  border-color: rgba(0, 0, 0, 0.1);
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1600px) {
  .list-group-horizontal-xxl {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #110f0f;
  margin-bottom: 0;
}
.list-group-item-primary.list-group-item-action:not(.disabled):hover, .list-group-item-primary.list-group-item-action:not(.disabled):focus {
  color: #110f0f;
  background-color: rgba(184, 184, 184, 0.1);
}
.list-group-item-primary.list-group-item-action.active {
  color: #110f0f;
  background-color: rgba(184, 184, 184, 0.1);
  border-left-color: #110f0f;
}
.list-group-item-primary.list-group-item-action.disabled, .list-group-item-primary.list-group-item-action:disabled {
  color: rgba(17, 15, 15, 0.4);
}

.list-group-item-secondary {
  color: #4a4d4f;
  margin-bottom: 0;
}
.list-group-item-secondary.list-group-item-action:not(.disabled):hover, .list-group-item-secondary.list-group-item-action:not(.disabled):focus {
  color: #4a4d4f;
  background-color: rgba(214, 217, 218, 0.1);
}
.list-group-item-secondary.list-group-item-action.active {
  color: #4a4d4f;
  background-color: rgba(214, 217, 218, 0.1);
  border-left-color: #4a4d4f;
}
.list-group-item-secondary.list-group-item-action.disabled, .list-group-item-secondary.list-group-item-action:disabled {
  color: rgba(74, 77, 79, 0.4);
}

.list-group-item-success {
  color: #116716;
  margin-bottom: 0;
}
.list-group-item-success.list-group-item-action:not(.disabled):hover, .list-group-item-success.list-group-item-action:not(.disabled):focus {
  color: #116716;
  background-color: rgba(184, 231, 187, 0.1);
}
.list-group-item-success.list-group-item-action.active {
  color: #116716;
  background-color: rgba(184, 231, 187, 0.1);
  border-left-color: #116716;
}
.list-group-item-success.list-group-item-action.disabled, .list-group-item-success.list-group-item-action:disabled {
  color: rgba(17, 103, 22, 0.4);
}

.list-group-item-info {
  color: #244594;
  margin-bottom: 0;
}
.list-group-item-info.list-group-item-action:not(.disabled):hover, .list-group-item-info.list-group-item-action:not(.disabled):focus {
  color: #244594;
  background-color: rgba(194, 213, 255, 0.1);
}
.list-group-item-info.list-group-item-action.active {
  color: #244594;
  background-color: rgba(194, 213, 255, 0.1);
  border-left-color: #244594;
}
.list-group-item-info.list-group-item-action.disabled, .list-group-item-info.list-group-item-action:disabled {
  color: rgba(36, 69, 148, 0.4);
}

.list-group-item-warning {
  color: #824b0f;
  margin-bottom: 0;
}
.list-group-item-warning.list-group-item-action:not(.disabled):hover, .list-group-item-warning.list-group-item-action:not(.disabled):focus {
  color: #824b0f;
  background-color: rgba(245, 216, 184, 0.1);
}
.list-group-item-warning.list-group-item-action.active {
  color: #824b0f;
  background-color: rgba(245, 216, 184, 0.1);
  border-left-color: #824b0f;
}
.list-group-item-warning.list-group-item-action.disabled, .list-group-item-warning.list-group-item-action:disabled {
  color: rgba(130, 75, 15, 0.4);
}

.list-group-item-danger {
  color: #842b22;
  margin-bottom: 0;
}
.list-group-item-danger.list-group-item-action:not(.disabled):hover, .list-group-item-danger.list-group-item-action:not(.disabled):focus {
  color: #842b22;
  background-color: rgba(245, 199, 194, 0.1);
}
.list-group-item-danger.list-group-item-action.active {
  color: #842b22;
  background-color: rgba(245, 199, 194, 0.1);
  border-left-color: #842b22;
}
.list-group-item-danger.list-group-item-action.disabled, .list-group-item-danger.list-group-item-action:disabled {
  color: rgba(132, 43, 34, 0.4);
}

.list-group-item-light {
  color: #777778;
  margin-bottom: 0;
}
.list-group-item-light.list-group-item-action:not(.disabled):hover, .list-group-item-light.list-group-item-action:not(.disabled):focus {
  color: #777778;
  background-color: rgba(238, 240, 240, 0.1);
}
.list-group-item-light.list-group-item-action.active {
  color: #777778;
  background-color: rgba(238, 240, 240, 0.1);
  border-left-color: #777778;
}
.list-group-item-light.list-group-item-action.disabled, .list-group-item-light.list-group-item-action:disabled {
  color: rgba(119, 119, 120, 0.4);
}

.list-group-item-dark {
  color: #333535;
  margin-bottom: 0;
}
.list-group-item-dark.list-group-item-action:not(.disabled):hover, .list-group-item-dark.list-group-item-action:not(.disabled):focus {
  color: #333535;
  background-color: rgba(202, 204, 204, 0.1);
}
.list-group-item-dark.list-group-item-action.active {
  color: #333535;
  background-color: rgba(202, 204, 204, 0.1);
  border-left-color: #333535;
}
.list-group-item-dark.list-group-item-action.disabled, .list-group-item-dark.list-group-item-action:disabled {
  color: rgba(51, 53, 53, 0.4);
}

.list-group-item-white {
  color: #959394;
  margin-bottom: 0;
}
.list-group-item-white.list-group-item-action:not(.disabled):hover, .list-group-item-white.list-group-item-action:not(.disabled):focus {
  color: #959394;
  background-color: rgba(255, 255, 255, 0.1);
}
.list-group-item-white.list-group-item-action.active {
  color: #959394;
  background-color: rgba(255, 255, 255, 0.1);
  border-left-color: #959394;
}
.list-group-item-white.list-group-item-action.disabled, .list-group-item-white.list-group-item-action:disabled {
  color: rgba(149, 147, 148, 0.4);
}

.list-group-item-black {
  color: #231f20;
  margin-bottom: 0;
}
.list-group-item-black.list-group-item-action:not(.disabled):hover, .list-group-item-black.list-group-item-action:not(.disabled):focus {
  color: #231f20;
  background-color: rgba(193, 192, 193, 0.1);
}
.list-group-item-black.list-group-item-action.active {
  color: #231f20;
  background-color: rgba(193, 192, 193, 0.1);
  border-left-color: #231f20;
}
.list-group-item-black.list-group-item-action.disabled, .list-group-item-black.list-group-item-action:disabled {
  color: rgba(35, 31, 32, 0.4);
}

.list-group-item-socgen {
  color: #880f24;
  margin-bottom: 0;
}
.list-group-item-socgen.list-group-item-action:not(.disabled):hover, .list-group-item-socgen.list-group-item-action:not(.disabled):focus {
  color: #880f24;
  background-color: rgba(248, 184, 195, 0.1);
}
.list-group-item-socgen.list-group-item-action.active {
  color: #880f24;
  background-color: rgba(248, 184, 195, 0.1);
  border-left-color: #880f24;
}
.list-group-item-socgen.list-group-item-action.disabled, .list-group-item-socgen.list-group-item-action:disabled {
  color: rgba(136, 15, 36, 0.4);
}

.list-group-item-primary-alt {
  color: #959394;
  margin-bottom: 0;
}
.list-group-item-primary-alt.list-group-item-action:not(.disabled):hover, .list-group-item-primary-alt.list-group-item-action:not(.disabled):focus {
  color: #959394;
  background-color: rgba(255, 255, 255, 0.1);
}
.list-group-item-primary-alt.list-group-item-action.active {
  color: #959394;
  background-color: rgba(255, 255, 255, 0.1);
  border-left-color: #959394;
}
.list-group-item-primary-alt.list-group-item-action.disabled, .list-group-item-primary-alt.list-group-item-action:disabled {
  color: rgba(149, 147, 148, 0.4);
}

.list-group-item {
  border-width: 0;
  border-left: 3px solid transparent;
}
.list-group-item.active {
  color: inherit;
  border-left-color: #000;
  border-bottom-width: 0;
  border-top-width: 0;
}

.list-group-flush .list-group-item {
  border-bottom-width: 1px;
}

.list-group-item-primary {
  color: black;
  margin-bottom: 0;
}
.list-group-item-primary.list-group-item-action:not(.disabled):hover, .list-group-item-primary.list-group-item-action:not(.disabled):focus {
  color: black;
  background-color: rgba(0, 0, 0, 0.1);
}
.list-group-item-primary.list-group-item-action.active {
  color: black;
  background-color: rgba(0, 0, 0, 0.1);
  border-left-color: black;
}
.list-group-item-primary.list-group-item-action.disabled, .list-group-item-primary.list-group-item-action:disabled {
  color: rgba(0, 0, 0, 0.4);
}

.list-group-item-secondary {
  color: #6e777a;
  margin-bottom: 0;
}
.list-group-item-secondary.list-group-item-action:not(.disabled):hover, .list-group-item-secondary.list-group-item-action:not(.disabled):focus {
  color: #6e777a;
  background-color: rgba(110, 119, 122, 0.1);
}
.list-group-item-secondary.list-group-item-action.active {
  color: #6e777a;
  background-color: rgba(110, 119, 122, 0.1);
  border-left-color: #6e777a;
}
.list-group-item-secondary.list-group-item-action.disabled, .list-group-item-secondary.list-group-item-action:disabled {
  color: rgba(110, 119, 122, 0.4);
}

.list-group-item-success {
  color: #00aa0c;
  margin-bottom: 0;
}
.list-group-item-success.list-group-item-action:not(.disabled):hover, .list-group-item-success.list-group-item-action:not(.disabled):focus {
  color: #00aa0c;
  background-color: rgba(0, 170, 12, 0.1);
}
.list-group-item-success.list-group-item-action.active {
  color: #00aa0c;
  background-color: rgba(0, 170, 12, 0.1);
  border-left-color: #00aa0c;
}
.list-group-item-success.list-group-item-action.disabled, .list-group-item-success.list-group-item-action:disabled {
  color: rgba(0, 170, 12, 0.4);
}

.list-group-item-info {
  color: #2469ff;
  margin-bottom: 0;
}
.list-group-item-info.list-group-item-action:not(.disabled):hover, .list-group-item-info.list-group-item-action:not(.disabled):focus {
  color: #2469ff;
  background-color: rgba(36, 105, 255, 0.1);
}
.list-group-item-info.list-group-item-action.active {
  color: #2469ff;
  background-color: rgba(36, 105, 255, 0.1);
  border-left-color: #2469ff;
}
.list-group-item-info.list-group-item-action.disabled, .list-group-item-info.list-group-item-action:disabled {
  color: rgba(36, 105, 255, 0.4);
}

.list-group-item-warning {
  color: #da7400;
  margin-bottom: 0;
}
.list-group-item-warning.list-group-item-action:not(.disabled):hover, .list-group-item-warning.list-group-item-action:not(.disabled):focus {
  color: #da7400;
  background-color: rgba(218, 116, 0, 0.1);
}
.list-group-item-warning.list-group-item-action.active {
  color: #da7400;
  background-color: rgba(218, 116, 0, 0.1);
  border-left-color: #da7400;
}
.list-group-item-warning.list-group-item-action.disabled, .list-group-item-warning.list-group-item-action:disabled {
  color: rgba(218, 116, 0, 0.4);
}

.list-group-item-danger {
  color: #dd3724;
  margin-bottom: 0;
}
.list-group-item-danger.list-group-item-action:not(.disabled):hover, .list-group-item-danger.list-group-item-action:not(.disabled):focus {
  color: #dd3724;
  background-color: rgba(221, 55, 36, 0.1);
}
.list-group-item-danger.list-group-item-action.active {
  color: #dd3724;
  background-color: rgba(221, 55, 36, 0.1);
  border-left-color: #dd3724;
}
.list-group-item-danger.list-group-item-action.disabled, .list-group-item-danger.list-group-item-action:disabled {
  color: rgba(221, 55, 36, 0.4);
}

.list-group-item-light {
  color: #c4c8ca;
  margin-bottom: 0;
}
.list-group-item-light.list-group-item-action:not(.disabled):hover, .list-group-item-light.list-group-item-action:not(.disabled):focus {
  color: #c4c8ca;
  background-color: rgba(196, 200, 202, 0.1);
}
.list-group-item-light.list-group-item-action.active {
  color: #c4c8ca;
  background-color: rgba(196, 200, 202, 0.1);
  border-left-color: #c4c8ca;
}
.list-group-item-light.list-group-item-action.disabled, .list-group-item-light.list-group-item-action:disabled {
  color: rgba(196, 200, 202, 0.4);
}

.list-group-item-dark {
  color: #414949;
  margin-bottom: 0;
}
.list-group-item-dark.list-group-item-action:not(.disabled):hover, .list-group-item-dark.list-group-item-action:not(.disabled):focus {
  color: #414949;
  background-color: rgba(65, 73, 73, 0.1);
}
.list-group-item-dark.list-group-item-action.active {
  color: #414949;
  background-color: rgba(65, 73, 73, 0.1);
  border-left-color: #414949;
}
.list-group-item-dark.list-group-item-action.disabled, .list-group-item-dark.list-group-item-action:disabled {
  color: rgba(65, 73, 73, 0.4);
}

.list-group-item-white {
  color: white;
  margin-bottom: 0;
}
.list-group-item-white.list-group-item-action:not(.disabled):hover, .list-group-item-white.list-group-item-action:not(.disabled):focus {
  color: white;
  background-color: rgba(255, 255, 255, 0.1);
}
.list-group-item-white.list-group-item-action.active {
  color: white;
  background-color: rgba(255, 255, 255, 0.1);
  border-left-color: white;
}
.list-group-item-white.list-group-item-action.disabled, .list-group-item-white.list-group-item-action:disabled {
  color: rgba(255, 255, 255, 0.4);
}

.list-group-item-black {
  color: #231f20;
  margin-bottom: 0;
}
.list-group-item-black.list-group-item-action:not(.disabled):hover, .list-group-item-black.list-group-item-action:not(.disabled):focus {
  color: #231f20;
  background-color: rgba(35, 31, 32, 0.1);
}
.list-group-item-black.list-group-item-action.active {
  color: #231f20;
  background-color: rgba(35, 31, 32, 0.1);
  border-left-color: #231f20;
}
.list-group-item-black.list-group-item-action.disabled, .list-group-item-black.list-group-item-action:disabled {
  color: rgba(35, 31, 32, 0.4);
}

.list-group-item-socgen {
  color: #e60028;
  margin-bottom: 0;
}
.list-group-item-socgen.list-group-item-action:not(.disabled):hover, .list-group-item-socgen.list-group-item-action:not(.disabled):focus {
  color: #e60028;
  background-color: rgba(230, 0, 40, 0.1);
}
.list-group-item-socgen.list-group-item-action.active {
  color: #e60028;
  background-color: rgba(230, 0, 40, 0.1);
  border-left-color: #e60028;
}
.list-group-item-socgen.list-group-item-action.disabled, .list-group-item-socgen.list-group-item-action:disabled {
  color: rgba(230, 0, 40, 0.4);
}

.list-group-item-primary-alt {
  color: white;
  margin-bottom: 0;
}
.list-group-item-primary-alt.list-group-item-action:not(.disabled):hover, .list-group-item-primary-alt.list-group-item-action:not(.disabled):focus {
  color: white;
  background-color: rgba(255, 255, 255, 0.1);
}
.list-group-item-primary-alt.list-group-item-action.active {
  color: white;
  background-color: rgba(255, 255, 255, 0.1);
  border-left-color: white;
}
.list-group-item-primary-alt.list-group-item-action.disabled, .list-group-item-primary-alt.list-group-item-action:disabled {
  color: rgba(255, 255, 255, 0.4);
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1;
  color: #6e777a;
  text-shadow: 0;
  opacity: 0.5;
}
.close:hover {
  color: #6e777a;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  -ms-flex-preferred-size: 22.5rem;
  flex-basis: 22.5rem;
  max-width: 22.5rem;
  font-size: 0.875rem;
  background-color: #000;
  background-clip: padding-box;
  border: 0 solid rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 10px 14px -6px rgba(0, 0, 0, 0.1), 0 22px 35px 3px rgba(0, 0, 0, 0.07), 0 8px 42px 7px rgba(0, 0, 0, 0.06);
  box-shadow: 0 10px 14px -6px rgba(0, 0, 0, 0.1), 0 22px 35px 3px rgba(0, 0, 0, 0.07), 0 8px 42px 7px rgba(0, 0, 0, 0.06);
  opacity: 0;
}
.toast:not(:last-child) {
  margin-bottom: 1rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}

.toast-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 1rem 1rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 0 solid rgba(0, 0, 0, 0.05);
}

.toast-body {
  padding: 1rem;
}

.toast {
  width: 100%;
  border-top: 1px solid transparent;
  background: #fff !important;
  position: relative;
}
.toast .close {
  position: absolute;
  right: 0;
  top: 0;
  width: calc(2.5rem + 5px);
  height: calc(2.5rem + 5px);
}

.toast-header {
  background: #fff;
}
.toast-header::before {
  content: "";
  width: 0.5rem;
  height: 0.5rem;
  margin-right: 0.5rem;
  display: inline-block;
}

.toast-body {
  padding-top: 0;
}

.toast-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 0 1rem 1rem;
}

.toast-primary {
  border-color: #000;
}
.toast-primary .toast-header {
  color: #000;
}
.toast-primary .toast-header::before {
  background: #000;
}

.toast-secondary {
  border-color: #6e777a;
}
.toast-secondary .toast-header {
  color: #6e777a;
}
.toast-secondary .toast-header::before {
  background: #6e777a;
}

.toast-success {
  border-color: #00aa0c;
}
.toast-success .toast-header {
  color: #00aa0c;
}
.toast-success .toast-header::before {
  background: #00aa0c;
}

.toast-info {
  border-color: #2469ff;
}
.toast-info .toast-header {
  color: #2469ff;
}
.toast-info .toast-header::before {
  background: #2469ff;
}

.toast-warning {
  border-color: #da7400;
}
.toast-warning .toast-header {
  color: #da7400;
}
.toast-warning .toast-header::before {
  background: #da7400;
}

.toast-danger {
  border-color: #dd3724;
}
.toast-danger .toast-header {
  color: #dd3724;
}
.toast-danger .toast-header::before {
  background: #dd3724;
}

.toast-light {
  border-color: #c4c8ca;
}
.toast-light .toast-header {
  color: #c4c8ca;
}
.toast-light .toast-header::before {
  background: #c4c8ca;
}

.toast-dark {
  border-color: #414949;
}
.toast-dark .toast-header {
  color: #414949;
}
.toast-dark .toast-header::before {
  background: #414949;
}

.toast-white {
  border-color: white;
}
.toast-white .toast-header {
  color: white;
}
.toast-white .toast-header::before {
  background: white;
}

.toast-black {
  border-color: #231f20;
}
.toast-black .toast-header {
  color: #231f20;
}
.toast-black .toast-header::before {
  background: #231f20;
}

.toast-socgen {
  border-color: #e60028;
}
.toast-socgen .toast-header {
  color: #e60028;
}
.toast-socgen .toast-header::before {
  background: #e60028;
}

.toast-primary-alt {
  border-color: #fff;
}
.toast-primary-alt .toast-header {
  color: #fff;
}
.toast-primary-alt .toast-header::before {
  background: #fff;
}

.bg-lvl4 .toast-secondary {
  border-color: #5f6668;
}
.bg-lvl4 .toast-secondary .toast-header {
  color: #5f6668;
}

.bg-lvl4 .toast-light {
  border-color: #b1b7b9;
}
.bg-lvl4 .toast-light .toast-header {
  color: #b1b7b9;
}

.bg-lvl4 .toast-dark {
  border-color: #323939;
}
.bg-lvl4 .toast-dark .toast-header {
  color: #323939;
}

.bg-lvl4 .toast-info {
  border-color: #0554ff;
}
.bg-lvl4 .toast-info .toast-header {
  color: #0554ff;
}

.bg-lvl4 .toast-success {
  border-color: #008a09;
}
.bg-lvl4 .toast-success .toast-header {
  color: #008a09;
}

.bg-lvl4 .toast-warning {
  border-color: #bc6500;
}
.bg-lvl4 .toast-warning .toast-header {
  color: #bc6500;
}

.bg-lvl4 .toast-danger {
  border-color: #c22f1f;
}
.bg-lvl4 .toast-danger .toast-header {
  color: #c22f1f;
}

.bg-lvl3 .toast-secondary {
  border-color: #666e70;
}
.bg-lvl3 .toast-secondary .toast-header {
  color: #666e70;
}

.bg-lvl3 .toast-light {
  border-color: #b9bec0;
}
.bg-lvl3 .toast-light .toast-header {
  color: #b9bec0;
}

.bg-lvl3 .toast-dark {
  border-color: #373e3e;
}
.bg-lvl3 .toast-dark .toast-header {
  color: #373e3e;
}

.bg-lvl3 .toast-info {
  border-color: #0f5bff;
}
.bg-lvl3 .toast-info .toast-header {
  color: #0f5bff;
}

.bg-lvl3 .toast-success {
  border-color: #00940a;
}
.bg-lvl3 .toast-success .toast-header {
  color: #00940a;
}

.bg-lvl3 .toast-warning {
  border-color: #c66a00;
}
.bg-lvl3 .toast-warning .toast-header {
  color: #c66a00;
}

.bg-lvl3 .toast-danger {
  border-color: #cb3120;
}
.bg-lvl3 .toast-danger .toast-header {
  color: #cb3120;
}

.bg-lvl2 .toast-secondary {
  border-color: #687073;
}
.bg-lvl2 .toast-secondary .toast-header {
  color: #687073;
}

.bg-lvl2 .toast-light {
  border-color: #c1c5c7;
}
.bg-lvl2 .toast-light .toast-header {
  color: #c1c5c7;
}

.bg-lvl2 .toast-dark {
  border-color: #3c4343;
}
.bg-lvl2 .toast-dark .toast-header {
  color: #3c4343;
}

.bg-lvl2 .toast-info {
  border-color: #1a62ff;
}
.bg-lvl2 .toast-info .toast-header {
  color: #1a62ff;
}

.bg-lvl2 .toast-success {
  border-color: #009e0b;
}
.bg-lvl2 .toast-success .toast-header {
  color: #009e0b;
}

.bg-lvl2 .toast-warning {
  border-color: #d06f00;
}
.bg-lvl2 .toast-warning .toast-header {
  color: #d06f00;
}

.bg-lvl2 .toast-danger {
  border-color: #d43321;
}
.bg-lvl2 .toast-danger .toast-header {
  color: #d43321;
}

.bg-lvl1 .toast-secondary {
  border-color: #6e777a;
}
.bg-lvl1 .toast-secondary .toast-header {
  color: #6e777a;
}

.bg-lvl1 .toast-light {
  border-color: #c4c8ca;
}
.bg-lvl1 .toast-light .toast-header {
  color: #c4c8ca;
}

.bg-lvl1 .toast-dark {
  border-color: #414949;
}
.bg-lvl1 .toast-dark .toast-header {
  color: #414949;
}

.bg-lvl1 .toast-info {
  border-color: #2469ff;
}
.bg-lvl1 .toast-info .toast-header {
  color: #2469ff;
}

.bg-lvl1 .toast-success {
  border-color: #00aa0c;
}
.bg-lvl1 .toast-success .toast-header {
  color: #00aa0c;
}

.bg-lvl1 .toast-warning {
  border-color: #da7400;
}
.bg-lvl1 .toast-warning .toast-header {
  color: #da7400;
}

.bg-lvl1 .toast-danger {
  border-color: #dd3724;
}
.bg-lvl1 .toast-danger .toast-header {
  color: #dd3724;
}

.bg-alt-lvl4 .toast-secondary {
  border-color: #70797a;
}
.bg-alt-lvl4 .toast-secondary .toast-header {
  color: #70797a;
}

.bg-alt-lvl4 .toast-light {
  border-color: #a3afb3;
}
.bg-alt-lvl4 .toast-light .toast-header {
  color: #a3afb3;
}

.bg-alt-lvl4 .toast-dark {
  border-color: #343737;
}
.bg-alt-lvl4 .toast-dark .toast-header {
  color: #343737;
}

.bg-alt-lvl4 .toast-info {
  border-color: #1476ff;
}
.bg-alt-lvl4 .toast-info .toast-header {
  color: #1476ff;
}

.bg-alt-lvl4 .toast-success {
  border-color: #00a83e;
}
.bg-alt-lvl4 .toast-success .toast-header {
  color: #00a83e;
}

.bg-alt-lvl4 .toast-warning {
  border-color: #cc7400;
}
.bg-alt-lvl4 .toast-warning .toast-header {
  color: #cc7400;
}

.bg-alt-lvl4 .toast-danger {
  border-color: #d93d26;
}
.bg-alt-lvl4 .toast-danger .toast-header {
  color: #d93d26;
}

.bg-alt-lvl3 .toast-secondary {
  border-color: #7a8385;
}
.bg-alt-lvl3 .toast-secondary .toast-header {
  color: #7a8385;
}

.bg-alt-lvl3 .toast-light {
  border-color: #b1bbbe;
}
.bg-alt-lvl3 .toast-light .toast-header {
  color: #b1bbbe;
}

.bg-alt-lvl3 .toast-dark {
  border-color: #3b3f3f;
}
.bg-alt-lvl3 .toast-dark .toast-header {
  color: #3b3f3f;
}

.bg-alt-lvl3 .toast-info {
  border-color: #2982ff;
}
.bg-alt-lvl3 .toast-info .toast-header {
  color: #2982ff;
}

.bg-alt-lvl3 .toast-success {
  border-color: #00b844;
}
.bg-alt-lvl3 .toast-success .toast-header {
  color: #00b844;
}

.bg-alt-lvl3 .toast-warning {
  border-color: #db7c00;
}
.bg-alt-lvl3 .toast-warning .toast-header {
  color: #db7c00;
}

.bg-alt-lvl3 .toast-danger {
  border-color: #e8442c;
}
.bg-alt-lvl3 .toast-danger .toast-header {
  color: #e8442c;
}

.bg-alt-lvl2 .toast-secondary {
  border-color: #848d8f;
}
.bg-alt-lvl2 .toast-secondary .toast-header {
  color: #848d8f;
}

.bg-alt-lvl2 .toast-light {
  border-color: #c2cacc;
}
.bg-alt-lvl2 .toast-light .toast-header {
  color: #c2cacc;
}

.bg-alt-lvl2 .toast-dark {
  border-color: #454a4a;
}
.bg-alt-lvl2 .toast-dark .toast-header {
  color: #454a4a;
}

.bg-alt-lvl2 .toast-info {
  border-color: #3d8eff;
}
.bg-alt-lvl2 .toast-info .toast-header {
  color: #3d8eff;
}

.bg-alt-lvl2 .toast-success {
  border-color: #00c74a;
}
.bg-alt-lvl2 .toast-success .toast-header {
  color: #00c74a;
}

.bg-alt-lvl2 .toast-warning {
  border-color: #eb8500;
}
.bg-alt-lvl2 .toast-warning .toast-header {
  color: #eb8500;
}

.bg-alt-lvl2 .toast-danger {
  border-color: #f44d34;
}
.bg-alt-lvl2 .toast-danger .toast-header {
  color: #f44d34;
}

.bg-alt-lvl1 .toast-secondary {
  border-color: #949c9e;
}
.bg-alt-lvl1 .toast-secondary .toast-header {
  color: #949c9e;
}

.bg-alt-lvl1 .toast-light {
  border-color: #d8ddde;
}
.bg-alt-lvl1 .toast-light .toast-header {
  color: #d8ddde;
}

.bg-alt-lvl1 .toast-dark {
  border-color: #515757;
}
.bg-alt-lvl1 .toast-dark .toast-header {
  color: #515757;
}

.bg-alt-lvl1 .toast-info {
  border-color: #529aff;
}
.bg-alt-lvl1 .toast-info .toast-header {
  color: #529aff;
}

.bg-alt-lvl1 .toast-success {
  border-color: #00d750;
}
.bg-alt-lvl1 .toast-success .toast-header {
  color: #00d750;
}

.bg-alt-lvl1 .toast-warning {
  border-color: #f98d00;
}
.bg-alt-lvl1 .toast-warning .toast-header {
  color: #f98d00;
}

.bg-alt-lvl1 .toast-danger {
  border-color: #ff563c;
}
.bg-alt-lvl1 .toast-danger .toast-header {
  color: #ff563c;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  -webkit-transition: -webkit-transform 0.3s ease-out;
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -50px);
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    -webkit-transition: none;
    transition: none;
  }
}
.modal.show .modal-dialog {
  -webkit-transform: none;
  transform: none;
}
.modal.modal-static .modal-dialog {
  -webkit-transform: scale(1.02);
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #000;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #231f20;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid transparent;
}
.modal-header .close {
  padding: 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.4285714286;
}

.modal-body {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid transparent;
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
  }

  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
@media (min-width: 576px) {
  .modal-body,
.modal-footer,
.modal-header {
    padding: 1.5rem;
  }
}
@media (min-width: 992px) {
  .modal-body,
.modal-footer,
.modal-header {
    padding: 2rem;
  }
}
.modal-body {
  overflow-y: auto;
  padding-top: 0;
}

.modal-content {
  max-height: 90vh;
  border: none;
}

.modal-title {
  line-height: 1;
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.4285714286;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 1;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 1rem;
  color: #fff;
  text-align: center;
  background-color: #000;
}

.tooltip {
  font-family: "Inter", "Noto_Sans_JP", sans-serif;
}

.tooltip-primary .tooltip-inner {
  background-color: #000;
}

.tooltip-primary .bs-tooltip-top .arrow::before, .tooltip-primary .bs-tooltip-auto[x-placement^=top] .arrow::before,
.tooltip-primary.bs-tooltip-top .arrow::before,
.tooltip-primary.bs-tooltip-auto[x-placement^=top] .arrow::before {
  border-top-color: #000;
}

.tooltip-primary .bs-tooltip-right .arrow::before, .tooltip-primary .bs-tooltip-auto[x-placement^=right] .arrow::before,
.tooltip-primary.bs-tooltip-right .arrow::before,
.tooltip-primary.bs-tooltip-auto[x-placement^=right] .arrow::before {
  border-right-color: #000;
}

.tooltip-primary .bs-tooltip-left .arrow::before, .tooltip-primary .bs-tooltip-auto[x-placement^=left] .arrow::before,
.tooltip-primary.bs-tooltip-left .arrow::before,
.tooltip-primary.bs-tooltip-auto[x-placement^=left] .arrow::before {
  border-left-color: #000;
}

.tooltip-primary .bs-tooltip-bottom .arrow::before, .tooltip-primary .bs-tooltip-auto[x-placement^=bottom] .arrow::before,
.tooltip-primary.bs-tooltip-bottom .arrow::before,
.tooltip-primary.bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  border-bottom-color: #000;
}

.tooltip-secondary .tooltip-inner {
  background-color: #6e777a;
}

.tooltip-secondary .bs-tooltip-top .arrow::before, .tooltip-secondary .bs-tooltip-auto[x-placement^=top] .arrow::before,
.tooltip-secondary.bs-tooltip-top .arrow::before,
.tooltip-secondary.bs-tooltip-auto[x-placement^=top] .arrow::before {
  border-top-color: #6e777a;
}

.tooltip-secondary .bs-tooltip-right .arrow::before, .tooltip-secondary .bs-tooltip-auto[x-placement^=right] .arrow::before,
.tooltip-secondary.bs-tooltip-right .arrow::before,
.tooltip-secondary.bs-tooltip-auto[x-placement^=right] .arrow::before {
  border-right-color: #6e777a;
}

.tooltip-secondary .bs-tooltip-left .arrow::before, .tooltip-secondary .bs-tooltip-auto[x-placement^=left] .arrow::before,
.tooltip-secondary.bs-tooltip-left .arrow::before,
.tooltip-secondary.bs-tooltip-auto[x-placement^=left] .arrow::before {
  border-left-color: #6e777a;
}

.tooltip-secondary .bs-tooltip-bottom .arrow::before, .tooltip-secondary .bs-tooltip-auto[x-placement^=bottom] .arrow::before,
.tooltip-secondary.bs-tooltip-bottom .arrow::before,
.tooltip-secondary.bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  border-bottom-color: #6e777a;
}

.tooltip-success .tooltip-inner {
  background-color: #00aa0c;
}

.tooltip-success .bs-tooltip-top .arrow::before, .tooltip-success .bs-tooltip-auto[x-placement^=top] .arrow::before,
.tooltip-success.bs-tooltip-top .arrow::before,
.tooltip-success.bs-tooltip-auto[x-placement^=top] .arrow::before {
  border-top-color: #00aa0c;
}

.tooltip-success .bs-tooltip-right .arrow::before, .tooltip-success .bs-tooltip-auto[x-placement^=right] .arrow::before,
.tooltip-success.bs-tooltip-right .arrow::before,
.tooltip-success.bs-tooltip-auto[x-placement^=right] .arrow::before {
  border-right-color: #00aa0c;
}

.tooltip-success .bs-tooltip-left .arrow::before, .tooltip-success .bs-tooltip-auto[x-placement^=left] .arrow::before,
.tooltip-success.bs-tooltip-left .arrow::before,
.tooltip-success.bs-tooltip-auto[x-placement^=left] .arrow::before {
  border-left-color: #00aa0c;
}

.tooltip-success .bs-tooltip-bottom .arrow::before, .tooltip-success .bs-tooltip-auto[x-placement^=bottom] .arrow::before,
.tooltip-success.bs-tooltip-bottom .arrow::before,
.tooltip-success.bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  border-bottom-color: #00aa0c;
}

.tooltip-info .tooltip-inner {
  background-color: #2469ff;
}

.tooltip-info .bs-tooltip-top .arrow::before, .tooltip-info .bs-tooltip-auto[x-placement^=top] .arrow::before,
.tooltip-info.bs-tooltip-top .arrow::before,
.tooltip-info.bs-tooltip-auto[x-placement^=top] .arrow::before {
  border-top-color: #2469ff;
}

.tooltip-info .bs-tooltip-right .arrow::before, .tooltip-info .bs-tooltip-auto[x-placement^=right] .arrow::before,
.tooltip-info.bs-tooltip-right .arrow::before,
.tooltip-info.bs-tooltip-auto[x-placement^=right] .arrow::before {
  border-right-color: #2469ff;
}

.tooltip-info .bs-tooltip-left .arrow::before, .tooltip-info .bs-tooltip-auto[x-placement^=left] .arrow::before,
.tooltip-info.bs-tooltip-left .arrow::before,
.tooltip-info.bs-tooltip-auto[x-placement^=left] .arrow::before {
  border-left-color: #2469ff;
}

.tooltip-info .bs-tooltip-bottom .arrow::before, .tooltip-info .bs-tooltip-auto[x-placement^=bottom] .arrow::before,
.tooltip-info.bs-tooltip-bottom .arrow::before,
.tooltip-info.bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  border-bottom-color: #2469ff;
}

.tooltip-warning .tooltip-inner {
  background-color: #da7400;
}

.tooltip-warning .bs-tooltip-top .arrow::before, .tooltip-warning .bs-tooltip-auto[x-placement^=top] .arrow::before,
.tooltip-warning.bs-tooltip-top .arrow::before,
.tooltip-warning.bs-tooltip-auto[x-placement^=top] .arrow::before {
  border-top-color: #da7400;
}

.tooltip-warning .bs-tooltip-right .arrow::before, .tooltip-warning .bs-tooltip-auto[x-placement^=right] .arrow::before,
.tooltip-warning.bs-tooltip-right .arrow::before,
.tooltip-warning.bs-tooltip-auto[x-placement^=right] .arrow::before {
  border-right-color: #da7400;
}

.tooltip-warning .bs-tooltip-left .arrow::before, .tooltip-warning .bs-tooltip-auto[x-placement^=left] .arrow::before,
.tooltip-warning.bs-tooltip-left .arrow::before,
.tooltip-warning.bs-tooltip-auto[x-placement^=left] .arrow::before {
  border-left-color: #da7400;
}

.tooltip-warning .bs-tooltip-bottom .arrow::before, .tooltip-warning .bs-tooltip-auto[x-placement^=bottom] .arrow::before,
.tooltip-warning.bs-tooltip-bottom .arrow::before,
.tooltip-warning.bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  border-bottom-color: #da7400;
}

.tooltip-danger .tooltip-inner {
  background-color: #dd3724;
}

.tooltip-danger .bs-tooltip-top .arrow::before, .tooltip-danger .bs-tooltip-auto[x-placement^=top] .arrow::before,
.tooltip-danger.bs-tooltip-top .arrow::before,
.tooltip-danger.bs-tooltip-auto[x-placement^=top] .arrow::before {
  border-top-color: #dd3724;
}

.tooltip-danger .bs-tooltip-right .arrow::before, .tooltip-danger .bs-tooltip-auto[x-placement^=right] .arrow::before,
.tooltip-danger.bs-tooltip-right .arrow::before,
.tooltip-danger.bs-tooltip-auto[x-placement^=right] .arrow::before {
  border-right-color: #dd3724;
}

.tooltip-danger .bs-tooltip-left .arrow::before, .tooltip-danger .bs-tooltip-auto[x-placement^=left] .arrow::before,
.tooltip-danger.bs-tooltip-left .arrow::before,
.tooltip-danger.bs-tooltip-auto[x-placement^=left] .arrow::before {
  border-left-color: #dd3724;
}

.tooltip-danger .bs-tooltip-bottom .arrow::before, .tooltip-danger .bs-tooltip-auto[x-placement^=bottom] .arrow::before,
.tooltip-danger.bs-tooltip-bottom .arrow::before,
.tooltip-danger.bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  border-bottom-color: #dd3724;
}

.tooltip-light .tooltip-inner {
  background-color: #c4c8ca;
}

.tooltip-light .bs-tooltip-top .arrow::before, .tooltip-light .bs-tooltip-auto[x-placement^=top] .arrow::before,
.tooltip-light.bs-tooltip-top .arrow::before,
.tooltip-light.bs-tooltip-auto[x-placement^=top] .arrow::before {
  border-top-color: #c4c8ca;
}

.tooltip-light .bs-tooltip-right .arrow::before, .tooltip-light .bs-tooltip-auto[x-placement^=right] .arrow::before,
.tooltip-light.bs-tooltip-right .arrow::before,
.tooltip-light.bs-tooltip-auto[x-placement^=right] .arrow::before {
  border-right-color: #c4c8ca;
}

.tooltip-light .bs-tooltip-left .arrow::before, .tooltip-light .bs-tooltip-auto[x-placement^=left] .arrow::before,
.tooltip-light.bs-tooltip-left .arrow::before,
.tooltip-light.bs-tooltip-auto[x-placement^=left] .arrow::before {
  border-left-color: #c4c8ca;
}

.tooltip-light .bs-tooltip-bottom .arrow::before, .tooltip-light .bs-tooltip-auto[x-placement^=bottom] .arrow::before,
.tooltip-light.bs-tooltip-bottom .arrow::before,
.tooltip-light.bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  border-bottom-color: #c4c8ca;
}

.tooltip-dark .tooltip-inner {
  background-color: #414949;
}

.tooltip-dark .bs-tooltip-top .arrow::before, .tooltip-dark .bs-tooltip-auto[x-placement^=top] .arrow::before,
.tooltip-dark.bs-tooltip-top .arrow::before,
.tooltip-dark.bs-tooltip-auto[x-placement^=top] .arrow::before {
  border-top-color: #414949;
}

.tooltip-dark .bs-tooltip-right .arrow::before, .tooltip-dark .bs-tooltip-auto[x-placement^=right] .arrow::before,
.tooltip-dark.bs-tooltip-right .arrow::before,
.tooltip-dark.bs-tooltip-auto[x-placement^=right] .arrow::before {
  border-right-color: #414949;
}

.tooltip-dark .bs-tooltip-left .arrow::before, .tooltip-dark .bs-tooltip-auto[x-placement^=left] .arrow::before,
.tooltip-dark.bs-tooltip-left .arrow::before,
.tooltip-dark.bs-tooltip-auto[x-placement^=left] .arrow::before {
  border-left-color: #414949;
}

.tooltip-dark .bs-tooltip-bottom .arrow::before, .tooltip-dark .bs-tooltip-auto[x-placement^=bottom] .arrow::before,
.tooltip-dark.bs-tooltip-bottom .arrow::before,
.tooltip-dark.bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  border-bottom-color: #414949;
}

.tooltip-white .tooltip-inner {
  background-color: white;
}

.tooltip-white .bs-tooltip-top .arrow::before, .tooltip-white .bs-tooltip-auto[x-placement^=top] .arrow::before,
.tooltip-white.bs-tooltip-top .arrow::before,
.tooltip-white.bs-tooltip-auto[x-placement^=top] .arrow::before {
  border-top-color: white;
}

.tooltip-white .bs-tooltip-right .arrow::before, .tooltip-white .bs-tooltip-auto[x-placement^=right] .arrow::before,
.tooltip-white.bs-tooltip-right .arrow::before,
.tooltip-white.bs-tooltip-auto[x-placement^=right] .arrow::before {
  border-right-color: white;
}

.tooltip-white .bs-tooltip-left .arrow::before, .tooltip-white .bs-tooltip-auto[x-placement^=left] .arrow::before,
.tooltip-white.bs-tooltip-left .arrow::before,
.tooltip-white.bs-tooltip-auto[x-placement^=left] .arrow::before {
  border-left-color: white;
}

.tooltip-white .bs-tooltip-bottom .arrow::before, .tooltip-white .bs-tooltip-auto[x-placement^=bottom] .arrow::before,
.tooltip-white.bs-tooltip-bottom .arrow::before,
.tooltip-white.bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  border-bottom-color: white;
}

.tooltip-black .tooltip-inner {
  background-color: #231f20;
}

.tooltip-black .bs-tooltip-top .arrow::before, .tooltip-black .bs-tooltip-auto[x-placement^=top] .arrow::before,
.tooltip-black.bs-tooltip-top .arrow::before,
.tooltip-black.bs-tooltip-auto[x-placement^=top] .arrow::before {
  border-top-color: #231f20;
}

.tooltip-black .bs-tooltip-right .arrow::before, .tooltip-black .bs-tooltip-auto[x-placement^=right] .arrow::before,
.tooltip-black.bs-tooltip-right .arrow::before,
.tooltip-black.bs-tooltip-auto[x-placement^=right] .arrow::before {
  border-right-color: #231f20;
}

.tooltip-black .bs-tooltip-left .arrow::before, .tooltip-black .bs-tooltip-auto[x-placement^=left] .arrow::before,
.tooltip-black.bs-tooltip-left .arrow::before,
.tooltip-black.bs-tooltip-auto[x-placement^=left] .arrow::before {
  border-left-color: #231f20;
}

.tooltip-black .bs-tooltip-bottom .arrow::before, .tooltip-black .bs-tooltip-auto[x-placement^=bottom] .arrow::before,
.tooltip-black.bs-tooltip-bottom .arrow::before,
.tooltip-black.bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  border-bottom-color: #231f20;
}

.tooltip-socgen .tooltip-inner {
  background-color: #e60028;
}

.tooltip-socgen .bs-tooltip-top .arrow::before, .tooltip-socgen .bs-tooltip-auto[x-placement^=top] .arrow::before,
.tooltip-socgen.bs-tooltip-top .arrow::before,
.tooltip-socgen.bs-tooltip-auto[x-placement^=top] .arrow::before {
  border-top-color: #e60028;
}

.tooltip-socgen .bs-tooltip-right .arrow::before, .tooltip-socgen .bs-tooltip-auto[x-placement^=right] .arrow::before,
.tooltip-socgen.bs-tooltip-right .arrow::before,
.tooltip-socgen.bs-tooltip-auto[x-placement^=right] .arrow::before {
  border-right-color: #e60028;
}

.tooltip-socgen .bs-tooltip-left .arrow::before, .tooltip-socgen .bs-tooltip-auto[x-placement^=left] .arrow::before,
.tooltip-socgen.bs-tooltip-left .arrow::before,
.tooltip-socgen.bs-tooltip-auto[x-placement^=left] .arrow::before {
  border-left-color: #e60028;
}

.tooltip-socgen .bs-tooltip-bottom .arrow::before, .tooltip-socgen .bs-tooltip-auto[x-placement^=bottom] .arrow::before,
.tooltip-socgen.bs-tooltip-bottom .arrow::before,
.tooltip-socgen.bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  border-bottom-color: #e60028;
}

.tooltip-primary-alt .tooltip-inner {
  background-color: #fff;
}

.tooltip-primary-alt .bs-tooltip-top .arrow::before, .tooltip-primary-alt .bs-tooltip-auto[x-placement^=top] .arrow::before,
.tooltip-primary-alt.bs-tooltip-top .arrow::before,
.tooltip-primary-alt.bs-tooltip-auto[x-placement^=top] .arrow::before {
  border-top-color: #fff;
}

.tooltip-primary-alt .bs-tooltip-right .arrow::before, .tooltip-primary-alt .bs-tooltip-auto[x-placement^=right] .arrow::before,
.tooltip-primary-alt.bs-tooltip-right .arrow::before,
.tooltip-primary-alt.bs-tooltip-auto[x-placement^=right] .arrow::before {
  border-right-color: #fff;
}

.tooltip-primary-alt .bs-tooltip-left .arrow::before, .tooltip-primary-alt .bs-tooltip-auto[x-placement^=left] .arrow::before,
.tooltip-primary-alt.bs-tooltip-left .arrow::before,
.tooltip-primary-alt.bs-tooltip-auto[x-placement^=left] .arrow::before {
  border-left-color: #fff;
}

.tooltip-primary-alt .bs-tooltip-bottom .arrow::before, .tooltip-primary-alt .bs-tooltip-auto[x-placement^=bottom] .arrow::before,
.tooltip-primary-alt.bs-tooltip-bottom .arrow::before,
.tooltip-primary-alt.bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  border-bottom-color: #fff;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.4285714286;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #000;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0;
}
.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.5rem;
}
.bs-popover-top > .arrow, .bs-popover-auto[x-placement^=top] > .arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^=top] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: black;
}
.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^=top] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}
.bs-popover-right > .arrow, .bs-popover-auto[x-placement^=right] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0 0;
}
.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^=right] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: black;
}
.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^=right] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.5rem;
}
.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^=bottom] > .arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^=bottom] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: black;
}
.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^=bottom] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #fff;
}

.bs-popover-left, .bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}
.bs-popover-left > .arrow, .bs-popover-auto[x-placement^=left] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0 0;
}
.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^=left] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: black;
}
.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^=left] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #303333;
  background-color: #fff;
  border-bottom: 1px solid #f2f2f2;
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #303333;
}

.popover {
  min-width: 200px;
  color: #000;
  font-family: unset;
}

.popover-header {
  border-bottom: none;
  font-weight: 700;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: -webkit-transform 0.6s ease-in-out;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    -webkit-transition: none;
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  -webkit-transform: none;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  -webkit-transition: opacity 0s 0.6s;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
    -webkit-transition: none;
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  -webkit-transition: opacity 0.15s ease;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
.carousel-control-next {
    -webkit-transition: none;
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50%/100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  -webkit-transition: opacity 0.6s ease;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    -webkit-transition: none;
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 5%;
  bottom: 20px;
  left: 5%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

.carousel-gallery {
  padding-bottom: 0;
  background: #222424;
}
.carousel-gallery .carousel-inner {
  margin-top: 3rem;
}
@media (min-width: 576px) {
  .carousel-gallery .carousel-inner {
    width: calc(100% - 20%);
    margin: 0;
  }
}
.carousel-gallery .carousel-indicators {
  font-size: 0;
  width: 100%;
  display: block;
  white-space: nowrap;
  overflow-x: auto;
  position: absolute;
  background: #c4c8ca;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  border: none;
  margin: 0;
  padding: 0.5rem;
  height: 4rem;
}
@media (min-width: 576px) {
  .carousel-gallery .carousel-indicators {
    padding: 1.5rem;
    left: auto;
    bottom: auto;
    width: 20%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }
}
.carousel-gallery .carousel-indicators li {
  margin: 0 0.5rem 0 0;
  text-align: center;
  position: relative;
  text-indent: 0;
  background: none;
  width: auto;
  height: 100%;
  display: inline-block;
}
.carousel-gallery .carousel-indicators li img {
  height: 3rem;
}
@media (min-width: 576px) {
  .carousel-gallery .carousel-indicators li {
    display: block;
    height: auto;
    margin: 0 0 0.5rem;
  }
  .carousel-gallery .carousel-indicators li:last-child {
    margin-bottom: 0;
  }
  .carousel-gallery .carousel-indicators li img {
    height: auto;
    opacity: 0.5;
  }
}
.carousel-gallery .carousel-indicators li.active img {
  opacity: 1;
  outline: 2px solid #000;
}
.carousel-gallery .gallery-controls {
  position: absolute;
  bottom: -2rem;
  left: 0;
  right: 0;
  height: 1.5rem;
}
.carousel-gallery .gallery-controls h4,
.carousel-gallery .gallery-controls h5 {
  margin: 0;
  line-height: 1.5rem;
}

.carousel:not(.carousel-gallery) .carousel-indicators li {
  width: 0.375rem;
  height: 0.375rem;
  background: #6e777a;
  border: none;
}
.carousel:not(.carousel-gallery) .carousel-indicators li.active {
  background: #000;
}

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 3rem;
  height: 3rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border 0.75s linear infinite;
  animation: spinner-border 0.75s linear infinite;
}

.spinner-border-sm {
  width: 2.2rem;
  height: 2.2rem;
  border-width: 0.2em;
}

@-webkit-keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 3rem;
  height: 3rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: spinner-grow 0.75s linear infinite;
  animation: spinner-grow 0.75s linear infinite;
}

.spinner-grow-sm {
  width: 2.2rem;
  height: 2.2rem;
}

.spinner-grow {
  display: inline-block;
  width: auto;
  height: auto;
  background-color: transparent;
  border-radius: 0;
  opacity: 1;
  -webkit-animation: none;
  animation: none;
  font-size: 0.875rem;
  white-space: nowrap;
  line-height: 2;
}
.spinner-grow::after {
  content: "";
  display: block;
  width: 3rem;
  height: 0.36rem;
  background: #000;
  -webkit-animation: blink 800ms infinite;
  animation: blink 800ms infinite;
}

@-webkit-keyframes blink {
  0% {
    background: #000;
  }
  40% {
    background: transparent;
  }
  100% {
    background: #000;
  }
}

@keyframes blink {
  0% {
    background: #000;
  }
  40% {
    background: transparent;
  }
  100% {
    background: #000;
  }
}
.spinner-grow-sm::after {
  width: 2.2rem;
  height: 0.264rem;
}

.spinner-grow-xs::after {
  width: 1.5rem;
  height: 0.18rem;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #000 !important;
}

a.bg-primary:not(.card):hover, a.bg-primary:not(.card):focus,
button.bg-primary:not(.card):hover,
button.bg-primary:not(.card):focus {
  background-color: black !important;
}

.bg-secondary {
  background-color: #6e777a !important;
}

a.bg-secondary:not(.card):hover, a.bg-secondary:not(.card):focus,
button.bg-secondary:not(.card):hover,
button.bg-secondary:not(.card):focus {
  background-color: #565d5f !important;
}

.bg-success {
  background-color: #00aa0c !important;
}

a.bg-success:not(.card):hover, a.bg-success:not(.card):focus,
button.bg-success:not(.card):hover,
button.bg-success:not(.card):focus {
  background-color: #007708 !important;
}

.bg-info {
  background-color: #2469ff !important;
}

a.bg-info:not(.card):hover, a.bg-info:not(.card):focus,
button.bg-info:not(.card):hover,
button.bg-info:not(.card):focus {
  background-color: #004cf0 !important;
}

.bg-warning {
  background-color: #da7400 !important;
}

a.bg-warning:not(.card):hover, a.bg-warning:not(.card):focus,
button.bg-warning:not(.card):hover,
button.bg-warning:not(.card):focus {
  background-color: #a75900 !important;
}

.bg-danger {
  background-color: #dd3724 !important;
}

a.bg-danger:not(.card):hover, a.bg-danger:not(.card):focus,
button.bg-danger:not(.card):hover,
button.bg-danger:not(.card):focus {
  background-color: #b22b1c !important;
}

.bg-light {
  background-color: #c4c8ca !important;
}

a.bg-light:not(.card):hover, a.bg-light:not(.card):focus,
button.bg-light:not(.card):hover,
button.bg-light:not(.card):focus {
  background-color: #a9afb2 !important;
}

.bg-dark {
  background-color: #414949 !important;
}

a.bg-dark:not(.card):hover, a.bg-dark:not(.card):focus,
button.bg-dark:not(.card):hover,
button.bg-dark:not(.card):focus {
  background-color: #292e2e !important;
}

.bg-white {
  background-color: white !important;
}

a.bg-white:not(.card):hover, a.bg-white:not(.card):focus,
button.bg-white:not(.card):hover,
button.bg-white:not(.card):focus {
  background-color: #e6e6e6 !important;
}

.bg-black {
  background-color: #231f20 !important;
}

a.bg-black:not(.card):hover, a.bg-black:not(.card):focus,
button.bg-black:not(.card):hover,
button.bg-black:not(.card):focus {
  background-color: #080707 !important;
}

.bg-socgen {
  background-color: #e60028 !important;
}

a.bg-socgen:not(.card):hover, a.bg-socgen:not(.card):focus,
button.bg-socgen:not(.card):hover,
button.bg-socgen:not(.card):focus {
  background-color: #b3001f !important;
}

.bg-primary-alt {
  background-color: #fff !important;
}

a.bg-primary-alt:not(.card):hover, a.bg-primary-alt:not(.card):focus,
button.bg-primary-alt:not(.card):hover,
button.bg-primary-alt:not(.card):focus {
  background-color: #e6e6e6 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid rgba(196, 200, 202, 0.7) !important;
}

.border-top {
  border-top: 1px solid rgba(196, 200, 202, 0.7) !important;
}

.border-right {
  border-right: 1px solid rgba(196, 200, 202, 0.7) !important;
}

.border-bottom {
  border-bottom: 1px solid rgba(196, 200, 202, 0.7) !important;
}

.border-left {
  border-left: 1px solid rgba(196, 200, 202, 0.7) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #000 !important;
}

.border-secondary {
  border-color: #6e777a !important;
}

.border-success {
  border-color: #00aa0c !important;
}

.border-info {
  border-color: #2469ff !important;
}

.border-warning {
  border-color: #da7400 !important;
}

.border-danger {
  border-color: #dd3724 !important;
}

.border-light {
  border-color: #c4c8ca !important;
}

.border-dark {
  border-color: #414949 !important;
}

.border-white {
  border-color: white !important;
}

.border-black {
  border-color: #231f20 !important;
}

.border-socgen {
  border-color: #e60028 !important;
}

.border-primary-alt {
  border-color: #fff !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0 !important;
}

.rounded {
  border-radius: 0 !important;
}

.rounded-top {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-right {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-left {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-lg {
  border-radius: 0 !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.d-inline-flex {
  display: -webkit-inline-box !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-md-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
@media (min-width: 1600px) {
  .d-xxl-none {
    display: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-print-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}

.flex-column {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}

.flex-row-reverse {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: reverse !important;
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: reverse !important;
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important;
}

.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
}

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  -webkit-box-flex: 1 !important;
  -ms-flex: 1 1 auto !important;
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  -webkit-box-flex: 0 !important;
  -ms-flex-positive: 0 !important;
  flex-grow: 0 !important;
}

.flex-grow-1 {
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  -ms-flex-negative: 1 !important;
  flex-shrink: 1 !important;
}

.justify-content-start {
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
}

.justify-content-end {
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}

.justify-content-center {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.justify-content-between {
  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}

.justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important;
}

.align-items-start {
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}

.align-items-end {
  -webkit-box-align: end !important;
  -ms-flex-align: end !important;
  align-items: flex-end !important;
}

.align-items-center {
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}

.align-items-baseline {
  -webkit-box-align: baseline !important;
  -ms-flex-align: baseline !important;
  align-items: baseline !important;
}

.align-items-stretch {
  -webkit-box-align: stretch !important;
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
}

.align-content-start {
  -ms-flex-line-pack: start !important;
  align-content: flex-start !important;
}

.align-content-end {
  -ms-flex-line-pack: end !important;
  align-content: flex-end !important;
}

.align-content-center {
  -ms-flex-line-pack: center !important;
  align-content: center !important;
}

.align-content-between {
  -ms-flex-line-pack: justify !important;
  align-content: space-between !important;
}

.align-content-around {
  -ms-flex-line-pack: distribute !important;
  align-content: space-around !important;
}

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
  align-content: stretch !important;
}

.align-self-auto {
  -ms-flex-item-align: auto !important;
  align-self: auto !important;
}

.align-self-start {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important;
}

.align-self-end {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important;
}

.align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important;
}

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
  align-self: baseline !important;
}

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }

  .flex-sm-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }

  .justify-content-sm-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }

  .align-items-sm-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }

  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }

  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }

  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }

  .align-self-sm-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }

  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }

  .flex-md-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }

  .justify-content-md-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }

  .align-items-md-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }

  .align-items-md-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }

  .align-items-md-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }

  .align-items-md-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }

  .align-content-md-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }

  .align-content-md-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }

  .align-content-md-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }

  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }

  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }

  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }

  .align-self-md-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }

  .align-self-md-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }

  .align-self-md-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }

  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }

  .flex-lg-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }

  .justify-content-lg-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }

  .align-items-lg-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }

  .align-content-lg-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }

  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }

  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }

  .align-self-lg-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }

  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }

  .flex-xl-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }

  .justify-content-xl-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }

  .align-items-xl-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }

  .align-content-xl-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }

  .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }

  .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }

  .align-self-xl-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }

  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}
@media (min-width: 1600px) {
  .flex-xxl-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }

  .flex-xxl-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }

  .flex-xxl-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }

  .flex-xxl-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }

  .flex-xxl-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }

  .justify-content-xxl-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }

  .align-items-xxl-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }

  .align-content-xxl-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }

  .align-self-xxl-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}
@media (min-width: 1600px) {
  .float-xxl-left {
    float: left !important;
  }

  .float-xxl-right {
    float: right !important;
  }

  .float-xxl-none {
    float: none !important;
  }
}
.user-select-all {
  -webkit-user-select: all !important;
  -moz-user-select: all !important;
  user-select: all !important;
}

.user-select-auto {
  -webkit-user-select: auto !important;
  -moz-user-select: auto !important;
  -ms-user-select: auto !important;
  user-select: auto !important;
}

.user-select-none {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 2px 1px -1px rgba(0, 0, 0, 0.06) !important;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 2px 1px -1px rgba(0, 0, 0, 0.06) !important;
}

.shadow {
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 0 8px 0px rgba(0, 0, 0, 0.06), 0 8px 15px 0 rgba(0, 0, 0, 0.12) !important;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 0 8px 0px rgba(0, 0, 0, 0.06), 0 8px 15px 0 rgba(0, 0, 0, 0.12) !important;
}

.shadow-lg {
  -webkit-box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.1), 0 4px 5px 0 rgba(0, 0, 0, 0.07), 0 1px 10px 0 rgba(0, 0, 0, 0.06) !important;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.1), 0 4px 5px 0 rgba(0, 0, 0, 0.07), 0 1px 10px 0 rgba(0, 0, 0, 0.06) !important;
}

.shadow-none {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
.my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
.mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
.my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
.mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .mt-sm-1,
.my-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mr-sm-1,
.mx-sm-1 {
    margin-right: 0.25rem !important;
  }

  .mb-sm-1,
.my-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-sm-1,
.mx-sm-1 {
    margin-left: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .mt-sm-2,
.my-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mr-sm-2,
.mx-sm-2 {
    margin-right: 0.5rem !important;
  }

  .mb-sm-2,
.my-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-sm-2,
.mx-sm-2 {
    margin-left: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3,
.my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3,
.mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3,
.my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3,
.mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4,
.my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4,
.mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4,
.my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4,
.mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5,
.my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5,
.mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5,
.my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5,
.mx-sm-5 {
    margin-left: 3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
.py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
.px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
.py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
.px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .pt-sm-1,
.py-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pr-sm-1,
.px-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pb-sm-1,
.py-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-sm-1,
.px-sm-1 {
    padding-left: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .pt-sm-2,
.py-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pr-sm-2,
.px-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pb-sm-2,
.py-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-sm-2,
.px-sm-2 {
    padding-left: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3,
.py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3,
.px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3,
.py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3,
.px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4,
.py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4,
.px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4,
.py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4,
.px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5,
.py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5,
.px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5,
.py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5,
.px-sm-5 {
    padding-left: 3rem !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .mt-sm-n1,
.my-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-sm-n1,
.mx-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-sm-n1,
.my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-sm-n1,
.mx-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .mt-sm-n2,
.my-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-sm-n2,
.mx-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-sm-n2,
.my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-sm-n2,
.mx-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .mt-sm-n3,
.my-sm-n3 {
    margin-top: -1rem !important;
  }

  .mr-sm-n3,
.mx-sm-n3 {
    margin-right: -1rem !important;
  }

  .mb-sm-n3,
.my-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-sm-n3,
.mx-sm-n3 {
    margin-left: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .mt-sm-n4,
.my-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-sm-n4,
.mx-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-sm-n4,
.my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-sm-n4,
.mx-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mt-sm-n5,
.my-sm-n5 {
    margin-top: -3rem !important;
  }

  .mr-sm-n5,
.mx-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n5,
.my-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-sm-n5,
.mx-sm-n5 {
    margin-left: -3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
.my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
.mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
.my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
.mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
.my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
.mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
.my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
.mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .mt-md-1,
.my-md-1 {
    margin-top: 0.25rem !important;
  }

  .mr-md-1,
.mx-md-1 {
    margin-right: 0.25rem !important;
  }

  .mb-md-1,
.my-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-md-1,
.mx-md-1 {
    margin-left: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .mt-md-2,
.my-md-2 {
    margin-top: 0.5rem !important;
  }

  .mr-md-2,
.mx-md-2 {
    margin-right: 0.5rem !important;
  }

  .mb-md-2,
.my-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-md-2,
.mx-md-2 {
    margin-left: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3,
.my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3,
.mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3,
.my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3,
.mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4,
.my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4,
.mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4,
.my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4,
.mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5,
.my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5,
.mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5,
.my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5,
.mx-md-5 {
    margin-left: 3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
.py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
.px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
.py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
.px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .pt-md-1,
.py-md-1 {
    padding-top: 0.25rem !important;
  }

  .pr-md-1,
.px-md-1 {
    padding-right: 0.25rem !important;
  }

  .pb-md-1,
.py-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-md-1,
.px-md-1 {
    padding-left: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .pt-md-2,
.py-md-2 {
    padding-top: 0.5rem !important;
  }

  .pr-md-2,
.px-md-2 {
    padding-right: 0.5rem !important;
  }

  .pb-md-2,
.py-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-md-2,
.px-md-2 {
    padding-left: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3,
.py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3,
.px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3,
.py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3,
.px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4,
.py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4,
.px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4,
.py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4,
.px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5,
.py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5,
.px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5,
.py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5,
.px-md-5 {
    padding-left: 3rem !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .mt-md-n1,
.my-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-md-n1,
.mx-md-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-md-n1,
.my-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-md-n1,
.mx-md-n1 {
    margin-left: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .mt-md-n2,
.my-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-md-n2,
.mx-md-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-md-n2,
.my-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-md-n2,
.mx-md-n2 {
    margin-left: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .mt-md-n3,
.my-md-n3 {
    margin-top: -1rem !important;
  }

  .mr-md-n3,
.mx-md-n3 {
    margin-right: -1rem !important;
  }

  .mb-md-n3,
.my-md-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-md-n3,
.mx-md-n3 {
    margin-left: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .mt-md-n4,
.my-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-md-n4,
.mx-md-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-md-n4,
.my-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-md-n4,
.mx-md-n4 {
    margin-left: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mt-md-n5,
.my-md-n5 {
    margin-top: -3rem !important;
  }

  .mr-md-n5,
.mx-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n5,
.my-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-md-n5,
.mx-md-n5 {
    margin-left: -3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
.my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
.mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
.my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
.mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
.my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
.mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
.my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
.mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .mt-lg-1,
.my-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mr-lg-1,
.mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  .mb-lg-1,
.my-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-lg-1,
.mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .mt-lg-2,
.my-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mr-lg-2,
.mx-lg-2 {
    margin-right: 0.5rem !important;
  }

  .mb-lg-2,
.my-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-lg-2,
.mx-lg-2 {
    margin-left: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3,
.my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3,
.mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3,
.my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3,
.mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4,
.my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4,
.mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4,
.my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4,
.mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5,
.my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5,
.mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5,
.my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5,
.mx-lg-5 {
    margin-left: 3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
.py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
.px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
.py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
.px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .pt-lg-1,
.py-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pr-lg-1,
.px-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pb-lg-1,
.py-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-lg-1,
.px-lg-1 {
    padding-left: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .pt-lg-2,
.py-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pr-lg-2,
.px-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pb-lg-2,
.py-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-lg-2,
.px-lg-2 {
    padding-left: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3,
.py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3,
.px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3,
.py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3,
.px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4,
.py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4,
.px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4,
.py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4,
.px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5,
.py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5,
.px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5,
.py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5,
.px-lg-5 {
    padding-left: 3rem !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .mt-lg-n1,
.my-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-lg-n1,
.mx-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-lg-n1,
.my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-lg-n1,
.mx-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .mt-lg-n2,
.my-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-lg-n2,
.mx-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-lg-n2,
.my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-lg-n2,
.mx-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .mt-lg-n3,
.my-lg-n3 {
    margin-top: -1rem !important;
  }

  .mr-lg-n3,
.mx-lg-n3 {
    margin-right: -1rem !important;
  }

  .mb-lg-n3,
.my-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-lg-n3,
.mx-lg-n3 {
    margin-left: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .mt-lg-n4,
.my-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-lg-n4,
.mx-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-lg-n4,
.my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-lg-n4,
.mx-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mt-lg-n5,
.my-lg-n5 {
    margin-top: -3rem !important;
  }

  .mr-lg-n5,
.mx-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n5,
.my-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-lg-n5,
.mx-lg-n5 {
    margin-left: -3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
.my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
.mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
.my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
.mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
.my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
.mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
.my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
.mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .mt-xl-1,
.my-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xl-1,
.mx-xl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xl-1,
.my-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xl-1,
.mx-xl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .mt-xl-2,
.my-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xl-2,
.mx-xl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xl-2,
.my-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xl-2,
.mx-xl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3,
.my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3,
.mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3,
.my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3,
.mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4,
.my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4,
.mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4,
.my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4,
.mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5,
.my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5,
.mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5,
.my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5,
.mx-xl-5 {
    margin-left: 3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
.py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
.px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
.py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
.px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .pt-xl-1,
.py-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xl-1,
.px-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xl-1,
.py-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xl-1,
.px-xl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .pt-xl-2,
.py-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xl-2,
.px-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xl-2,
.py-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xl-2,
.px-xl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3,
.py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3,
.px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3,
.py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3,
.px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4,
.py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4,
.px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4,
.py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4,
.px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5,
.py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5,
.px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5,
.py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5,
.px-xl-5 {
    padding-left: 3rem !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .mt-xl-n1,
.my-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-xl-n1,
.mx-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xl-n1,
.my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-xl-n1,
.mx-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .mt-xl-n2,
.my-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-xl-n2,
.mx-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-xl-n2,
.my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xl-n2,
.mx-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .mt-xl-n3,
.my-xl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xl-n3,
.mx-xl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xl-n3,
.my-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xl-n3,
.mx-xl-n3 {
    margin-left: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xl-n4,
.my-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xl-n4,
.mx-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xl-n4,
.my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xl-n4,
.mx-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mt-xl-n5,
.my-xl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xl-n5,
.mx-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n5,
.my-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xl-n5,
.mx-xl-n5 {
    margin-left: -3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
.my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
.mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
.my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
.mx-xl-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1600px) {
  .m-xxl-0 {
    margin: 0 !important;
  }

  .mt-xxl-0,
.my-xxl-0 {
    margin-top: 0 !important;
  }

  .mr-xxl-0,
.mx-xxl-0 {
    margin-right: 0 !important;
  }

  .mb-xxl-0,
.my-xxl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xxl-0,
.mx-xxl-0 {
    margin-left: 0 !important;
  }

  .m-xxl-1 {
    margin: 0.25rem !important;
  }

  .mt-xxl-1,
.my-xxl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xxl-1,
.mx-xxl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xxl-1,
.my-xxl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xxl-1,
.mx-xxl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xxl-2 {
    margin: 0.5rem !important;
  }

  .mt-xxl-2,
.my-xxl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xxl-2,
.mx-xxl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xxl-2,
.my-xxl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xxl-2,
.mx-xxl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xxl-3 {
    margin: 1rem !important;
  }

  .mt-xxl-3,
.my-xxl-3 {
    margin-top: 1rem !important;
  }

  .mr-xxl-3,
.mx-xxl-3 {
    margin-right: 1rem !important;
  }

  .mb-xxl-3,
.my-xxl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xxl-3,
.mx-xxl-3 {
    margin-left: 1rem !important;
  }

  .m-xxl-4 {
    margin: 1.5rem !important;
  }

  .mt-xxl-4,
.my-xxl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xxl-4,
.mx-xxl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xxl-4,
.my-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xxl-4,
.mx-xxl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xxl-5 {
    margin: 3rem !important;
  }

  .mt-xxl-5,
.my-xxl-5 {
    margin-top: 3rem !important;
  }

  .mr-xxl-5,
.mx-xxl-5 {
    margin-right: 3rem !important;
  }

  .mb-xxl-5,
.my-xxl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xxl-5,
.mx-xxl-5 {
    margin-left: 3rem !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .pt-xxl-0,
.py-xxl-0 {
    padding-top: 0 !important;
  }

  .pr-xxl-0,
.px-xxl-0 {
    padding-right: 0 !important;
  }

  .pb-xxl-0,
.py-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xxl-0,
.px-xxl-0 {
    padding-left: 0 !important;
  }

  .p-xxl-1 {
    padding: 0.25rem !important;
  }

  .pt-xxl-1,
.py-xxl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xxl-1,
.px-xxl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xxl-1,
.py-xxl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xxl-1,
.px-xxl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xxl-2 {
    padding: 0.5rem !important;
  }

  .pt-xxl-2,
.py-xxl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xxl-2,
.px-xxl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xxl-2,
.py-xxl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xxl-2,
.px-xxl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xxl-3 {
    padding: 1rem !important;
  }

  .pt-xxl-3,
.py-xxl-3 {
    padding-top: 1rem !important;
  }

  .pr-xxl-3,
.px-xxl-3 {
    padding-right: 1rem !important;
  }

  .pb-xxl-3,
.py-xxl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xxl-3,
.px-xxl-3 {
    padding-left: 1rem !important;
  }

  .p-xxl-4 {
    padding: 1.5rem !important;
  }

  .pt-xxl-4,
.py-xxl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xxl-4,
.px-xxl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xxl-4,
.py-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xxl-4,
.px-xxl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xxl-5 {
    padding: 3rem !important;
  }

  .pt-xxl-5,
.py-xxl-5 {
    padding-top: 3rem !important;
  }

  .pr-xxl-5,
.px-xxl-5 {
    padding-right: 3rem !important;
  }

  .pb-xxl-5,
.py-xxl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xxl-5,
.px-xxl-5 {
    padding-left: 3rem !important;
  }

  .m-xxl-n1 {
    margin: -0.25rem !important;
  }

  .mt-xxl-n1,
.my-xxl-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-xxl-n1,
.mx-xxl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xxl-n1,
.my-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-xxl-n1,
.mx-xxl-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xxl-n2 {
    margin: -0.5rem !important;
  }

  .mt-xxl-n2,
.my-xxl-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-xxl-n2,
.mx-xxl-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-xxl-n2,
.my-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xxl-n2,
.mx-xxl-n2 {
    margin-left: -0.5rem !important;
  }

  .m-xxl-n3 {
    margin: -1rem !important;
  }

  .mt-xxl-n3,
.my-xxl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xxl-n3,
.mx-xxl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xxl-n3,
.my-xxl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xxl-n3,
.mx-xxl-n3 {
    margin-left: -1rem !important;
  }

  .m-xxl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xxl-n4,
.my-xxl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xxl-n4,
.mx-xxl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xxl-n4,
.my-xxl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xxl-n4,
.mx-xxl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xxl-n5 {
    margin: -3rem !important;
  }

  .mt-xxl-n5,
.my-xxl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xxl-n5,
.mx-xxl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xxl-n5,
.my-xxl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xxl-n5,
.mx-xxl-n5 {
    margin-left: -3rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mt-xxl-auto,
.my-xxl-auto {
    margin-top: auto !important;
  }

  .mr-xxl-auto,
.mx-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-auto,
.my-xxl-auto {
    margin-bottom: auto !important;
  }

  .ml-xxl-auto,
.mx-xxl-auto {
    margin-left: auto !important;
  }
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1600px) {
  .text-xxl-left {
    text-align: left !important;
  }

  .text-xxl-right {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #000 !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: black !important;
}

.text-secondary {
  color: #6e777a !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #4a5052 !important;
}

.text-success {
  color: #00aa0c !important;
}

a.text-success:hover, a.text-success:focus {
  color: #005e07 !important;
}

.text-info {
  color: #2469ff !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0044d7 !important;
}

.text-warning {
  color: #da7400 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #8e4b00 !important;
}

.text-danger {
  color: #dd3724 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #9c2618 !important;
}

.text-light {
  color: #c4c8ca !important;
}

a.text-light:hover, a.text-light:focus {
  color: #9ca2a6 !important;
}

.text-dark {
  color: #414949 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #1d2121 !important;
}

.text-white {
  color: white !important;
}

a.text-white:hover, a.text-white:focus {
  color: #d9d9d9 !important;
}

.text-black {
  color: #231f20 !important;
}

a.text-black:hover, a.text-black:focus {
  color: black !important;
}

.text-socgen {
  color: #e60028 !important;
}

a.text-socgen:hover, a.text-socgen:focus {
  color: #9a001b !important;
}

.text-primary-alt {
  color: #fff !important;
}

a.text-primary-alt:hover, a.text-primary-alt:focus {
  color: #d9d9d9 !important;
}

.text-body {
  color: #000 !important;
}

.text-muted {
  color: rgba(0, 0, 0, 0.4) !important;
}

.text-black-50 {
  color: rgba(35, 31, 32, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.bg-primary {
  background-color: #000 !important;
}

a.bg-primary:not(.card):hover, a.bg-primary:not(.card):focus,
button.bg-primary:not(.card):hover,
button.bg-primary:not(.card):focus {
  background-color: black !important;
}

.bg-secondary {
  background-color: #6e777a !important;
}

a.bg-secondary:not(.card):hover, a.bg-secondary:not(.card):focus,
button.bg-secondary:not(.card):hover,
button.bg-secondary:not(.card):focus {
  background-color: #565d5f !important;
}

.bg-success {
  background-color: #00aa0c !important;
}

a.bg-success:not(.card):hover, a.bg-success:not(.card):focus,
button.bg-success:not(.card):hover,
button.bg-success:not(.card):focus {
  background-color: #007708 !important;
}

.bg-info {
  background-color: #2469ff !important;
}

a.bg-info:not(.card):hover, a.bg-info:not(.card):focus,
button.bg-info:not(.card):hover,
button.bg-info:not(.card):focus {
  background-color: #004cf0 !important;
}

.bg-warning {
  background-color: #da7400 !important;
}

a.bg-warning:not(.card):hover, a.bg-warning:not(.card):focus,
button.bg-warning:not(.card):hover,
button.bg-warning:not(.card):focus {
  background-color: #a75900 !important;
}

.bg-danger {
  background-color: #dd3724 !important;
}

a.bg-danger:not(.card):hover, a.bg-danger:not(.card):focus,
button.bg-danger:not(.card):hover,
button.bg-danger:not(.card):focus {
  background-color: #b22b1c !important;
}

.bg-light {
  background-color: #c4c8ca !important;
}

a.bg-light:not(.card):hover, a.bg-light:not(.card):focus,
button.bg-light:not(.card):hover,
button.bg-light:not(.card):focus {
  background-color: #a9afb2 !important;
}

.bg-dark {
  background-color: #414949 !important;
}

a.bg-dark:not(.card):hover, a.bg-dark:not(.card):focus,
button.bg-dark:not(.card):hover,
button.bg-dark:not(.card):focus {
  background-color: #292e2e !important;
}

.bg-white {
  background-color: white !important;
}

a.bg-white:not(.card):hover, a.bg-white:not(.card):focus,
button.bg-white:not(.card):hover,
button.bg-white:not(.card):focus {
  background-color: #e6e6e6 !important;
}

.bg-black {
  background-color: #231f20 !important;
}

a.bg-black:not(.card):hover, a.bg-black:not(.card):focus,
button.bg-black:not(.card):hover,
button.bg-black:not(.card):focus {
  background-color: #080707 !important;
}

.bg-socgen {
  background-color: #e60028 !important;
}

a.bg-socgen:not(.card):hover, a.bg-socgen:not(.card):focus,
button.bg-socgen:not(.card):hover,
button.bg-socgen:not(.card):focus {
  background-color: #b3001f !important;
}

.bg-primary-alt {
  background-color: #fff !important;
}

a.bg-primary-alt:not(.card):hover, a.bg-primary-alt:not(.card):focus,
button.bg-primary-alt:not(.card):hover,
button.bg-primary-alt:not(.card):focus {
  background-color: #e6e6e6 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.bg-lvl1 {
  background-color: #fff !important;
}

a.bg-lvl1:not(.card):hover, a.bg-lvl1:not(.card):focus,
button.bg-lvl1:not(.card):hover,
button.bg-lvl1:not(.card):focus {
  background-color: #e6e6e6 !important;
}

.bg-lvl2 {
  background-color: #f5f6f7 !important;
}

a.bg-lvl2:not(.card):hover, a.bg-lvl2:not(.card):focus,
button.bg-lvl2:not(.card):hover,
button.bg-lvl2:not(.card):focus {
  background-color: #d9dde0 !important;
}

.bg-lvl3 {
  background-color: #eceeef !important;
}

a.bg-lvl3:not(.card):hover, a.bg-lvl3:not(.card):focus,
button.bg-lvl3:not(.card):hover,
button.bg-lvl3:not(.card):focus {
  background-color: #d0d5d8 !important;
}

.bg-lvl4 {
  background-color: #e3e6e7 !important;
}

a.bg-lvl4:not(.card):hover, a.bg-lvl4:not(.card):focus,
button.bg-lvl4:not(.card):hover,
button.bg-lvl4:not(.card):focus {
  background-color: #c8cdcf !important;
}

.bg-alt-lvl1 {
  background-color: #303333 !important;
}

a.bg-alt-lvl1:not(.card):hover, a.bg-alt-lvl1:not(.card):focus,
button.bg-alt-lvl1:not(.card):hover,
button.bg-alt-lvl1:not(.card):focus {
  background-color: #171919 !important;
}

.bg-alt-lvl2 {
  background-color: #222424 !important;
}

a.bg-alt-lvl2:not(.card):hover, a.bg-alt-lvl2:not(.card):focus,
button.bg-alt-lvl2:not(.card):hover,
button.bg-alt-lvl2:not(.card):focus {
  background-color: #090a0a !important;
}

.bg-alt-lvl3 {
  background-color: #151616 !important;
}

a.bg-alt-lvl3:not(.card):hover, a.bg-alt-lvl3:not(.card):focus,
button.bg-alt-lvl3:not(.card):hover,
button.bg-alt-lvl3:not(.card):focus {
  background-color: black !important;
}

.bg-alt-lvl4 {
  background-color: #000 !important;
}

a.bg-alt-lvl4:not(.card):hover, a.bg-alt-lvl4:not(.card):focus,
button.bg-alt-lvl4:not(.card):hover,
button.bg-alt-lvl4:not(.card):focus {
  background-color: black !important;
}

.bg-light-1 {
  background-color: #fff !important;
}

a.bg-light-1:not(.card):hover, a.bg-light-1:not(.card):focus,
button.bg-light-1:not(.card):hover,
button.bg-light-1:not(.card):focus {
  background-color: #e6e6e6 !important;
}

.bg-light-2 {
  background-color: #f5f6f7 !important;
}

a.bg-light-2:not(.card):hover, a.bg-light-2:not(.card):focus,
button.bg-light-2:not(.card):hover,
button.bg-light-2:not(.card):focus {
  background-color: #d9dde0 !important;
}

.bg-light-3 {
  background-color: #eceeef !important;
}

a.bg-light-3:not(.card):hover, a.bg-light-3:not(.card):focus,
button.bg-light-3:not(.card):hover,
button.bg-light-3:not(.card):focus {
  background-color: #d0d5d8 !important;
}

.bg-light-4 {
  background-color: #e3e6e7 !important;
}

a.bg-light-4:not(.card):hover, a.bg-light-4:not(.card):focus,
button.bg-light-4:not(.card):hover,
button.bg-light-4:not(.card):focus {
  background-color: #c8cdcf !important;
}

.bg-dark-1 {
  background-color: #303333 !important;
}

a.bg-dark-1:not(.card):hover, a.bg-dark-1:not(.card):focus,
button.bg-dark-1:not(.card):hover,
button.bg-dark-1:not(.card):focus {
  background-color: #171919 !important;
}

.bg-dark-2 {
  background-color: #222424 !important;
}

a.bg-dark-2:not(.card):hover, a.bg-dark-2:not(.card):focus,
button.bg-dark-2:not(.card):hover,
button.bg-dark-2:not(.card):focus {
  background-color: #090a0a !important;
}

.bg-dark-3 {
  background-color: #151616 !important;
}

a.bg-dark-3:not(.card):hover, a.bg-dark-3:not(.card):focus,
button.bg-dark-3:not(.card):hover,
button.bg-dark-3:not(.card):focus {
  background-color: black !important;
}

.bg-dark-4 {
  background-color: #000 !important;
}

a.bg-dark-4:not(.card):hover, a.bg-dark-4:not(.card):focus,
button.bg-dark-4:not(.card):hover,
button.bg-dark-4:not(.card):focus {
  background-color: black !important;
}

.bg-apricot {
  background-color: #e88e5e !important;
}

a.bg-apricot:not(.card):hover, a.bg-apricot:not(.card):focus,
button.bg-apricot:not(.card):hover,
button.bg-apricot:not(.card):focus {
  background-color: #e26f31 !important;
}

.bg-azure {
  background-color: #38699f !important;
}

a.bg-azure:not(.card):hover, a.bg-azure:not(.card):focus,
button.bg-azure:not(.card):hover,
button.bg-azure:not(.card):focus {
  background-color: #2b5079 !important;
}

.bg-cornflower-blue {
  background-color: #6488ff !important;
}

a.bg-cornflower-blue:not(.card):hover, a.bg-cornflower-blue:not(.card):focus,
button.bg-cornflower-blue:not(.card):hover,
button.bg-cornflower-blue:not(.card):focus {
  background-color: #3161ff !important;
}

.bg-faded-jade {
  background-color: #408182 !important;
}

a.bg-faded-jade:not(.card):hover, a.bg-faded-jade:not(.card):focus,
button.bg-faded-jade:not(.card):hover,
button.bg-faded-jade:not(.card):focus {
  background-color: #2f5f60 !important;
}

.bg-heather {
  background-color: #b4bbcb !important;
}

a.bg-heather:not(.card):hover, a.bg-heather:not(.card):focus,
button.bg-heather:not(.card):hover,
button.bg-heather:not(.card):focus {
  background-color: #96a0b6 !important;
}

.bg-mako {
  background-color: #444852 !important;
}

a.bg-mako:not(.card):hover, a.bg-mako:not(.card):focus,
button.bg-mako:not(.card):hover,
button.bg-mako:not(.card):focus {
  background-color: #2d3036 !important;
}

.bg-puerto-rico {
  background-color: #41c49d !important;
}

a.bg-puerto-rico:not(.card):hover, a.bg-puerto-rico:not(.card):focus,
button.bg-puerto-rico:not(.card):hover,
button.bg-puerto-rico:not(.card):focus {
  background-color: #32a07f !important;
}

.bg-royal-blue {
  background-color: #5c56e8 !important;
}

a.bg-royal-blue:not(.card):hover, a.bg-royal-blue:not(.card):focus,
button.bg-royal-blue:not(.card):hover,
button.bg-royal-blue:not(.card):focus {
  background-color: #3129e2 !important;
}

.bg-spicy-mix {
  background-color: #854b4b !important;
}

a.bg-spicy-mix:not(.card):hover, a.bg-spicy-mix:not(.card):focus,
button.bg-spicy-mix:not(.card):hover,
button.bg-spicy-mix:not(.card):focus {
  background-color: #643939 !important;
}

.bg-valencia {
  background-color: #dc4941 !important;
}

a.bg-valencia:not(.card):hover, a.bg-valencia:not(.card):focus,
button.bg-valencia:not(.card):hover,
button.bg-valencia:not(.card):focus {
  background-color: #c62d24 !important;
}

.bg-victoria {
  background-color: #514b90 !important;
}

a.bg-victoria:not(.card):hover, a.bg-victoria:not(.card):focus,
button.bg-victoria:not(.card):hover,
button.bg-victoria:not(.card):focus {
  background-color: #3e3a6e !important;
}

.bg-mars {
  background-color: #d53229 !important;
}

a.bg-mars:not(.card):hover, a.bg-mars:not(.card):focus,
button.bg-mars:not(.card):hover,
button.bg-mars:not(.card):focus {
  background-color: #aa2821 !important;
}

.bg-mercury {
  background-color: #e8e8e8 !important;
}

a.bg-mercury:not(.card):hover, a.bg-mercury:not(.card):focus,
button.bg-mercury:not(.card):hover,
button.bg-mercury:not(.card):focus {
  background-color: #cfcfcf !important;
}

.bg-manatee {
  background-color: #8b93a8 !important;
}

a.bg-manatee:not(.card):hover, a.bg-manatee:not(.card):focus,
button.bg-manatee:not(.card):hover,
button.bg-manatee:not(.card):focus {
  background-color: #6e7892 !important;
}

.bg-dodger-blue {
  background-color: #5d55ff !important;
}

a.bg-dodger-blue:not(.card):hover, a.bg-dodger-blue:not(.card):focus,
button.bg-dodger-blue:not(.card):hover,
button.bg-dodger-blue:not(.card):focus {
  background-color: #2c22ff !important;
}

.bg-pacific-blue {
  background-color: #00b4bb !important;
}

a.bg-pacific-blue:not(.card):hover, a.bg-pacific-blue:not(.card):focus,
button.bg-pacific-blue:not(.card):hover,
button.bg-pacific-blue:not(.card):focus {
  background-color: #008388 !important;
}

.bg-turquoise-blue {
  background-color: #68efad !important;
}

a.bg-turquoise-blue:not(.card):hover, a.bg-turquoise-blue:not(.card):focus,
button.bg-turquoise-blue:not(.card):hover,
button.bg-turquoise-blue:not(.card):focus {
  background-color: #3aea94 !important;
}

.bg-buttercup {
  background-color: #f6a416 !important;
}

a.bg-buttercup:not(.card):hover, a.bg-buttercup:not(.card):focus,
button.bg-buttercup:not(.card):hover,
button.bg-buttercup:not(.card):focus {
  background-color: #d18708 !important;
}

.bg-copper-rust {
  background-color: #974a5a !important;
}

a.bg-copper-rust:not(.card):hover, a.bg-copper-rust:not(.card):focus,
button.bg-copper-rust:not(.card):hover,
button.bg-copper-rust:not(.card):focus {
  background-color: #753946 !important;
}

.bg-east-bay {
  background-color: #4a5885 !important;
}

a.bg-east-bay:not(.card):hover, a.bg-east-bay:not(.card):focus,
button.bg-east-bay:not(.card):hover,
button.bg-east-bay:not(.card):focus {
  background-color: #384264 !important;
}

.bg-plantation {
  background-color: #275855 !important;
}

a.bg-plantation:not(.card):hover, a.bg-plantation:not(.card):focus,
button.bg-plantation:not(.card):hover,
button.bg-plantation:not(.card):focus {
  background-color: #173533 !important;
}

.bg-lvl4 .bg-secondary {
  background-color: #5f6668 !important;
}

.bg-lvl4 .bg-light {
  background-color: #b1b7b9 !important;
}

.bg-lvl4 .bg-dark {
  background-color: #323939 !important;
}

.bg-lvl4 .bg-info {
  background-color: #0554ff !important;
}

.bg-lvl4 .bg-success {
  background-color: #008a09 !important;
}

.bg-lvl4 .bg-warning {
  background-color: #bc6500 !important;
}

.bg-lvl4 .bg-danger {
  background-color: #c22f1f !important;
}

.bg-lvl3 .bg-secondary {
  background-color: #666e70 !important;
}

.bg-lvl3 .bg-light {
  background-color: #b9bec0 !important;
}

.bg-lvl3 .bg-dark {
  background-color: #373e3e !important;
}

.bg-lvl3 .bg-info {
  background-color: #0f5bff !important;
}

.bg-lvl3 .bg-success {
  background-color: #00940a !important;
}

.bg-lvl3 .bg-warning {
  background-color: #c66a00 !important;
}

.bg-lvl3 .bg-danger {
  background-color: #cb3120 !important;
}

.bg-lvl2 .bg-secondary {
  background-color: #687073 !important;
}

.bg-lvl2 .bg-light {
  background-color: #c1c5c7 !important;
}

.bg-lvl2 .bg-dark {
  background-color: #3c4343 !important;
}

.bg-lvl2 .bg-info {
  background-color: #1a62ff !important;
}

.bg-lvl2 .bg-success {
  background-color: #009e0b !important;
}

.bg-lvl2 .bg-warning {
  background-color: #d06f00 !important;
}

.bg-lvl2 .bg-danger {
  background-color: #d43321 !important;
}

.bg-lvl1 .bg-secondary {
  background-color: #6e777a !important;
}

.bg-lvl1 .bg-light {
  background-color: #c4c8ca !important;
}

.bg-lvl1 .bg-dark {
  background-color: #414949 !important;
}

.bg-lvl1 .bg-info {
  background-color: #2469ff !important;
}

.bg-lvl1 .bg-success {
  background-color: #00aa0c !important;
}

.bg-lvl1 .bg-warning {
  background-color: #da7400 !important;
}

.bg-lvl1 .bg-danger {
  background-color: #dd3724 !important;
}

.bg-alt-lvl4 .bg-secondary {
  background-color: #70797a !important;
}

.bg-alt-lvl4 .bg-light {
  background-color: #a3afb3 !important;
}

.bg-alt-lvl4 .bg-dark {
  background-color: #343737 !important;
}

.bg-alt-lvl4 .bg-info {
  background-color: #1476ff !important;
}

.bg-alt-lvl4 .bg-success {
  background-color: #00a83e !important;
}

.bg-alt-lvl4 .bg-warning {
  background-color: #cc7400 !important;
}

.bg-alt-lvl4 .bg-danger {
  background-color: #d93d26 !important;
}

.bg-alt-lvl3 .bg-secondary {
  background-color: #7a8385 !important;
}

.bg-alt-lvl3 .bg-light {
  background-color: #b1bbbe !important;
}

.bg-alt-lvl3 .bg-dark {
  background-color: #3b3f3f !important;
}

.bg-alt-lvl3 .bg-info {
  background-color: #2982ff !important;
}

.bg-alt-lvl3 .bg-success {
  background-color: #00b844 !important;
}

.bg-alt-lvl3 .bg-warning {
  background-color: #db7c00 !important;
}

.bg-alt-lvl3 .bg-danger {
  background-color: #e8442c !important;
}

.bg-alt-lvl2 .bg-secondary {
  background-color: #848d8f !important;
}

.bg-alt-lvl2 .bg-light {
  background-color: #c2cacc !important;
}

.bg-alt-lvl2 .bg-dark {
  background-color: #454a4a !important;
}

.bg-alt-lvl2 .bg-info {
  background-color: #3d8eff !important;
}

.bg-alt-lvl2 .bg-success {
  background-color: #00c74a !important;
}

.bg-alt-lvl2 .bg-warning {
  background-color: #eb8500 !important;
}

.bg-alt-lvl2 .bg-danger {
  background-color: #f44d34 !important;
}

.bg-alt-lvl1 .bg-secondary {
  background-color: #949c9e !important;
}

.bg-alt-lvl1 .bg-light {
  background-color: #d8ddde !important;
}

.bg-alt-lvl1 .bg-dark {
  background-color: #515757 !important;
}

.bg-alt-lvl1 .bg-info {
  background-color: #529aff !important;
}

.bg-alt-lvl1 .bg-success {
  background-color: #00d750 !important;
}

.bg-alt-lvl1 .bg-warning {
  background-color: #f98d00 !important;
}

.bg-alt-lvl1 .bg-danger {
  background-color: #ff563c !important;
}

.border-sm {
  border-width: 0.03125rem !important;
}

.border-md {
  border-width: 0.0625rem !important;
}

.border-lg {
  border-width: 0.1875rem !important;
}

.border-xl {
  border-width: 0.375rem !important;
}

.border-apricot {
  border-color: #e88e5e !important;
}

.border-azure {
  border-color: #38699f !important;
}

.border-cornflower-blue {
  border-color: #6488ff !important;
}

.border-faded-jade {
  border-color: #408182 !important;
}

.border-heather {
  border-color: #b4bbcb !important;
}

.border-mako {
  border-color: #444852 !important;
}

.border-puerto-rico {
  border-color: #41c49d !important;
}

.border-royal-blue {
  border-color: #5c56e8 !important;
}

.border-spicy-mix {
  border-color: #854b4b !important;
}

.border-valencia {
  border-color: #dc4941 !important;
}

.border-victoria {
  border-color: #514b90 !important;
}

.border-mars {
  border-color: #d53229 !important;
}

.border-mercury {
  border-color: #e8e8e8 !important;
}

.border-manatee {
  border-color: #8b93a8 !important;
}

.border-dodger-blue {
  border-color: #5d55ff !important;
}

.border-pacific-blue {
  border-color: #00b4bb !important;
}

.border-turquoise-blue {
  border-color: #68efad !important;
}

.border-buttercup {
  border-color: #f6a416 !important;
}

.border-copper-rust {
  border-color: #974a5a !important;
}

.border-east-bay {
  border-color: #4a5885 !important;
}

.border-plantation {
  border-color: #275855 !important;
}

.flex-row {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}

.flex-column {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}

.flex-row-reverse {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: reverse !important;
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: reverse !important;
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important;
}

.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
}

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  -webkit-box-flex: 1 !important;
  -ms-flex: 1 1 auto !important;
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  -webkit-box-flex: 0 !important;
  -ms-flex-positive: 0 !important;
  flex-grow: 0 !important;
}

.flex-grow-1 {
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  -ms-flex-negative: 1 !important;
  flex-shrink: 1 !important;
}

.justify-content-start {
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
}

.justify-content-end {
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}

.justify-content-center {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.justify-content-between {
  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}

.justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important;
}

.align-items-start {
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}

.align-items-end {
  -webkit-box-align: end !important;
  -ms-flex-align: end !important;
  align-items: flex-end !important;
}

.align-items-center {
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}

.align-items-baseline {
  -webkit-box-align: baseline !important;
  -ms-flex-align: baseline !important;
  align-items: baseline !important;
}

.align-items-stretch {
  -webkit-box-align: stretch !important;
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
}

.align-content-start {
  -ms-flex-line-pack: start !important;
  align-content: flex-start !important;
}

.align-content-end {
  -ms-flex-line-pack: end !important;
  align-content: flex-end !important;
}

.align-content-center {
  -ms-flex-line-pack: center !important;
  align-content: center !important;
}

.align-content-between {
  -ms-flex-line-pack: justify !important;
  align-content: space-between !important;
}

.align-content-around {
  -ms-flex-line-pack: distribute !important;
  align-content: space-around !important;
}

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
  align-content: stretch !important;
}

.align-self-auto {
  -ms-flex-item-align: auto !important;
  align-self: auto !important;
}

.align-self-start {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important;
}

.align-self-end {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important;
}

.align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important;
}

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
  align-self: baseline !important;
}

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }

  .flex-sm-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }

  .justify-content-sm-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }

  .align-items-sm-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }

  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }

  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }

  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }

  .align-self-sm-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }

  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }

  .flex-md-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }

  .justify-content-md-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }

  .align-items-md-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }

  .align-items-md-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }

  .align-items-md-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }

  .align-items-md-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }

  .align-content-md-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }

  .align-content-md-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }

  .align-content-md-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }

  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }

  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }

  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }

  .align-self-md-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }

  .align-self-md-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }

  .align-self-md-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }

  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }

  .flex-lg-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }

  .justify-content-lg-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }

  .align-items-lg-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }

  .align-content-lg-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }

  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }

  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }

  .align-self-lg-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }

  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }

  .flex-xl-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }

  .justify-content-xl-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }

  .align-items-xl-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }

  .align-content-xl-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }

  .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }

  .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }

  .align-self-xl-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }

  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}
@media (min-width: 1600px) {
  .flex-xxl-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }

  .flex-xxl-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }

  .flex-xxl-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }

  .flex-xxl-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }

  .flex-xxl-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }

  .justify-content-xxl-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }

  .align-items-xxl-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }

  .align-content-xxl-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }

  .align-self-xxl-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}
.flex-basis-100 {
  -ms-flex-preferred-size: 100% !important;
  flex-basis: 100% !important;
}

@media (min-width: 576px) {
  .flex-basis-sm-100 {
    -ms-flex-preferred-size: 100% !important;
    flex-basis: 100% !important;
  }
}
@media (min-width: 768px) {
  .flex-basis-md-100 {
    -ms-flex-preferred-size: 100% !important;
    flex-basis: 100% !important;
  }
}
@media (min-width: 992px) {
  .flex-basis-lg-100 {
    -ms-flex-preferred-size: 100% !important;
    flex-basis: 100% !important;
  }
}
@media (min-width: 1200px) {
  .flex-basis-xl-100 {
    -ms-flex-preferred-size: 100% !important;
    flex-basis: 100% !important;
  }
}
@media (min-width: 1600px) {
  .flex-basis-xxl-100 {
    -ms-flex-preferred-size: 100% !important;
    flex-basis: 100% !important;
  }
}
.flex-center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.flex-between {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.flex-between.flex-column {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: space-between;
  -ms-flex-align: space-between;
  align-items: space-between;
}

.bg-primary-alpha-xxl {
  background: rgba(0, 0, 0, 0.7) !important;
}

.alpha-xxl {
  opacity: 0.7 !important;
}

.bg-secondary-alpha-xxl {
  background: rgba(110, 119, 122, 0.7) !important;
}

.alpha-xxl {
  opacity: 0.7 !important;
}

.bg-success-alpha-xxl {
  background: rgba(0, 170, 12, 0.7) !important;
}

.alpha-xxl {
  opacity: 0.7 !important;
}

.bg-info-alpha-xxl {
  background: rgba(36, 105, 255, 0.7) !important;
}

.alpha-xxl {
  opacity: 0.7 !important;
}

.bg-warning-alpha-xxl {
  background: rgba(218, 116, 0, 0.7) !important;
}

.alpha-xxl {
  opacity: 0.7 !important;
}

.bg-danger-alpha-xxl {
  background: rgba(221, 55, 36, 0.7) !important;
}

.alpha-xxl {
  opacity: 0.7 !important;
}

.bg-light-alpha-xxl {
  background: rgba(196, 200, 202, 0.7) !important;
}

.alpha-xxl {
  opacity: 0.7 !important;
}

.bg-dark-alpha-xxl {
  background: rgba(65, 73, 73, 0.7) !important;
}

.alpha-xxl {
  opacity: 0.7 !important;
}

.bg-white-alpha-xxl {
  background: rgba(255, 255, 255, 0.7) !important;
}

.alpha-xxl {
  opacity: 0.7 !important;
}

.bg-black-alpha-xxl {
  background: rgba(35, 31, 32, 0.7) !important;
}

.alpha-xxl {
  opacity: 0.7 !important;
}

.bg-socgen-alpha-xxl {
  background: rgba(230, 0, 40, 0.7) !important;
}

.alpha-xxl {
  opacity: 0.7 !important;
}

.bg-primary-alt-alpha-xxl {
  background: rgba(255, 255, 255, 0.7) !important;
}

.alpha-xxl {
  opacity: 0.7 !important;
}

.bg-primary-alpha-xl {
  background: rgba(0, 0, 0, 0.4) !important;
}

.alpha-xl {
  opacity: 0.4 !important;
}

.bg-secondary-alpha-xl {
  background: rgba(110, 119, 122, 0.4) !important;
}

.alpha-xl {
  opacity: 0.4 !important;
}

.bg-success-alpha-xl {
  background: rgba(0, 170, 12, 0.4) !important;
}

.alpha-xl {
  opacity: 0.4 !important;
}

.bg-info-alpha-xl {
  background: rgba(36, 105, 255, 0.4) !important;
}

.alpha-xl {
  opacity: 0.4 !important;
}

.bg-warning-alpha-xl {
  background: rgba(218, 116, 0, 0.4) !important;
}

.alpha-xl {
  opacity: 0.4 !important;
}

.bg-danger-alpha-xl {
  background: rgba(221, 55, 36, 0.4) !important;
}

.alpha-xl {
  opacity: 0.4 !important;
}

.bg-light-alpha-xl {
  background: rgba(196, 200, 202, 0.4) !important;
}

.alpha-xl {
  opacity: 0.4 !important;
}

.bg-dark-alpha-xl {
  background: rgba(65, 73, 73, 0.4) !important;
}

.alpha-xl {
  opacity: 0.4 !important;
}

.bg-white-alpha-xl {
  background: rgba(255, 255, 255, 0.4) !important;
}

.alpha-xl {
  opacity: 0.4 !important;
}

.bg-black-alpha-xl {
  background: rgba(35, 31, 32, 0.4) !important;
}

.alpha-xl {
  opacity: 0.4 !important;
}

.bg-socgen-alpha-xl {
  background: rgba(230, 0, 40, 0.4) !important;
}

.alpha-xl {
  opacity: 0.4 !important;
}

.bg-primary-alt-alpha-xl {
  background: rgba(255, 255, 255, 0.4) !important;
}

.alpha-xl {
  opacity: 0.4 !important;
}

.bg-primary-alpha-lg {
  background: rgba(0, 0, 0, 0.2) !important;
}

.alpha-lg {
  opacity: 0.2 !important;
}

.bg-secondary-alpha-lg {
  background: rgba(110, 119, 122, 0.2) !important;
}

.alpha-lg {
  opacity: 0.2 !important;
}

.bg-success-alpha-lg {
  background: rgba(0, 170, 12, 0.2) !important;
}

.alpha-lg {
  opacity: 0.2 !important;
}

.bg-info-alpha-lg {
  background: rgba(36, 105, 255, 0.2) !important;
}

.alpha-lg {
  opacity: 0.2 !important;
}

.bg-warning-alpha-lg {
  background: rgba(218, 116, 0, 0.2) !important;
}

.alpha-lg {
  opacity: 0.2 !important;
}

.bg-danger-alpha-lg {
  background: rgba(221, 55, 36, 0.2) !important;
}

.alpha-lg {
  opacity: 0.2 !important;
}

.bg-light-alpha-lg {
  background: rgba(196, 200, 202, 0.2) !important;
}

.alpha-lg {
  opacity: 0.2 !important;
}

.bg-dark-alpha-lg {
  background: rgba(65, 73, 73, 0.2) !important;
}

.alpha-lg {
  opacity: 0.2 !important;
}

.bg-white-alpha-lg {
  background: rgba(255, 255, 255, 0.2) !important;
}

.alpha-lg {
  opacity: 0.2 !important;
}

.bg-black-alpha-lg {
  background: rgba(35, 31, 32, 0.2) !important;
}

.alpha-lg {
  opacity: 0.2 !important;
}

.bg-socgen-alpha-lg {
  background: rgba(230, 0, 40, 0.2) !important;
}

.alpha-lg {
  opacity: 0.2 !important;
}

.bg-primary-alt-alpha-lg {
  background: rgba(255, 255, 255, 0.2) !important;
}

.alpha-lg {
  opacity: 0.2 !important;
}

.bg-primary-alpha-md {
  background: rgba(0, 0, 0, 0.1) !important;
}

.alpha-md {
  opacity: 0.1 !important;
}

.bg-secondary-alpha-md {
  background: rgba(110, 119, 122, 0.1) !important;
}

.alpha-md {
  opacity: 0.1 !important;
}

.bg-success-alpha-md {
  background: rgba(0, 170, 12, 0.1) !important;
}

.alpha-md {
  opacity: 0.1 !important;
}

.bg-info-alpha-md {
  background: rgba(36, 105, 255, 0.1) !important;
}

.alpha-md {
  opacity: 0.1 !important;
}

.bg-warning-alpha-md {
  background: rgba(218, 116, 0, 0.1) !important;
}

.alpha-md {
  opacity: 0.1 !important;
}

.bg-danger-alpha-md {
  background: rgba(221, 55, 36, 0.1) !important;
}

.alpha-md {
  opacity: 0.1 !important;
}

.bg-light-alpha-md {
  background: rgba(196, 200, 202, 0.1) !important;
}

.alpha-md {
  opacity: 0.1 !important;
}

.bg-dark-alpha-md {
  background: rgba(65, 73, 73, 0.1) !important;
}

.alpha-md {
  opacity: 0.1 !important;
}

.bg-white-alpha-md {
  background: rgba(255, 255, 255, 0.1) !important;
}

.alpha-md {
  opacity: 0.1 !important;
}

.bg-black-alpha-md {
  background: rgba(35, 31, 32, 0.1) !important;
}

.alpha-md {
  opacity: 0.1 !important;
}

.bg-socgen-alpha-md {
  background: rgba(230, 0, 40, 0.1) !important;
}

.alpha-md {
  opacity: 0.1 !important;
}

.bg-primary-alt-alpha-md {
  background: rgba(255, 255, 255, 0.1) !important;
}

.alpha-md {
  opacity: 0.1 !important;
}

.bg-primary-alpha-sm {
  background: rgba(0, 0, 0, 0.05) !important;
}

.alpha-sm {
  opacity: 0.05 !important;
}

.bg-secondary-alpha-sm {
  background: rgba(110, 119, 122, 0.05) !important;
}

.alpha-sm {
  opacity: 0.05 !important;
}

.bg-success-alpha-sm {
  background: rgba(0, 170, 12, 0.05) !important;
}

.alpha-sm {
  opacity: 0.05 !important;
}

.bg-info-alpha-sm {
  background: rgba(36, 105, 255, 0.05) !important;
}

.alpha-sm {
  opacity: 0.05 !important;
}

.bg-warning-alpha-sm {
  background: rgba(218, 116, 0, 0.05) !important;
}

.alpha-sm {
  opacity: 0.05 !important;
}

.bg-danger-alpha-sm {
  background: rgba(221, 55, 36, 0.05) !important;
}

.alpha-sm {
  opacity: 0.05 !important;
}

.bg-light-alpha-sm {
  background: rgba(196, 200, 202, 0.05) !important;
}

.alpha-sm {
  opacity: 0.05 !important;
}

.bg-dark-alpha-sm {
  background: rgba(65, 73, 73, 0.05) !important;
}

.alpha-sm {
  opacity: 0.05 !important;
}

.bg-white-alpha-sm {
  background: rgba(255, 255, 255, 0.05) !important;
}

.alpha-sm {
  opacity: 0.05 !important;
}

.bg-black-alpha-sm {
  background: rgba(35, 31, 32, 0.05) !important;
}

.alpha-sm {
  opacity: 0.05 !important;
}

.bg-socgen-alpha-sm {
  background: rgba(230, 0, 40, 0.05) !important;
}

.alpha-sm {
  opacity: 0.05 !important;
}

.bg-primary-alt-alpha-sm {
  background: rgba(255, 255, 255, 0.05) !important;
}

.alpha-sm {
  opacity: 0.05 !important;
}

.bg-lvl1-alpha-xxl {
  background: rgba(255, 255, 255, 0.7) !important;
}

.alpha-xxl {
  opacity: 0.7 !important;
}

.bg-lvl2-alpha-xxl {
  background: rgba(245, 246, 247, 0.7) !important;
}

.alpha-xxl {
  opacity: 0.7 !important;
}

.bg-lvl3-alpha-xxl {
  background: rgba(236, 238, 239, 0.7) !important;
}

.alpha-xxl {
  opacity: 0.7 !important;
}

.bg-lvl4-alpha-xxl {
  background: rgba(227, 230, 231, 0.7) !important;
}

.alpha-xxl {
  opacity: 0.7 !important;
}

.bg-lvl1-alpha-xl {
  background: rgba(255, 255, 255, 0.4) !important;
}

.alpha-xl {
  opacity: 0.4 !important;
}

.bg-lvl2-alpha-xl {
  background: rgba(245, 246, 247, 0.4) !important;
}

.alpha-xl {
  opacity: 0.4 !important;
}

.bg-lvl3-alpha-xl {
  background: rgba(236, 238, 239, 0.4) !important;
}

.alpha-xl {
  opacity: 0.4 !important;
}

.bg-lvl4-alpha-xl {
  background: rgba(227, 230, 231, 0.4) !important;
}

.alpha-xl {
  opacity: 0.4 !important;
}

.bg-lvl1-alpha-lg {
  background: rgba(255, 255, 255, 0.2) !important;
}

.alpha-lg {
  opacity: 0.2 !important;
}

.bg-lvl2-alpha-lg {
  background: rgba(245, 246, 247, 0.2) !important;
}

.alpha-lg {
  opacity: 0.2 !important;
}

.bg-lvl3-alpha-lg {
  background: rgba(236, 238, 239, 0.2) !important;
}

.alpha-lg {
  opacity: 0.2 !important;
}

.bg-lvl4-alpha-lg {
  background: rgba(227, 230, 231, 0.2) !important;
}

.alpha-lg {
  opacity: 0.2 !important;
}

.bg-lvl1-alpha-md {
  background: rgba(255, 255, 255, 0.1) !important;
}

.alpha-md {
  opacity: 0.1 !important;
}

.bg-lvl2-alpha-md {
  background: rgba(245, 246, 247, 0.1) !important;
}

.alpha-md {
  opacity: 0.1 !important;
}

.bg-lvl3-alpha-md {
  background: rgba(236, 238, 239, 0.1) !important;
}

.alpha-md {
  opacity: 0.1 !important;
}

.bg-lvl4-alpha-md {
  background: rgba(227, 230, 231, 0.1) !important;
}

.alpha-md {
  opacity: 0.1 !important;
}

.bg-lvl1-alpha-sm {
  background: rgba(255, 255, 255, 0.05) !important;
}

.alpha-sm {
  opacity: 0.05 !important;
}

.bg-lvl2-alpha-sm {
  background: rgba(245, 246, 247, 0.05) !important;
}

.alpha-sm {
  opacity: 0.05 !important;
}

.bg-lvl3-alpha-sm {
  background: rgba(236, 238, 239, 0.05) !important;
}

.alpha-sm {
  opacity: 0.05 !important;
}

.bg-lvl4-alpha-sm {
  background: rgba(227, 230, 231, 0.05) !important;
}

.alpha-sm {
  opacity: 0.05 !important;
}

.bg-lvl1-alpha-xxl {
  background: rgba(48, 51, 51, 0.7) !important;
}

.alpha-xxl {
  opacity: 0.7 !important;
}

.bg-lvl2-alpha-xxl {
  background: rgba(34, 36, 36, 0.7) !important;
}

.alpha-xxl {
  opacity: 0.7 !important;
}

.bg-lvl3-alpha-xxl {
  background: rgba(21, 22, 22, 0.7) !important;
}

.alpha-xxl {
  opacity: 0.7 !important;
}

.bg-lvl4-alpha-xxl {
  background: rgba(0, 0, 0, 0.7) !important;
}

.alpha-xxl {
  opacity: 0.7 !important;
}

.bg-lvl1-alpha-xl {
  background: rgba(48, 51, 51, 0.4) !important;
}

.alpha-xl {
  opacity: 0.4 !important;
}

.bg-lvl2-alpha-xl {
  background: rgba(34, 36, 36, 0.4) !important;
}

.alpha-xl {
  opacity: 0.4 !important;
}

.bg-lvl3-alpha-xl {
  background: rgba(21, 22, 22, 0.4) !important;
}

.alpha-xl {
  opacity: 0.4 !important;
}

.bg-lvl4-alpha-xl {
  background: rgba(0, 0, 0, 0.4) !important;
}

.alpha-xl {
  opacity: 0.4 !important;
}

.bg-lvl1-alpha-lg {
  background: rgba(48, 51, 51, 0.2) !important;
}

.alpha-lg {
  opacity: 0.2 !important;
}

.bg-lvl2-alpha-lg {
  background: rgba(34, 36, 36, 0.2) !important;
}

.alpha-lg {
  opacity: 0.2 !important;
}

.bg-lvl3-alpha-lg {
  background: rgba(21, 22, 22, 0.2) !important;
}

.alpha-lg {
  opacity: 0.2 !important;
}

.bg-lvl4-alpha-lg {
  background: rgba(0, 0, 0, 0.2) !important;
}

.alpha-lg {
  opacity: 0.2 !important;
}

.bg-lvl1-alpha-md {
  background: rgba(48, 51, 51, 0.1) !important;
}

.alpha-md {
  opacity: 0.1 !important;
}

.bg-lvl2-alpha-md {
  background: rgba(34, 36, 36, 0.1) !important;
}

.alpha-md {
  opacity: 0.1 !important;
}

.bg-lvl3-alpha-md {
  background: rgba(21, 22, 22, 0.1) !important;
}

.alpha-md {
  opacity: 0.1 !important;
}

.bg-lvl4-alpha-md {
  background: rgba(0, 0, 0, 0.1) !important;
}

.alpha-md {
  opacity: 0.1 !important;
}

.bg-lvl1-alpha-sm {
  background: rgba(48, 51, 51, 0.05) !important;
}

.alpha-sm {
  opacity: 0.05 !important;
}

.bg-lvl2-alpha-sm {
  background: rgba(34, 36, 36, 0.05) !important;
}

.alpha-sm {
  opacity: 0.05 !important;
}

.bg-lvl3-alpha-sm {
  background: rgba(21, 22, 22, 0.05) !important;
}

.alpha-sm {
  opacity: 0.05 !important;
}

.bg-lvl4-alpha-sm {
  background: rgba(0, 0, 0, 0.05) !important;
}

.alpha-sm {
  opacity: 0.05 !important;
}

.bg-apricot-alpha-xxl {
  background: rgba(232, 142, 94, 0.7) !important;
}

.alpha-xxl {
  opacity: 0.7 !important;
}

.bg-azure-alpha-xxl {
  background: rgba(56, 105, 159, 0.7) !important;
}

.alpha-xxl {
  opacity: 0.7 !important;
}

.bg-cornflower-blue-alpha-xxl {
  background: rgba(100, 136, 255, 0.7) !important;
}

.alpha-xxl {
  opacity: 0.7 !important;
}

.bg-faded-jade-alpha-xxl {
  background: rgba(64, 129, 130, 0.7) !important;
}

.alpha-xxl {
  opacity: 0.7 !important;
}

.bg-heather-alpha-xxl {
  background: rgba(180, 187, 203, 0.7) !important;
}

.alpha-xxl {
  opacity: 0.7 !important;
}

.bg-mako-alpha-xxl {
  background: rgba(68, 72, 82, 0.7) !important;
}

.alpha-xxl {
  opacity: 0.7 !important;
}

.bg-puerto-rico-alpha-xxl {
  background: rgba(65, 196, 157, 0.7) !important;
}

.alpha-xxl {
  opacity: 0.7 !important;
}

.bg-royal-blue-alpha-xxl {
  background: rgba(92, 86, 232, 0.7) !important;
}

.alpha-xxl {
  opacity: 0.7 !important;
}

.bg-spicy-mix-alpha-xxl {
  background: rgba(133, 75, 75, 0.7) !important;
}

.alpha-xxl {
  opacity: 0.7 !important;
}

.bg-valencia-alpha-xxl {
  background: rgba(220, 73, 65, 0.7) !important;
}

.alpha-xxl {
  opacity: 0.7 !important;
}

.bg-victoria-alpha-xxl {
  background: rgba(81, 75, 144, 0.7) !important;
}

.alpha-xxl {
  opacity: 0.7 !important;
}

.bg-mars-alpha-xxl {
  background: rgba(213, 50, 41, 0.7) !important;
}

.alpha-xxl {
  opacity: 0.7 !important;
}

.bg-mercury-alpha-xxl {
  background: rgba(232, 232, 232, 0.7) !important;
}

.alpha-xxl {
  opacity: 0.7 !important;
}

.bg-manatee-alpha-xxl {
  background: rgba(139, 147, 168, 0.7) !important;
}

.alpha-xxl {
  opacity: 0.7 !important;
}

.bg-dodger-blue-alpha-xxl {
  background: rgba(93, 85, 255, 0.7) !important;
}

.alpha-xxl {
  opacity: 0.7 !important;
}

.bg-pacific-blue-alpha-xxl {
  background: rgba(0, 180, 187, 0.7) !important;
}

.alpha-xxl {
  opacity: 0.7 !important;
}

.bg-turquoise-blue-alpha-xxl {
  background: rgba(104, 239, 173, 0.7) !important;
}

.alpha-xxl {
  opacity: 0.7 !important;
}

.bg-buttercup-alpha-xxl {
  background: rgba(246, 164, 22, 0.7) !important;
}

.alpha-xxl {
  opacity: 0.7 !important;
}

.bg-copper-rust-alpha-xxl {
  background: rgba(151, 74, 90, 0.7) !important;
}

.alpha-xxl {
  opacity: 0.7 !important;
}

.bg-east-bay-alpha-xxl {
  background: rgba(74, 88, 133, 0.7) !important;
}

.alpha-xxl {
  opacity: 0.7 !important;
}

.bg-plantation-alpha-xxl {
  background: rgba(39, 88, 85, 0.7) !important;
}

.alpha-xxl {
  opacity: 0.7 !important;
}

.bg-apricot-alpha-xl {
  background: rgba(232, 142, 94, 0.4) !important;
}

.alpha-xl {
  opacity: 0.4 !important;
}

.bg-azure-alpha-xl {
  background: rgba(56, 105, 159, 0.4) !important;
}

.alpha-xl {
  opacity: 0.4 !important;
}

.bg-cornflower-blue-alpha-xl {
  background: rgba(100, 136, 255, 0.4) !important;
}

.alpha-xl {
  opacity: 0.4 !important;
}

.bg-faded-jade-alpha-xl {
  background: rgba(64, 129, 130, 0.4) !important;
}

.alpha-xl {
  opacity: 0.4 !important;
}

.bg-heather-alpha-xl {
  background: rgba(180, 187, 203, 0.4) !important;
}

.alpha-xl {
  opacity: 0.4 !important;
}

.bg-mako-alpha-xl {
  background: rgba(68, 72, 82, 0.4) !important;
}

.alpha-xl {
  opacity: 0.4 !important;
}

.bg-puerto-rico-alpha-xl {
  background: rgba(65, 196, 157, 0.4) !important;
}

.alpha-xl {
  opacity: 0.4 !important;
}

.bg-royal-blue-alpha-xl {
  background: rgba(92, 86, 232, 0.4) !important;
}

.alpha-xl {
  opacity: 0.4 !important;
}

.bg-spicy-mix-alpha-xl {
  background: rgba(133, 75, 75, 0.4) !important;
}

.alpha-xl {
  opacity: 0.4 !important;
}

.bg-valencia-alpha-xl {
  background: rgba(220, 73, 65, 0.4) !important;
}

.alpha-xl {
  opacity: 0.4 !important;
}

.bg-victoria-alpha-xl {
  background: rgba(81, 75, 144, 0.4) !important;
}

.alpha-xl {
  opacity: 0.4 !important;
}

.bg-mars-alpha-xl {
  background: rgba(213, 50, 41, 0.4) !important;
}

.alpha-xl {
  opacity: 0.4 !important;
}

.bg-mercury-alpha-xl {
  background: rgba(232, 232, 232, 0.4) !important;
}

.alpha-xl {
  opacity: 0.4 !important;
}

.bg-manatee-alpha-xl {
  background: rgba(139, 147, 168, 0.4) !important;
}

.alpha-xl {
  opacity: 0.4 !important;
}

.bg-dodger-blue-alpha-xl {
  background: rgba(93, 85, 255, 0.4) !important;
}

.alpha-xl {
  opacity: 0.4 !important;
}

.bg-pacific-blue-alpha-xl {
  background: rgba(0, 180, 187, 0.4) !important;
}

.alpha-xl {
  opacity: 0.4 !important;
}

.bg-turquoise-blue-alpha-xl {
  background: rgba(104, 239, 173, 0.4) !important;
}

.alpha-xl {
  opacity: 0.4 !important;
}

.bg-buttercup-alpha-xl {
  background: rgba(246, 164, 22, 0.4) !important;
}

.alpha-xl {
  opacity: 0.4 !important;
}

.bg-copper-rust-alpha-xl {
  background: rgba(151, 74, 90, 0.4) !important;
}

.alpha-xl {
  opacity: 0.4 !important;
}

.bg-east-bay-alpha-xl {
  background: rgba(74, 88, 133, 0.4) !important;
}

.alpha-xl {
  opacity: 0.4 !important;
}

.bg-plantation-alpha-xl {
  background: rgba(39, 88, 85, 0.4) !important;
}

.alpha-xl {
  opacity: 0.4 !important;
}

.bg-apricot-alpha-lg {
  background: rgba(232, 142, 94, 0.2) !important;
}

.alpha-lg {
  opacity: 0.2 !important;
}

.bg-azure-alpha-lg {
  background: rgba(56, 105, 159, 0.2) !important;
}

.alpha-lg {
  opacity: 0.2 !important;
}

.bg-cornflower-blue-alpha-lg {
  background: rgba(100, 136, 255, 0.2) !important;
}

.alpha-lg {
  opacity: 0.2 !important;
}

.bg-faded-jade-alpha-lg {
  background: rgba(64, 129, 130, 0.2) !important;
}

.alpha-lg {
  opacity: 0.2 !important;
}

.bg-heather-alpha-lg {
  background: rgba(180, 187, 203, 0.2) !important;
}

.alpha-lg {
  opacity: 0.2 !important;
}

.bg-mako-alpha-lg {
  background: rgba(68, 72, 82, 0.2) !important;
}

.alpha-lg {
  opacity: 0.2 !important;
}

.bg-puerto-rico-alpha-lg {
  background: rgba(65, 196, 157, 0.2) !important;
}

.alpha-lg {
  opacity: 0.2 !important;
}

.bg-royal-blue-alpha-lg {
  background: rgba(92, 86, 232, 0.2) !important;
}

.alpha-lg {
  opacity: 0.2 !important;
}

.bg-spicy-mix-alpha-lg {
  background: rgba(133, 75, 75, 0.2) !important;
}

.alpha-lg {
  opacity: 0.2 !important;
}

.bg-valencia-alpha-lg {
  background: rgba(220, 73, 65, 0.2) !important;
}

.alpha-lg {
  opacity: 0.2 !important;
}

.bg-victoria-alpha-lg {
  background: rgba(81, 75, 144, 0.2) !important;
}

.alpha-lg {
  opacity: 0.2 !important;
}

.bg-mars-alpha-lg {
  background: rgba(213, 50, 41, 0.2) !important;
}

.alpha-lg {
  opacity: 0.2 !important;
}

.bg-mercury-alpha-lg {
  background: rgba(232, 232, 232, 0.2) !important;
}

.alpha-lg {
  opacity: 0.2 !important;
}

.bg-manatee-alpha-lg {
  background: rgba(139, 147, 168, 0.2) !important;
}

.alpha-lg {
  opacity: 0.2 !important;
}

.bg-dodger-blue-alpha-lg {
  background: rgba(93, 85, 255, 0.2) !important;
}

.alpha-lg {
  opacity: 0.2 !important;
}

.bg-pacific-blue-alpha-lg {
  background: rgba(0, 180, 187, 0.2) !important;
}

.alpha-lg {
  opacity: 0.2 !important;
}

.bg-turquoise-blue-alpha-lg {
  background: rgba(104, 239, 173, 0.2) !important;
}

.alpha-lg {
  opacity: 0.2 !important;
}

.bg-buttercup-alpha-lg {
  background: rgba(246, 164, 22, 0.2) !important;
}

.alpha-lg {
  opacity: 0.2 !important;
}

.bg-copper-rust-alpha-lg {
  background: rgba(151, 74, 90, 0.2) !important;
}

.alpha-lg {
  opacity: 0.2 !important;
}

.bg-east-bay-alpha-lg {
  background: rgba(74, 88, 133, 0.2) !important;
}

.alpha-lg {
  opacity: 0.2 !important;
}

.bg-plantation-alpha-lg {
  background: rgba(39, 88, 85, 0.2) !important;
}

.alpha-lg {
  opacity: 0.2 !important;
}

.bg-apricot-alpha-md {
  background: rgba(232, 142, 94, 0.1) !important;
}

.alpha-md {
  opacity: 0.1 !important;
}

.bg-azure-alpha-md {
  background: rgba(56, 105, 159, 0.1) !important;
}

.alpha-md {
  opacity: 0.1 !important;
}

.bg-cornflower-blue-alpha-md {
  background: rgba(100, 136, 255, 0.1) !important;
}

.alpha-md {
  opacity: 0.1 !important;
}

.bg-faded-jade-alpha-md {
  background: rgba(64, 129, 130, 0.1) !important;
}

.alpha-md {
  opacity: 0.1 !important;
}

.bg-heather-alpha-md {
  background: rgba(180, 187, 203, 0.1) !important;
}

.alpha-md {
  opacity: 0.1 !important;
}

.bg-mako-alpha-md {
  background: rgba(68, 72, 82, 0.1) !important;
}

.alpha-md {
  opacity: 0.1 !important;
}

.bg-puerto-rico-alpha-md {
  background: rgba(65, 196, 157, 0.1) !important;
}

.alpha-md {
  opacity: 0.1 !important;
}

.bg-royal-blue-alpha-md {
  background: rgba(92, 86, 232, 0.1) !important;
}

.alpha-md {
  opacity: 0.1 !important;
}

.bg-spicy-mix-alpha-md {
  background: rgba(133, 75, 75, 0.1) !important;
}

.alpha-md {
  opacity: 0.1 !important;
}

.bg-valencia-alpha-md {
  background: rgba(220, 73, 65, 0.1) !important;
}

.alpha-md {
  opacity: 0.1 !important;
}

.bg-victoria-alpha-md {
  background: rgba(81, 75, 144, 0.1) !important;
}

.alpha-md {
  opacity: 0.1 !important;
}

.bg-mars-alpha-md {
  background: rgba(213, 50, 41, 0.1) !important;
}

.alpha-md {
  opacity: 0.1 !important;
}

.bg-mercury-alpha-md {
  background: rgba(232, 232, 232, 0.1) !important;
}

.alpha-md {
  opacity: 0.1 !important;
}

.bg-manatee-alpha-md {
  background: rgba(139, 147, 168, 0.1) !important;
}

.alpha-md {
  opacity: 0.1 !important;
}

.bg-dodger-blue-alpha-md {
  background: rgba(93, 85, 255, 0.1) !important;
}

.alpha-md {
  opacity: 0.1 !important;
}

.bg-pacific-blue-alpha-md {
  background: rgba(0, 180, 187, 0.1) !important;
}

.alpha-md {
  opacity: 0.1 !important;
}

.bg-turquoise-blue-alpha-md {
  background: rgba(104, 239, 173, 0.1) !important;
}

.alpha-md {
  opacity: 0.1 !important;
}

.bg-buttercup-alpha-md {
  background: rgba(246, 164, 22, 0.1) !important;
}

.alpha-md {
  opacity: 0.1 !important;
}

.bg-copper-rust-alpha-md {
  background: rgba(151, 74, 90, 0.1) !important;
}

.alpha-md {
  opacity: 0.1 !important;
}

.bg-east-bay-alpha-md {
  background: rgba(74, 88, 133, 0.1) !important;
}

.alpha-md {
  opacity: 0.1 !important;
}

.bg-plantation-alpha-md {
  background: rgba(39, 88, 85, 0.1) !important;
}

.alpha-md {
  opacity: 0.1 !important;
}

.bg-apricot-alpha-sm {
  background: rgba(232, 142, 94, 0.05) !important;
}

.alpha-sm {
  opacity: 0.05 !important;
}

.bg-azure-alpha-sm {
  background: rgba(56, 105, 159, 0.05) !important;
}

.alpha-sm {
  opacity: 0.05 !important;
}

.bg-cornflower-blue-alpha-sm {
  background: rgba(100, 136, 255, 0.05) !important;
}

.alpha-sm {
  opacity: 0.05 !important;
}

.bg-faded-jade-alpha-sm {
  background: rgba(64, 129, 130, 0.05) !important;
}

.alpha-sm {
  opacity: 0.05 !important;
}

.bg-heather-alpha-sm {
  background: rgba(180, 187, 203, 0.05) !important;
}

.alpha-sm {
  opacity: 0.05 !important;
}

.bg-mako-alpha-sm {
  background: rgba(68, 72, 82, 0.05) !important;
}

.alpha-sm {
  opacity: 0.05 !important;
}

.bg-puerto-rico-alpha-sm {
  background: rgba(65, 196, 157, 0.05) !important;
}

.alpha-sm {
  opacity: 0.05 !important;
}

.bg-royal-blue-alpha-sm {
  background: rgba(92, 86, 232, 0.05) !important;
}

.alpha-sm {
  opacity: 0.05 !important;
}

.bg-spicy-mix-alpha-sm {
  background: rgba(133, 75, 75, 0.05) !important;
}

.alpha-sm {
  opacity: 0.05 !important;
}

.bg-valencia-alpha-sm {
  background: rgba(220, 73, 65, 0.05) !important;
}

.alpha-sm {
  opacity: 0.05 !important;
}

.bg-victoria-alpha-sm {
  background: rgba(81, 75, 144, 0.05) !important;
}

.alpha-sm {
  opacity: 0.05 !important;
}

.bg-mars-alpha-sm {
  background: rgba(213, 50, 41, 0.05) !important;
}

.alpha-sm {
  opacity: 0.05 !important;
}

.bg-mercury-alpha-sm {
  background: rgba(232, 232, 232, 0.05) !important;
}

.alpha-sm {
  opacity: 0.05 !important;
}

.bg-manatee-alpha-sm {
  background: rgba(139, 147, 168, 0.05) !important;
}

.alpha-sm {
  opacity: 0.05 !important;
}

.bg-dodger-blue-alpha-sm {
  background: rgba(93, 85, 255, 0.05) !important;
}

.alpha-sm {
  opacity: 0.05 !important;
}

.bg-pacific-blue-alpha-sm {
  background: rgba(0, 180, 187, 0.05) !important;
}

.alpha-sm {
  opacity: 0.05 !important;
}

.bg-turquoise-blue-alpha-sm {
  background: rgba(104, 239, 173, 0.05) !important;
}

.alpha-sm {
  opacity: 0.05 !important;
}

.bg-buttercup-alpha-sm {
  background: rgba(246, 164, 22, 0.05) !important;
}

.alpha-sm {
  opacity: 0.05 !important;
}

.bg-copper-rust-alpha-sm {
  background: rgba(151, 74, 90, 0.05) !important;
}

.alpha-sm {
  opacity: 0.05 !important;
}

.bg-east-bay-alpha-sm {
  background: rgba(74, 88, 133, 0.05) !important;
}

.alpha-sm {
  opacity: 0.05 !important;
}

.bg-plantation-alpha-sm {
  background: rgba(39, 88, 85, 0.05) !important;
}

.alpha-sm {
  opacity: 0.05 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

@media (min-width: 576px) {
  .overflow-sm-visible {
    overflow: visible !important;
  }
}
@media (min-width: 768px) {
  .overflow-md-visible {
    overflow: visible !important;
  }
}
@media (min-width: 992px) {
  .overflow-lg-visible {
    overflow: visible !important;
  }
}
@media (min-width: 1200px) {
  .overflow-xl-visible {
    overflow: visible !important;
  }
}
@media (min-width: 1600px) {
  .overflow-xxl-visible {
    overflow: visible !important;
  }
}
.shadow-sm {
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 2px 1px -1px rgba(0, 0, 0, 0.06) !important;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 2px 1px -1px rgba(0, 0, 0, 0.06) !important;
}

.shadow {
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 0 8px 0px rgba(0, 0, 0, 0.06), 0 8px 15px 0 rgba(0, 0, 0, 0.12) !important;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 0 8px 0px rgba(0, 0, 0, 0.06), 0 8px 15px 0 rgba(0, 0, 0, 0.12) !important;
}

.shadow-lg {
  -webkit-box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.1), 0 4px 5px 0 rgba(0, 0, 0, 0.07), 0 1px 10px 0 rgba(0, 0, 0, 0.06) !important;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.1), 0 4px 5px 0 rgba(0, 0, 0, 0.07), 0 1px 10px 0 rgba(0, 0, 0, 0.06) !important;
}

.shadow-none {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.shadow-xl {
  -webkit-box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.1), 0 8px 10px 1px rgba(0, 0, 0, 0.07), 0 3px 14px 2px rgba(0, 0, 0, 0.06) !important;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.1), 0 8px 10px 1px rgba(0, 0, 0, 0.07), 0 3px 14px 2px rgba(0, 0, 0, 0.06) !important;
}

.shadow-xxl {
  -webkit-box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.1), 0 16px 24px 2px rgba(0, 0, 0, 0.07), 0 6px 30px 5px rgba(0, 0, 0, 0.06) !important;
  box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.1), 0 16px 24px 2px rgba(0, 0, 0, 0.07), 0 6px 30px 5px rgba(0, 0, 0, 0.06) !important;
}

.shadow-max {
  -webkit-box-shadow: 0 10px 14px -6px rgba(0, 0, 0, 0.1), 0 22px 35px 3px rgba(0, 0, 0, 0.07), 0 8px 42px 7px rgba(0, 0, 0, 0.06) !important;
  box-shadow: 0 10px 14px -6px rgba(0, 0, 0, 0.1), 0 22px 35px 3px rgba(0, 0, 0, 0.07), 0 8px 42px 7px rgba(0, 0, 0, 0.06) !important;
}

.spacing-m-5 {
  margin: 10rem !important;
}

.spacing-mt-5,
.spacing-my-5 {
  margin-top: 10rem !important;
}

.spacing-mr-5,
.spacing-mx-5 {
  margin-right: 10rem !important;
}

.spacing-mb-5,
.spacing-my-5 {
  margin-bottom: 10rem !important;
}

.spacing-ml-5,
.spacing-mx-5 {
  margin-left: 10rem !important;
}

.spacing-m-4 {
  margin: 8rem !important;
}

.spacing-mt-4,
.spacing-my-4 {
  margin-top: 8rem !important;
}

.spacing-mr-4,
.spacing-mx-4 {
  margin-right: 8rem !important;
}

.spacing-mb-4,
.spacing-my-4 {
  margin-bottom: 8rem !important;
}

.spacing-ml-4,
.spacing-mx-4 {
  margin-left: 8rem !important;
}

.spacing-m-3 {
  margin: 6rem !important;
}

.spacing-mt-3,
.spacing-my-3 {
  margin-top: 6rem !important;
}

.spacing-mr-3,
.spacing-mx-3 {
  margin-right: 6rem !important;
}

.spacing-mb-3,
.spacing-my-3 {
  margin-bottom: 6rem !important;
}

.spacing-ml-3,
.spacing-mx-3 {
  margin-left: 6rem !important;
}

.spacing-m-2 {
  margin: 4rem !important;
}

.spacing-mt-2,
.spacing-my-2 {
  margin-top: 4rem !important;
}

.spacing-mr-2,
.spacing-mx-2 {
  margin-right: 4rem !important;
}

.spacing-mb-2,
.spacing-my-2 {
  margin-bottom: 4rem !important;
}

.spacing-ml-2,
.spacing-mx-2 {
  margin-left: 4rem !important;
}

.spacing-m-1 {
  margin: 2rem !important;
}

.spacing-mt-1,
.spacing-my-1 {
  margin-top: 2rem !important;
}

.spacing-mr-1,
.spacing-mx-1 {
  margin-right: 2rem !important;
}

.spacing-mb-1,
.spacing-my-1 {
  margin-bottom: 2rem !important;
}

.spacing-ml-1,
.spacing-mx-1 {
  margin-left: 2rem !important;
}

.spacing-m-0 {
  margin: 0 !important;
}

.spacing-mt-0,
.spacing-my-0 {
  margin-top: 0 !important;
}

.spacing-mr-0,
.spacing-mx-0 {
  margin-right: 0 !important;
}

.spacing-mb-0,
.spacing-my-0 {
  margin-bottom: 0 !important;
}

.spacing-ml-0,
.spacing-mx-0 {
  margin-left: 0 !important;
}

@media (min-width: 576px) {
  .spacing-m-sm-5 {
    margin: 10rem !important;
  }

  .spacing-mt-sm-5,
.spacing-my-sm-5 {
    margin-top: 10rem !important;
  }

  .spacing-mr-sm-5,
.spacing-mx-sm-5 {
    margin-right: 10rem !important;
  }

  .spacing-mb-sm-5,
.spacing-my-sm-5 {
    margin-bottom: 10rem !important;
  }

  .spacing-ml-sm-5,
.spacing-mx-sm-5 {
    margin-left: 10rem !important;
  }

  .spacing-m-sm-4 {
    margin: 8rem !important;
  }

  .spacing-mt-sm-4,
.spacing-my-sm-4 {
    margin-top: 8rem !important;
  }

  .spacing-mr-sm-4,
.spacing-mx-sm-4 {
    margin-right: 8rem !important;
  }

  .spacing-mb-sm-4,
.spacing-my-sm-4 {
    margin-bottom: 8rem !important;
  }

  .spacing-ml-sm-4,
.spacing-mx-sm-4 {
    margin-left: 8rem !important;
  }

  .spacing-m-sm-3 {
    margin: 6rem !important;
  }

  .spacing-mt-sm-3,
.spacing-my-sm-3 {
    margin-top: 6rem !important;
  }

  .spacing-mr-sm-3,
.spacing-mx-sm-3 {
    margin-right: 6rem !important;
  }

  .spacing-mb-sm-3,
.spacing-my-sm-3 {
    margin-bottom: 6rem !important;
  }

  .spacing-ml-sm-3,
.spacing-mx-sm-3 {
    margin-left: 6rem !important;
  }

  .spacing-m-sm-2 {
    margin: 4rem !important;
  }

  .spacing-mt-sm-2,
.spacing-my-sm-2 {
    margin-top: 4rem !important;
  }

  .spacing-mr-sm-2,
.spacing-mx-sm-2 {
    margin-right: 4rem !important;
  }

  .spacing-mb-sm-2,
.spacing-my-sm-2 {
    margin-bottom: 4rem !important;
  }

  .spacing-ml-sm-2,
.spacing-mx-sm-2 {
    margin-left: 4rem !important;
  }

  .spacing-m-sm-1 {
    margin: 2rem !important;
  }

  .spacing-mt-sm-1,
.spacing-my-sm-1 {
    margin-top: 2rem !important;
  }

  .spacing-mr-sm-1,
.spacing-mx-sm-1 {
    margin-right: 2rem !important;
  }

  .spacing-mb-sm-1,
.spacing-my-sm-1 {
    margin-bottom: 2rem !important;
  }

  .spacing-ml-sm-1,
.spacing-mx-sm-1 {
    margin-left: 2rem !important;
  }

  .spacing-m-sm-0 {
    margin: 0 !important;
  }

  .spacing-mt-sm-0,
.spacing-my-sm-0 {
    margin-top: 0 !important;
  }

  .spacing-mr-sm-0,
.spacing-mx-sm-0 {
    margin-right: 0 !important;
  }

  .spacing-mb-sm-0,
.spacing-my-sm-0 {
    margin-bottom: 0 !important;
  }

  .spacing-ml-sm-0,
.spacing-mx-sm-0 {
    margin-left: 0 !important;
  }
}
@media (min-width: 768px) {
  .spacing-m-md-5 {
    margin: 10rem !important;
  }

  .spacing-mt-md-5,
.spacing-my-md-5 {
    margin-top: 10rem !important;
  }

  .spacing-mr-md-5,
.spacing-mx-md-5 {
    margin-right: 10rem !important;
  }

  .spacing-mb-md-5,
.spacing-my-md-5 {
    margin-bottom: 10rem !important;
  }

  .spacing-ml-md-5,
.spacing-mx-md-5 {
    margin-left: 10rem !important;
  }

  .spacing-m-md-4 {
    margin: 8rem !important;
  }

  .spacing-mt-md-4,
.spacing-my-md-4 {
    margin-top: 8rem !important;
  }

  .spacing-mr-md-4,
.spacing-mx-md-4 {
    margin-right: 8rem !important;
  }

  .spacing-mb-md-4,
.spacing-my-md-4 {
    margin-bottom: 8rem !important;
  }

  .spacing-ml-md-4,
.spacing-mx-md-4 {
    margin-left: 8rem !important;
  }

  .spacing-m-md-3 {
    margin: 6rem !important;
  }

  .spacing-mt-md-3,
.spacing-my-md-3 {
    margin-top: 6rem !important;
  }

  .spacing-mr-md-3,
.spacing-mx-md-3 {
    margin-right: 6rem !important;
  }

  .spacing-mb-md-3,
.spacing-my-md-3 {
    margin-bottom: 6rem !important;
  }

  .spacing-ml-md-3,
.spacing-mx-md-3 {
    margin-left: 6rem !important;
  }

  .spacing-m-md-2 {
    margin: 4rem !important;
  }

  .spacing-mt-md-2,
.spacing-my-md-2 {
    margin-top: 4rem !important;
  }

  .spacing-mr-md-2,
.spacing-mx-md-2 {
    margin-right: 4rem !important;
  }

  .spacing-mb-md-2,
.spacing-my-md-2 {
    margin-bottom: 4rem !important;
  }

  .spacing-ml-md-2,
.spacing-mx-md-2 {
    margin-left: 4rem !important;
  }

  .spacing-m-md-1 {
    margin: 2rem !important;
  }

  .spacing-mt-md-1,
.spacing-my-md-1 {
    margin-top: 2rem !important;
  }

  .spacing-mr-md-1,
.spacing-mx-md-1 {
    margin-right: 2rem !important;
  }

  .spacing-mb-md-1,
.spacing-my-md-1 {
    margin-bottom: 2rem !important;
  }

  .spacing-ml-md-1,
.spacing-mx-md-1 {
    margin-left: 2rem !important;
  }

  .spacing-m-md-0 {
    margin: 0 !important;
  }

  .spacing-mt-md-0,
.spacing-my-md-0 {
    margin-top: 0 !important;
  }

  .spacing-mr-md-0,
.spacing-mx-md-0 {
    margin-right: 0 !important;
  }

  .spacing-mb-md-0,
.spacing-my-md-0 {
    margin-bottom: 0 !important;
  }

  .spacing-ml-md-0,
.spacing-mx-md-0 {
    margin-left: 0 !important;
  }
}
@media (min-width: 992px) {
  .spacing-m-lg-5 {
    margin: 10rem !important;
  }

  .spacing-mt-lg-5,
.spacing-my-lg-5 {
    margin-top: 10rem !important;
  }

  .spacing-mr-lg-5,
.spacing-mx-lg-5 {
    margin-right: 10rem !important;
  }

  .spacing-mb-lg-5,
.spacing-my-lg-5 {
    margin-bottom: 10rem !important;
  }

  .spacing-ml-lg-5,
.spacing-mx-lg-5 {
    margin-left: 10rem !important;
  }

  .spacing-m-lg-4 {
    margin: 8rem !important;
  }

  .spacing-mt-lg-4,
.spacing-my-lg-4 {
    margin-top: 8rem !important;
  }

  .spacing-mr-lg-4,
.spacing-mx-lg-4 {
    margin-right: 8rem !important;
  }

  .spacing-mb-lg-4,
.spacing-my-lg-4 {
    margin-bottom: 8rem !important;
  }

  .spacing-ml-lg-4,
.spacing-mx-lg-4 {
    margin-left: 8rem !important;
  }

  .spacing-m-lg-3 {
    margin: 6rem !important;
  }

  .spacing-mt-lg-3,
.spacing-my-lg-3 {
    margin-top: 6rem !important;
  }

  .spacing-mr-lg-3,
.spacing-mx-lg-3 {
    margin-right: 6rem !important;
  }

  .spacing-mb-lg-3,
.spacing-my-lg-3 {
    margin-bottom: 6rem !important;
  }

  .spacing-ml-lg-3,
.spacing-mx-lg-3 {
    margin-left: 6rem !important;
  }

  .spacing-m-lg-2 {
    margin: 4rem !important;
  }

  .spacing-mt-lg-2,
.spacing-my-lg-2 {
    margin-top: 4rem !important;
  }

  .spacing-mr-lg-2,
.spacing-mx-lg-2 {
    margin-right: 4rem !important;
  }

  .spacing-mb-lg-2,
.spacing-my-lg-2 {
    margin-bottom: 4rem !important;
  }

  .spacing-ml-lg-2,
.spacing-mx-lg-2 {
    margin-left: 4rem !important;
  }

  .spacing-m-lg-1 {
    margin: 2rem !important;
  }

  .spacing-mt-lg-1,
.spacing-my-lg-1 {
    margin-top: 2rem !important;
  }

  .spacing-mr-lg-1,
.spacing-mx-lg-1 {
    margin-right: 2rem !important;
  }

  .spacing-mb-lg-1,
.spacing-my-lg-1 {
    margin-bottom: 2rem !important;
  }

  .spacing-ml-lg-1,
.spacing-mx-lg-1 {
    margin-left: 2rem !important;
  }

  .spacing-m-lg-0 {
    margin: 0 !important;
  }

  .spacing-mt-lg-0,
.spacing-my-lg-0 {
    margin-top: 0 !important;
  }

  .spacing-mr-lg-0,
.spacing-mx-lg-0 {
    margin-right: 0 !important;
  }

  .spacing-mb-lg-0,
.spacing-my-lg-0 {
    margin-bottom: 0 !important;
  }

  .spacing-ml-lg-0,
.spacing-mx-lg-0 {
    margin-left: 0 !important;
  }
}
@media (min-width: 1200px) {
  .spacing-m-xl-5 {
    margin: 10rem !important;
  }

  .spacing-mt-xl-5,
.spacing-my-xl-5 {
    margin-top: 10rem !important;
  }

  .spacing-mr-xl-5,
.spacing-mx-xl-5 {
    margin-right: 10rem !important;
  }

  .spacing-mb-xl-5,
.spacing-my-xl-5 {
    margin-bottom: 10rem !important;
  }

  .spacing-ml-xl-5,
.spacing-mx-xl-5 {
    margin-left: 10rem !important;
  }

  .spacing-m-xl-4 {
    margin: 8rem !important;
  }

  .spacing-mt-xl-4,
.spacing-my-xl-4 {
    margin-top: 8rem !important;
  }

  .spacing-mr-xl-4,
.spacing-mx-xl-4 {
    margin-right: 8rem !important;
  }

  .spacing-mb-xl-4,
.spacing-my-xl-4 {
    margin-bottom: 8rem !important;
  }

  .spacing-ml-xl-4,
.spacing-mx-xl-4 {
    margin-left: 8rem !important;
  }

  .spacing-m-xl-3 {
    margin: 6rem !important;
  }

  .spacing-mt-xl-3,
.spacing-my-xl-3 {
    margin-top: 6rem !important;
  }

  .spacing-mr-xl-3,
.spacing-mx-xl-3 {
    margin-right: 6rem !important;
  }

  .spacing-mb-xl-3,
.spacing-my-xl-3 {
    margin-bottom: 6rem !important;
  }

  .spacing-ml-xl-3,
.spacing-mx-xl-3 {
    margin-left: 6rem !important;
  }

  .spacing-m-xl-2 {
    margin: 4rem !important;
  }

  .spacing-mt-xl-2,
.spacing-my-xl-2 {
    margin-top: 4rem !important;
  }

  .spacing-mr-xl-2,
.spacing-mx-xl-2 {
    margin-right: 4rem !important;
  }

  .spacing-mb-xl-2,
.spacing-my-xl-2 {
    margin-bottom: 4rem !important;
  }

  .spacing-ml-xl-2,
.spacing-mx-xl-2 {
    margin-left: 4rem !important;
  }

  .spacing-m-xl-1 {
    margin: 2rem !important;
  }

  .spacing-mt-xl-1,
.spacing-my-xl-1 {
    margin-top: 2rem !important;
  }

  .spacing-mr-xl-1,
.spacing-mx-xl-1 {
    margin-right: 2rem !important;
  }

  .spacing-mb-xl-1,
.spacing-my-xl-1 {
    margin-bottom: 2rem !important;
  }

  .spacing-ml-xl-1,
.spacing-mx-xl-1 {
    margin-left: 2rem !important;
  }

  .spacing-m-xl-0 {
    margin: 0 !important;
  }

  .spacing-mt-xl-0,
.spacing-my-xl-0 {
    margin-top: 0 !important;
  }

  .spacing-mr-xl-0,
.spacing-mx-xl-0 {
    margin-right: 0 !important;
  }

  .spacing-mb-xl-0,
.spacing-my-xl-0 {
    margin-bottom: 0 !important;
  }

  .spacing-ml-xl-0,
.spacing-mx-xl-0 {
    margin-left: 0 !important;
  }
}
@media (min-width: 1600px) {
  .spacing-m-xxl-5 {
    margin: 10rem !important;
  }

  .spacing-mt-xxl-5,
.spacing-my-xxl-5 {
    margin-top: 10rem !important;
  }

  .spacing-mr-xxl-5,
.spacing-mx-xxl-5 {
    margin-right: 10rem !important;
  }

  .spacing-mb-xxl-5,
.spacing-my-xxl-5 {
    margin-bottom: 10rem !important;
  }

  .spacing-ml-xxl-5,
.spacing-mx-xxl-5 {
    margin-left: 10rem !important;
  }

  .spacing-m-xxl-4 {
    margin: 8rem !important;
  }

  .spacing-mt-xxl-4,
.spacing-my-xxl-4 {
    margin-top: 8rem !important;
  }

  .spacing-mr-xxl-4,
.spacing-mx-xxl-4 {
    margin-right: 8rem !important;
  }

  .spacing-mb-xxl-4,
.spacing-my-xxl-4 {
    margin-bottom: 8rem !important;
  }

  .spacing-ml-xxl-4,
.spacing-mx-xxl-4 {
    margin-left: 8rem !important;
  }

  .spacing-m-xxl-3 {
    margin: 6rem !important;
  }

  .spacing-mt-xxl-3,
.spacing-my-xxl-3 {
    margin-top: 6rem !important;
  }

  .spacing-mr-xxl-3,
.spacing-mx-xxl-3 {
    margin-right: 6rem !important;
  }

  .spacing-mb-xxl-3,
.spacing-my-xxl-3 {
    margin-bottom: 6rem !important;
  }

  .spacing-ml-xxl-3,
.spacing-mx-xxl-3 {
    margin-left: 6rem !important;
  }

  .spacing-m-xxl-2 {
    margin: 4rem !important;
  }

  .spacing-mt-xxl-2,
.spacing-my-xxl-2 {
    margin-top: 4rem !important;
  }

  .spacing-mr-xxl-2,
.spacing-mx-xxl-2 {
    margin-right: 4rem !important;
  }

  .spacing-mb-xxl-2,
.spacing-my-xxl-2 {
    margin-bottom: 4rem !important;
  }

  .spacing-ml-xxl-2,
.spacing-mx-xxl-2 {
    margin-left: 4rem !important;
  }

  .spacing-m-xxl-1 {
    margin: 2rem !important;
  }

  .spacing-mt-xxl-1,
.spacing-my-xxl-1 {
    margin-top: 2rem !important;
  }

  .spacing-mr-xxl-1,
.spacing-mx-xxl-1 {
    margin-right: 2rem !important;
  }

  .spacing-mb-xxl-1,
.spacing-my-xxl-1 {
    margin-bottom: 2rem !important;
  }

  .spacing-ml-xxl-1,
.spacing-mx-xxl-1 {
    margin-left: 2rem !important;
  }

  .spacing-m-xxl-0 {
    margin: 0 !important;
  }

  .spacing-mt-xxl-0,
.spacing-my-xxl-0 {
    margin-top: 0 !important;
  }

  .spacing-mr-xxl-0,
.spacing-mx-xxl-0 {
    margin-right: 0 !important;
  }

  .spacing-mb-xxl-0,
.spacing-my-xxl-0 {
    margin-bottom: 0 !important;
  }

  .spacing-ml-xxl-0,
.spacing-mx-xxl-0 {
    margin-left: 0 !important;
  }
}
.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1600px) {
  .text-xxl-left {
    text-align: left !important;
  }

  .text-xxl-right {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #000 !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: black !important;
}

.text-secondary {
  color: #6e777a !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #4a5052 !important;
}

.text-success {
  color: #00aa0c !important;
}

a.text-success:hover, a.text-success:focus {
  color: #005e07 !important;
}

.text-info {
  color: #2469ff !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0044d7 !important;
}

.text-warning {
  color: #da7400 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #8e4b00 !important;
}

.text-danger {
  color: #dd3724 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #9c2618 !important;
}

.text-light {
  color: #c4c8ca !important;
}

a.text-light:hover, a.text-light:focus {
  color: #9ca2a6 !important;
}

.text-dark {
  color: #414949 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #1d2121 !important;
}

.text-white {
  color: white !important;
}

a.text-white:hover, a.text-white:focus {
  color: #d9d9d9 !important;
}

.text-black {
  color: #231f20 !important;
}

a.text-black:hover, a.text-black:focus {
  color: black !important;
}

.text-socgen {
  color: #e60028 !important;
}

a.text-socgen:hover, a.text-socgen:focus {
  color: #9a001b !important;
}

.text-primary-alt {
  color: #fff !important;
}

a.text-primary-alt:hover, a.text-primary-alt:focus {
  color: #d9d9d9 !important;
}

.text-body {
  color: #000 !important;
}

.text-muted {
  color: rgba(0, 0, 0, 0.4) !important;
}

.text-black-50 {
  color: rgba(35, 31, 32, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.text-default {
  font-weight: 400;
  font-size: 0.875rem !important;
  line-height: 1.4285714286 !important;
}

.text-small {
  font-size: 0.75rem;
  line-height: 1.3333333333;
  letter-spacing: 0;
}

.text-large {
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: -0.01em;
}

.text-xlarge {
  font-size: 1.125rem;
  line-height: 1.5555555556;
  letter-spacing: -0.015em;
}

.text-xxlarge {
  font-size: 1.3125rem;
  line-height: 1.5238095238;
  letter-spacing: -0.02em;
}

.font-weight-light {
  font-weight: 300;
}

.font-weight-normal {
  font-weight: 400;
}

.font-weight-semibold {
  font-weight: 600;
}

.font-weight-bold {
  font-weight: 700;
}

.font-weight-extrabold {
  font-weight: 800;
}

.font-weight-medium {
  font-weight: 500;
}

.text-figures {
  line-height: 1;
  font-weight: 500;
}

.tabular-figures {
  -webkit-font-feature-settings: "cv01", "cv10", "tnum";
  font-feature-settings: "cv01", "cv10", "tnum";
  letter-spacing: -0.07em;
  line-height: 1;
  font-weight: 500;
}

.text-apricot {
  color: #e88e5e !important;
}

a.text-apricot:hover, a.text-apricot:focus {
  color: #da601f !important;
}

.text-azure {
  color: #38699f !important;
}

a.text-azure:hover, a.text-azure:focus {
  color: #244466 !important;
}

.text-cornflower-blue {
  color: #6488ff !important;
}

a.text-cornflower-blue:hover, a.text-cornflower-blue:focus {
  color: #184dff !important;
}

.text-faded-jade {
  color: #408182 !important;
}

a.text-faded-jade:hover, a.text-faded-jade:focus {
  color: #274e4f !important;
}

.text-heather {
  color: #b4bbcb !important;
}

a.text-heather:hover, a.text-heather:focus {
  color: #8792ac !important;
}

.text-mako {
  color: #444852 !important;
}

a.text-mako:hover, a.text-mako:focus {
  color: #212328 !important;
}

.text-puerto-rico {
  color: #41c49d !important;
}

a.text-puerto-rico:hover, a.text-puerto-rico:focus {
  color: #2c8d70 !important;
}

.text-royal-blue {
  color: #5c56e8 !important;
}

a.text-royal-blue:hover, a.text-royal-blue:focus {
  color: #241dd5 !important;
}

.text-spicy-mix {
  color: #854b4b !important;
}

a.text-spicy-mix:hover, a.text-spicy-mix:focus {
  color: #542f2f !important;
}

.text-valencia {
  color: #dc4941 !important;
}

a.text-valencia:hover, a.text-valencia:focus {
  color: #b02820 !important;
}

.text-victoria {
  color: #514b90 !important;
}

a.text-victoria:hover, a.text-victoria:focus {
  color: #35315e !important;
}

.text-mars {
  color: #d53229 !important;
}

a.text-mars:hover, a.text-mars:focus {
  color: #95231d !important;
}

.text-mercury {
  color: #e8e8e8 !important;
}

a.text-mercury:hover, a.text-mercury:focus {
  color: #c2c2c2 !important;
}

.text-manatee {
  color: #8b93a8 !important;
}

a.text-manatee:hover, a.text-manatee:focus {
  color: #636c84 !important;
}

.text-dodger-blue {
  color: #5d55ff !important;
}

a.text-dodger-blue:hover, a.text-dodger-blue:focus {
  color: #1409ff !important;
}

.text-pacific-blue {
  color: #00b4bb !important;
}

a.text-pacific-blue:hover, a.text-pacific-blue:focus {
  color: #006a6f !important;
}

.text-turquoise-blue {
  color: #68efad !important;
}

a.text-turquoise-blue:hover, a.text-turquoise-blue:focus {
  color: #23e887 !important;
}

.text-buttercup {
  color: #f6a416 !important;
}

a.text-buttercup:hover, a.text-buttercup:focus {
  color: #b87707 !important;
}

.text-copper-rust {
  color: #974a5a !important;
}

a.text-copper-rust:hover, a.text-copper-rust:focus {
  color: #64313b !important;
}

.text-east-bay {
  color: #4a5885 !important;
}

a.text-east-bay:hover, a.text-east-bay:focus {
  color: #2f3754 !important;
}

.text-plantation {
  color: #275855 !important;
}

a.text-plantation:hover, a.text-plantation:focus {
  color: #102322 !important;
}

.bg-lvl4 .text-secondary {
  color: #5f6668 !important;
}

.bg-lvl4 .text-light {
  color: #b1b7b9 !important;
}

.bg-lvl4 .text-dark {
  color: #323939 !important;
}

.bg-lvl4 .text-info {
  color: #0554ff !important;
}

.bg-lvl4 .text-success {
  color: #008a09 !important;
}

.bg-lvl4 .text-warning {
  color: #bc6500 !important;
}

.bg-lvl4 .text-danger {
  color: #c22f1f !important;
}

.bg-lvl3 .text-secondary {
  color: #666e70 !important;
}

.bg-lvl3 .text-light {
  color: #b9bec0 !important;
}

.bg-lvl3 .text-dark {
  color: #373e3e !important;
}

.bg-lvl3 .text-info {
  color: #0f5bff !important;
}

.bg-lvl3 .text-success {
  color: #00940a !important;
}

.bg-lvl3 .text-warning {
  color: #c66a00 !important;
}

.bg-lvl3 .text-danger {
  color: #cb3120 !important;
}

.bg-lvl2 .text-secondary {
  color: #687073 !important;
}

.bg-lvl2 .text-light {
  color: #c1c5c7 !important;
}

.bg-lvl2 .text-dark {
  color: #3c4343 !important;
}

.bg-lvl2 .text-info {
  color: #1a62ff !important;
}

.bg-lvl2 .text-success {
  color: #009e0b !important;
}

.bg-lvl2 .text-warning {
  color: #d06f00 !important;
}

.bg-lvl2 .text-danger {
  color: #d43321 !important;
}

.bg-lvl1 .text-secondary {
  color: #6e777a !important;
}

.bg-lvl1 .text-light {
  color: #c4c8ca !important;
}

.bg-lvl1 .text-dark {
  color: #414949 !important;
}

.bg-lvl1 .text-info {
  color: #2469ff !important;
}

.bg-lvl1 .text-success {
  color: #00aa0c !important;
}

.bg-lvl1 .text-warning {
  color: #da7400 !important;
}

.bg-lvl1 .text-danger {
  color: #dd3724 !important;
}

.bg-alt-lvl4 .text-secondary {
  color: #70797a !important;
}

.bg-alt-lvl4 .text-light {
  color: #a3afb3 !important;
}

.bg-alt-lvl4 .text-dark {
  color: #343737 !important;
}

.bg-alt-lvl4 .text-info {
  color: #1476ff !important;
}

.bg-alt-lvl4 .text-success {
  color: #00a83e !important;
}

.bg-alt-lvl4 .text-warning {
  color: #cc7400 !important;
}

.bg-alt-lvl4 .text-danger {
  color: #d93d26 !important;
}

.bg-alt-lvl3 .text-secondary {
  color: #7a8385 !important;
}

.bg-alt-lvl3 .text-light {
  color: #b1bbbe !important;
}

.bg-alt-lvl3 .text-dark {
  color: #3b3f3f !important;
}

.bg-alt-lvl3 .text-info {
  color: #2982ff !important;
}

.bg-alt-lvl3 .text-success {
  color: #00b844 !important;
}

.bg-alt-lvl3 .text-warning {
  color: #db7c00 !important;
}

.bg-alt-lvl3 .text-danger {
  color: #e8442c !important;
}

.bg-alt-lvl2 .text-secondary {
  color: #848d8f !important;
}

.bg-alt-lvl2 .text-light {
  color: #c2cacc !important;
}

.bg-alt-lvl2 .text-dark {
  color: #454a4a !important;
}

.bg-alt-lvl2 .text-info {
  color: #3d8eff !important;
}

.bg-alt-lvl2 .text-success {
  color: #00c74a !important;
}

.bg-alt-lvl2 .text-warning {
  color: #eb8500 !important;
}

.bg-alt-lvl2 .text-danger {
  color: #f44d34 !important;
}

.bg-alt-lvl1 .text-secondary {
  color: #949c9e !important;
}

.bg-alt-lvl1 .text-light {
  color: #d8ddde !important;
}

.bg-alt-lvl1 .text-dark {
  color: #515757 !important;
}

.bg-alt-lvl1 .text-info {
  color: #529aff !important;
}

.bg-alt-lvl1 .text-success {
  color: #00d750 !important;
}

.bg-alt-lvl1 .text-warning {
  color: #f98d00 !important;
}

.bg-alt-lvl1 .text-danger {
  color: #ff563c !important;
}

.bg-lvl4 .text-success:not(h1):not(.h1):not(h2):not(.h2):not(h3):not(.h3):not(.display-1):not(.display-2):not(.display-3):not(.display-4) {
  color: #006b07 !important;
}
.bg-lvl4 .text-warning:not(h1):not(.h1):not(h2):not(.h2):not(h3):not(.h3):not(.display-1):not(.display-2):not(.display-3):not(.display-4) {
  color: #934f00 !important;
}
.bg-lvl4 .text-danger:not(h1):not(.h1):not(h2):not(.h2):not(h3):not(.h3):not(.display-1):not(.display-2):not(.display-3):not(.display-4) {
  color: #c22f1f !important;
}

.bg-lvl3 .text-success:not(h1):not(.h1):not(h2):not(.h2):not(h3):not(.h3):not(.display-1):not(.display-2):not(.display-3):not(.display-4) {
  color: #007508 !important;
}
.bg-lvl3 .text-warning:not(h1):not(.h1):not(h2):not(.h2):not(h3):not(.h3):not(.display-1):not(.display-2):not(.display-3):not(.display-4) {
  color: #9d5400 !important;
}
.bg-lvl3 .text-danger:not(h1):not(.h1):not(h2):not(.h2):not(h3):not(.h3):not(.display-1):not(.display-2):not(.display-3):not(.display-4) {
  color: #cb3120 !important;
}

.bg-lvl2 .text-success:not(h1):not(.h1):not(h2):not(.h2):not(h3):not(.h3):not(.display-1):not(.display-2):not(.display-3):not(.display-4) {
  color: #007f09 !important;
}
.bg-lvl2 .text-warning:not(h1):not(.h1):not(h2):not(.h2):not(h3):not(.h3):not(.display-1):not(.display-2):not(.display-3):not(.display-4) {
  color: #a75900 !important;
}
.bg-lvl2 .text-danger:not(h1):not(.h1):not(h2):not(.h2):not(h3):not(.h3):not(.display-1):not(.display-2):not(.display-3):not(.display-4) {
  color: #d43321 !important;
}

.bg-lvl1 .text-success:not(h1):not(.h1):not(h2):not(.h2):not(h3):not(.h3):not(.display-1):not(.display-2):not(.display-3):not(.display-4) {
  color: #008b0a !important;
}
.bg-lvl1 .text-warning:not(h1):not(.h1):not(h2):not(.h2):not(h3):not(.h3):not(.display-1):not(.display-2):not(.display-3):not(.display-4) {
  color: #b15e00 !important;
}
.bg-lvl1 .text-danger:not(h1):not(.h1):not(h2):not(.h2):not(h3):not(.h3):not(.display-1):not(.display-2):not(.display-3):not(.display-4) {
  color: #dd3724 !important;
}

.bg-alt-lvl4 .text-danger:not(h1):not(.h1):not(h2):not(.h2):not(h3):not(.h3):not(.display-1):not(.display-2):not(.display-3):not(.display-4) {
  color: #db4e33 !important;
}
.bg-alt-lvl4 .text-success:not(h1):not(.h1):not(h2):not(.h2):not(h3):not(.h3):not(.display-1):not(.display-2):not(.display-3):not(.display-4) {
  color: #00a83e !important;
}
.bg-alt-lvl4 .text-warning:not(h1):not(.h1):not(h2):not(.h2):not(h3):not(.h3):not(.display-1):not(.display-2):not(.display-3):not(.display-4) {
  color: #cc7400 !important;
}

.bg-alt-lvl3 .text-danger:not(h1):not(.h1):not(h2):not(.h2):not(h3):not(.h3):not(.display-1):not(.display-2):not(.display-3):not(.display-4) {
  color: #ea563a !important;
}
.bg-alt-lvl3 .text-success:not(h1):not(.h1):not(h2):not(.h2):not(h3):not(.h3):not(.display-1):not(.display-2):not(.display-3):not(.display-4) {
  color: #00b844 !important;
}
.bg-alt-lvl3 .text-warning:not(h1):not(.h1):not(h2):not(.h2):not(h3):not(.h3):not(.display-1):not(.display-2):not(.display-3):not(.display-4) {
  color: #db7c00 !important;
}

.bg-alt-lvl2 .text-danger:not(h1):not(.h1):not(h2):not(.h2):not(h3):not(.h3):not(.display-1):not(.display-2):not(.display-3):not(.display-4) {
  color: #f56043 !important;
}
.bg-alt-lvl2 .text-success:not(h1):not(.h1):not(h2):not(.h2):not(h3):not(.h3):not(.display-1):not(.display-2):not(.display-3):not(.display-4) {
  color: #00c74a !important;
}
.bg-alt-lvl2 .text-warning:not(h1):not(.h1):not(h2):not(.h2):not(h3):not(.h3):not(.display-1):not(.display-2):not(.display-3):not(.display-4) {
  color: #eb8500 !important;
}

.bg-alt-lvl1 .text-danger:not(h1):not(.h1):not(h2):not(.h2):not(h3):not(.h3):not(.display-1):not(.display-2):not(.display-3):not(.display-4) {
  color: #ff694b !important;
}
.bg-alt-lvl1 .text-success:not(h1):not(.h1):not(h2):not(.h2):not(h3):not(.h3):not(.display-1):not(.display-2):not(.display-3):not(.display-4) {
  color: #00d750 !important;
}
.bg-alt-lvl1 .text-warning:not(h1):not(.h1):not(h2):not(.h2):not(h3):not(.h3):not(.display-1):not(.display-2):not(.display-3):not(.display-4) {
  color: #f98d00 !important;
}

@media print {
  *,
*::before,
*::after {
    text-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
img {
    page-break-inside: avoid;
  }

  p,
h2,
h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
h3 {
    page-break-after: avoid;
  }

  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }

  .container {
    min-width: 992px !important;
  }

  .navbar {
    display: none;
  }

  .badge {
    border: 1px solid #231f20;
  }

  .table {
    border-collapse: collapse !important;
  }
  .table td,
.table th {
    background-color: #fff !important;
  }

  .table-bordered th,
.table-bordered td {
    border: 1px solid #dee2e6 !important;
  }

  .table-dark {
    color: inherit;
  }
  .table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
    border-color: rgba(196, 200, 202, 0.7);
  }

  .table .thead-dark th {
    color: inherit;
    border-color: rgba(196, 200, 202, 0.7);
  }
}
.card-header [data-toggle=collapse]:not(.collapsed) .icon {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.card-header [data-toggle=collapse].btn:hover, .card-header [data-toggle=collapse].btn:focus, .card-header [data-toggle=collapse].btn:active {
  background: transparent;
}

.sgbs-collapse-separator .card {
  background: transparent;
}
.sgbs-collapse-separator .card-header [data-toggle=collapse] {
  position: relative;
  border-top: 1px solid rgba(196, 200, 202, 0.7);
}
.sgbs-collapse-separator .card-header [data-toggle=collapse].collapsed span {
  color: #6e777a;
}
.sgbs-collapse-separator .card-header [data-toggle=collapse]:not(.collapsed) .icon {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-transform-origin: 50% 46%;
  transform-origin: 50% 46%;
}
.sgbs-collapse-separator .card-header [data-toggle=collapse] span {
  z-index: 1;
  padding: 0.5rem;
  color: #000;
  white-space: nowrap;
}
.sgbs-collapse-separator.sgbs-collapse-separator-centered .card-header [data-toggle=collapse] {
  border-top: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.sgbs-collapse-separator.sgbs-collapse-separator-centered .card-header [data-toggle=collapse]::after, .sgbs-collapse-separator.sgbs-collapse-separator-centered .card-header [data-toggle=collapse]::before {
  content: "";
  border-top: 1px solid rgba(196, 200, 202, 0.7);
  height: 2px;
  width: 100%;
  display: block;
}
.sgbs-collapse-separator.sgbs-collapse-separator-centered span {
  padding: 0.5rem;
  color: #000;
}

.sgbs-combobox {
  position: relative;
  overflow: visible;
}
.sgbs-combobox > .dropdown-toggle::after {
  display: none;
}
.sgbs-combobox .sgbs-combobox-menu {
  border: none;
  position: relative;
  margin-top: -34px;
  width: 100%;
  font-size: 0.875rem;
}
.sgbs-combobox .sgbs-combobox-menu .input-group-merged {
  z-index: 5;
}
[size=lg] .sgbs-combobox .sgbs-combobox-menu {
  margin-top: -42px;
  font-size: 1rem;
}
[size=md] .sgbs-combobox .sgbs-combobox-menu {
  margin-top: -34px;
  font-size: 0.875rem;
}
[size=xl] .sgbs-combobox .sgbs-combobox-menu {
  margin-top: -50px;
  font-size: 1.125rem;
}
[size=sm] .sgbs-combobox .sgbs-combobox-menu {
  margin-top: -21px;
  font-size: 0.75rem;
}
.sgbs-combobox .sgbs-combobox-menu > .input-group {
  position: relative;
  z-index: 2;
}
.sgbs-combobox .sgbs-combobox-menu > .input-group .form-control {
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 2px solid #000;
  border-bottom: 1px solid rgba(196, 200, 202, 0.7);
}
.sgbs-combobox .sgbs-combobox-menu-inner {
  position: relative;
  z-index: 1;
  margin: -2px 2px 0;
  background: #fff;
}
.sgbs-combobox .sgbs-combobox-menu-inner:empty::before {
  content: "YOUR CONTENT HERE";
  text-align: center;
  height: 120px;
  width: 100%;
  display: block;
  line-height: 120px;
  font-weight: 700;
}

.sgbs-datepicker {
  background: #fff;
  min-width: 320px;
}

.sgbs-datepicker-inner {
  width: 320px;
  margin: 0 auto;
}

.sgbs-datepicker-calendar {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  margin: 0 auto;
}

.sgbs-datepicker-calendar-item {
  display: inline-block;
  text-align: center;
  height: 2.5rem;
  vertical-align: center;
  background-color: transparent;
  font-size: 1rem;
  will-change: background-color;
  -webkit-transition: background-color 300ms;
  transition: background-color 300ms;
  color: #000;
  width: 41px;
  padding: 0;
}
.sgbs-datepicker-calendar-item.inactive {
  color: #6e777a;
}
.sgbs-datepicker-calendar-item.active {
  background-color: #2469ff;
  color: #fff;
}
.sgbs-datepicker-calendar-item:hover:not([disabled]) {
  background-color: rgba(36, 105, 255, 0.6);
  color: #fff;
}
.sgbs-datepicker-calendar-item.sgbs-datepicker-calendar-weekday {
  opacity: 1;
}

.icon {
  font-weight: normal;
  font-style: normal;
  display: inline-block;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  vertical-align: middle;
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  -webkit-font-feature-settings: "liga";
  font-feature-settings: "liga";
  -webkit-transform: rotate(0.03deg);
  transform: rotate(0.03deg);
}
.icon > svg {
  fill: currentColor;
  height: 1em;
  width: 1em;
  margin-top: -0.5em;
}

button .icon,
a .icon {
  cursor: pointer;
  line-height: 1;
}

.icon-sm {
  font-size: 1.125rem;
}

.icon-md {
  font-size: 1.5rem;
}

.icon-lg {
  font-size: 2rem;
}

.icon-xl {
  font-size: 3rem;
}

.sgcib-only,
.sgcib-only-mini {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 1rem 1rem 0;
  border-color: transparent #e60028 transparent transparent;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 20;
}

.sgcib-only-mini {
  border-width: 0 0.5rem 0.5rem 0;
}

::-moz-selection {
  background-color: rgba(19, 97, 255, 0.9);
  color: #fff;
}

::selection {
  background-color: rgba(19, 97, 255, 0.9);
  color: #fff;
}

*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar:horizontal {
  height: 10px;
}

.scrollbar-sm::-webkit-scrollbar {
  width: 5px;
}

.scrollbar-sm::-webkit-scrollbar:horizontal {
  height: 5px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*:hover::-webkit-scrollbar-track {
  background: #f5f6f7;
}

*::-webkit-scrollbar-thumb {
  background: #c4c8ca;
}

*:hover::-webkit-scrollbar-thumb {
  background: #000;
}

body::-webkit-scrollbar,
.no-sgbs-custom-scrollbar::-webkit-scrollbar {
  width: 17px;
}
body::-webkit-scrollbar-track,
.no-sgbs-custom-scrollbar::-webkit-scrollbar-track {
  background: #f5f6f7;
}
body::-webkit-scrollbar-button,
.no-sgbs-custom-scrollbar::-webkit-scrollbar-button {
  background-position: top;
  background-color: #f5f6f7;
  height: 24px;
}
body::-webkit-scrollbar-button:decrement,
.no-sgbs-custom-scrollbar::-webkit-scrollbar-button:decrement {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#000" d="M7 14l5-5 5 5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
}
body::-webkit-scrollbar-button:increment,
.no-sgbs-custom-scrollbar::-webkit-scrollbar-button:increment {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#000" d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
}
body::-webkit-scrollbar-thumb, body:hover::-webkit-scrollbar-thumb,
.no-sgbs-custom-scrollbar::-webkit-scrollbar-thumb,
.no-sgbs-custom-scrollbar:hover::-webkit-scrollbar-thumb {
  background: #000;
  border-left: 3px solid #f5f6f7;
  border-right: 3px solid #f5f6f7;
}

.sgbs-slider input[type=range] {
  background: rgba(110, 119, 122, 0.2);
}
.sgbs-slider input[type=range]::-webkit-slider-thumb {
  background: url('data:image/svg+xml,<svg width="16" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 24">        <polygon points="8,0 0,8, 16,8" fill="%23000" />        </svg>');
}
.sgbs-slider input[type=range]::-webkit-slider-thumb:hover, .sgbs-slider input[type=range]::-webkit-slider-thumb:active {
  background: url('data:image/svg+xml,<svg width="16" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 24">          <polygon points="8,0 0,8, 16,8" fill="%23000" />          <rect width="16" height="16" fill="%23000" y="8" />          </svg>');
}

.sgbs-frame input[type=range] {
  border-bottom: 0.0625rem solid #c4c8ca;
}
.sgbs-frame input[type=range]::-webkit-slider-thumb,
.sgbs-frame input[type=range]::-webkit-slider-thumb:hover,
.sgbs-frame input[type=range]::-webkit-slider-thumb:active {
  background: url('data:image/svg+xml,<svg width="8" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 24">      <polygon points="8,0 0,8, 8,8" fill="%23000" />      <rect width="8" height="16" fill="%23000" y="8" />      </svg>');
  background-repeat: no-repeat;
}
.sgbs-frame input[type=range] + input[type=range]::-webkit-slider-thumb,
.sgbs-frame input[type=range] + input[type=range]::-webkit-slider-thumb:hover,
.sgbs-frame input[type=range] + input[type=range]::-webkit-slider-thumb:active {
  background: url('data:image/svg+xml,<svg width="8" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 24">      <polygon points="0,0 0,8, 8,8" fill="%23000" />      <rect width="8" height="16" fill="%23000" y="8" />      </svg>');
  background-position: right;
  background-repeat: no-repeat;
}

.sgbs-stepper {
  padding-left: 0;
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.sgbs-stepper-step {
  -webkit-box-flex: 2;
  -ms-flex: 2;
  flex: 2;
  position: relative;
}
.sgbs-stepper-step.inactive {
  color: rgba(110, 119, 122, 0.4);
}
.sgbs-stepper-step.inactive .sgbs-stepper-label {
  color: #6e777a !important;
}
.sgbs-stepper-step.blocked .sgbs-stepper-step-inner::after {
  color: rgba(110, 119, 122, 0.4);
}
.sgbs-stepper-step.blocked.muted .sgbs-stepper-step-inner::before {
  color: #000;
}

.sgbs-stepper-step-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.sgbs-stepper-step-inner::before, .sgbs-stepper-step-inner::after {
  content: "";
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  height: 2px;
  background: currentColor;
}

.sgbs-stepper-label {
  font-weight: 500;
  margin-top: 0.75rem;
  font-size: 0.875rem;
  display: block;
}
.sgbs-stepper:not(.sgbs-stepper-vertical) .sgbs-stepper-label {
  text-align: center;
}

.sgbs-stepper-indicator {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  border: 2px solid currentColor;
  text-align: center;
  background: transparent;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.fill .sgbs-stepper-indicator {
  background: currentColor;
}

.sgbs-stepper-no-indicator {
  height: 1.5rem;
  text-align: center;
  background: transparent;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* Exceptions */
.sgbs-stepper-step:not(.current) .icon {
  color: #fff;
  height: 0.8rem;
  line-height: 0.8rem;
}

button.sgbs-stepper-indicator {
  cursor: pointer;
  padding: 0;
}
.text-primary button.sgbs-stepper-indicator {
  color: #000;
}
.text-secondary button.sgbs-stepper-indicator {
  color: #6e777a;
}
.text-success button.sgbs-stepper-indicator {
  color: #00aa0c;
}
.text-info button.sgbs-stepper-indicator {
  color: #2469ff;
}
.text-warning button.sgbs-stepper-indicator {
  color: #da7400;
}
.text-danger button.sgbs-stepper-indicator {
  color: #dd3724;
}
.text-light button.sgbs-stepper-indicator {
  color: #c4c8ca;
}
.text-dark button.sgbs-stepper-indicator {
  color: #414949;
}
.text-white button.sgbs-stepper-indicator {
  color: white;
}
.text-black button.sgbs-stepper-indicator {
  color: #231f20;
}
.text-socgen button.sgbs-stepper-indicator {
  color: #e60028;
}
.text-primary-alt button.sgbs-stepper-indicator {
  color: #fff;
}

.sgbs-stepper .sgbs-stepper-step:first-child {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.sgbs-stepper .sgbs-stepper-step:first-child .sgbs-stepper-step-inner::before {
  display: none;
}
.sgbs-stepper .sgbs-stepper-step:first-child .sgbs-stepper-label {
  text-align: left;
}
.sgbs-stepper .sgbs-stepper-step:last-child {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.sgbs-stepper .sgbs-stepper-step:last-child .sgbs-stepper-step-inner::after {
  display: none;
}
.sgbs-stepper .sgbs-stepper-step:last-child .sgbs-stepper-label {
  text-align: right;
}

/* Vertical */
.sgbs-stepper-vertical {
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.sgbs-stepper-vertical .sgbs-stepper-step-inner {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
}
.sgbs-stepper-vertical .sgbs-stepper-step-inner {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
}
.sgbs-stepper-vertical .sgbs-stepper-step-inner::before, .sgbs-stepper-vertical .sgbs-stepper-step-inner::after {
  content: "";
  width: 2px;
  height: 100%;
}
.sgbs-stepper-vertical .sgbs-stepper-label {
  position: absolute;
  margin-top: 0;
  top: 50%;
  -webkit-transform: translate(0, -0.525rem);
  transform: translate(0, -0.525rem);
  left: 3rem;
  white-space: nowrap;
}
.sgbs-stepper-vertical .sgbs-stepper-step:first-child .sgbs-stepper-label {
  top: 0;
  -webkit-transform: none;
  transform: none;
}
.sgbs-stepper-vertical .sgbs-stepper-step:last-child .sgbs-stepper-label {
  bottom: 0;
  top: auto;
  -webkit-transform: none;
  transform: none;
}

.table-form thead th {
  padding-left: 0.625rem;
}
.table-form tbody td {
  padding: 0;
}
.table-form tbody th {
  vertical-align: middle;
  padding-top: 0;
  padding-bottom: 0;
}
.table-form td button.focus,
.table-form td button:focus {
  outline: none;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(48, 51, 51, 0.25) inset;
  box-shadow: 0 0 0 0.2rem rgba(48, 51, 51, 0.25) inset;
}
.table-form .form-control {
  background: transparent;
}
.table-form .form-control.disabled, .table-form .form-control:disabled, .table-form .form-control.readonly, .table-form .form-control[readonly] {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.table-form.table-sm thead th {
  padding: 0 0.5rem;
  line-height: 1.5;
  font-size: 0.75rem;
}
.table-form .input-group {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.sgbs-timeline {
  list-style: none;
  padding-left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.sgbs-timeline:not(.sgbs-timeline-alternative) .sgbs-timeline-item::after {
  content: "";
  position: absolute;
  display: block;
  height: 0.5rem;
  width: 0.5rem;
  background: currentColor;
  border-radius: 50%;
  bottom: -0.25rem;
  left: -0.25rem;
}
.sgbs-timeline:not(.sgbs-timeline-alternative) .sgbs-timeline-item:last-child {
  border-bottom: none;
}
.sgbs-timeline:not(.sgbs-timeline-alternative) .sgbs-timeline-item .sgbs-timeline-day,
.sgbs-timeline:not(.sgbs-timeline-alternative) .sgbs-timeline-item .sgbs-timeline-date {
  margin-left: -0.25rem;
}

.sgbs-timeline-item {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding-bottom: 1rem;
  border-bottom: 0.0625rem solid #000;
  color: #000;
  position: relative;
}
.sgbs-timeline-item.inactive {
  border-bottom-color: rgba(110, 119, 122, 0.4);
  color: rgba(110, 119, 122, 0.4);
}
.sgbs-timeline-item.inactive::before {
  background-color: #6e777a !important;
}
.sgbs-timeline-item.inactive .sgbs-timeline-day,
.sgbs-timeline-item.inactive .sgbs-timeline-date {
  color: #6e777a;
}

.sgbs-timeline:not(.sgbs-timeline-vertical) .sgbs-timeline-item.active {
  background: rgba(0, 0, 0, 0.05);
}

.sgbs-timeline-day {
  display: block;
  font-size: 0.875rem;
  text-transform: uppercase;
}

.sgbs-timeline-date {
  display: block;
  font-weight: 400;
}
.active .sgbs-timeline-date {
  font-weight: 500;
}

.sgbs-timeline-alternative .sgbs-timeline-item {
  padding: 0.25rem 1.5rem;
}
.sgbs-timeline-alternative .sgbs-timeline-item::before {
  content: "";
  display: block;
  height: 0.5rem;
  width: 0.5rem;
  background: currentColor;
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
}

.sgbs-timeline-vertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.sgbs-timeline-vertical .sgbs-timeline-item {
  border-bottom: none;
  padding-left: 1rem;
  border-left: 0.0625rem solid #000;
}
.sgbs-timeline-vertical .sgbs-timeline-item::after {
  bottom: auto;
  top: -0.25rem;
}
.sgbs-timeline-vertical .sgbs-timeline-day {
  margin-top: -0.5rem;
}
.sgbs-timeline-vertical:not(.sgbs-timeline-alternative) .sgbs-timeline-item:last-child {
  border-left: none;
}

.sgbs-footer-light {
  color: #6e777a;
  background-color: transparent;
}
.sgbs-footer-light .list-unstyled .h5 {
  font-size: 1.125rem;
}
.sgbs-footer-light .sgbs-footer-item {
  border: none;
  padding: 0;
  color: #6e777a;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}
.sgbs-footer-light .sgbs-footer-item:not(span):hover {
  color: #6e777a;
}

.sgbs-footer-dark {
  color: #949c9e;
  background-color: #303333;
}
.sgbs-footer-dark .list-unstyled .h5 {
  font-size: 1.125rem;
}
.sgbs-footer-dark .sgbs-footer-item {
  border: none;
  padding: 0;
  color: #949c9e;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}
.sgbs-footer-dark .sgbs-footer-item:not(span):hover {
  color: #949c9e;
}

.sgbs-footer-black {
  color: #70797a;
  background-color: #000;
}
.sgbs-footer-black .list-unstyled .h5 {
  font-size: 1.125rem;
}
.sgbs-footer-black .sgbs-footer-item {
  border: none;
  padding: 0;
  color: #70797a;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}
.sgbs-footer-black .sgbs-footer-item:not(span):hover {
  color: #70797a;
}

.upload-squares {
  width: 1rem;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.upload-squares::before, .upload-squares::after {
  content: "";
  width: 1rem;
  height: 1rem;
}
.upload-squares:first-child::before {
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
}
.upload-squares:first-child::after {
  border-bottom: 1px solid #ccc;
  border-left: 1px solid #ccc;
}
.upload-squares:last-child {
  right: 0;
  left: auto;
}
.upload-squares:last-child::before {
  border-top: 1px solid #ccc;
  border-right: 1px solid #ccc;
}
.upload-squares:last-child::after {
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
}

.upload-progress {
  height: 3px;
}

.badge.badge-discreet-success {
  color: #008b0a;
}

.badge.badge-discreet-warning {
  color: #b15e00;
}

.btn.btn-discreet-success,
.btn.btn-flat-success {
  color: #008b0a;
}
.btn.btn-discreet-success:hover,
.btn.btn-flat-success:hover {
  color: #008b0a;
}
.btn.btn-discreet-success:focus, .btn.btn-discreet-success.focus,
.btn.btn-flat-success:focus,
.btn.btn-flat-success.focus {
  color: #008b0a;
}
.btn.btn-discreet-success:not(:disabled):not(.disabled):active, .btn.btn-discreet-success:not(:disabled):not(.disabled).active,
.btn.btn-flat-success:not(:disabled):not(.disabled):active,
.btn.btn-flat-success:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
}

.btn.btn-discreet-warning,
.btn.btn-flat-warning {
  color: #b15e00;
}
.btn.btn-discreet-warning:hover,
.btn.btn-flat-warning:hover {
  color: #b15e00;
}
.btn.btn-discreet-warning:focus, .btn.btn-discreet-warning.focus,
.btn.btn-flat-warning:focus,
.btn.btn-flat-warning.focus {
  color: #b15e00;
}
.btn.btn-discreet-warning:not(:disabled):not(.disabled):active, .btn.btn-discreet-warning:not(:disabled):not(.disabled).active,
.btn.btn-flat-warning:not(:disabled):not(.disabled):active,
.btn.btn-flat-warning:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.75) !important;
}

.text-success:not(h1):not(.h1):not(h2):not(.h2):not(h3):not(.h3):not(.display-1):not(.display-2):not(.display-3):not(.display-4) {
  color: #008b0a !important;
}

.text-warning:not(h1):not(.h1):not(h2):not(.h2):not(h3):not(.h3):not(.display-1):not(.display-2):not(.display-3):not(.display-4) {
  color: #b15e00 !important;
}

.display-5.text-success,
.h4.text-success,
h4.text-success,
.h5.text-success,
h5.text-succes {
  color: #008b0a !important;
}

.display-5.text-warning,
.h4.text-warning,
h4.text-warning,
.h5.text-warning,
h5.text-warning {
  color: #b15e00 !important;
}

/*# sourceMappingURL=sg-bootstrap-standard.css.map */